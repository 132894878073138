import React, { useEffect } from 'react';
import { Flex, Grid, } from "@chakra-ui/react";
import SellerTable from './components/SellerTable';
import Header from './components/Header';
import { useHistory } from 'react-router-dom';

const AffiliateSeller = () => {
    let history = useHistory();

    useEffect(()=>{
        checkUserLogin();
    },[]);

    const checkUserLogin=()=>{
        let userToken = localStorage.getItem("token");

        if(!userToken){
            history.push({
                pathname: "/"
            });
        }
    }
    return (
        <Flex 
            direction='column' 
            pt={{ base: "70px", md: "75px" }}
            pl="10px"   
            pr='10px' 
        >
            <Grid
                templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
                gap='0px'>
                <Header />
                <SellerTable/>
            </Grid>

        </Flex>
    )
}

export default AffiliateSeller