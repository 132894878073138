import React, { useEffect, useState } from 'react';
import { PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { PaymentIntent } from 'components/API/AuthAPI';
import { Ck } from 'react-flags-select';
import { getAddUpdateCart } from 'components/API/AuthAPI';
import { getPlaceOrderDetails } from 'components/API/AuthAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

const ApplePay1 = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  let history = useHistory();
  const [paymentRequest, setPaymentRequest] = useState(null);
  // console.log("token", props.accounttoken)
  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Demo total',
        amount: 1999,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      // requestShipping:true,
      // disableWallets:["link"],

    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', (e) => {
      let ck;
      PaymentIntent()
        .then((res) => {
          ck = res.intent.client_secret
          const { paymentIntent, error: confirmError } = stripe.confirmCardPayment(
            res.intent.client_secret,
            { payment_method: e.paymentMethod.id },
            { handleActions: false }
          );

          if (confirmError) {

            e.complete('fail');
            Swal.fire({
              icon: 'error',
              title: 'Opps',
              text: 'Something went wrong with your payment!'
            })
            // console.log('fail');
          } else {

            // console.log('success');
            let email = e.paymentMethod.billing_details.email;
            let first_name = e.paymentMethod.billing_details.name;
            let last_name = e.paymentMethod.billing_details.name;
            let location = e.paymentMethod.billing_details.address.line1;
            let address = e.paymentMethod.billing_details.address.line2;
            let city = e.paymentMethod.billing_details.address.city;
            let zipcode = e.paymentMethod.billing_details.address.postal_code;
            let phone_number = e.paymentMethod.billing_details.phone ? e.paymentMethod.billing_details.phone : "9898989898";
            let comment = 'no-comment'
            let cart;
            getAddUpdateCart(props.accounttoken, props.productId, props.qty)
              .then((responseJson) => {
                if (responseJson) {
                  cart = responseJson.data.id
                  // console.log("product----101", responseJson.data.id)
                  // setCartId(responseJson.data.id)
                  cart = responseJson.data.id;
                  getPlaceOrderDetails(props.accounttoken, cart, email, first_name, last_name, location, address,
                    city, zipcode, phone_number, comment)
                    .then((responseJson) => {
                      if (responseJson) {
                        alert(responseJson.message);
                      }
                    })
                }
              })
            e.complete('success');
            history.push("./pay")

            // console.log("Payment Internt", paymentIntent)
            // console.log("type", e.paymentMethod.billing_details)
            
          }
        })

    
      // console.log('Client secret returned');


    });
  }, [stripe, elements]);

  return (
    <form id="payment-form">

      {paymentRequest && 
        
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      
      }

      {/* <StatusMessages messages={messages} /> */}


    </form>
  );
};

export default ApplePay1;