import { Flex, Image, Text } from '@chakra-ui/react';
import { wishlistText,categoriesText,galleryText} from 'assets/constants/strings';
import { Link } from 'react-router-dom';
import React from 'react'
import { AiOutlineHeart } from 'react-icons/ai';
import { BsHeart } from 'react-icons/bs';
import {MdManageSearch} from 'react-icons/md'
import cutLogo from "../../assets/svg/cut-logo.svg";


const GalleryFooter = (props) => {
  return (
    <div style={{boxSizing:'border-box'}}>
         <Flex
                    justifyContent='space-between'
                    className={props.disp ? 'gallery__footer' : ""}
                    // style={{ position: props.disp ? 'flex' : 'none' }}
                >
                    <Link to='/gallery'>
                    <Flex
                        flexDirection='column'
                        alignItems='center'
                        ml='2'
                        cursor={'pointer'}
                    >
                        <Image
                           className='galleryIcon'
                            src={cutLogo}
                        />
                        <Text
                            fontWeight={'black'}
                        >
                            {galleryText}
                        </Text>
                    </Flex>
                    </Link>
                    <Link to='/gallery-list'>
                        <Flex flexDirection='column'>
                            <MdManageSearch color="inherit" className='galleryIcon' />
                            <Text
                                fontWeight={'black'}
                            >
                                {categoriesText}
                            </Text>
                        </Flex>
                    </Link>
                    <Link to='/gallery-wishlist'>
                        <Flex
                            flexDirection='column'
                            mr='2'
                        >
                            <BsHeart color="inherit" className='galleryIconheart'/>
                            <Text
                                fontWeight={'black'}
                            >
                                {wishlistText}
                            </Text>
                        </Flex>
                    </Link>
                </Flex>
    </div>
  )
}

export default GalleryFooter