import React, { useEffect, useState } from 'react'
import { Text, Collapse, Flex, Image, useDisclosure, ChakraProvider } from '@chakra-ui/react'
import './paymodal.css';
import Card from "components/Card/Card.js";
import theme from 'theme/theme.js'
import { getAllStoreproducts, getListedCart, shippingFees } from '../API/AuthAPI'
import Emitter from 'Emitter';
import { useSelector } from 'react-redux';

function PaymentModal({ countryList }) {
    const { isOpen, onToggle } = useDisclosure();
    const [accountToken, setAccountToken] = useState();
    const [currencyExchange, setCurrencyExchange] = useState();
    const [currencyIcon, setCurrencyIcon] = useState();
    const [shipping, setShipping] = useState([]);
    const [varientPrice, setVarientPrice] = useState(0);
    const [vendor, setVendor] = useState([]);
    const [storeId] = useState();
    const [shippingWeight, setShippingWeight] = useState();
    const [finalTotalAmount, setFinalTotalAmount] = useState()
    const [getSummary, setGetSummary] = useState([])
    const cart1 = useSelector((state) => state.cart);

    useEffect(() => {
        let convertedCurrency = localStorage.getItem("convertedCurrency");
        let currencySymbol = localStorage.getItem("changeUser");
        if (convertedCurrency) {
            setCurrencyExchange(convertedCurrency)
        }
        if (currencySymbol) {
            setCurrencyIcon(currencySymbol);
        }
    }, [])

    useEffect(() => {
        const login = async () => {
            const accToken = await localStorage.getItem("loginToken");
            setAccountToken(accToken)
            if (accountToken) {
                getOrderSummaryDetails()
            }
        }
        login()
        getShippingFees();
    }, [accountToken])


   /**
    * This function calculates the total additional price of all variants in the cart and sets it as
    * the variant price.
    */
    const calculateVarientPrice = () => {
        if (getSummary) {
            const totalScore = getSummary?.cart_lines && getSummary?.cart_lines.map((item) => item.my_varient[0].additional_price).reduce((acc, score) => acc + score, 0);
            setVarientPrice(totalScore ? totalScore : 0)
        }
    }
    useEffect(() => {
        calculateVarientPrice();
    }, [getSummary])

    Emitter.emit("CART_ID", getSummary.id)
    sessionStorage.setItem("idCart", getSummary.id)
    const getOrderSummaryDetails = () => {
        getListedCart(accountToken).then(responseJson => {
            if (responseJson) {
                setGetSummary(responseJson.data || [])
                responseJson.data.cart_lines?.map((item) => {
                    setVendor(item.product_details.product_vendor);
                })
            }
        }).catch(err => console.log(err))
    }

  /**
   * This function retrieves shipping fees and sets them in the state.
   */
    const getShippingFees = () => {
        shippingFees().then((res) => {
            if (res) {
                setShipping(res.shipping_list)
            }
        })
    }


  /**
   * This function iterates through a list of shipping items and sets a fee based on the vendor's
   * shipping profile for a specific country.
   */
    const Calculate = () => {
        shipping.map((item) => {
            if (item.user == vendor) {
                if (item.vendor_shipping_profiles.length > 0) {
                    for (let i = 0; i <= item.vendor_shipping_profiles.length; i++) {
                        if (item.vendor_shipping_profiles[i]?.country == countryList) {
                            // console.log("filtered", item.vendor_shipping_profiles[i]);
                            // setFee(item.vendor_shipping_profiles[i]);
                        }
                    }
                }
            }
        })
        
    }
    useEffect(() => {
        Calculate();
    }, [countryList]);
    // const getProductList = () => {
    //     getAllStoreproducts().then(res => {
    //         let arr = []
    //         if (res) {
    //             let prodList = res.data;
    //             if (prodList?.length !== 0) {
    //                 getSummary?.cart_lines.map((ele) => {
    //                     prodList.map((item) => {
    //                         if (ele.product_details.id === item.id) {
    //                             setProduct([...product, ele])
    //                             arr = [...arr, item]
    //                             return ele;
    //                         }
    //                     })

    //                 })
    //             }

    //         }
    //         CalculateShipping(arr)
    //     })

    // }

    // useEffect(() => {
    //     getProductList()
    // }, [getSummary])

    // const CalculateShipping = (arr) => {
    //     let sumWithInitial;
    //     let weight = []
    //     let sum = 0
    //     let vendorsList = []
    //     if (arr.length > 0) {
    //         arr.map((ele) => {
    //             weight = [...weight, JSON.parse(ele.PRDWeight)];
    //             sum = sum + ele.PRDWeight
    //             if (shipping.length > 0) {
    //                 shipping.map((item) => {
    //                     if (ele.product_vendor == item.id) {
    //                         vendorsList = [...vendorsList, item]
    //                     }
    //                 })
    //                 setVendor(vendorsList)
    //             }
    //         })
    //         let sum = weight.reduce((partialSum, a) => partialSum + a, 0);
    //         setShippingWeight(sum);
    //         shippingCharge(vendorsList)
    //     }
    // }

    useEffect(() => {
        shippingCharge()
    }, [countryList])
    const [finalShipping, setFinalShipping] = useState([]);
    const shippingCharge = (vendorsList) => {
        if (vendorsList) {
            vendorsList?.map((item) => {
                if (item.id == storeId) {
                    item.vendor_shipping_profiles.map((ele) => {
                        if (countryList == ele.country) {
                            if (ele.weight_from >= shippingWeight && ele.weight_to <= shippingWeight) {
                                setFinalShipping(ele.shipping_fee);
                            } else {
                                setFinalShipping('FREE')
                            }
                        } else {
                            setFinalShipping('FREE')
                        }
                    })
                }
            })
        }
        if (finalShipping && finalShipping == 'FREE') {

            setFinalTotalAmount((parseFloat(getSummary.sub_total_amount || 0 * currencyExchange).toFixed(2)))
            Emitter.emit("FINALTOTAL_AMOUNT", (getSummary.sub_total_amount || 0 * currencyExchange))
            localStorage.setItem("TotalAmount", (getSummary.sub_total_amount || 0 * currencyExchange))
        }
        else
            setFinalTotalAmount((parseFloat((getSummary.sub_total_amount + Number(finalShipping)) || 0 * currencyExchange).toFixed(2)))
        Emitter.emit("FINALTOTAL_AMOUNT", ((getSummary.sub_total_amount + Number(finalShipping)) || 0 * currencyExchange))
        localStorage.setItem("TotalAmount", ((getSummary.sub_total_amount + Number(finalShipping)) || 0 * currencyExchange))
    }
    return (
        <ChakraProvider theme={theme} resetCSS={false} className='payment_wrapper'>
            <Card
                bg='#fff'
                p='10'
                // w='800px'
                className='checkout_order_summary'
                display={{ sm: "none", md: "none", lg: "flex" }}>
                <Text fontSize='2xl' fontWeight='bold' >ORDER SUMMARY</Text>
                <Flex mt='10' flexDir={'column'} >

                    {
                        accountToken ?
                            <>
                                <Flex justifyContent='space-between'>
                                    <Text
                                        className='order_summery_text'
                                    >
                                        SUBTOTAL (INCL VAT)
                                    </Text>
                                    <Text
                                        className='order_summery_text'
                                    >
                                        {(parseFloat((getSummary?.sub_total_amount + varientPrice) || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}

                                    </Text>
                                    {/* {console.log(getSummary)} */}
                                </Flex>
                                <Flex justifyContent='space-between'>
                                    <Text
                                        className='order_summery_text'
                                    >
                                        SHIPPING & HANDLING
                                    </Text>
                                    <Text
                                        className='order_summery_text'
                                    >FREE</Text>

                                    {/* <Text
                                        className='order_summery_text'
                                    >{parseFloat(Number(fee?.shipping_fee || 0 ) * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}  {currencyIcon ? currencyIcon : "€"}</Text> */}

                                </Flex>
                                <Flex justifyContent='space-between' mt='5' fontWeight='bold'>
                                    <Text
                                        className='order_summery_text'
                                    >
                                        TOTAL
                                    </Text>
                                    {finalShipping && finalShipping == 'FREE' ?
                                        <Text
                                            className='order_summery_text'
                                        >
                                            {(parseFloat((getSummary.sub_total_amount + varientPrice) || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}

                                        </Text>
                                        :
                                        <Text
                                            className='order_summery_text'
                                        >
                                            {(parseFloat(((getSummary.sub_total_amount + varientPrice) + Number(finalShipping)) || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}

                                        </Text>
                                    }
                                </Flex>
                            </>
                            :
                            <>
                                <Flex justifyContent='space-between'>
                                    <Text
                                        className='order_summery_text'
                                    >
                                        SUBTOTAL (INCL VAT)
                                    </Text>
                                    <Text
                                        className='order_summery_text'
                                    >
                                        {(parseFloat(cart1?.cartTotalAmount || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}

                                    </Text>
                                </Flex>
                                <Flex justifyContent='space-between'>
                                    <Text
                                        className='order_summery_text'
                                    >
                                        SHIPPING & HANDLING
                                    </Text>

                                    <Text
                                        className='order_summery_text'
                                    >
                                        FREE
                                    </Text>
                                </Flex>
                                <Flex justifyContent='space-between' mt='5' fontWeight='bold'>
                                    <Text
                                        className='order_summery_text'
                                    >
                                        TOTAL
                                    </Text>
                                    {finalShipping && finalShipping == 'FREE' ?
                                        <Text
                                            className='order_summery_text'
                                        >
                                            {(parseFloat(cart1?.cartTotalAmount || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}
                                        </Text>
                                        :
                                        <Text
                                            className='order_summery_text'
                                        >
                                            {(parseFloat((cart1?.cartTotalAmount + Number(finalShipping)) || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}
                                        </Text>
                                    }
                                </Flex>
                            </>
                    }
                </Flex>
                {
                    accountToken && (getSummary.cart_lines)?.length || (getSummary.cart_lines) !== undefined
                        ? (getSummary.cart_lines).flat(3).map((summary, index) => {
                            return (
                                <>
                                    <Text border='1px solid gray' w='100%' mt='5'></Text>
                                    <Flex
                                        key={index}
                                        justifyContent='space-between'
                                        mt='5'
                                    >
                                        <Image
                                            src={"https://adminmyboutiqe.progfeel.co.in" + summary.product_details.product_image}
                                            className='prod_img_checkout'
                                            w='150px'
                                            h='200px'
                                        />
                                        <Flex flexDirection='column' p='2'>
                                            <Text fontSize='lg' noOfLines={[1, 2, 3]}>
                                                {summary.product_details.product_name}

                                            </Text>

                                            <Text mt='3' fontSize='lg' noOfLines={[1, 2, 3]}>
                                                Variant: {summary.my_varient[0].product_values}
                                            </Text>
                                            <Text
                                                mt='3'
                                                fontSize='lg'
                                            >
                                                Quantity: {summary.quantity}
                                            </Text>
                                        </Flex>
                                        <Text
                                            mt='2'
                                            maxW={'120px'}
                                            width='100%'
                                        >
                                            {(parseFloat((summary.product_details.PRDPrice + summary.my_varient[0].additional_price) * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}
                                        </Text>
                                    </Flex>
                                </>
                            )
                        }) :
                        cart1.cartItems.map((summary, index) => {
                            return (
                                <>
                                    <Text border='1px solid gray' w='100%' mt='5'></Text>
                                    <Flex
                                        key={index}
                                        justifyContent='space-between'
                                        mt='5'
                                    >
                                        <Image
                                            src={"https://adminmyboutiqe.progfeel.co.in" + summary.product_image}
                                            className='prod_img_checkout'
                                            w='150px'
                                            h='200px'
                                        />
                                        <Flex flexDirection='column' p='2'>
                                            <Text fontSize='lg' noOfLines={[1, 2, 3]}>
                                                {summary.product_name}
                                            </Text>
                                            {summary.product_varient.map((ele) => {
                                                return (
                                                    <>
                                                        {summary.varient === ele.id ?
                                                            <Text mt='3' fontSize='lg' noOfLines={[1, 2, 3]}>
                                                                Variant: {ele.product_values}
                                                            </Text>
                                                            :
                                                            ""
                                                        }
                                                    </>
                                                )

                                            })}
                                            <Text
                                                mt='3'
                                                fontSize='lg'
                                            >
                                                Quantity: {summary.cartQuantity}
                                            </Text>

                                        </Flex>
                                        {summary.product_varient.map((ele) => {
                                            return (
                                                <>
                                                    {summary.varient === ele.id ?
                                                        <Text
                                                            mt='2'
                                                        >
                                                            {(parseFloat((summary.PRDPrice + ele.additional_price) * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}
                                                        </Text>
                                                        :
                                                        ""
                                                    }
                                                </>
                                            )

                                        })}
                                    </Flex>
                                </>
                            )
                        })
                }
            </Card>
            <Card
                bg='#fff'
                p='10'
                display={{ sm: "flex", md: "flex", lg: "none" }}
                mb='5'
            >
                <Flex fontWeight='bold' justifyContent='space-between' onClick={onToggle}>
                    <Text fontSize='2xl' bg='#fff'
                        className='product_tag_name'>
                        ORDER SUMMARY
                    </Text>
                    <Flex bg='#fff' >
                        {
                            accountToken ?
                                <Text fontSize='2xl'
                                    className={isOpen ? "checkout_hide" : "checkout_show"}
                                >{(Number(getSummary.sub_total_amount))} {currencyIcon ? currencyIcon : "€"}</Text>
                                :
                                <Text
                                    fontSize='2xl'
                                    className={isOpen ? "checkout_hide" : "checkout_show"}
                                >{(Number(cart1?.cartTotalAmount))} {currencyIcon ? currencyIcon : "€"}</Text>
                        }
                        {
                            !isOpen ?
                                <span className='checkout_order_arrow_down'></span>
                                :
                                <span className='checkout_order_arrow_focus'></span>
                        }
                    </Flex>
                </Flex>
                <Collapse in={isOpen} animateOpacity>
                    {
                        accountToken && (getSummary.cart_lines)?.length || (getSummary.cart_lines) !== undefined
                            ? (getSummary.cart_lines).flat(3).map((summary, index) => {
                                return (
                                    <>
                                        <Flex
                                            key={index}
                                            justifyContent='space-between'
                                            mt='10'
                                            className='checkout_products'
                                        >
                                            <Image
                                                src={"https://adminmyboutiqe.progfeel.co.in" + summary.product_details.product_image}
                                                w='100px'
                                                h='100px'
                                            />
                                            <Flex flexDirection='column' p='2' >
                                                <Text fontSize='lg' noOfLines={[1, 2, 3]}>
                                                    {summary.product_details.product_name}

                                                </Text>
                                                <Text mt='3' fontSize='lg' noOfLines={[1, 2, 3]}>
                                                    Variant: {summary.my_varient[0].product_values}
                                                </Text>
                                                <Text
                                                    mt='3'
                                                    fontSize='lg'
                                                >
                                                    Quantity: {summary.quantity}
                                                </Text>
                                            </Flex>
                                            <Text mt='2'>{(parseFloat(summary.product_details.PRDPrice * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}</Text>
                                        </Flex>
                                        <Text border='1px solid gray' w='100%' mt='5'></Text>
                                    </>
                                )
                            }) :
                            cart1.cartItems.map((summary, index) => {
                                return (
                                    <>
                                        <Flex
                                            key={index}
                                            justifyContent='space-between'
                                            mt='10'
                                            className='checkout_products'
                                        >
                                            <Image
                                                src={"https://adminmyboutiqe.progfeel.co.in" + summary.product_image}
                                                w='100px'
                                                h='100px'
                                            />
                                            <Flex flexDirection='column' p='2' >
                                                <Text fontSize='lg' noOfLines={[1, 2, 3]}>
                                                    {summary.product_name}
                                                </Text>
                                                {/* <Text mt='3' fontSize='lg' noOfLines={[1, 2, 3]}>
                                                    Variant: {summary.product_varient[0].product_values}
                                                </Text> */}
                                                {summary.product_varient.map((ele) => {
                                                    return (
                                                        <>
                                                            {summary.varient === ele.id ?
                                                                <Text mt='3' fontSize='lg' noOfLines={[1, 2, 3]}>
                                                                    Variant: {ele.product_values}
                                                                </Text>
                                                                :
                                                                ""
                                                            }
                                                        </>
                                                    )

                                                })}
                                                <Text
                                                    mt='3'
                                                    fontSize='lg'
                                                >
                                                    Quantity: {summary.cartQuantity}
                                                </Text>
                                            </Flex>
                                            {summary.product_varient.map((ele) => {
                                                return (
                                                    <>
                                                        {summary.varient === ele.id ?
                                                            <Text
                                                                mt='2'
                                                            >
                                                                {(parseFloat((summary.PRDPrice + ele.additional_price) * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}
                                                            </Text>
                                                            :
                                                            ""
                                                        }
                                                    </>
                                                )

                                            })}
                                            {/* <Text mt='2'>{(parseFloat(summary.PRDPrice * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}</Text> */}
                                        </Flex>
                                        <Text border='1px solid gray' w='100%' mt='5'></Text>
                                    </>
                                )
                            })
                    }

                    {
                        accountToken ?
                            <>
                                <Flex mt='10' justifyContent='space-between'>
                                    <Text fontSize='xl'>SUBTOTAL (INCL VAT)</Text>
                                    <Text fontSize='xl' >{(parseFloat(Number(getSummary.sub_total_amount * currencyExchange)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}</Text>
                                </Flex>
                                <Flex justifyContent='space-between'>
                                    <Text fontSize='xl' >SHIPPING & HANDLING</Text>
                                    <Text fontSize='xl' >FREE </Text>
                                </Flex>
                                <Flex justifyContent='space-between' mt='5' fontWeight='bold'>
                                    <Text fontSize='xl' >TOTAL</Text>
                                    {finalShipping && finalShipping == 'FREE' ?
                                        <Text fontSize='xl' >
                                            {(parseFloat(getSummary.sub_total_amount || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}
                                        </Text>
                                        :
                                        <Text fontSize='xl' >
                                            {(parseFloat((getSummary.sub_total_amount + Number(finalShipping)) || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}
                                        </Text>
                                    }
                                </Flex>
                            </>
                            :
                            <>
                                <Flex mt='10' justifyContent='space-between'>
                                    <Text fontSize='xl'>SUBTOTAL (INCL VAT)</Text>
                                    <Text fontSize='xl' >{(parseFloat(Number(cart1?.cartTotalAmount * currencyExchange)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}</Text>
                                </Flex>
                                <Flex justifyContent='space-between'>
                                    <Text fontSize='xl' >SHIPPING & HANDLING</Text>
                                    <Text fontSize='xl' >FREE </Text>
                                </Flex>
                                <Flex justifyContent='space-between' mt='5' fontWeight='bold'>
                                    <Text fontSize='xl' >TOTAL</Text>
                                    {finalShipping && finalShipping == 'FREE' ?
                                        <Text fontSize='xl' >
                                            {(parseFloat(cart1?.cartTotalAmount || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}
                                        </Text>
                                        :
                                        <Text fontSize='xl' >
                                            {(parseFloat((cart1?.cartTotalAmount + Number(finalShipping)) || 0 * currencyExchange).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : "€"}
                                        </Text>
                                    }
                                </Flex>
                            </>
                    }
                </Collapse>
            </Card>
        </ChakraProvider>
    )
}

export default PaymentModal
