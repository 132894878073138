import {
    Flex, Text, Grid, SimpleGrid, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl,
    FormLabel, Input, useDisclosure, useToast, Box, Stack
} from '@chakra-ui/react'
import QRCode from "qrcode.react";
import React, { useState, useRef, useEffect } from 'react'
import ProductTable from './ProductTable';
import { getSellerStockDetails } from 'components/API/AuthAPI';
import { Translate } from 'components/DeepL/Translate';
import Select from 'react-select';
import "./styles.css"
import Emitter from 'Emitter';
import { qrCode, download, selectStore, shareboutiques, viewStore, storeLink as selectStoreLink } from 'assets/constants/strings';

const Header = () => {
    const { isOpen: isSecondModalOpen, onOpen: onSecondModalOpen, onClose: onSecondModalClose } = useDisclosure()
    const initialRef = useRef(null)
    const finalRef = useRef(null)
    const [boutiqName, setBoutiqName] = useState();
    const [store, setStore] = useState([]);
    const [sellerData, setSelletData] = useState();
    const [token, setToken] = useState();
    const [sellerId, setSellerId] = useState();
    const [selectedOption] = useState();
    const [storeLink, setStoreLink] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("GB")
    const [lang, setLang] = useState()
    const toast = useToast()
    let storeBaseUrl = 'https://myboutique.progfeel.co.in/'
    useEffect(() => {
        const userToken = localStorage.getItem("token");
        if (userToken) {
            setToken(userToken);
        }
        if (token) {
            getSellerList()
        }
    }, [token])

    useEffect(() => {
        if (!boutiqName == '') {
            getSellerId()
        }
    }, [boutiqName]);

    const getSellerId = () => {

        const data = sellerData.filter(function (item) {
            return item.store_name == boutiqName;
        }).map(function ({ store_name, seller_store, boutique_store_url, slug }) {
            return { store_name, seller_store, boutique_store_url, slug };
        });
        setSellerId(data)
        if (boutiqName) {
            setStoreLink(storeBaseUrl + data[0].slug);
        } else {
            setStoreLink(storeBaseUrl);
        }
    }


    const styles = {
        option: (styles) => ({
            ...styles,
            "&:hover": {
                backgroundColor: "#219691",
                color: "#FFFFFF",
                height: '100%',
                width: '100%',

            }
        })
    };
    let options = store.map(function (BoutiqStore) {
        return { value: BoutiqStore, label: BoutiqStore };
    })

    useEffect(() => {
        let language1 = localStorage.getItem('language');
        if (language1) {
            setSelectedLanguage(language1);
        } else {
            setSelectedLanguage("GB");
        }
    }, [lang])
    Emitter.on("LANGUAGE", (lang) => setLang(lang));



    const getSellerList = () => {
        getSellerStockDetails(token)
            .then((responseJson) => {
                let seller_list = responseJson.data;
                setSelletData(responseJson.data);
                const map1 = seller_list.map(item => item.store_name);

                setStore(map1);

            })
    }

    const downloadQRCode = () => {
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${storeLink}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleCopy = () => {
        if (boutiqName) {


            navigator.clipboard.writeText(storeLink)
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        Link copied
                    </Box>
                ),
            })

        }
        else {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        Please select store!
                    </Box>
                ),
            })
        }
    }



    const handleChange = (selectedOption) => {
        setBoutiqName(selectedOption.value)
    }

    return (
        <Flex direction="column">
            <Grid pb="20px">
                <Text
                    color="#219691"
                    fontWeight={"bold"}
                    fontSize={{ lg: "24px" }}
                    py="20px"
                >
                    {selectedLanguage !== "GB" ? Translate("AFFILIATE PRODUCTS") : "AFFILIATE PRODUCTS"}
                </Text>
            </Grid>
            <Grid>
                <Text
                    color="#219691"
                    px="10px"
                    fontSize={{ base: '14px', md: '15px', lg: '16px' }}
                >
                    {/* { Translate("Select Boutiq") ? Translate("Select Boutiq") : "Select Boutiq"} */}
                    Select Boutiq
                </Text>

                <Grid
                    display="flex"
                    py="10px"
                >

                    <Stack
                        display={{ sm: 'none', md: 'flex', lg: 'flex' }}
                    >
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            value={selectedOption}
                            isSearchable={false}
                            onChange={handleChange}
                            placeholder='Select Store'
                            options={options}
                            styles={styles}

                        />
                    </Stack>
                    <Stack
                        display={{ sm: 'flex', md: 'none', lg: 'none' }}
                    >
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            value={selectedOption}
                            onChange={handleChange}
                            isSearchable={false}
                            // placeholder='Select Store'
                            options={options}
                            styles={styles}

                        />
                    </Stack>

                    <Grid
                        ml={{ base: '5px', md: '60px', lg: '30px' }}
                    >
                        <Button
                            colorScheme='none'
                            variant='outline'
                            w='100%'
                            bg="#219691"
                            color="white"
                            fontSize={{ base: '12px', md: '15px', lg: '16px' }}
                            onClick={(e) => {
                                if (boutiqName) {
                                    window.open(`${window.location.origin}/${sellerId[0].slug}`, '_blank', 'noopener,noreferrer')
                                }
                                else {
                                    toast({
                                        position: 'top',
                                        render: () => (
                                            <Box
                                                color='white'
                                                p={3} bg='#219691'
                                                textAlign={'center'}
                                                fontWeight='bold'
                                            >
                                                {selectStore}
                                            </Box>
                                        ),
                                    })
                                }
                            }}
                        >
                            {/* { Translate("View Store") ? Translate("View Store") : "View Store"} */}
                            {viewStore}
                        </Button>


                    </Grid>
                    <Grid
                        ml={{ base: '5px', md: '25px', lg: '10px' }}>
                        <Button
                            onClick={handleCopy}
                            colorScheme='none'
                            variant='outline'
                            w='100%'
                            bg="#219691"
                            color="white"
                            fontSize={{ base: '12px', md: '15px', lg: '16px' }}

                        >
                            {/* { Translate("Store Link") ? Translate("Store Link") : "Store Link"} */}
                            {selectStoreLink}
                        </Button>
                    </Grid>
                    <Grid
                        ml={{ base: '5px', md: '25px', lg: '10px' }}>
                        <Button
                            onClick={() => {
                                if (boutiqName) {
                                    onSecondModalOpen()
                                }
                                else {
                                    toast({
                                        position: 'top',
                                        render: () => (
                                            <Box
                                                color='white'
                                                p={3} bg='#219691'
                                                textAlign={'center'}
                                                fontWeight='bold'
                                            >
                                                {selectStore}
                                            </Box>
                                        ),
                                    })
                                }
                            }}
                            colorScheme='none'
                            variant='outline'
                            w='100%'
                            bg="#219691"
                            color="white"
                            fontSize={{ base: '12px', md: '15px', lg: '16px' }}
                        >
                            {/* { Translate("QR Code") ? Translate("QR Code") : "QR Code"} */}
                            {qrCode}
                        </Button>

                    </Grid>
                </Grid>
            </Grid>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isSecondModalOpen}
                onClose={onSecondModalClose}
                size={"sm"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        {/* { Translate("Share Boutiq Store") ? Translate("Share Boutiq Store") : "Share Boutiq Store"} */}
                        {shareboutiques}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Grid
                            columns={{ sm: 1, md: 1, xl: 1 }}
                            spacing='24px'
                            justifyContent="center"
                        >
                            <QRCode
                                id="qr-gen"
                                value={storeLink}
                                size={290}
                                level={"Q"}
                                includeMargin={true}
                            />
                            <Button
                                bg="#219691"
                                color="white"
                                colorScheme="none"
                                mt="20px"
                                onClick={downloadQRCode}
                            >
                                {/* { Translate("Download") ? Translate("Download") : "Download"} */}
                                {download}
                            </Button>
                        </Grid>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <ProductTable boutiqName={boutiqName} sellerId={sellerId} storeLink={storeLink} />
        </Flex>
    )
}

export default Header

