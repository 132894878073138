import React, { useEffect, Suspense } from "react";
import { ChakraProvider, Text} from '@chakra-ui/react'
import ReactDOM from "react-dom";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';
import Serviceworker from "./serviceworkers.js"
import { onMessageListener } from "./firebaseInit";
import App from "./App";
import ContextModal from "components/AffiliateSeller/components/ContextModal.js";
// import store from "components/redux/store.js";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import cartReducer, { getTotals } from "./Slices/cartslice";
import launchpadReducer, {productLoad} from "./Slices/launchpadslice";
import theme from "theme/theme.js";

const store = configureStore({
    reducer: {
      cart: cartReducer,
      launchpad: launchpadReducer,
    },
   
  });

  store.dispatch(getTotals());
//   store.dispatch(productLoad());
// import RTLLayout from "layouts/RTL.js";

// useEffect(()=>{
//   const msg=firebase.messaging();
//   msg.requestPermission().then(()=>{
//     return msg.getToken();
//   }).then((data)=>{
//     console.warn("token",data)
//   })
// })

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ["EN", "DE"],
        fallbackLng: "DE",
        debug: false,
        // Options for language detector
        detection: {
            order: ["path", "cookie", "htmlTag"],
            caches: ["cookie"],
        },
        // react: { useSuspense: false },
        backend: {
            loadPath: "/assets/locales/{{lng}}/translation.json",
        },
    })

const loadingMarkup = (
    <div className="py-4 text-center">
        <Text>Please Wait</Text>
        {/* <h3>Loading..</h3> */}
    </div>
)





ReactDOM.render(
    <Suspense fallback={loadingMarkup}>
        <ChakraProvider>
            <ContextModal>

                <Provider store={store}>
                <App />
                </Provider>
            </ContextModal>
        </ChakraProvider>
    </Suspense>,
    document.getElementById("root")

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
Serviceworker();



