// Chakra imports
import {
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconBox from "components/Icons/IconBox";
import React from "react";

const MiniStatistics = ({ title, amount, percentage, icon, month }) => {
  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card maxH='150px'>
      <CardBody>
        <Flex flexDirection='row' align='center' display="flex" pos="relative" alignItems="center" justify='center' w='100%' mt="1vh">
          <Flex pos="sticky">
            <IconBox
              h={"60px"}
              w={"60px"}
              bg={"#219691"}
            >
              {icon}
            </IconBox>
          </Flex>
          <Stat me='auto'>
            <Flex
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {/* <Tooltip bg='gray.300' color='black' label={title}> */}
                <StatLabel
                  fontSize={{sm:'md',md:"md",lg:"1vw"}}
                  color='gray.600'
                  fontWeight='bold'
                  textAlign='center'
                  // noOfLines={1}
                  pb='.1rem'>
                  {title}
                </StatLabel>
              {/* </Tooltip> */}
            </Flex>
            <Flex
              display="flex"
              justifyContent="center"
            >
              <StatNumber fontSize='xl' fontWeight="extrabold" color={textColor}>
                {amount}
              </StatNumber>

            </Flex>
            <Flex>
              <StatHelpText
                alignSelf='flex-end'
                justifySelf='flex-end'
                color='gray.400'
                m='0px'
                fontWeight='bold'
                ps='3px'
                fontSize='sm'>
                {month}
              </StatHelpText>
            </Flex>

          </Stat>

        </Flex>
      </CardBody>
    </Card>
  );
};

export default MiniStatistics;
