import React, { useState, useEffect, useContext } from 'react';
import AuthLayout from "layouts/Auth.js";
import StoreLayout from "layouts/store.js"
import AdminLayout from "layouts/Admin.js";
import TestLayout from "layouts/Test.js";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, useLocation } from "react-router-dom";
import { onMessageListener, getToken } from "./firebaseInit";
import addNotification from 'react-push-notification';
import { initReactI18next } from "react-i18next";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next"
import './App.css';
import Homepage from 'components/home/Homepage';
import ProductDetails from 'components/product/ProductDetails';
import PaymentSuccessMsg from 'components/payment/PaymentSuccessMsg';
import AffiliateInvitation from 'components/EmailConformation/AffiliateInvitation';
import ConfirmNewEmail from 'components/EmailConformation/ConfirmNewEmail';
import Registration from 'components/EmailConformation/Registration';
import InviteSeller from 'components/InviteSeller/InviteSeller';
import OrderDetails from 'components/AffiliateOrders/components/OrderDetails';
import Checkout from './components/checkout/Checkout';
import ResetPassword from 'components/ResetPassword/ResetPassoword';
import ResetPassword1 from 'components/ResetPassword/ResetPassword1';
import Registration1 from 'components/EmailConformation/Registration1';
import ConfirmNewEmail1 from 'components/EmailConformation/ConfirmNewEmail1';
import ReturnOrder from 'components/AffiliateEmailConformation/ReturnOrder';
import BuyerOrderConformation from 'components/AffiliateEmailConformation/BuyerOrderConformation';
import BuyerReturnConformation from 'components/AffiliateEmailConformation/BuyerReturnConformation';
import AffiliateInviteAffiliate from 'components/EmailConformation/AffiliateInviteAffiliate';
import SellerInvitation from 'components/EmailConformation/SellerInvitation';
import Launchpad from 'components/Launchpad';
import GalleryPage from 'components/gallery/GalleryPage';
import GalleryList from 'components/gallery/GalleryList';
import GetGalleryCategory from 'components/gallery/GetGalleryCategory';
import GalleryWishList from 'components/gallery/GalleryWishList';
import { ResetAccPassword } from 'components/header/ResetAccPassword';
import AffiliateCookies from 'components/Cookies/AffiliateCookies';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { forSignupModal } from 'components/AffiliateSeller/components/ContextModal';
import LocalizedStrings from 'react-localization';
import Language from './Localization/Language.json'
import SignIn3 from 'views/Auth/SignIn3';
import PaymentFailureMsg from 'components/PaymentFailure/PaymentFailureMsg';
import GalleryDesktopView from 'components/galleryDesktopView/GalleryDesktopPage';
import ProductDetails2 from 'components/product/ProductDetails2';
import Carousel from 'components/galleryDesktopView/Carousel';
import SignUp3 from 'views/Auth/SignUp3';
import ForgotPassword1 from 'views/Auth/ForgotPassword1';
import VerifyEmail from 'views/Auth/VerifyEmail';
import Loading from 'components/PlaceholderLoading/Loading';



let strings = new LocalizedStrings(Language);


function App({ categoryRender }) {
  const [home, setHome] = useState({
    language: 'en'
  })

  strings.setLanguage(home.language);

  const [getName, setGetName] = useState()


  const [state, setState] = useState({
    headerdata: ""
  })



  const handleCallback1 = (childData) => {

    setState({ headerdata: childData })

  }



  let path = location.pathname;

  let fields = path.split('/');

  useEffect(() => {
    setGetName(featureContext.categoryRender);
    handleCallback1(getName)
  }, [getName])


  const featureContext = useContext(forSignupModal)

  return (
    <>
      <AffiliateCookies />
      <GoogleOAuthProvider clientId="33636003832-qoekiat1irk8m8njvu162msasj2ffash.apps.googleusercontent.com">
        <BrowserRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/store`} component={StoreLayout} />
            <Route path={`/affiliate`} component={AdminLayout} />
            <Route path={'/test'} component={TestLayout} />
            <Route path={'/caro'} component={Carousel} />
            <Route path='/product-details/:productId' component={ProductDetails2} />
            <Route path='/pay' component={PaymentSuccessMsg} />
            <Route path='/failure' component={PaymentFailureMsg} />
            <Route path="/forgotpassword" component={ForgotPassword1} />
            <Route path='/verifyemail' component={VerifyEmail} />
            <Route path="/forgot-password" component={ResetPassword} />
            <Route path="/resetpassword" component={ResetPassword1} />
            <Route path="/registrate" component={Registration} />
            <Route path="/emailregistation" component={Registration1} />
            <Route path="/affiliate-invitation" component={AffiliateInvitation} />
            <Route path="/affiliate-invites-affiliate" component={AffiliateInviteAffiliate} />
            <Route path="/seller-invites" component={SellerInvitation} />
            <Route path="/confirmEmail" component={ConfirmNewEmail} />
            <Route path="/verifynewEmail" component={ConfirmNewEmail1} />
            <Route path="/seller-invitation" component={InviteSeller} />
            <Route path="/signup" component={SignUp3} />
            <Route path="/signin" component={SignIn3} />
            <Route path="/return-order" component={ReturnOrder} />
            <Route path="/buyer-order" component={BuyerOrderConformation} />
            <Route path="/buyer-return" component={BuyerReturnConformation} />
            <Route path="/launchpad" component={Launchpad} />
            <Route path="/orderDetails" component={OrderDetails} />
            <Route path="/checkout" component={Checkout} />
            <Route path="/gallery" component={GalleryPage} />
            {/* <Route path="/galleryDesktop" component={GalleryDesktopView} /> */}
            <Route path="/gallery-list" component={GalleryList} />
            <Route path="/gallery-category" component={GetGalleryCategory} />
            <Route path="/gallery-wishlist" component={GalleryWishList} />
            <Route path='/loading' component={Loading}/>
            <Route path="/account-password/forgot-password" component={ResetAccPassword} />
            <Route path={fields[1] == '' || null || undefined ? "/product-details" : `/${featureContext.categoryRender}`} component={Homepage} handleCallback1={handleCallback1} />

            {/* <Route path="/product-info" component={PaymentModal} /> */}
            {/* <Route path={"/admin/affiliateorderdetails"} component={OrderDetails} /> */}
            <Redirect from={`/`} to="/signin" />
          </Switch>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;