import { Flex } from '@chakra-ui/react'
import React from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";

const AffiliateCookies = () => {
  return (
   <Flex>
    <CookieConsent
  location="bottom"
  buttonText="Yes, I Accept"
  cookieName="MyBoutiqCookies"
  style={{ background: "#219691" }}
  buttonStyle={{ color: "#000000", fontSize: "13px" }}
  expires={150}
  enableDeclineButton
  onDecline={() => {
        
  }}
>
  MyBoutiq asks you to accept cookies for performance. Social media and advertising purposes. Social media
   and advertising cookies for third parties are used to offer you social media functionalities and personalised
   ads. To get more information or amend your preferences.
   Do you accept these cookies and the processing of personal data invloved.{" "}
  {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
</CookieConsent>
   </Flex>
  )
}

export default AffiliateCookies