import React, { useRef, useState, useEffect } from 'react'
import {
    Flex, Text, Image, Button, Box, useToast, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
} from '@chakra-ui/react'
import { getAddUpdateCart, } from '../API/AuthAPI'
import { AddIcon, MinusIcon, ArrowForwardIcon, CloseIcon } from '@chakra-ui/icons'
import './mainHeader.css'
import { useDispatch, useSelector } from "react-redux";
import { addToCart, decreaseCart, getTotals, removeFromCart, } from "../../Slices/cartslice";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { myCart, checkout, SubTotal } from 'assets/constants/strings'

export function AddToCart({ cart, onClose, userId, currencyIcon }) {
    const dispatch = useDispatch();
    const [cartId, setCartId] = useState()
    const [accountToken, setAccountToken] = useState()
    const [getorderId, setgetOrderId] = useState()
    const cart1 = useSelector((state) => state.cart);
    const [currencyExchange, setCurrencyExchange] = useState();
    const btnRef = useRef()
    const { cartTotalQuantity } = useSelector((state) => state.cart);
    const toast = useToast()
    let history = useHistory();

    useEffect(() => {
        dispatch(getTotals());
    }, [cart1, dispatch]);

    /**
     * The function handles adding a product to the cart and updates the cart quantity if the user is
     * logged in.
     */
    const handleAddToCart = (product) => {

        dispatch(addToCart(product));
        if (accountToken) {
            getAddUpdateCart(accountToken, product.id, product.cartQuantity + 1, product.varient)
        }
    };

    /**
     * This function handles decreasing the quantity of a product in the cart and updates the cart in
     * the backend if the user is logged in.
     */
    const handleDecreaseCart = (product) => {
        dispatch(decreaseCart(product));
        if (accountToken) {
            getAddUpdateCart(accountToken, product.id, product.cartQuantity - 1, product.varient)
        }
    };

    /**
     * The function handles removing a product from the cart and updates the cart in the backend if the
     * user is logged in.
     */
    const handleRemoveFromCart = (product) => {
        dispatch(removeFromCart(product));
        if (accountToken) {
            getAddUpdateCart(accountToken, product.id, 0, product.varient)
        }
    };

    useEffect(() => {
        let exchange = localStorage.getItem('convertedCurrency');
        setCurrencyExchange(exchange ? exchange : 1)
    }, [currencyExchange])


    useEffect(() => {
        const accToken = localStorage.getItem("loginToken")
        setAccountToken(accToken)
        let storeToken = localStorage.getItem("storeId")
        setCartId(storeToken)
        let homeorderid = localStorage.getItem("orderId")
        setgetOrderId(homeorderid)
    }, [accountToken, cartId, getorderId, userId, cart])

    /**
     * This function displays a toast message indicating that the cart is empty.
     */
    const handleShowError = () => {
        toast({
            position: 'top',
            render: () => (
                <Box
                    color='white'
                    p={3} bg='#219691'
                    textAlign={'center'}
                    fontWeight='bold'
                >
                    Your cart is empty
                </Box>
            ),
        })
    }

    return (
        <>
            <div>
                <Drawer
                    size={"md"}
                    isOpen={cart}
                    placement='right'
                    onClose={onClose}
                    finalFocusRef={btnRef}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton mt='2' />
                        <DrawerHeader className='home_drawer_header' >
                            <div className='header_content'>

                                <Text fontWeight='black' fontSize='2xl' mr='4' >
                                    {myCart}
                                </Text>
                                <span ><ArrowForwardIcon /></span>
                                <Text ml='5'
                                    fontWeight='light'
                                    fontSize='2xl'>
                                    {checkout}
                                </Text>
                            </div>
                        </DrawerHeader>
                        <DrawerBody>
                            <Flex
                                flexDir={'column'}
                            >
                                {cart1.cartItems.map((cartItem, i) => {
                                    return (
                                        <Flex
                                            className='home_wrapper_cart'
                                            mt='5'
                                            gap='5'
                                            key={i}
                                        >
                                            <Image
                                                w={{ sm: "150px", md: "150px", lg: "200px" }}
                                                h={{ sm: "160px", md: "150px", lg: "200px" }}
                                                objectFit='cover'
                                                src={"https://adminmyboutiqe.progfeel.co.in" + cartItem.product_image}
                                                alt='Dan Abramov'
                                            />
                                            <Flex flexDirection='column' w='50%'>
                                                <Flex
                                                    className='home_wrapper_main'
                                                >
                                                    <Text
                                                        fontWeight='bold'
                                                        fontSize={{ sm: '16px', md: '16px', lg: '18px' }}
                                                        className='cart_product_heading'
                                                        noOfLines={2}
                                                    >
                                                        {cartItem.product_name}
                                                    </Text>
                                                    <CloseIcon
                                                        w='2.5'
                                                        h='2.5'
                                                        mt='2'
                                                        // ml='1'
                                                        pos={'absolute'}
                                                        right='5%'
                                                        cursor='pointer'
                                                        onClick={() => {
                                                            handleRemoveFromCart(cartItem)
                                                        }} />
                                                </Flex>
                                                {cartItem.product_varient?.map((item, i) => {
                                                    return (
                                                        <Flex
                                                            key={i}
                                                            flexDir='column'
                                                        >
                                                            {item.id == cartItem.varient ?
                                                                <>
                                                                    <Text
                                                                        fontSize={{ sm: '14px', lg: '15px' }}
                                                                        mt='2'
                                                                    >
                                                                        Variant: {item.product_values}
                                                                    </Text>
                                                                    {item.additional_price > 0 ?
                                                                        <Text mt='3'
                                                                            fontWeight='bold'
                                                                            fontSize={{ sm: '14px', lg: '16px' }}
                                                                        >
                                                                            {(parseFloat(item.additional_price + cartItem.PRDPrice).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : '€'}
                                                                        </Text>

                                                                        :
                                                                        <Text mt='3'
                                                                            fontWeight='bold'
                                                                            fontSize={{ sm: '14px', lg: '16px' }}
                                                                        >
                                                                            {(parseFloat(cartItem.PRDPrice).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currencyIcon ? currencyIcon : '€'}
                                                                        </Text>
                                                                    }
                                                                </>
                                                                :
                                                                ""
                                                            }
                                                        </Flex>
                                                    )
                                                })}
                                                <Flex mt='5'>
                                                    <Box
                                                        cursor='pointer'
                                                        onClick={() => {

                                                            handleDecreaseCart(cartItem)

                                                        }}
                                                        p='3' bg='gray.400' color='white' className='box_color'>
                                                        <MinusIcon />
                                                    </Box>
                                                    <Box p='3' bg='gray.400' color='white' className='box_color'>
                                                        {cartItem.cartQuantity}
                                                    </Box>
                                                    <Box cursor='pointer'
                                                        onClick={() => {
                                                            handleAddToCart(cartItem)
                                                        }}
                                                        p='3' bg='gray.400' color='white' className='box_color'>
                                                        <AddIcon />
                                                    </Box>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    )
                                })
                                }
                            </Flex>
                            <div className='home_total_pay' >
                                <p className='home_wrapper_line'></p>
                                <Flex className='home_total'>
                                    <Text fontSize='xl' >{SubTotal}</Text>
                                    <Text fontSize='xl' >
                                        {parseFloat(cart1.cartTotalAmount || 0 * (currencyExchange ? currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currencyIcon ? currencyIcon : '€'}
                                    </Text>
                                </Flex>
                                <>
                                    {cartTotalQuantity ?
                                        <Button
                                            mt='5'
                                            colorScheme='none'
                                            bg="#219691"
                                            size='lg'
                                            onClick={() => {
                                                history.push("/checkout")
                                            }
                                            }
                                            w='100%'>
                                            {checkout}
                                        </Button>
                                        :
                                        <Button
                                            mt='5'
                                            colorScheme='none'
                                            bg="#219691"
                                            size='lg'
                                            onClick={() => {
                                                handleShowError()
                                            }
                                            }
                                            w='100%'>
                                            {checkout}
                                        </Button>
                                    }
                                </>
                            </div>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </div>
        </>
    )
}