import { createSlice, current } from "@reduxjs/toolkit";


const initialState = {
  cartItems: localStorage.getItem("loginToken") ? localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [] : sessionStorage.getItem("cartItems")
    ? JSON.parse(sessionStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  productPrice: 0,
  isVisible: false,
  checkoutFields: [],
  isLoggedIn: localStorage.getItem("loginToken") ? true : false,
  sidebar: "large"
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const addSignleObjectToCart = (state, action) => {
        const existingIndex = state.cartItems.findIndex(
          (item) => item.id === action.payload.id
        );
        const existingVarient = state.cartItems.findIndex(
          (item) => item.varient === action.payload.varient
        );

        if (existingIndex >= 0 && existingVarient >= 0) {


          if (existingIndex == existingVarient) {

            state.cartItems[existingIndex] = {
              ...state.cartItems[existingIndex],
              cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
            };
          }
          else {
            state.cartItems[existingVarient] = {
              ...state.cartItems[existingVarient],
              cartQuantity: state.cartItems[existingVarient].cartQuantity + 1,
            };
          }

        } else {
          let tempProductItem = { ...action.payload, cartQuantity: 1 };
          state.cartItems.push(tempProductItem);

        }
        if (state.isLoggedIn) {
          localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        } else {
          sessionStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        }
      }

      if (Array.isArray(action.payload)) {

        action.payload.forEach((item) => {
          addSignleObjectToCart(state, item);
        })
      }
      addSignleObjectToCart(state, action);


    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      const existingVarientIndex = state.cartItems.findIndex(
        (item) => item.varient === action.payload.varient
      );

      if (itemIndex == existingVarientIndex) {

        if (state.cartItems[itemIndex].cartQuantity > 1) {
          state.cartItems[itemIndex].cartQuantity -= 1;

        } else if (state.cartItems[itemIndex].cartQuantity === 1) {

          if (itemIndex == existingVarientIndex) {
            const nextCartItems = state.cartItems.filter((_, i) => i !== itemIndex);
            state.cartItems = nextCartItems;

          }


        }
      } else {
        if (state.cartItems[existingVarientIndex].cartQuantity > 1) {
          state.cartItems[existingVarientIndex].cartQuantity -= 1;

        } else if (state.cartItems[existingVarientIndex].cartQuantity === 1) {
          const nextCartItems = state.cartItems.filter((_, i) => i !== existingVarientIndex);
          state.cartItems = nextCartItems;

        }
      }


      if (state.isLoggedIn) {
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      } else {
        sessionStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },
    removeFromCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.id
      );
      const existingVarientIndex = state.cartItems.findIndex(
        (item) => item.varient === action.payload.varient
      );

      if (itemIndex == existingVarientIndex) {
        const nextCartItems = state.cartItems.filter((_, i) => i !== itemIndex);
        state.cartItems = nextCartItems;

      } else {
        const nextCartItems = state.cartItems.filter((_, i) => i !== existingVarientIndex);
        state.cartItems = nextCartItems;

      }

      if (state.isLoggedIn) {
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      } else {
        sessionStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
      return state;
    },
    getTotals(state, action) {

      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { PRDPrice, cartQuantity, product_varient, varient } = cartItem;
          const prodPRD = product_varient.find((item) => item.id === varient)
          if (prodPRD.additional_price) {
            const itemTotal = (prodPRD.additional_price + PRDPrice) * cartQuantity;
            cartTotal.total += itemTotal;
            cartTotal.quantity += cartQuantity;

            return cartTotal;
          } else {
            const itemTotal = PRDPrice * cartQuantity;
            cartTotal.total += itemTotal;
            cartTotal.quantity += cartQuantity;

            return cartTotal;
          }
          // cartTotal.total += itemTotal;
          // cartTotal.quantity += cartQuantity;

          // return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
    clearCart(state, action) {
      state.cartItems = [];
      if (state.isLoggedIn) {
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      } else {
        sessionStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      }
    },
    ProductLoad(state, action) {
      state.productPrice = action.payload.PRDPrice;
    },
    PriceVisible(state, action) {

      state.isVisible = action.payload
    },
    CheckoutAddressFields(state, action) {
      state.checkoutFields = action.payload;
    },
    sidebarFields(state, action){
      // console.log(action.payload);
      state.sidebar = action.payload;
    }

  },
});

export const { addToCart, decreaseCart, removeFromCart, getTotals, clearCart, ProductLoad, PriceVisible, CheckoutAddressFields, sidebarFields} =
  cartSlice.actions;

export default cartSlice.reducer;