// Chakra imports
import {
  Box, Button, Flex, HStack, Text, useColorModeValue, Heading, Image,
} from "@chakra-ui/react";
import {
  DocumentIcon, HomeIcon, PersonIcon, RocketIcon,
} from "components/Icons/Icons";
import PropTypes from "prop-types";
import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../../assets/svg/logo1.png";
import routes from "routes.js";
export default function AuthNavbar1(props) {
  const [open, setOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const { logoText, secondary, ...rest } = props;
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };
  let navbarIcon = useColorModeValue("gray.700", "gray.200");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarBg = useColorModeValue(
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  let navbarBorder = useColorModeValue(
    "1.5px solid #FFFFFF",
    "1.5px solid rgba(255, 255, 255, 0.31)"
  );
  let navbarShadow = useColorModeValue(
    "0px 7px 23px rgba(0, 0, 0, 0.05)",
    "none"
  );
  let navbarFilter = useColorModeValue(
    "none",
    "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
  );
  let navbarBackdrop = "blur(21px)";
  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );
  let navbarPosition = "fixed";
  let colorButton = "white";
  if (props.secondary === true) {
    navbarIcon = "white";
    navbarBg = "none";
    navbarBorder = "none";
    navbarShadow = "initial";
    navbarFilter = "initial";
    navbarBackdrop = "none";
    bgButton = "white";
    colorButton = "gray.700";
    mainText = "white";
    navbarPosition = "absolute";
  }
  var brand = (
    <Link
      target="_blank"
      display="flex"
      fontWeight="black"
      justifyContent="center"
      alignItems="center"
      color={mainText}
    >
      <Image
        src={logo}
        width={{ sm: '50%', md: "25%", lg: "30%" }}
      />
    </Link>
  );
  return (
    <Flex
      position={navbarPosition}
      top="0px"
      left="50%"
      transform="translate(-50%, 0px)"
      background={navbarBg}
      border={navbarBorder}
      boxShadow={navbarShadow}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      px="16px"
      py="15px"
      mx="auto"
      width="100%"
      maxW="100%"
      alignItems="center"
      zIndex={1}
    >
      <Flex w="100%" >
        {brand}

        <Box
          display={{ base: "flex", lg: "none" }}
        >

        </Box>

      </Flex>
    </Flex>
  );
}

AuthNavbar1.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string,
};
