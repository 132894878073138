import React, { useState, useEffect } from "react";
import {
    Box, Flex, Button, FormControl, Heading, Input, HStack, Icon, Text, Grid,
    Checkbox, InputGroup, InputRightElement, GridItem, ChakraProvider, useToast,
} from "@chakra-ui/react";
import { FaFacebook, } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { login, loginWithSDK } from "../../components/API/AuthAPI";
import { Link, useHistory } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import AuthNavbar1 from "components/Navbars/SearchBar/AuthNavbar1";
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import "./styles.css";
import theme from "theme/theme";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
    enterPasswordText, enterValidCredentialsText, enterEmailAndPassoword, entervalidEmailandPassword, alreadyVerifiedEmailmsg, emailverified,
    signInText, usernameOrEmail, passwordText, rememberMe, forgotpasswordText, loginText, continueWith, donothaveacc, signInwithFacebook, signInwithGoogle, signUpText,
} from "assets/constants/strings";

function SignIn3() {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [user_type, setUser_type] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isRemember, setIsRemember] = useState(false);
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    const toast = useToast();
    let location = useLocation();
    let history = useHistory();
    const hashRefId = location.hash.substring(1);

    /**
     * This function logs in a user with Facebook and sets their access token, name, user ID, and login
     * type, and then redirects them to the appropriate dashboard based on their user type.
     */
    const responseFacebook = (response) => {
        let log_type = "facebook";
        let usertype = 'affiliate'
        if (response.accessToken) {
            loginWithSDK(response.name, usertype, response.accessToken, response.userID, log_type, response.name)
                .then((responseJson) => {
                    if (responseJson) {
                        if (responseJson["token"]) {
                            try {
                                console.log("token", responseJson["token"])
                                userToken = responseJson["token"];
                                let user = responseJson["user_type"];
                                localStorage.setItem('token', responseJson["token"])
                                localStorage.setItem('user_type', user);
                                if (user == "affiliate" || "affiliate_manager") {
                                    history.push({
                                        pathname: '/affiliate/dashboard',
                                        state: { token: responseJson["token"] }
                                    })
                                }
                            } catch (e) { }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }


    /* The above code is a JavaScript function that handles the login process using Google OAuth2.0. It
    retrieves the user's information from Google after a successful login and sets the access token,
    name, email, UID, and login type. It then calls another function `loginWithSDK` to log in the
    user with the retrieved information. If the login is successful, it sets the user's token, user
    type, and affiliate ID in the local storage and redirects the user to the affiliate dashboard. */
    const login1 = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + (tokenResponse.access_token),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }).then((res) => res.json());
            let log_type = "google"
            let usertype = 'affiliate';
            if (tokenResponse.access_token) {
                loginWithSDK(userInfo.email, usertype, tokenResponse.access_token, userInfo.sub, log_type, userInfo.given_name)
                    .then((responseJson) => {
                        if (responseJson) {
                            if (responseJson["token"]) {
                                try {
                                    console.log("token", responseJson["token"])
                                    userToken = responseJson["token"];
                                    let user = responseJson["user_type"];
                                    let user_id = responseJson["user_id"];
                                    localStorage.setItem('token', responseJson["token"])
                                    localStorage.setItem('user_type', user);
                                    localStorage.setItem('affiliate_id', user_id)
                                    if (user == "affiliate" || "affiliate_manager") {
                                        history.push({
                                            pathname: '/affiliate/dashboard',
                                            state: { token: responseJson["token"] }
                                        })
                                    }
                                } catch (e) { }
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    });

    useEffect(() => {
        if (hashRefId == "True") {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        {emailverified}
                    </Box>
                ),
            })
        } else if (hashRefId == "False") {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        {alreadyVerifiedEmailmsg}
                    </Box>
                ),
            })
        }
    }, [hashRefId])

    let userToken;
    const checkUserLogin = () => {
        let userToken = localStorage.getItem("token");
        if (userToken) {
            history.push({
                pathname: "/affiliate/dashboard"
            })
        }
    }

    useEffect(() => {
        const checkCredentials = JSON.parse(localStorage.getItem('rememberMe'));
        if (checkCredentials) {
            if (checkCredentials.username !== "" && checkCredentials.password !== "") {
                setUsername(checkCredentials.username);
                setPassword(checkCredentials.password)
            }
        }
        checkUserLogin()
    }, [])

    /**
     * This function handles the login process and displays appropriate error messages based on the
     * response from the server.
     */
    const handleLogin = (e) => {
        e.preventDefault();
        setIsSubmitted(true)
        login(username, password, user_type,)
            .then((responseJson) => {
                if (isRemember == true) {
                    localStorage.setItem('rememberMe', JSON.stringify({ "username": username, "password": password }))
                } else {
                    localStorage.removeItem('rememberMe');
                }
                if (responseJson["token"]) {
                    if (responseJson["user_type"] !== "seller" && responseJson["user_type"] !== "buyer") {
                        userToken = responseJson["token"];
                        let user = responseJson["user_type"];
                        let user_id = responseJson["user_id"];
                        localStorage.setItem('token', responseJson["token"])
                        localStorage.setItem('user_type', user);
                        localStorage.setItem('affiliate_id', user_id)
                        // if (user == "affiliate" || "affiliate_manager") {
                        history.push({
                            pathname: '/affiliate/dashboard',
                            state: { token: responseJson["token"] }
                        })
                    } else {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                // mt='20%'
                                >
                                    {entervalidEmailandPassword}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                } else if (responseJson['password'] == "This field is required." && responseJson["username_or_email"] == 'This field may not be blank.') {
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {enterEmailAndPassoword}
                            </Box>
                        ),
                    })
                    setIsSubmitted(false)
                }
                else if (responseJson['password'] == "This field is required.") {
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {enterPasswordText}
                            </Box>
                        ),
                    })
                    setIsSubmitted(false)
                }
                else {
                    toast.closeAll()
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {enterValidCredentialsText}
                            </Box>
                        ),
                    })
                    setIsSubmitted(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <ChakraProvider theme={theme} resetCss={false} w="100%">
            <Flex
                mb='40px'
                pt="45px"
                h="100%"
                w="100%"
            >
                <AuthNavbar1/>
                <Flex
                    w='100%'
                    mx='auto'
                    justifyContent='center'
                    mb='30px'
                    pt={{ sm: "10vh", md: "0px", lg: "5vh" }}
                >
                    <Flex
                        display="flex"
                        flexWrap={"nowrap"}
                        alignItems='center'
                        justifyContent='center'
                        w={{ base: "100%", md: "50%", lg: "42%" }}
                    >
                        <Flex
                            direction='column'
                            w='100%'
                            maxWidth={"350px"}
                            background='#FFFFFF'
                            border={{ sm: "none", md: "1px solid #EEE", lg: "1px solid #EEE" }}
                            borderRadius={{ sm: "none", md: "10px", lg: "10px" }}
                            p='25px'
                            mt={{ md: "100px", lg: "80px" }}>
                            <Heading color={"#383e50"} fontSize='18px' align="left" >
                                {signInText}
                            </Heading>
                            <form onSubmit={handleLogin}>
                                <FormControl
                                    pt="24px"
                                >
                                    <Input
                                        id="username"
                                        borderRadius='5px'
                                        mb='24px'
                                        bg="#F4F5F9"
                                        color="#4f5d77"
                                        fontSize='sm'
                                        // type='text'
                                        placeholder={usernameOrEmail}
                                        _placeholder={{ color: 'inherit' }}
                                        size='lg'
                                        h='41px'
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <InputGroup>
                                        <Input
                                            id="password"
                                            borderRadius='5px'
                                            mb='20px'
                                            bg="#F4F5F9"
                                            color="#4f5d77"
                                            fontSize='sm'
                                            type={show ? '' : 'password'}
                                            placeholder={passwordText}
                                            _placeholder={{ color: 'inherit' }}
                                            size='lg'
                                            h='41px'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <InputRightElement width='4.5rem' py="20px">
                                            <Button h='1.75rem' bg="transparent" colorScheme="none" color="#000000" size='sm' onClick={handleClick}>
                                                {show ? <ViewOffIcon /> : <ViewIcon />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    <Flex display="flex" justifyContent="space-between">
                                        <Checkbox
                                            colorScheme='teal'
                                            onChange={(e) => { setIsRemember(e.target.checked) }}
                                        >
                                            <Text
                                                fontSize={"sm"}
                                                color={"#ADB5BD"}
                                            >
                                                {rememberMe}
                                            </Text>
                                        </Checkbox>
                                        <Link to="/forgotpassword" >
                                            <Text
                                                fontWeight='normal'
                                                fontSize={"sm"}
                                                color={"#ADB5BD"}
                                            >
                                                {forgotpasswordText}
                                            </Text>

                                        </Link>
                                    </Flex>
                                    {!isSubmitted ?
                                        <Button
                                            fontSize='sm'
                                            type='submit'
                                            bg='#219691'
                                            w='100%'
                                            h='41px'
                                            mb='20px'
                                            color='white'
                                            borderRadius="4px"
                                            mt='20px'
                                            _hover={{
                                                bg: "#219691",
                                            }}
                                            _active={{
                                                bg: "teal.400",
                                            }}
                                        >
                                            {loginText}
                                        </Button>
                                        :
                                        <Button
                                            fontSize='sm'
                                            type='submit'
                                            isLoading
                                            loadingText='please wait....'
                                            colorScheme='teal'
                                            variant='solid'
                                            bg='#219691'
                                            w='100%'
                                            h='41px'
                                            mb='20px'
                                            color='white'
                                            borderRadius="4px"
                                            mt='20px'
                                            _hover={{
                                                bg: "#219691",
                                            }}
                                            _active={{
                                                bg: "teal.400",
                                            }}
                                        >
                                            {loginText}
                                        </Button>
                                    }
                                </FormControl>
                            </form>
                            <Grid templateColumns='repeat(5)'>
                                <GridItem colSpan={2} h='0.1' mt="12px" bg='gray.400' />
                                <Text
                                    fontSize='sm'
                                    color='gray.400'
                                    fontWeight='normal'
                                    textAlign='center'
                                    w="100%"
                                    mb='22px'>
                                    {continueWith}
                                </Text>
                                <GridItem colStart={4} colEnd={6} h='0.1' mt="12px" bg='gray.400' />
                            </Grid>
                            <HStack
                                justifyContent={"center"}
                                flexDirection={"column"}
                            >
                                <GoogleOAuthProvider clientId="986514694096-q7uu00p7v288smbm6rbvbp80fuaird3q.apps.googleusercontent.com">
                                    <Button
                                        onClick={login1}
                                        color="#000000"
                                        _hover={{ bg: "#219691", color: "#FFFFFF" }}
                                        bg="transparent"
                                        border="0.5px solid #219691"
                                        w='100%'
                                        mb="20px"
                                        ml="8px"
                                    >
                                        <Icon
                                            as={FcGoogle}
                                            w='30px'
                                            h='30px'
                                            _hover={{ filter: "brightness(120%)" }}

                                        />
                                        <Text
                                            fontWeight="normal"
                                            pl="10px"
                                            fontSize={'sm'}
                                        >
                                            {signInwithGoogle}
                                        </Text>
                                    </Button>

                                </GoogleOAuthProvider>
                                <FacebookLogin
                                    appId="1526645904430403"
                                    autoLoad={false}
                                    callback={responseFacebook}
                                    render={renderProps => (
                                        <Button
                                            onClick={renderProps.onClick}
                                            _hover={{ bg: "#219691", color: "#FFFFFF" }}
                                            bg="trasparent"
                                            border="0.5px solid #219691"
                                            w='100%'
                                            color="#000000"
                                        >
                                            <Icon
                                                as={FaFacebook}
                                                w='30px'
                                                h='30px'
                                                color="#3b5998"
                                                _hover={{ filter: "brightness(120%)" }}
                                            />
                                            <Text
                                                fontWeight="normal"
                                                pl='10px'
                                                fontSize={'sm'}
                                            >
                                                {signInwithFacebook}
                                            </Text>
                                        </Button>
                                    )}
                                />
                            </HStack>
                            <Flex
                                pt="30px"
                                pb="30px"
                                display="flex"
                                justifyContent="center"
                            >
                                <Text
                                    fontSize="sm"
                                    display="flex"
                                    textAlign={"center"}
                                    color={"#4F5D77"}
                                >
                                    {donothaveacc}
                                    <Link to="/signup" >
                                        <Text
                                            color='#219691'
                                            ml='5px'
                                        >
                                            {signUpText}
                                        </Text>
                                    </Link>
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </ChakraProvider>
    );
}

export default SignIn3;
