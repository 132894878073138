import React, { useEffect, useState } from 'react'
import { Flex, Box, Text, Center, Stack, Avatar, Container, Grid, Button, Image, ChakraProvider, useToast } from '@chakra-ui/react'
import './paymentSuccess.css'
import Footer from 'components/Footer/Footer'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import theme from 'theme/theme'
import { useDispatch, useSelector } from 'react-redux'
import { clearCart } from 'Slices/cartslice'
import { placeOrderWithoutLogin, generateChargeID, captureTransactionId, getPlaceOrderDetails, saveTransactionIdForWOLogin} from '../API/AuthAPI'


function PaymentSuccessMsg(props) {
    let history = useHistory();
    const [storeurl, setStoreurl] = useState();
    const [orderId, setOrderId] = useState();
    const [token, setToken] = useState();
    const tagging_token = sessionStorage.getItem('reference_id');
    const user_id = localStorage.getItem("loginUser_id");
    const checkout = JSON.parse(sessionStorage.getItem('checkout'));
    const order_Id = sessionStorage.getItem('merchant_order_id');
    const toast = useToast();
    const cart1 = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const myArray = [];

    const handleClearCart = () => {
        dispatch(clearCart());
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const authToken = localStorage.getItem("loginToken")
        if (authToken) {
            setToken(authToken);
        }
        const redirectStatus = urlParams.get('redirect_status');

        for (let i = 0; i < cart1.cartItems.length; i++) {
          const { id, varient, cartQuantity } = cart1.cartItems[i];
          const obj = { product: id, varient: varient, quantity:cartQuantity };
          myArray.push(obj);
        }

        if (redirectStatus === 'succeeded') {
            // Payment succeeded
            if (authToken) {
                getOrder();
            } else {
                placeOrder();
            }
        } else {
            // Payment failed
            console.log('Payment failed or was cancelled.');
        }
    }, []);



    useEffect(() => {

        let store = localStorage.getItem('storename')
        if (store) {
            setStoreurl(store)
        } else {
            setStoreurl('sachin-store')
        }


    }, [storeurl]);

    const getOrder = async () => {
        let ref_id;
        if (!tagging_token == '') {
            ref_id = tagging_token;
        } else {
            ref_id = ''
        }
        try {
            let merchant_id, order_id, tx_id, amount, currency;
            let gateway = 'stripe';
            let status = 'success';
            let mode = 'card';
            const result1 = await getPlaceOrderDetails(checkout.token, checkout.card_id, checkout.email, checkout.first_name, checkout.last_name, checkout.location, checkout.address_line1,
                checkout.city, checkout.postal_code, checkout.country_code, checkout.contact_no, checkout.comment).then(responseJson => {
                    if (responseJson['message']) {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {responseJson['message']}
                                </Box>
                            ),
                        })
                        order_id = responseJson?.order_details?.id;
                        merchant_id = responseJson?.order_details?.merchant_order_id;
                        setOrderId(responseJson?.order_details?.merchant_order_id);
                        handleClearCart();
                    }
                }
                )
            const result2 = await generateChargeID(checkout.payment_id).then((response) => {

                tx_id = response.data.charges.data[0].id;
                amount = response.data.charges.data[0].amount;
                currency = response.data.charges.data[0].currency;

            })

            const result3 = await captureTransactionId(checkout.token, user_id,order_id, gateway, status,
                tx_id, ref_id, currency, amount / 100, mode, merchant_id, checkout.first_name,
                checkout.address_line1, checkout.address_line2, checkout.postal_code, checkout.email).then((res) => {
                    if (res['message'] == 'Payment captured successfully.') {
                        sessionStorage.removeItem('reference_id');
                        sessionStorage.removeItem('checkout');
                    }
                })


        } catch (e) {
            //   console.log(e)
        }
    }

    const placeOrder = async () => {
        let ref_id;
        if (!tagging_token == '') {
            ref_id = tagging_token;
        } else {
            ref_id = ''
        }
        try {
            let merchant_id, order_id, tx_id, amount, currency;
            let gateway = 'stripe';
            let status = 'success';
            let mode = 'card';
            const result1 = await placeOrderWithoutLogin( myArray, checkout.country, checkout.email, checkout.first_name, checkout.last_name, checkout.location, checkout.address_line1,
                checkout.city, checkout.postal_code, checkout.country_code, checkout.contact_no, checkout.comment).then(responseJson => {
                    if (responseJson['message']) {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {responseJson['message']}
                                </Box>
                            ),
                        })
                        order_id = responseJson?.order_id;
                        merchant_id = responseJson?.merchant_order_id;
                        setOrderId(responseJson?.merchant_order_id);
                        handleClearCart();
                    }
                }
                )
            const result2 = await generateChargeID(checkout.payment_id).then((response) => {
                // console.log(response)
                tx_id = response.data.charges.data[0].id;
                amount = response.data.charges.data[0].amount;
                currency = response.data.charges.data[0].currency;

            })

            const result3 = await saveTransactionIdForWOLogin( order_id, gateway, status,
                tx_id, ref_id, currency, amount / 100, mode, merchant_id, checkout.first_name,
                checkout.address_line1, checkout.address_line2, checkout.postal_code,checkout.email).then((res) => {
                    if (res['message'] == 'Payment captured successfully.') {
                        sessionStorage.removeItem('reference_id');
                        sessionStorage.removeItem('checkout');
                        // console.log('success', res['message'])
                    }
                })


        } catch (e) {
            //   console.log(e)
        }
    }




    return (
        <ChakraProvider theme={theme} resetCSS={false}>
            <div className='success_wrapper'>
                <Flex flexDirection='column'
                    pt={{ base: "20px", md: "50px", lg: '10px' }}
                    maxW='100%'
                    height='auto'
                >
                    <Grid
                        display='felx'
                        justifyContent={'center'}
                        flexWrap='nowrap'
                        margin={'0 auto'}
                    >
                        <Text
                            fontSize={{ base: 'xl', lg: '4xl' }}
                            fontWeight='bold'
                            color="#219691"
                        >
                            MY BOUTIQ
                        </Text>
                    </Grid>

                    <Flex
                        mt='30'
                        className='success_wrapper_content'
                        // direction={{ sm: "column", md: "row" }}
                        maxW={'100%'}
                    >
                        <Image
                            className='success_page_img'
                            src='https://bashooka.com/wp-content/uploads/2018/04/scg-canvas-background-animation-24.jpg'
                            w='100vw'
                            h={{ sm: "200px", md: '300px', xl: "370px" }}
                        />

                        <Image
                            className='success_wrapper_profile'
                            src='https://bit.ly/dan-abramov'
                            w={{ sm: "110px", md: '180px', lg: "230px" }}
                            h={{ sm: "110px", md: '180px', lg: "230px" }}
                            borderRadius='50%'
                        />
                    </Flex>

                    <Container>
                        <Flex flexDirection="column">


                            <Text
                                className='payment_success_msg'
                                fontWeight='bold'
                                noOfLines={3}
                                zIndex='1'
                                mt='8'
                                fontSize='2xl'
                                ml='10'
                            >
                                Thank you for your order.You will soon receive an order confirmation.
                            </Text>
                            {
                                orderId ?
                                    <Text
                                        className='payment_success_msg'
                                        fontWeight='bold'
                                        noOfLines={3}
                                        zIndex='1'
                                        mt='8'
                                        fontSize='2xl'
                                        ml='10'
                                    >
                                        Your order-id is {orderId}.
                                    </Text>
                                    :
                                    ""
                            }
                            {
                                order_Id ?
                                    <Text
                                        className='payment_success_msg'
                                        fontWeight='bold'
                                        noOfLines={3}
                                        zIndex='1'
                                        mt='8'
                                        fontSize='2xl'
                                        ml='10'
                                    >
                                        Your order-id is {order_Id}.
                                    </Text>
                                    :
                                    ""
                            }


                        </Flex>
                    </Container>

                    <Grid
                        //    className='discover'
                        pos={'absolute'}
                    >
                        <Box
                            className='discover'
                            dir='column'
                            border='1px solid white'
                            boxShadow={{ sm: "5px 5px 10px 5px #D9DDDC", md: "5px 5px 10px 5px #D9DDDC", lg: "5px 5px 10px 5px #D9DDDC" }}
                            borderRadius={{ sm: "15px", md: "15px", lg: "15px" }}
                            width={'150px'}
                            cursor='pointer'
                            onClick={(e) => {
                                localStorage.removeItem('merchant_order_id')
                                history.push(`/${storeurl}`)
                            }}

                        >

                            <Text
                                textAlign={'center'}
                                fontSize='15px'
                                fontWeight='bold'
                                className='discover_text'
                            >
                                DISCOVER
                            </Text>
                            <Text
                                color='#219691'
                                fontWeight={'bold'}
                                fontSize='20px'
                                textAlign='center'
                                className='discover_heading'
                            >
                                MY BOUTIQ
                            </Text>
                        </Box>
                    </Grid>


                </Flex>
            </div>
        </ChakraProvider>
    )
}


export default PaymentSuccessMsg
