import React, { useState, useEffect } from 'react'
import useFabric from './useFabric'
import FBCanvas from './CanvasManager';
import { Button, Flex, Grid, Text } from '@chakra-ui/react';
import Emitter from 'Emitter';
import "./style.css";
import { useSelector } from 'react-redux';

const Canvas1 = (props) => {

    const [selectedCanvas, setSelectedCanvas] = useState(null)
    const [can, setCan] = useState(null);
    const [canvasHeight, setCanvasHeight] = useState(666);  
    const [canvasWidth, setCanvasWidth] = useState(666);
    const launch = useSelector((state) => state.launchpad)
    const canvasRef = useFabric((fabricCanvas) => {
        return setCan(fabricCanvas)
    }, { backgroundColor: "#ffffff",
         width:canvasWidth,
         height: canvasHeight    
})


    useEffect(() => {
        const canvas = new FBCanvas(can);
        setSelectedCanvas(canvas) 
        props.onChange(0,canvas);
    }, [can])

    const selectCanvas = (index) => {
        props.onClick(index, selectedCanvas)
    }

    useEffect(() => {
        const child = document.getElementById("my-canvas");
        const parent = document.getElementById("fabric-canvas");
        if(launch.platform =='fbg' || launch.platform == "facebook"){
            parent.classList.add("canvas-parent1");
            child.classList.add("canvas-parent1");
            parent.classList.remove("canvas-google","canvas-facebook","canvas-instagram","canvas-linkedIn","canvas-pinterest","canvas-twitter");
            child.classList.remove("canvas-google","canvas-facebook","canvas-instagram","canvas-linkedIn","canvas-pinterest","canvas-twitter");
        }else if(launch.platform == "instagram"){
            parent.classList.add("canvas-instagram");
            child.classList.add("canvas-instagram");
            parent.classList.remove("canvas-google","canvas-facebook","canvas-parent1","canvas-linkedIn","canvas-pinterest","canvas-twitter");
            child.classList.remove("canvas-google","canvas-facebook","canvas-parent1","canvas-linkedIn","canvas-pinterest","canvas-twitter");
        }else if(launch.platform == "gmb"){
            parent.classList.add("canvas-google");
            child.classList.add("canvas-google");
            parent.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-linkedIn","canvas-pinterest","canvas-twitter");
            child.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-linkedIn","canvas-pinterest","canvas-twitter");
        }else if(launch.platform == "linkedin"){
            parent.classList.add("canvas-linkedIn");
            child.classList.add("canvas-linkedIn");
            parent.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-google","canvas-pinterest","canvas-twitter");
            child.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-google","canvas-pinterest","canvas-twitter");
        }else if(launch.platform == "pinterest"){
            parent.classList.add("canvas-pinterest");
            child.classList.add("canvas-pinterest");
            parent.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-google","canvas-linkedIn","canvas-twitter");
            child.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-google","canvas-linkedIn","canvas-twitter");
        }else if(launch.platform == "twitter"){
            parent.classList.add("canvas-twitter");
            child.classList.add("canvas-twitter");
            parent.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-google","canvas-linkedIn","canvas-pinterest");
            child.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-google","canvas-linkedIn","canvas-pinterest");
        }
        else{
            parent.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-google","canvas-linkedIn","canvas-pinterest","canvas-twitter");
            child.classList.remove("canvas-instagram","canvas-facebook","canvas-parent1","canvas-google","canvas-linkedIn","canvas-pinterest","canvas-twitter");
        }
    }, [launch])

    return (
        <>
            <div
                className="fabric-canvas-wrapper"
                id='fabric-canvas'
                onClick={() => {
                    selectCanvas(props.index)
                }}
            >
                    <canvas
                        id="my-canvas"
                        ref={canvasRef}
                        // width={794}
                        // height={666}
                    >
                    </canvas>
            </div>
        </>

    )
}

export default Canvas1