import React, { useEffect, useState } from 'react'
import { Flex, Box, Text, Center, Stack, Avatar, Container } from '@chakra-ui/react'
import './PaymentFailure.css'
import { CurrencyExchange } from 'components/CurrencyExchangeRate/CurrencyExchange';
import { generateCurrency } from 'components/API/AuthAPI';
// import axios from 'axios';


function PaymentFailureMsg(props) {
    const [currecy, setCurrency] = useState();
    // const [usd, setUsd] = useState();
    // const [gbp, setGbp] = useState();
    // const [chf, setChf] = useState();
    // const [cad, setCad] = useState();
    // const [jpy, setJpy] = useState();
    // const [pln, setPln] = useState();
    // const [zar, setZar] = useState();
    // const [aud, setAud] = useState();
    
    let currency = CurrencyExchange("EUR");
    console.log("NEW-Currency", currency);

    // useEffect(() => {
    //     generateCurrency("EUR")
    //         .then(function (response) {
    //             console.log("conversion rate", response.conversion_rates);
    //             setCurrency(response.conversion_rates);
    //             setUsd(response.conversion_rates.USD);
    //             setGbp(response.conversion_rates.GBP);
    //             setChf(response.conversion_rates.CHF);
    //             setCad(response.conversion_rates.CAD);
    //             setJpy(response.conversion_rates.JPY);
    //             setPln(response.conversion_rates.PLN);
    //             setZar(response.conversion_rates.ZAR);
    //             setAud(response.conversion_rates.AUD);
    //         })
    //     console.log(currecy);
    // }, [])



    // let newCurrency = axios({
    //     method: "GET",
    //     url: "https://v6.exchangerate-api.com/v6/745b86228eb140998a47b62f/latest/USD",
    //     withCredentials: false,
    // })
    // .then(function (response) {
    //     console.log(response);
    //   })

    return (
        <div className='success_wrapper'>
            <Flex flexDirection='column' pt={{ base: "120px", md: "50px" }}>
                <Stack spacing={3}>
                    <Text textAlign='center' fontSize='4xl'>MY BOUTIQ</Text>
                </Stack>

                <Center mt='30'>

                    <Flex
                        className='success_wrapper_content'
                        direction={{ sm: "column", md: "row" }}
                        w={{ sm: "100%", md: '100%', xl: "65%" }}
                    >
                        <Avatar
                            borderRadius='0px'
                            src='https://bashooka.com/wp-content/uploads/2018/04/scg-canvas-background-animation-24.jpg'
                            w={{ sm: "100%", md: '100%', xl: "100%" }}
                            h='320px'
                        />

                        <Avatar
                            className='success_wrapper_profile'
                            src='https://bit.ly/dan-abramov'
                            w={{ sm: "70px", md: '120px', xl: "200px" }}
                            h={{ sm: "70px", md: '120px', xl: "200px" }}

                            borderRadius='full'
                        />



                    </Flex>

                    {/* <Flex dir='column'>
                        <Text>||{10 * usd} ||</Text>
                        <Text>||{10 * gbp} ||</Text>
                        <Text>||{10 * chf} ||</Text>
                        <Text>||{10 * cad} ||</Text>
                        <Text>||{10 * jpy} ||</Text>
                        <Text>||{10 * pln} ||</Text>
                        <Text>||{10 * zar} ||</Text>
                        <Text>||{10 * aud} ||</Text>
                    </Flex> */}
                </Center>

                <Container>
                    <Flex flexDirection="column">


                        <Text fontWeight='bold' noOfLines={[1, 2, 3]}
                            zIndex='1' mt='8' fontSize='2xl' ml='10'>
                            Your Transaction has been declined. Kindly repeat the payment again.
                        </Text>


                    </Flex>
                </Container>


            </Flex>
        </div>
    )
}


export default PaymentFailureMsg
