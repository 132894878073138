import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import {
    Flex, Text, Image, Input, Button, Avatar, Select, InputGroup, Box, Checkbox, InputRightElement, ChakraProvider,
    useToast, Badge, FormControl, FormLabel, Menu, MenuButton, MenuList, MenuItem, Modal, ModalOverlay, ModalContent,
    ModalHeader, ModalBody,
} from '@chakra-ui/react'
import { BiShow, BiHide } from "react-icons/bi"
import theme from 'theme/theme'
import {
    register, resetAccountpassword, sellerCurrencyList,
    login, getAccountProfile, getuserupdateprofile, getAllStoreproducts, getReturnOrderItem, getPlace_order, getListedCart, getAddUpdateCart
} from '../API/AuthAPI'
import './mainHeader.css'
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import hamer from 'assets/img/hamburger-menu.png'
import { OrderOverView } from './OrderOverView'
import MyOrders from './MyOrders'
import ReturnProduct from './ReturnProduct'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { CurrencyExchange } from 'components/CurrencyExchangeRate/CurrencyExchange'
import Emitter from 'Emitter'
import { useDispatch, useSelector } from "react-redux";
import { addToCart, cartslice } from 'Slices/cartslice'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import logo from "../../assets/svg/logo1.png";


export function MainHeader({ onOpencart, handleCallback, userId1 }) {

    const { cartTotalQuantity } = useSelector((state) => state.cart);
    const [orderDetails, setOrderDetails] = useState(false)
    const [orderInfo] = useState([]);
    const [returnPro, setReturnPro] = useState(false)
    const [showold, setShowold] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showConf, setShowConf] = useState(false);
    const [showAcc, setShowAcc] = useState(false)
    const [token, setToken] = useState();
    const [getsellerId, setGetsellerId] = useState()
    const [hamburge, setHamburge] = useState(false);
    const [dropdown, setDropdown] = useState(false)
    const [signup, setSignup] = useState(false)
    const [forgetpass, setForgetpass] = useState(false)
    const [orders, setOrders] = useState(false)
    const [overview, setOverview] = useState(false)
    const [returnItem, setReturnItem] = useState(false)
    const [myProfile, setMyProfile] = useState(false)
    const [resetPass, setResetPass] = useState(false)
    const [overviewHamer, setOverviewHamer] = useState(false)
    const [mainlist, setMainlist] = useState([])
    const [storeCurrency, setStoreCurrency] = useState([])
    const [currentCurrency, setCurrentCurrency] = useState()
    const [resetacc, setResetacc] = useState('')
    const [getuser_name, setGetusername] = useState('')
    const [getpassword, setGetpassword] = useState("")
    const [user_Type] = useState('buyer')
    const [shortName, setShortName] = useState("EUR");
    const [bio, setBio] = useState();
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [userId, setUserId] = useState();
    const [code, setCode] = useState()
    const [user_name, setUser_name] = useState();
    const [phoneno, setPhoneno] = useState();
    const [requestSignin, setRequestSignin] = useState(false);
    const [loginToken, setLoginToken] = useState();
    const [signIn, setSignIn] = useState(false);
    const [getReturnDeatils, setGetReturnDeatils] = useState([])
    const [storeurl, setStoreurl] = useState();
    const [returnOrder, setReturnOrder] = useState({
        orderId: "",
        client_email: ""
    })
    const [order, setOrder] = useState({
        orderIdGet: "",
        getClient_email: ""
    })
    const [registration, setRegistration] = useState({
        username: "",
        email: "",
        password: "",
        phone_number: "9422675973",
        user_type: "buyer"
    })
    const history = useHistory();


    useEffect(() => {
        const store = localStorage.getItem('storename')
        if (store) {
            setStoreurl(store)
        } else {
            setStoreurl('sachin-store')
        }
    }, [storeurl])



    const dispatch = useDispatch();

    const showAccPass = () => setShowAcc(!showAcc)
    const showOldPass = () => setShowold(!showold)
    const showNewPass = () => setShowNew(!showNew)
    const showConfPass = () => setShowConf(!showConf)
    const cart1 = useSelector((state) => state.cart);

    // console.log('cartsList',cart1.cartItems);

    const toast = useToast();
    // To Open Homepage Hamburge Dropdown 

    const openHam = () => {
        setHamburge(true)
    }

    const closeHam = () => {
        setHamburge(false)
    }

    //1***** TO open Account login menu ------ Modal

    const openDropdown = () => {
        setDropdown(true)
        setSignup(false)
    }

   

    const closeDropdown = () => {
        setRequestSignin(false)
        setDropdown(false)
    }




    //1***** 1 Sign Up Modal ----- Account Login



    const openSignup = () => {
        setSignup(true)
        setDropdown(false)
    }

    const closesignup = () => {
        setRequestSignin(false)
        setSignup(false)
    }



    //1****** 2 Forget Password Modal ----- Account Login




    const openForgetpass = () => {
        setForgetpass(true)
        setDropdown(false)
    }


    const closeForgetpass = () => {
        setForgetpass(false)
    }





    //2***** TO open My Orders menu ---- Modal



    const openOrders = () => {
        setOrders(true)
        setDropdown(false)
        setReturnPro(false)
    }


    const closeOrders = () => {
        setOrders(false)
    }


    //2***** 1 TO open order overview Item menu --- Modal



    const openAccountOverview = () => {
        setOverview(false)
        setDropdown(false)
        setOrders(false)
        setOrderDetails(false)
    }


    const closeAccountOverview = () => {
        setOverview(false)
    }


    //2****** 2 Order Details


    const getAccountorderDetails = (e) => {
        e.preventDefault()
        getReturnOrderItem(loginToken, orderIdGet, getClient_email).then(responseJson => {
            if (responseJson.merchant_order_id[0] == "Invalid order id") {
                toast({
                    position: 'top',
                    render: () => (
                        <Box
                            color='white'
                            p={3} bg='#219691'
                            textAlign={'center'}
                            fontWeight='bold'
                        >
                            Please enter valid order id
                        </Box>
                    ),
                })


            }
            else {
                setReturnItem(true)
                setReturnPro(false)
                setGetReturnDeatils(responseJson || [])

            }


        }).catch(err => console.log(err))
    }


    const closeOrderDetails = () => {
        setOrderDetails(false)
    }







    //3****** TO open Return Item menu --- Modal




    const openReturnPro = () => {
        setReturnPro(true)
        setOrders(false)
        setDropdown(false)
    }


    const closeReturnPro = () => {
        setReturnPro(false)
    }


    const closeReturnItem = () => {
        setReturnItem(false)
    }



    // To Open My profile modal



    // const getMyProfileDetails = () => {
    //     setMyProfile(true)
    //     setResetPass(false)
    //     setOrderDetails(false)
    // }

    const getCloseprofileDetails = () => {
        setMyProfile(false)
    }




    // Reset Password Modal ----- My Profile


    const ResetPassword = () => {
        setResetPass(true)
        setMyProfile(false)
    }

    const closeResetpass = () => {
        setResetPass(false)
    }


    // onClick on My profile/order Details/order Overview --- hamburger


    const overviewHam = () => {
        setOverviewHamer(true)
    }

    // onClick on My profile/order Details/order Overview --- hamburger Account Login


    const getmyaccount = () => {
        setOverviewHamer(false)
        setOverview(false)
        setOrderDetails(false)
        setMyProfile(true)
    }

    // onClick on My profile/order Details/order Overview ---- hamburger My orders


    const getMyorders = () => {
        setOverviewHamer(false)
        setOverview(false)
        setMyProfile(false)
        setOrderDetails(true)
    }

    useEffect(() => {
        checkUserLogin();
    }, [token, getsellerId]);

    const checkUserLogin = () => {
        const userToken = localStorage.getItem("loginToken");
        setToken(userToken);
        getlist(userToken)
        // getcurrenyList(userToken)
        const sellerId = JSON.parse(localStorage.getItem("storeId"))
        setGetsellerId(sellerId)
    }






    const getlist = (token) => {
        getAllStoreproducts(token).then(res => {
            setMainlist(res.data)
            //console.log("getAllStoreproducts", mainlist)
        })
    }


    useEffect(() => {
        const sellerId = JSON.parse(localStorage.getItem("storeId"));
        if (sellerId) {
            getSellerCurrency(sellerId);
        }
    }, [setStoreCurrency])

    const getSellerCurrency = (sellerId) => {
        sellerCurrencyList(sellerId).then(responseJson => {
            setStoreCurrency(responseJson.data.currency || [])
        })
    }






    useEffect(() => {
        handleCallback(currentCurrency)

    }, [setCurrentCurrency, storeCurrency, userId1])






    const changeCurrency = (e) => {
        if (currentCurrency !== []) {

            setCurrentCurrency(e.target.value)
            handleCallback(e.target.value)
            setShortName(e.target.value)
            // console.log("e", e.target.value)
        } else {
            setCurrentCurrency("€")
            handleCallback("€")
        }
    }






    // For Registration - Account Login- signUp
    const { username, email, password, phone_number, user_type } = registration


    const RegAccountLogin = (e) => {
        setRegistration({ ...registration, [e.target.name]: e.target.value })
    }

    const getRegistrationdetails = (e) => {
        e.preventDefault()
        register(username, password, email, phone_number, user_type)
            .then((responseJson) => {
                // console.log("regi ==>", responseJson)
                if (responseJson) {

                    if (responseJson["message"]) {
                        // alert(responseJson['message'])
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    Registration successful.
                                </Box>
                            ),
                        })
                        openDropdown()

                    } else if (responseJson["email"] == "Enter a valid email address.") {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    Enter a valid email address
                                </Box>
                            ),
                        })
                    } else {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    You have already registered.
                                </Box>
                            ),
                        })
                        // alert("You have already Registered")
                    }
                }

            })
            .catch((err) => (console.log("error", err)));

    }






    /// Account forget password



    const RegAccountpass = (e) => {
        setResetacc(e.target.value)
    }


    const getResetPassword = (e) => {
        e.preventDefault()
        resetAccountpassword(resetacc).then(responseJson => {
            if (responseJson) {
                // alert(responseJson['message'])
                toast({
                    position: 'top',
                    render: () => (
                        <Box
                            color='white'
                            p={3} bg='#219691'
                            textAlign={'center'}
                            fontWeight='bold'
                        >
                            {responseJson['message']}
                        </Box>
                    ),
                })
                //console.log("response---", responseJson)
            }
        }).catch(err => console.log(err))
    }



    // console.log(shortName);

    let allCurrencies = JSON.parse(localStorage.getItem("currencyExchange"));
    if (allCurrencies == undefined) {
        allCurrencies = 1;
    } else {
        allCurrencies = JSON.parse(localStorage.getItem("currencyExchange"));
    }

    useEffect(() => {
        if (allCurrencies) {
            if (shortName == 'USD') {
                localStorage.setItem("convertedCurrency", allCurrencies.USD);
                localStorage.setItem("changeUser", '$')
                localStorage.setItem("currencyName", 'usd')
            }
            else if (shortName == 'GBP') {
                localStorage.setItem("convertedCurrency", allCurrencies.GBP);
                localStorage.setItem("changeUser", '£')
                localStorage.setItem("currencyName", 'gbp')
            }
            else if (shortName == 'CHF') {
                localStorage.setItem("convertedCurrency", allCurrencies.CHF);
                localStorage.setItem("changeUser", '₣')
                localStorage.setItem("currencyName", 'chf')
            }
            else if (shortName == 'CAD') {
                localStorage.setItem("convertedCurrency", allCurrencies.CAD);
                localStorage.setItem("changeUser", '¢')
                localStorage.setItem("currencyName", 'caf')
            }
            else if (shortName == 'JPY') {
                localStorage.setItem("convertedCurrency", allCurrencies.JPY);
                localStorage.setItem("changeUser", '¥')
                localStorage.setItem("currencyName", 'jpy')
            }
            else if (shortName == 'PLN') {
                localStorage.setItem("convertedCurrency", allCurrencies.PLN);
                localStorage.setItem("changeUser", 'zł')
                localStorage.setItem("currencyName", 'pln')
            }
            else if (shortName == 'ZAR') {
                localStorage.setItem("convertedCurrency", allCurrencies.ZAR);
                localStorage.setItem("changeUser", 'R')
                localStorage.setItem("currencyName", 'zar')
            }
            else if (shortName == 'AUD') {
                localStorage.setItem("convertedCurrency", allCurrencies.AUD);
                localStorage.setItem("changeUser", '$')
                localStorage.setItem("currencyName", 'aud')
            }
            else if (shortName == 'INR') {
                localStorage.setItem("convertedCurrency", allCurrencies.INR);
                localStorage.setItem("changeUser", '₹')
                localStorage.setItem("currencyName", 'inr')
            }
            else if (shortName == 'IDR') {
                localStorage.setItem("convertedCurrency", allCurrencies.INR);
                localStorage.setItem("changeUser", 'Rp')
                localStorage.setItem("currencyName", 'idr')
            }
            else if (shortName == 'RUB') {
                localStorage.setItem("convertedCurrency", allCurrencies.RUB)
                localStorage.setItem("changeUser", '₽')
                localStorage.setItem("currencyName", 'rub')
            }
            else if (shortName == 'Yuan') {
                localStorage.setItem("convertedCurrency", allCurrencies.CNY)
                localStorage.setItem("changeUser", '¥')
                localStorage.setItem("currencyName", 'cny')
            }
            else if (shortName == 'CNY') {
                localStorage.setItem("convertedCurrency", allCurrencies.CNY)
                localStorage.setItem("changeUser", '¥')
                localStorage.setItem("currencyName", 'cny')
            }
            else {
                localStorage.setItem("convertedCurrency", allCurrencies.EUR ? allCurrencies.EUR : 1)
                localStorage.setItem("changeUser", '€')
                localStorage.setItem("currencyName", 'eur')
            }
            // console.log("newCurrencyConverted", allCurrencies.shortName);
        }
    }, [shortName]);






    Emitter.on("SIGN_IN", (e) => { setRequestSignin(e) });
    // console.log("sign", requestSignin)

    useEffect(() => {
        // alert("true")
        if (requestSignin) {
            setDropdown(true);
        }
    }, [requestSignin]);

    /// Account Sign In

    useEffect(() => {
        const accToken = localStorage.getItem("loginToken")
        setLoginToken(accToken);
        setToken(accToken);
        if (token) {
            getUserInfo();
        }
    }, [loginToken])



    const getRegisterDetails = (e) => {
        e.preventDefault()
        login(getuser_name, getpassword, user_Type)
            .then((responseJson) => {
                if (responseJson) {
                    if (responseJson['token']) {
                        if (responseJson["user_type"] == "buyer") {
                            localStorage.setItem("loginToken", responseJson['token'])
                            localStorage.setItem("loginUser_id", responseJson['user_id'])
                            setToken(responseJson['token']);
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        {responseJson["message"]}
                                    </Box>
                                ),
                            })
                            getListedCart(responseJson['token']).then((res) => {
                                if (res) {
                                    // setOldCart(res.cart_lines);

                                    if (!!Object.keys(res.data)) {
                                        const { data: {
                                            cart_lines = [],
                                        } } = res
                                        const parsedData = cart_lines.map(({
                                            my_varient = [],
                                            quantity = "",
                                            product_details: { id = "", PRDPrice = "", product_name = "", product_brand_name = "", product_image = "", product_vendor = "" }

                                        }) => {
                                            return {
                                                PRDPrice: PRDPrice,
                                                cartQuantity: Number(quantity),
                                                id: id,
                                                product_brand_name: product_brand_name,
                                                product_name: product_name,
                                                product_image: product_image,
                                                product_vendor: product_vendor,
                                                product_varient: my_varient,
                                                varient: my_varient[0]?.id || "",
                                            }
                                        })
                                        const parsedData1 = cart1.cartItems.map(({
                                            PRDPrice = '',
                                            cartQuantity = '',
                                            id = '',
                                            product_brand_name = '',
                                            product_image = '',
                                            product_name = '',
                                            product_varient = [],
                                            product_vendor = '',
                                            varient = ''

                                        }) => {
                                            return {
                                                id: id,
                                                varient: varient,
                                                cartQuantity: cartQuantity,
                                            }
                                        })
                                        const notAvailableInArray1 = parsedData.filter(item => !parsedData1.some(item1 => item1.id === item.id && item1.variant === item.variant));
                                        const notAvailableInArray2 = parsedData1.filter(item => !parsedData.some(item1 => item1.id === item.id && item1.variant === item.variant && item1.cartQuantity == item.cartQuantity));
                                        if (notAvailableInArray2.length > 0) {
                                            notAvailableInArray2?.map((item, index) => {

                                                getAddUpdateCart(responseJson['token'], item.id, item.cartQuantity, item.varient)
                                                // dispatch(addToCart(combinedObject));
                                            })
                                        }
                                        let cartI = localStorage.getItem('cartItems');
                                        if (cartI == null) {
                                            // console.log("true")
                                            localStorage.setItem("cartItems", [""])
                                        } else {
                                            // console.log("false")
                                        }
                                        if (notAvailableInArray1.length > 0) {
                                            notAvailableInArray1?.map((item, index) => {

                                                let ele = parsedData[index]
                                                let combinedObject = { ...ele, "varient": item.varient }

                                                for (let i = 0; i < item.cartQuantity; i++) {
                                                    dispatch(addToCart(combinedObject));
                                                }

                                            })
                                        }

                                    }
                                }
                            })


                            setOverview(false)
                            setDropdown(false)
                            setOrders(false)
                            if (token) {
                                getUserInfo()
                            }


                            setDropdown(false)
                        } else {
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        Please enter valid credentials to login
                                    </Box>
                                ),
                            })

                        }

                    } else if (responseJson["invalid_login"]) {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {responseJson["invalid_login"]}
                                </Box>
                            ),
                        })
                    }

                }
            })
            .catch((err) => {
                console.log(err)
            })

    }

    /// My Profile Get Details    

    // const onBioChange = (e) => {
    //     setBio(e.target.value);
    // }
    const onFirstnameChange = (e) => {
        setFirstname(e.target.value);
    }
    const onLastnameChange = (e) => {
        setLastname(e.target.value);
    }
    const onAddressChange = (e) => {
        setAddress(e.target.value);
    }
    const onCityChange = (e) => {
        setCity(e.target.value)
    }
    const onCodeChange = (e) => {
        setCode(e.target.value)
    }
    const onCountryChange = (e) => {
        setCountry(e.target.value);
    }
    // const onUsernameChange = (e) => {
    //     setUser_name(e.target.value);
    // }
    // const onEmailChange = (e) => {

    //     setEmail(e.target.value);
    // }
    const onPhoneChange = (e) => {
        setPhoneno(e.target.value);
    }
    // console.log("phone-----", phoneno)



    ///// get buyer profile


    const getUserInfo = () => {
        getAccountProfile(token)
            .then((responseJson) => {
                if (responseJson) {
                    let userDetails = responseJson.user_details || {};
                    setUserId(userDetails.id || "");
                    setBio(userDetails.user_profile.bio || "")
                    setFirstname(userDetails.first_name || "");
                    setLastname(userDetails.last_name || "");
                    setAddress(userDetails.user_profile.address || "");
                    setCity(userDetails.user_profile.city || "");
                    setCode(userDetails.user_profile.post_code || "");
                    setCountry(userDetails.user_profile.country || "");
                    setUser_name(userDetails.username || "")
                    setPhoneno(userDetails.user_profile.mobile_number);

                }
            })
            .catch((err) => { console.log(err) })


    }


    /// Update Buyer Profile

    const getUpdatedProfile = (e) => {
        e.preventDefault()

        getuserupdateprofile(loginToken, firstname, lastname, bio, address, city, country, email, phoneno, user_name, code, userId)
            .then((responseJson) => {
                if (responseJson) {
                    if (responseJson["user_details"]) {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    Profile updated successfully
                                </Box>
                            ),
                        })
                        // alert("Profile updated successfully.")
                    }

                }
            })
            .catch((err) => (console.log("error", err)))
    }


    const { orderId, client_email } = returnOrder

    const getReturnOrder = (e) => {
        setReturnOrder({ ...returnOrder, [e.target.name]: e.target.value })
    }



    const { orderIdGet, getClient_email } = order

    const getOrder = (e) => {
        setOrder({ ...order, [e.target.name]: e.target.value })
    }

    // console.log("return", getReturnDeatils)
    const getReturnedProductInfo = (e) => {
        e.preventDefault()
        getReturnOrderItem(loginToken, orderId, client_email).then(responseJson => {
            if (responseJson.merchant_order_id[0] == "Invalid order id") {
                toast({
                    position: 'top',
                    render: () => (
                        <Box
                            color='white'
                            p={3} bg='#219691'
                            textAlign={'center'}
                            fontWeight='bold'
                        >
                            Please enter valid order id
                        </Box>
                    ),
                })


            }
            else {
                setReturnItem(true)
                setReturnPro(false)
                setGetReturnDeatils(responseJson || [])
            }


        }).catch(err => console.log(err))
    }


    return (
        <ChakraProvider
            className='header_wrapper'
            theme={theme}
            resetCss={false}
        >

            <Flex
                className="header_order_summary"
                pl='2%'
                pr='2%'
            >

                <Menu>
                    <MenuButton
                        className='hamburger_btn'
                    // bg='#FFFFFF'
                    >
                        <Flex
                            // w={{sm:'30%',lg:'50%'}}
                            h='80%'
                            w='100%'
                            // bg='#219691'
                            onClick={openHam}
                            justifyContent="space-between"
                            cursor={'pointer'}
                        >

                            {/* <Image
                                className='header_wrapper_drop'
                                cursor={'pointer'}
                                w='40%                                                                                                                                                                                                                                                                                                                                   '
                                h='100%'
                                mt='3'
                                src={hamer}
                                onClick={openHam}
                            /> */}
                            <HamburgerIcon

                                w='40px'
                                h='30px'
                                onClick={openHam}
                            />


                        </Flex>
                    </MenuButton>

                    <MenuList w='50%'>
                        <MenuItem
                        >
                            <Flex
                                flexDirection='column'>
                                <Flex
                                    // justifyContent='space-between'
                                    mb='3' mt='5'

                                >
                                    <Image
                                        className='header_wrapper_hamer'
                                        w={{ sm: "1%", md: "10%", lg: "6%" }}
                                        h="8%"
                                        src={hamer}

                                    />
                                    {/* <Text
                                        display='flex'
                                        justifyContent='center'
                                        margin='0 auto'
                                        fontSize='2xl'
                                        fontWeight='bold'
                                        color='#219691'
                                        fontFamily={"'Quicksand', sans-serif"}
                                    >
                                        MY BOUTIQ
                                    </Text> */}
                                    <Image
                                        src={logo}
                                        width={{ sm: '50%', md: "25%", lg: "30%" }}
                                        // width='25%'
                                        margin={'0 auto'}
                                    />
                                    <CloseIcon mt='1'
                                        onClick={closeHam}

                                    />
                                </Flex>

                                {/* Hamburger - Details */}


                                {!token ?
                                    <Flex
                                        flexDirection='column'
                                        alignItems='center' mt='5'
                                        display={{ sm: "none", md: "flex", xl: "flex" }}
                                    >
                                        <p className='home_line' ></p>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            onClick={openDropdown}
                                            cursor='pointer'>
                                            Account Login
                                        </Text>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            onClick={openOrders}
                                            cursor='pointer'>
                                            My Orders
                                        </Text>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            mb='20'
                                            onClick={openReturnPro}
                                            cursor='pointer'>
                                            Return Item
                                        </Text>
                                    </Flex>

                                    :

                                    <Flex
                                        flexDirection='column'
                                        alignItems='center' mt='5'
                                        display={{ sm: "none", md: "flex", xl: "flex" }}
                                    >
                                        <p className='home_line' ></p>

                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            onClick={openOrders}
                                            cursor='pointer'>
                                            My Orders
                                        </Text>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'

                                            onClick={openReturnPro}
                                            cursor='pointer'>
                                            Return Item
                                        </Text>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            mb='20'
                                            onClick={() => {
                                                localStorage.removeItem("loginToken")
                                                location.reload()
                                            }}
                                            cursor='pointer'>
                                            Logout
                                        </Text>
                                    </Flex>
                                }
                                {!token ?
                                    <Flex
                                        flexDirection='column'
                                        alignItems='center' mt='5'
                                        display={{ sm: "flex", md: "none", xl: "none" }}
                                    >
                                        <p className='home_line' ></p>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            onClick={openDropdown}
                                            cursor='pointer'>
                                            Account Login
                                        </Text>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            onClick={openOrders}
                                            cursor='pointer'>
                                            My Orders
                                        </Text>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            mb='5'
                                            onClick={openReturnPro}
                                            cursor='pointer'>
                                            Return Item
                                        </Text>

                                        <Flex
                                            justifyContent={'center'}
                                            display='flex'
                                            margin={'0 auto'}
                                            flexDir='column'
                                        >
                                            <Select w='150px' mr='3'>
                                                <option value='option1'>Bulgarian</option>
                                                <option value='option2'>Chinese</option>
                                                <option value='option2'>Czech</option>
                                                <option value='option2'>Danish</option>
                                                <option value='option2'>Dutch</option>
                                                <option value='option2'>English</option>
                                                <option value='option2'>Estonian</option>
                                                <option value='option2'>Finnish</option>
                                                <option value='option2'>French</option>
                                                <option value='option2'>German</option>
                                                <option value='option2'>Greek</option>
                                                <option value='option2'>Hungarian</option>
                                                <option value='option2'>Indonesian</option>
                                                <option value='option2'>Italian</option>
                                                <option value='option2'>Japanese</option>
                                                <option value='option2'>Latvian</option>
                                                <option value='option2'>Lithuanian</option>
                                                <option value='option2'>Polish</option>
                                                <option value='option2'>Portuguese</option>
                                                <option value='option2'>Romanian</option>
                                                <option value='option2'>Russian</option>
                                                <option value='option2'>Slovak</option>
                                                <option value='option2'>Slovenian</option>
                                                <option value='option2'>Spanish</option>
                                                <option value='option2'>Swedish</option>
                                                <option value='option2'>Turkish</option>
                                                <option value='option2'>Ukrainian</option>
                                            </Select>
                                            <Select onChange={changeCurrency}
                                                className='decorated'
                                                w='150px' mr='3' mt='5'
                                            >
                                                <option>EUR</option>
                                                {storeCurrency.map((ele, index) => {

                                                    return (

                                                        <option value={ele.short_name} key={index}>

                                                            {ele.short_name}


                                                        </option>

                                                    )

                                                })}
                                            </Select>
                                        </Flex>

                                    </Flex>
                                    :
                                    <Flex
                                        flexDirection='column'
                                        alignItems='center' mt='5'
                                        display={{ sm: "flex", md: "none", xl: "none" }}
                                    >
                                        <p className='home_line' ></p>

                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            onClick={openOrders}
                                            cursor='pointer'>
                                            My Orders
                                        </Text>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            onClick={openReturnPro}
                                            cursor='pointer'>
                                            Return Item
                                        </Text>
                                        <Text
                                            fontWeight='bold'
                                            fontSize='lg' mt='8'
                                            mb='5'
                                            onClick={() => {
                                                localStorage.removeItem("loginToken")
                                                location.reload()
                                            }}
                                            cursor='pointer'>
                                            Logout

                                        </Text>
                                        <Flex
                                            justifyContent={'center'}
                                            display='flex'
                                            margin={'0 auto'}
                                            flexDir='column'
                                        >
                                            <Select w='150px' mr='3'>
                                                <option value='option1'>Bulgarian</option>
                                                <option value='option2'>Chinese</option>
                                                <option value='option2'>Czech</option>
                                                <option value='option2'>Danish</option>
                                                <option value='option2'>Dutch</option>
                                                <option value='option2'>English</option>
                                                <option value='option2'>Estonian</option>
                                                <option value='option2'>Finnish</option>
                                                <option value='option2'>French</option>
                                                <option value='option2'>German</option>
                                                <option value='option2'>Greek</option>
                                                <option value='option2'>Hungarian</option>
                                                <option value='option2'>Indonesian</option>
                                                <option value='option2'>Italian</option>
                                                <option value='option2'>Japanese</option>
                                                <option value='option2'>Latvian</option>
                                                <option value='option2'>Lithuanian</option>
                                                <option value='option2'>Polish</option>
                                                <option value='option2'>Portuguese</option>
                                                <option value='option2'>Romanian</option>
                                                <option value='option2'>Russian</option>
                                                <option value='option2'>Slovak</option>
                                                <option value='option2'>Slovenian</option>
                                                <option value='option2'>Spanish</option>
                                                <option value='option2'>Swedish</option>
                                                <option value='option2'>Turkish</option>
                                                <option value='option2'>Ukrainian</option>
                                            </Select>
                                            <Select onChange={changeCurrency}
                                                className='decorated'
                                                w='150px' mr='3' mt='5'
                                            >
                                                <option>EUR</option>
                                                {storeCurrency.map((ele, index) => {

                                                    return (

                                                        <option value={ele.short_name} key={index}>

                                                            {ele.short_name}


                                                        </option>

                                                    )

                                                })}
                                            </Select>
                                        </Flex>

                                    </Flex>
                                }
                            </Flex>
                        </MenuItem>

                    </MenuList>


                    {/*1....Header Hamburger...... Get Account Login */}


                    <Modal
                        isOpen={dropdown}
                        size='xl'
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader
                                onClick={closeHam}
                                className='hamburge_modal'
                                mt='5'
                            >
                                {/* <Text
                                    className='product_hamburger_text'
                                    color='#219691'
                                    display='flex'
                                    margin='0 auto'
                                >
                                    MY BOUTIQ
                                </Text> */}
                                <Image
                                    src={logo}
                                    width={{ sm: '50%', md: "25%", lg: "30%" }}
                                    // width='25%'
                                    margin={'0 auto'}
                                />

                                <CloseIcon
                                    cursor='pointer'
                                    onClick={closeDropdown}
                                />
                            </ModalHeader>

                            <ModalBody>

                                <div
                                // className={dropdown == true ? "hamburgOpen" : "hamburgClose"}
                                >
                                    <p className='home_line' ></p>
                                    <Text
                                        ml='14'
                                        fontWeight='bold'
                                        fontSize='lg' mt='5'
                                        onClick={openDropdown}
                                    // cursor='pointer'
                                    >
                                        Account Login
                                    </Text>

                                    <form action=""
                                        onSubmit={getRegisterDetails}>
                                        <Flex mt='5' m='3'
                                            alignItems='center'
                                            flexDirection='column'
                                        >
                                            <Input
                                                text="email"
                                                required
                                                mt='5'
                                                size='lg'
                                                w='80%'
                                                onChange={(e) => setGetusername(e.target.value)}
                                                placeholder='Username'
                                            />
                                            <FormControl pt="20px">

                                                <InputGroup
                                                    size='lg'
                                                    w='80%'
                                                    mt='5'
                                                    margin='0 auto'
                                                >
                                                    <Input
                                                        required
                                                        onChange={(e) => setGetpassword(e.target.value)}
                                                        type={showold ? 'text' : 'password'}
                                                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                        title="Must contain at least one number and one uppercase and 
                                                        lowercase letter, and at least 8 or more characters"
                                                        placeholder='Password'
                                                        mb='24px'
                                                        h="50px"
                                                    />
                                                    <InputRightElement py="25px">
                                                        <Button
                                                            h='1.75rem'
                                                            size='xs'
                                                            onClick={showOldPass}>
                                                            {showold ? <BiHide /> : <BiShow />}
                                                        </Button>
                                                    </InputRightElement>
                                                </InputGroup>
                                            </FormControl>
                                            <Button
                                                mt='10'
                                                w='80%'
                                                colorScheme='none'
                                                bg="#219691"
                                                type='submit'>
                                                Sign In
                                            </Button>
                                        </Flex>
                                    </form>
                                    <Flex mt='5'
                                        mb='20'
                                        gap='10'
                                        className='header_wrapper_member'
                                    >
                                        <Text
                                            onClick={openSignup}
                                            margin='0 auto'
                                        >
                                            Not a member ?
                                            <span
                                                style={{ textDecoration: "underline", marginLeft: '10px', cursor: 'pointer' }}>
                                                Sign up
                                            </span>
                                        </Text>
                                        <Text
                                            onClick={openForgetpass}
                                            margin='0 auto'
                                            textDecoration='underline'
                                            cursor={'pointer'}
                                        >
                                            Forget Password
                                        </Text>
                                    </Flex>

                                </div>

                            </ModalBody>
                        </ModalContent>
                    </Modal>


                    {/* Account Login - Forget password */}


                    <Modal blockScrollOnMount={false}
                        isOpen={forgetpass}
                        size='2xl'
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                <Flex
                                    justifyContent='space-between'
                                    mt='5'>
                                    {/* <Text

                                        textAlign='center'
                                        flex='auto'
                                        color='#219691'>
                                        MY BOUTIQ
                                    </Text> */}
                                    <Image
                                        src={logo}
                                        width={{ sm: '50%', md: "25%", lg: "30%" }}
                                        // width='25%'
                                        margin={'0 auto'}
                                    />
                                    <CloseIcon
                                        cursor='pointer'
                                        mt='1'
                                        onClick={closeForgetpass}
                                    />
                                </Flex>

                            </ModalHeader>

                            <ModalBody>
                                <p className='home_line' ></p>
                                <Text
                                    ml='14'
                                    fontWeight='bold'
                                    fontSize='lg' mt='5'
                                    onClick={openDropdown}
                                >
                                    Forget Password
                                </Text>
                                <form action=""
                                    onSubmit={getResetPassword}
                                >

                                    <Flex mt='5' m='3'
                                        alignItems='center'
                                        flexDirection='column'
                                    >

                                        <Input
                                            text="email"
                                            mr='3'
                                            required
                                            mt='5'
                                            size='lg'
                                            w='80%'
                                            // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            // title="Must contain at least one number and one uppercase and 
                                            //             lowercase letter, and at least 8 or more characters"
                                            value={resetacc}
                                            name='resetacc'
                                            onChange={RegAccountpass}
                                            placeholder='Email'
                                        />

                                        <Button
                                            mt='10'
                                            w='80%'
                                            mb='10'
                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            title="Must contain at least one number and one uppercase and 
                                                        lowercase letter, and at least 8 or more characters"
                                            colorScheme='none'
                                            bg="#219691"
                                            type='submit'>
                                            Reset Password
                                        </Button>


                                    </Flex>
                                </form>
                            </ModalBody>

                        </ModalContent>
                    </Modal>

                    {/* Account Login - signu up modal */}


                    <Modal blockScrollOnMount={false}
                        isOpen={signup}
                        size='xl'
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                <Flex
                                    justifyContent='space-between'
                                    mt='5'>
                                    {/* <Text
                                        textAlign='center'
                                        flex='auto'
                                        color="#219691">
                                        MY BOUTIQ
                                    </Text> */}
                                    <Image
                                        src={logo}
                                        width={{ sm: '50%', md: "25%", lg: "30%" }}
                                        // width='25%'
                                        margin={'0 auto'}
                                    />
                                    <CloseIcon
                                        cursor='pointer'
                                        mt='1'
                                        onClick={closesignup}

                                    />
                                </Flex>


                            </ModalHeader>

                            <ModalBody>
                                <p className='home_line' ></p>
                                <Text
                                    ml='16'
                                    fontWeight='bold'
                                    fontSize='lg' mt='5'
                                    onClick={openDropdown}
                                    cursor='pointer'>
                                    Sign up
                                </Text>
                                <form
                                    onSubmit={getRegistrationdetails}>

                                    <Flex
                                        mt='5' m='3'
                                        alignItems='center'
                                        flexDirection='column'
                                    >
                                        <Input
                                            text="email"
                                            required
                                            mt='5'
                                            size='lg'
                                            w='80%'
                                            onChange={RegAccountLogin}
                                            value={username}
                                            name='username'
                                            placeholder='Username'
                                        />
                                        <Input
                                            text="email"
                                            required
                                            mt='5'
                                            size='lg'
                                            w='80%'
                                            onChange={RegAccountLogin}
                                            value={email}
                                            name='email'
                                            placeholder='Email'
                                        />
                                        <FormControl pt="20px">

                                            <InputGroup
                                                size='lg'
                                                w='80%'
                                                mt='5'
                                                margin='0 auto'
                                            >
                                                <Input
                                                    text="email"
                                                    required
                                                    type={showAcc ? 'text' : 'password'}
                                                    name='password'
                                                    value={password}
                                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                    title="Must contain at least one number and one uppercase and 
                                                        lowercase letter, and at least 8 or more characters"
                                                    onChange={RegAccountLogin}
                                                    placeholder='Password'
                                                />
                                                <InputRightElement py="25px">
                                                    <Button h='1.75rem'
                                                        size='xs'
                                                        onClick={showAccPass}>
                                                        {showAcc ? <BiHide /> : <BiShow />}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        </FormControl>
                                        <Button
                                            mt='10'
                                            w='80%'
                                            mb='20'
                                            // onClick={openDropdown}
                                            colorScheme='none'
                                            bg="#219691"
                                            type='submit'
                                        >
                                            Sign Up
                                        </Button>
                                    </Flex>
                                </form>
                            </ModalBody>

                        </ModalContent>
                    </Modal>



                    {/* Account - My Profile Details */}


                    <Modal
                        isOpen={myProfile}
                        size='5xl'
                    >
                        <ModalOverlay />
                        <ModalContent>
                            <Menu>
                                <ModalHeader
                                    display='flex'
                                    mt='5'>
                                    <MenuButton
                                        className='myprofile_header'
                                        onClick={overviewHam} >
                                        <Image ml='5'
                                            w='8%'
                                            h='8%'
                                            src={hamer}
                                        />

                                    </MenuButton>
                                    <Text
                                        className='product_hamburger_inner_text'
                                        fontSize='2xl'
                                    >
                                        {/* <Text
                                            color='#219691'
                                            fontWeight='bold'>
                                            MY BOUTIQ
                                        </Text> */}
                                        <Image
                                            src={logo}
                                            width={{ sm: '50%', md: "25%", lg: "30%" }}
                                            // width='25%'
                                            margin={'0 auto'}
                                        />
                                        <CloseIcon mr='6'
                                            w='6%'
                                            h='50%'
                                            cursor='pointer'
                                            onClick={getCloseprofileDetails}
                                        />
                                    </Text>
                                    <MenuList>
                                        <MenuItem
                                            className='inner_hamer_login'>
                                            <Flex
                                                flexDirection='column'
                                                w='50vh' top='0'>
                                                <Flex
                                                    justifyContent='space-between'
                                                    mb='3' mt='5' >
                                                    {/* <Text
                                                        fontSize='2xl'
                                                        color='#219691'
                                                        className='product_inner_hamer'
                                                    >
                                                        MY BOUTIQ
                                                    </Text> */}
                                                    <Image
                                                        src={logo}
                                                        width={{ sm: '50%', md: "25%", lg: "30%" }}
                                                        // width='25%'
                                                        margin={'0 auto'}
                                                    />
                                                    <CloseIcon mt='1'
                                                        onClick={closeHam} />
                                                </Flex>
                                                <Flex
                                                    flexDirection='column'
                                                    alignItems='center' mt='5'>
                                                    <p className='home_line' ></p>
                                                    <Text
                                                        fontWeight='bold'
                                                        fontSize='lg' mt='8'
                                                        onClick={getmyaccount}
                                                        cursor='pointer'>
                                                        My Account
                                                    </Text>
                                                    <Text
                                                        fontWeight='bold'
                                                        fontSize='lg' mt='8'
                                                        onClick={getMyorders}
                                                        cursor='pointer'>
                                                        My Orders
                                                    </Text>
                                                    <Text
                                                        fontWeight='bold'
                                                        fontSize='lg' mt='8'
                                                        onClick={openReturnPro}
                                                        cursor='pointer'>
                                                        Logout
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </MenuItem>
                                    </MenuList>

                                </ModalHeader>
                            </Menu>

                            <ModalBody
                                display='flex'
                                justifyContent='space-around'
                                flexDirection='column'
                            >
                                <form action=""
                                    onSubmit={getUpdatedProfile}>
                                    <Text
                                        fontSize='2xl'
                                        mt='10'
                                    >
                                        MY PROFILE
                                    </Text>
                                    <Flex
                                        justifyContent='space-between'
                                        className='product_my_profile'
                                        mt='10'
                                    >
                                        <Flex
                                            flexDirection='column'>
                                            <Box bg='#219691' p={4}
                                                color='white'
                                                mt='5'
                                                w={{ sm: '28vh', md: '38vh', lg: '48vh' }}
                                                display='flex'
                                                fontSize='xl'
                                                fontWeight='bold'
                                                borderRadius='10px'
                                            >
                                                Profile Information
                                            </Box>
                                            <Text
                                                mt='10'
                                                ml='3'>
                                                Location / Country
                                            </Text>
                                            <Select
                                                placeholder='Select option'
                                                mt='2'>
                                                <option
                                                    value={country}
                                                    name={country}
                                                    onChange={onCountryChange}>
                                                    {country}
                                                </option>
                                            </Select>
                                            <Flex
                                                gap='2'
                                                mt='10'>
                                                <FormControl >
                                                    <FormLabel
                                                        ml='2'>
                                                        First Name
                                                    </FormLabel>
                                                    <Input
                                                        required
                                                        pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                                        // title='only characters are allowed'
                                                        name="firstname"
                                                        onChange={onFirstnameChange}
                                                        value={firstname}
                                                    />
                                                </FormControl>
                                                <FormControl >
                                                    <FormLabel
                                                        ml='2'>
                                                        Last Name
                                                    </FormLabel>
                                                    <Input
                                                        required
                                                        pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                                        // title='only characters are allowed'
                                                        name="lastname"
                                                        onChange={onLastnameChange}
                                                        value={lastname} />
                                                </FormControl>
                                            </Flex>
                                            <FormControl>
                                                <FormLabel
                                                    ml='2' mt='8'>
                                                    Phone Number
                                                </FormLabel>
                                                <Input
                                                    required
                                                    maxLength="10"
                                                    pattern="[0-9]{1}[0-9]{9}"
                                                    // title="Please enter 10 digit number,Please enter numbers only,"
                                                    fontSize='sm'
                                                    borderRadius='15px'
                                                    onChange={onPhoneChange}
                                                    name="phoneno"
                                                    value={phoneno}

                                                    // pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                                    placeholder='Phone Number'

                                                />
                                            </FormControl>

                                            <FormControl>
                                                <FormLabel
                                                    ml='2'
                                                    mt='8'>
                                                    Address - 1 line
                                                </FormLabel>
                                                <Input
                                                    required
                                                    pattern='^[#.0-9a-zA-Z\s,-]+$'
                                                    name="address"
                                                    onChange={onAddressChange}
                                                    value={address} />
                                            </FormControl>
                                            <FormControl >
                                                <FormLabel ml='2' mt='10'>
                                                    Address - 2 line
                                                </FormLabel>
                                                <Input
                                                    required
                                                    pattern='^[#.0-9a-zA-Z\s,-]+$'
                                                    name="address"
                                                    onChange={onAddressChange}
                                                    value={address} />
                                            </FormControl>
                                            <Flex gap='2' mt='10'>
                                                <FormControl >
                                                    <FormLabel ml='2'>
                                                        Post Code
                                                    </FormLabel>
                                                    <Input
                                                        required
                                                        name="code"
                                                        onChange={onCodeChange}
                                                        value={code} />
                                                </FormControl>
                                                <FormControl >
                                                    <FormLabel ml='2'>
                                                        Town / City
                                                    </FormLabel>
                                                    <Input
                                                        required
                                                        pattern="^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$"
                                                        name="city"
                                                        onChange={onCityChange}
                                                        value={city} />
                                                </FormControl>
                                            </Flex>
                                        </Flex>
                                        <Flex flexDirection='column'>
                                            <Button bg='#219691' p={4}
                                                color='white'
                                                mt='5'
                                                size='xl'
                                                colorScheme='none'
                                                onClick={ResetPassword}
                                                borderRadius='10px'
                                                display='flex'
                                                fontSize='xl'
                                                fontWeight='bold'
                                            >
                                                Change Password
                                            </Button>

                                            <Button
                                                color='white'
                                                mt='5'
                                                bg='#219691' p={4}
                                                size='xl'
                                                colorScheme='none'
                                                // onClick={ResetPassword}
                                                borderRadius='10px'
                                                display='flex'
                                                fontSize='xl'
                                                fontWeight='bold'
                                            >

                                                Special MY BOUTIQ Updates
                                            </Button>
                                            <Flex gap='10' mt='5'>
                                                <Checkbox ml='2'
                                                    size='lg' colorScheme='red'>

                                                </Checkbox>
                                                <Text mt='2'>
                                                    Get access to our best events, deals, tips & tricks.
                                                    <br />
                                                    No spam. we hate it more than you do!
                                                </Text>
                                            </Flex>

                                        </Flex>
                                    </Flex>
                                    <Button
                                        className='profile_updated_value'
                                        color='white'
                                        mt='10'
                                        type='submit'
                                        colorScheme='none'
                                        bg="#219691"
                                        size='lg'
                                    >
                                        Save
                                    </Button>
                                </form>
                            </ModalBody>

                            <Box bg='#219691' w='100%' p={20}
                                color='white'
                                mt='20'
                                display='flex'
                                gap='20'
                            >
                            </Box>
                        </ModalContent>
                    </Modal>


                    {/* Account - My Profile Details  - Reset Password */}


                    <Modal
                        isOpen={resetPass}
                        size='xl'>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>
                                <Flex
                                    justifyContent='space-between'>
                                    <Text>
                                        Change Password
                                    </Text>
                                    <CloseIcon
                                        cursor='pointer'
                                        mt='1'
                                        onClick={closeResetpass} />
                                </Flex>


                            </ModalHeader>
                            <ModalBody>

                                <FormControl >
                                    <FormLabel ml='2' mt='10'>
                                        Old Password
                                    </FormLabel>
                                    <InputGroup>
                                        <Input
                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            title="Password Must contain at least one number and one uppercase and 
                                                        lowercase letter, and at least 8 or more characters"
                                            type={showold ? 'text' : 'password'}
                                            placeholder='Password'
                                            mb='24px'

                                            h="50px"
                                        />
                                        <InputRightElement py="25px">
                                            <Button h='1.75rem'
                                                size='xs' onClick={showOldPass}>
                                                {showold ? <BiHide /> : <BiShow />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <FormControl >
                                    <FormLabel ml='2' >
                                        New Password
                                    </FormLabel>
                                    <InputGroup>
                                        <Input
                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            title="Password Must contain at least one number and one uppercase and 
                                                        lowercase letter, and at least 8 or more characters"
                                            type={showNew ? 'text' : 'password'}
                                            placeholder='Password'
                                            mb='24px'

                                            h="50px"
                                        />
                                        <InputRightElement py="25px">
                                            <Button h='1.75rem' size='xs'
                                                onClick={showNewPass}>
                                                {showNew ? <BiHide /> : <BiShow />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <FormControl >
                                    <FormLabel ml='2' >Confirm Password</FormLabel>
                                    <InputGroup>
                                        <Input
                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            title="password Must contain at least one number and one uppercase and 
                                                        lowercase letter, and at least 8 or more characters"
                                            type={showConf ? 'text' : 'password'}
                                            placeholder='Password'
                                            mb='24px'

                                            h="50px"
                                        />
                                        <InputRightElement py="25px">
                                            <Button h='1.75rem' size='xs' onClick={showConfPass}>
                                                {showConf ? <BiHide /> : <BiShow />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <Button
                                    m='0 auto'
                                    colorScheme='none'
                                    bg="#219691"
                                    p={4}
                                    color='white'
                                    mt='16'
                                    size='lg'
                                    mb='20'
                                    display='flex'
                                    justifyContent='center'
                                >

                                    Update Password
                                </Button>
                            </ModalBody>

                        </ModalContent>
                    </Modal>


                    {/*2....Header Hamburger....... Get My Orders Modal ---- sign in  */}


                    <Modal
                        isOpen={orders}
                        size='2xl' >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader
                                onClick={closeHam}
                                className='hamburge_modal' mt='5'>
                                {/* <Text
                                    className='product_hamburger_text'
                                    color='#219691'
                                >
                                    MY BOUTIQ
                                </Text> */}
                                <Image
                                    src={logo}
                                    width={{ sm: '50%', md: "25%", lg: "30%" }}
                                    // width='25%'
                                    margin={'0 auto'}
                                />

                                <CloseIcon
                                    cursor='pointer'
                                    onClick={closeOrders} />
                            </ModalHeader>

                            <ModalBody>

                                <div
                                    className={orders ? "hamburgOpen" : "hamburgClose"}
                                >
                                    <p className='home_line' ></p>
                                    <Text ml='14'
                                        fontWeight='bold'
                                        fontSize='lg' mt='5'
                                        onClick={openOrders}
                                        cursor='pointer'>
                                        My Orders
                                    </Text>
                                    <form onSubmit={getAccountorderDetails}>
                                        <Flex mt='5' mb='10'
                                            alignItems='center'
                                            flexDirection='column'
                                            justifyContent='space-between'>

                                            <Input
                                                text="email"
                                                mr='3'
                                                required
                                                placeholder='Email'
                                                w='80%'
                                                mt='5'
                                                size='lg'
                                                name='getClient_email'
                                                value={getClient_email}
                                                onChange={getOrder}

                                            />
                                            <Input
                                                text="email"
                                                mr='3'
                                                required
                                                placeholder='Order ID'
                                                mt='5' size='lg'
                                                w='80%'
                                                name='orderIdGet'
                                                value={orderIdGet}
                                                onChange={getOrder}
                                            />
                                            <Button w='80%' mb='20'
                                                colorScheme='none'
                                                bg="#219691"
                                                // onClick={getAccountorderDetails}
                                                type='submit'
                                                mt='10'
                                            >
                                                Sign In
                                            </Button>

                                        </Flex>
                                    </form>
                                </div>

                            </ModalBody>
                        </ModalContent>
                    </Modal>



                    <OrderOverView
                        overview={overview}
                        getmyaccount={getmyaccount}
                        getMyorders={getMyorders}
                        orderDetails={orderDetails}
                        closeAccountOverview={closeAccountOverview}

                    />



                    <MyOrders
                        openAccountOverview={openAccountOverview}
                        closeAccountOverview={closeAccountOverview}
                        orderDetails={orderDetails}
                        closeOrderDetails={closeOrderDetails}
                        getCloseprofileDetails={getCloseprofileDetails}
                        getmyaccount={getmyaccount}
                        orderInfo={orderInfo}

                    />








                    {/*3.....Header Hamburger.......Get Return Item Modal */}

                    <Modal
                        isOpen={returnPro}
                        size='2xl' >
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader
                                onClick={closeHam}
                                className='hamburge_modal' mt='5'>
                                {/* <Text
                                    className='product_hamburger_text'
                                    color='#219691'
                                >
                                    MY BOUTIQ
                                </Text> */}
                                <Image
                                    src={logo}
                                    width={{ sm: '50%', md: "25%", lg: "30%" }}
                                    // width='25%'
                                    margin={'0 auto'}
                                />

                                <CloseIcon
                                    cursor='pointer'
                                    onClick={closeReturnPro} />
                            </ModalHeader>

                            <ModalBody>

                                <div
                                    className={returnPro ? "hamburgOpen" : "hamburgClose"}
                                >
                                    <p className='home_line' ></p>
                                    <Text ml='14'
                                        fontWeight='bold'
                                        fontSize='lg' mt='5'
                                        onClick={openReturnPro}
                                        cursor='pointer'>
                                        Return Item
                                    </Text>
                                    <form action="" onSubmit={getReturnedProductInfo}>
                                        <Flex mt='5'
                                            alignItems='center'
                                            flexDirection='column'
                                            justifyContent='space-between'>

                                            <Input
                                                text="email"
                                                required
                                                placeholder='Email'
                                                mt='5' size='lg'
                                                w='80%'
                                                name="client_email"
                                                value={client_email}
                                                onChange={getReturnOrder}
                                            />
                                            <Input
                                                text="email"
                                                required
                                                placeholder='Order ID'
                                                mt='5' size='lg'
                                                w='80%'
                                                name="orderId"
                                                value={orderId}
                                                onChange={getReturnOrder}
                                            />
                                            <Button
                                                w='80%'
                                                mb='20'
                                                colorScheme='none'
                                                bg="#219691"
                                                type='submit' mt='10'
                                                onClick={(e) => { setSignIn(true) }}
                                            >
                                                Sign In
                                            </Button>
                                        </Flex>
                                    </form>
                                </div>

                            </ModalBody>
                        </ModalContent>
                    </Modal>


                </Menu>


                <ReturnProduct
                    returnItem={returnItem}
                    client_email={client_email}
                    Return_orderId={orderId}
                    signIn={signIn}
                    getReturnDeatils={getReturnDeatils}
                    closeReturnItem={closeReturnItem}
                />





                {/* <Text
                    // mr='22%'
                    w=''
                    display='flex'
                    justifyContent='center'
                    margin='0 auto'
                    justifyItems='center'
                    color='#219691'
                    fontWeight='bold'
                    fontSize={{ sm: "md", md: "lg", lg: "4xl" }} mt='2'
                    className='header_my_boutiq'
                    cursor={'pointer'}
                    onClick={()=>{history.push(`/${storeurl}`)}}
                >
                    MY BOUTIQ
                </Text> */}
                <Image
                    // className='header_my_boutiq'
                    src={logo}
                    width={{ sm: '50%', md: "25%", lg: "15%" }}
                    margin={'0 auto'}
                    cursor={'pointer'}
                    onClick={() => { history.push(`/${storeurl}`) }}
                />
                <Flex
                    display={{ sm: "none", md: "flex", lg: "flex" }}>
                    <Select
                        w='100px'
                        mr='3'
                    >
                        <option value='option1'>Bulgarian</option>
                        <option value='option2'>Chinese</option>
                        <option value='option2'>Czech</option>
                        <option value='option2'>Danish</option>
                        <option value='option2'>Dutch</option>
                        <option value='option2'>English</option>
                        <option value='option2'>Estonian</option>
                        <option value='option2'>Finnish</option>
                        <option value='option2'>French</option>
                        <option value='option2'>German</option>
                        <option value='option2'>Greek</option>
                        <option value='option2'>Hungarian</option>
                        <option value='option2'>Indonesian</option>
                        <option value='option2'>Italian</option>
                        <option value='option2'>Japanese</option>
                        <option value='option2'>Latvian</option>
                        <option value='option2'>Lithuanian</option>
                        <option value='option2'>Polish</option>
                        <option value='option2'>Portuguese</option>
                        <option value='option2'>Romanian</option>
                        <option value='option2'>Russian</option>
                        <option value='option2'>Slovak</option>
                        <option value='option2'>Slovenian</option>
                        <option value='option2'>Spanish</option>
                        <option value='option2'>Swedish</option>
                        <option value='option2'>Turkish</option>
                        <option value='option2'>Ukrainian</option>
                    </Select>
                    <Select onChange={changeCurrency}
                        w='100px' mr='3'>
                        <option >EUR</option>
                        {storeCurrency.map((ele, index) => {
                            return (
                                <option value={ele.short_name} key={index}>
                                    {ele.short_name}
                                </option>
                            )
                        })}

                    </Select>
                    {/* <div> */}
                    <Box position="relative" display="inline-block">
                        <HiOutlineShoppingBag
                            style={{
                                width: "45px",
                                height: "40px"
                            }}
                            // boxSize="40px"
                            cursor='pointer'
                            onClick={onOpencart}
                        />
                        <Badge
                            position="absolute"
                            top="24px"
                            right="22px"
                            transform="translate(50%,-50%)"
                            bg='transparent'
                            cursor='pointer'
                            onClick={onOpencart}
                        >
                            {cartTotalQuantity}
                        </Badge>
                    </Box>
                </Flex>
                <Flex display={{ sm: "flex", md: "none", xl: "none" }}>
                    <Box position="relative" display="inline-block">
                        <HiOutlineShoppingBag
                            style={{
                                width: "45px",
                                height: "40px"
                            }}
                            // boxSize="40px"
                            cursor='pointer'
                            onClick={onOpencart}
                        />
                        <Badge
                            position="absolute"
                            top="24px"
                            right="22px"
                            transform="translate(50%,-50%)"
                            bg='transparent'
                            cursor={'pointer'}
                            onClick={onOpencart}
                        >
                            {cartTotalQuantity}
                        </Badge>
                    </Box>
                </Flex>
            </Flex>
        </ChakraProvider>
    )
}