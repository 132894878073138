import React, { useRef } from 'react'
import { Flex, Text, Icon, Link, Button, Input, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

export default function NavItem({ icon, title, description, active, navSize }) {
    const btnRef = useRef()
    return (
        <Flex
            mt={30}
            flexDir="column"
            w="100%"
            alignItems={navSize == "small" ? "center" : "flex-start"}
        >

            <Flex
                display="flex"
                margin="0 auto"
                pt="10px"
            >

                <Tab flexDir="column">
                    <Icon
                        as={icon}
                        fontSize="xl"
                        // color={active ? "#82AAAD" : "#219691"}
                        display="flex"
                        margin="0 auto"
                    />
                    {/* <Tab> */}
                    <Text
                        display={navSize == "small" ? "none" : "flex"}
                        justifyContent="center"
                        color="#219691"
                    >
                        {title}
                    </Text>
                </Tab>

            </Flex>

           
        </Flex>
    )
}