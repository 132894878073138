import { Box, Button, Flex, Grid, Image, Radio, RadioGroup, SimpleGrid, Text, List } from '@chakra-ui/react';
import Emitter from 'Emitter';
import React, { useEffect, useState } from 'react';
import image_1 from "../../../assets/img/Launchpad/Background/image_1.png";
import image_2 from "../../../assets/img/Launchpad/Background/image_2.png";
import image_3 from "../../../assets/img/Launchpad/Background/image_3.png";
import image_4 from "../../../assets/img/Launchpad/Background/image_4.png";
import image_5 from "../../../assets/img/Launchpad/Background/image_5.png";
import image_6 from "../../../assets/img/Launchpad/Background/image_6.png";
import image_7 from "../../../assets/img/Launchpad/Background/image_7.png";
import image_8 from "../../../assets/img/Launchpad/Background/image_8.png";
import image_9 from "../../../assets/img/Launchpad/Background/image_9.png";
import image_10 from "../../../assets/img/Launchpad/Background/image_10.png";
import image_11 from "../../../assets/img/Launchpad/Background/image_11.png";
import image_12 from "../../../assets/img/Launchpad/Background/image_12.png";
import image_13 from "../../../assets/img/Launchpad/Background/image_13.png";
import image_14 from "../../../assets/img/Launchpad/Background/image_14.png";
import image_15 from "../../../assets/img/Launchpad/Background/image_15.png";
import image_16 from "../../../assets/img/Launchpad/Background/image_16.png";
import image_17 from "../../../assets/img/Launchpad/Background/image_17.png";
import image_18 from "../../../assets/img/Launchpad/Background/image_18.png";
import image_19 from "../../../assets/img/Launchpad/Background/image_19.png";
import image_20 from "../../../assets/img/Launchpad/Background/image_20.png";
import image_21 from "../../../assets/img/Launchpad/Background/image_21.png";
import image_22 from "../../../assets/img/Launchpad/Background/image_22.png";
import image_23 from "../../../assets/img/Launchpad/Background/image_23.png";
import image_24 from "../../../assets/img/Launchpad/Background/image_24.png";
import image_25 from "../../../assets/img/Launchpad/Background/image_25.png";
import image_26 from "../../../assets/img/Launchpad/Background/image_26.png";
import image_27 from "../../../assets/img/Launchpad/Background/image_27.png";
import image_28 from "../../../assets/img/Launchpad/Background/image_28.png";
import image_29 from "../../../assets/img/Launchpad/Background/image_29.png";
import image_30 from "../../../assets/img/Launchpad/Background/image_30.png";
import image_31 from "../../../assets/img/Launchpad/Background/image_31.png";
import image_32 from "../../../assets/img/Launchpad/Background/image_32.png";
import image_33 from "../../../assets/img/Launchpad/Background/image_33.png";
import image_34 from "../../../assets/img/Launchpad/Background/image_34.png";
import image_35 from "../../../assets/img/Launchpad/Background/image_35.jpg";
import image_36 from "../../../assets/img/Launchpad/Background/image_36.jpg";
import image_37 from "../../../assets/img/Launchpad/Background/image_37.jpg";
import image_38 from "../../../assets/img/Launchpad/Background/image_38.jpg";
import image_39 from "../../../assets/img/Launchpad/Background/image_39.jpg";
import image_40 from "../../../assets/img/Launchpad/Background/image_40.jpg";
import image_41 from "../../../assets/img/Launchpad/Background/image_41.jpg";
import image_42 from "../../../assets/img/Launchpad/Background/image_42.jpg";
import image_43 from "../../../assets/img/Launchpad/Background/image_43.jpg";
import image_44 from "../../../assets/img/Launchpad/Background/image_44.jpg";
import image_45 from "../../../assets/img/Launchpad/Background/image_45.jpg";
import image_46 from "../../../assets/img/Launchpad/Background/image_46.jpg";
import image_47 from "../../../assets/img/Launchpad/Background/image_47.jpg";
import image_48 from "../../../assets/img/Launchpad/Background/image_48.jpg";
import image_49 from "../../../assets/img/Launchpad/Background/image_49.jpg";
import image_50 from "../../../assets/img/Launchpad/Background/image_50.jpg";
import image_51 from "../../../assets/img/Launchpad/Background/image_51.jpg";
import image_52 from "../../../assets/img/Launchpad/Background/image_52.jpg";
import image_53 from "../../../assets/img/Launchpad/Background/image_53.jpg";
import image_54 from "../../../assets/img/Launchpad/Background/image_54.jpg";
import image_55 from "../../../assets/img/Launchpad/Background/image_55.jpg";
import image_56 from "../../../assets/img/Launchpad/Background/image_56.jpg";
import image_57 from "../../../assets/img/Launchpad/Background/image_57.jpg";
import image_58 from "../../../assets/img/Launchpad/Background/image_58.jpg";
import image_59 from "../../../assets/img/Launchpad/Background/image_59.jpg";
import image_60 from "../../../assets/img/Launchpad/Background/image_60.jpg";
import image_61 from "../../../assets/img/Launchpad/Background/image_61.jpg";
import { Translate } from 'components/DeepL/Translate';
import LazyLoad from 'react-lazy-load';
import { useDispatch } from 'react-redux';
import { selectBackground, addLoader } from 'Slices/launchpadslice';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import LazyImage from './LazyImage';
import { backgroundImages } from 'assets/constants/strings';

const SelectBackground = (props) => {
    const [images] = useState([
        { id: '1', name: "Image 1", image: image_1 },
        { id: '2', name: "Image 2", image: image_2 },
        { id: '3', name: "Image 3", image: image_3 },
        { id: '4', name: "Image 4", image: image_4 },
        { id: '5', name: "Image 5", image: image_5 },
        { id: '6', name: "Image 6", image: image_6 },
        { id: '7', name: "Image 7", image: image_7 },
        { id: '8', name: "Image 8", image: image_8 },
        { id: '9', name: "Image 9", image: image_9 },
        { id: '10', name: "Image 10", image: image_10 },
        { id: '11', name: "Image 11", image: image_11 },
        { id: '12', name: "Image 12", image: image_12 },
        { id: '13', name: "Image 13", image: image_13 },
        { id: '14', name: "Image 14", image: image_14 },
        { id: '15', name: "Image 15", image: image_15 },
        { id: '16', name: "Image 16", image: image_16 },
        { id: '17', name: "Image 17", image: image_17 },
        { id: '18', name: "Image 18", image: image_18 },
        { id: '19', name: "Image 19", image: image_19 },
        { id: '20', name: "Image 20", image: image_20 },
        { id: '21', name: "Image 21", image: image_21 },
        { id: '22', name: "Image 22", image: image_22 },
        { id: '23', name: "Image 23", image: image_23 },
        { id: '24', name: "Image 24", image: image_24 },
        { id: '25', name: "Image 25", image: image_25 },
        { id: '26', name: "Image 26", image: image_26 },
        { id: '27', name: "Image 27", image: image_27 },
        { id: '28', name: "Image 28", image: image_28 },
        { id: '29', name: "Image 29", image: image_29 },
        { id: '30', name: "Image 30", image: image_30 },
        { id: '31', name: "Image 1", image: image_31 },
        { id: '32', name: "Image 2", image: image_32 },
        { id: '33', name: "Image 3", image: image_33 },
        { id: '34', name: "Image 4", image: image_34 },
        { id: '35', name: "Image 5", image: image_35 },
        { id: '36', name: "Image 6", image: image_36 },
        { id: '37', name: "Image 7", image: image_37 },
        { id: '38', name: "Image 8", image: image_38 },
        { id: '39', name: "Image 9", image: image_39 },
        { id: '40', name: "Image 40", image: image_40 },
        { id: '41', name: "Image 41", image: image_41 },
        { id: '42', name: "Image 42", image: image_42 },
        { id: '43', name: "Image 43", image: image_43 },
        { id: '44', name: "Image 44", image: image_44 },
        { id: '45', name: "Image 45", image: image_45 },
        { id: '46', name: "Image 46", image: image_46 },
        { id: '47', name: "Image 47", image: image_47 },
        { id: '48', name: "Image 48", image: image_48 },
        { id: '49', name: "Image 49", image: image_49 },
        { id: '50', name: "Image 50", image: image_50 },
        { id: '51', name: "Image 51", image: image_51 },
        { id: '52', name: "Image 52", image: image_52 },
        { id: '53', name: "Image 53", image: image_53 },
        { id: '54', name: "Image 54", image: image_54 },
        { id: '55', name: "Image 55", image: image_55 },
        { id: '56', name: "Image 56", image: image_56 },
        { id: '57', name: "Image 57", image: image_57 },
        { id: '58', name: "Image 58", image: image_58 },
        { id: '59', name: "Image 59", image: image_59 },
        { id: '60', name: "Image 60", image: image_60 },
        { id: '61', name: "Image 61", image: image_61 },


    ]);
    const dispatch = useDispatch();
    
    //select background image
    const handleClick = (e) => {
        dispatch(addLoader(true));
        dispatch(selectBackground(e));
        Emitter.emit('INPUT_FROM_SELECTBACKGROUND', e);
        props.onClick();
    }

    return (
        <Flex
            flexDirection={"column"}
        >
            <Flex
                flexDirection={"column"}
                h={{ sm: "50vh", lg: "100%" }}
                backgroundSize='cover'
                backgroundRepeat='no-repeat'
                pt="20px"
                pl="20px"
                pr="20px"
                pb="30px"
            >
                <Text
                    fontWeight={"bold"}
                    textAlign="center"
                >
                    {/* {Translate ? "Background Images" : Translate("Background Images")} */}
                    {backgroundImages}
                </Text>
                <Box
                    overflowY="scroll"
                    height="700px"
                    border={"1px solid black"}
                >
                    <SimpleGrid
                        mt="20px"

                        px="10px"
                        py="10px"
                        columns={{ sm: 3, md: 3, xl: 3 }}
                        spacing='10px'
                    >
                        {images.map((item, i) => (
                            <Flex
                                flexDirection="column"
                                key={item.id}
                                onClick={() => { handleClick(item.image) }}
                            >
                                <LazyImage
                                    src={item.image}
                                    alt="background Image"
                                />
                                {/* <Image
                                    w={{ sm: "100px", lg: "125px" }}
                                    h={{ sm: "150px", lg: "125px" }}
                                    // pl="1"
                                    borderRadius="20px"
                                    colorScheme={"teal"}
                                    src={item.image}
                                    alt="background"
                                    cursor={'pointer'}
                                    onClick={() => { handleClick(item.image) }}
                                /> */}
                            </Flex>
                        ))}

                    </SimpleGrid>
                </Box>



            </Flex>
        </Flex>
    )
}

export default SelectBackground