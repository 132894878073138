import React, { useState, useEffect } from 'react';
import { Flex, Text, ChakraProvider, SimpleGrid, } from '@chakra-ui/react';
import { useHistory, useLocation } from 'react-router-dom';
import './homepage.css';
import theme from 'theme/theme';
import {
    getsellerProduct, getCategoryProduct, getAllStoreproducts,
    themeSetting, StoreInfo, checkLinkVisitor
} from '../API/AuthAPI';
import { MainHeader } from 'components/header/MainHeader';
import { ProductGallery } from 'components/header/ProductGallery';
import { StoreFooter } from 'components/header/StoreFooter';
import { NewsLetter } from 'components/header/NewsLetter';
import { AddToCart } from 'components/header/AddToCart';
import { Translate } from 'components/DeepL/Translate';
import LazyLoad from 'react-lazy-load';
import logo from "../../assets/svg/logo1.png";
import Loading from 'components/PlaceholderLoading/Loading';
import ProductList from './ProductList'
import StoreInformation from './StoreInformation'
import Categories from './Categories'
import StoreBackground from './StoreBackground'

function Homepage() {
    let location = useLocation()
    let hashRefId = location.hash.substring(1);
    const [storeName, setStoreName] = useState();
    const [loader, setLoader] = useState(false);
    const [backgroundProfile, setBackgroundProfile] = useState([])
    const [getuserProduct, setGetuserProduct] = useState([])
    const [catwiselist, setCatwiselist] = useState([])
    const [selectedId, setSelectedId] = useState('')
    const [accounttoken, setAccounttoken] = useState()
    const [uniqueorderId, setUniqueOrderId] = useState()
    const [store_id, setStore_id] = useState([])
    const [userId] = useState()
    const [getsellerId, setGetsellerId] = useState()
    const [userId1] = useState()
    const [openList, setOpenList] = useState(false)
    // const [getTheme, setGetTheme] = useState([])
    const [fontsetting, setFontSetting] = useState([])
    // const [bgsetting, setBgSetting] = useState([])
    // const [colorsetting, setColorSetting] = useState([])
    const [flag, setFlag] = useState(true)
    const [firstenter, setFirstenter] = useState(true)
    const [storeDetails, setStoreDetails] = useState(false)


    const onClose = () => {
        setCart(false)
    }
    // To Open Cart Modal
    const [cart, setCart] = useState(false)

    const onOpencart = () => {
        setCart(true)
    }



    /// To Get Currency From ------ MainHeader
    const [state, setState] = useState({
        headerdata: ""
    })


    //console.log("data-------", state)

    const handleCallback = (childData) => {
        // localStorage.setItem("changeUser", childData)
        setState({ headerdata: childData })

    }

    //exchange-currency-api
    const [currencyExchange, setCurrencyExchange] = useState();
    const [currencyIcon, setCurrencyIcon] = useState();


    // let currency = CurrencyExchange("EUR");

    useEffect(() => {
        let curr = localStorage.getItem('convertedCurrency')
        if (!curr == '') {
            setCurrencyExchange(localStorage.getItem('convertedCurrency'));
        }
        else {
            setCurrencyExchange(1);
        }
        setCurrencyIcon(localStorage.getItem("changeUser"))

    }, [state])

    /**
     * The function sets loader to true, retrieves store information using a store name, stores the
     * retrieved information in local storage, and calls other functions to retrieve and store
     * additional details.
     */
    const Info = (storeName) => {
        setLoader(true)
        StoreInfo(storeName).then(responseJson => {
            if (responseJson) {
                localStorage.setItem('storeId', responseJson.data[0].seller_store)
                localStorage.setItem("storename", responseJson.data[0].slug)
                getDeatils(responseJson?.data[0]?.seller_store);
                setBackgroundProfile(responseJson?.data || [])
                localStorage.setItem("backgroundProfile", JSON.stringify(responseJson?.data))
            }
        })
    }
    /**
     * The function "checkUserLogin" retrieves and sets various data related to the user's login and store
     * information.
     */

    const checkUserLogin = () => {
        let sellerId;
        const userToken = localStorage.getItem("token");
        sellerId = JSON.parse(localStorage.getItem("storeId"))
        setGetsellerId(sellerId)
        setStoreName(location?.pathname.substring(1));
        Info(location?.pathname.substring(1));
        getThemeSettimg(userToken)
        getAllCartDetails()
        handleCallback()

    }

    useEffect(() => {
        checkUserLogin();
    }, [storeName])



    /// to display category list
    const getDeatils = (getsellerId) => {
        setLoader(true)
        getsellerProduct(getsellerId).then(responseJson => {
            if (responseJson) {
                setGetuserProduct(responseJson.data || []);
                setLoader(false)
                if (!localStorage.getItem("wishlist")) {
                    localStorage.setItem("wishlist", "[]");
                }
            }
        }).catch(err => console.log(err))

    }

    // category wise product ------------
    /**
     * This function sets various states and retrieves a list of products based on a selected category
     * ID.
     */
    const getcatDetails = (id) => {
        setSelectedId(id)
        setFlag(false)
        setFirstenter(false)
        setOpenList(false)
        setLoader(true)
        getCategoryProduct(getsellerId, id).then(responseJson => {
            if (responseJson) {
                setLoader(false)
                setCatwiselist(responseJson.data);
            }
        }).catch(err => console.log(err))
    }

    // token from ---- account login
    useEffect(() => {
        const checkUserLogin = async () => {
            let accToken;
            let getuniuqId;
            try {
                accToken = localStorage.getItem("loginToken")
                setAccounttoken(accToken)
                getAddedProductCart()
                setUniqueOrderId(getuniuqId)
                let newcart = JSON.parse(localStorage.getItem("neworder"))
                setUser_cart(newcart)
            } catch (e) { }
        }
        checkUserLogin();
    }, [accounttoken, getsellerId, uniqueorderId, userId])

    /**
     * This function retrieves all store product IDs and sets them in the store_id variable.
     */
    const getAllCartDetails = () => {
        getAllStoreproducts().then(res => {
            let getAllId = []
            for (let responseJson of res.data) {
                getAllId.push(responseJson.id)
            }
            setStore_id(getAllId)
        })
    }

    const getThemeSettimg = (token) => {
        themeSetting(token).then(responseJson => {
            if (responseJson) {
                // setGetTheme(responseJson.data)
                // let arr = []
                // for (let response of responseJson.data) {
                //     arr.push(response.main_color)
                // }
                // setColorSetting(arr)
                // let arr1 = []
                // for (let response of responseJson.data) {
                //     arr1.push(response.category_background_color)
                // }
                // setBgSetting(arr1)
                let arr3 = []
                for (let response of responseJson.data) {
                    arr3.push(response.primary_font_size)
                }
                setFontSetting(arr3)
            }
        }).catch(err => console.log(err))
    }


    /**
     * The function toggles the state of a list.
     */
    const getListofCategory = () => {
        setOpenList(!openList)
    }

    /**
     * The function sets various state variables to trigger a re-render in a React component.
     */
    const getalldetails = () => {
        setFlag(true)
        setOpenList(false)
        setFirstenter(!firstenter)
    }


    /**
     * The function sets the value of a variable called "storeDetails" to true.
     */
    const getStoreInformation = () => {
        setStoreDetails(true)
    }
    /**
     * The function `getClosestore` sets the value of `storeDetails` to false.
     */
    const getClosestore = () => {
        setStoreDetails(false)
    }

    /* The above code is using the `useEffect` hook in React to set a `reference_id` value in the
    `sessionStorage` object and call the `checkLink` function if the `pathname` contains more than
    two fields. The `hashRefId` variable is used to set the `reference_id` value after removing any
    forward slashes. */
    useEffect(() => {
        let path = location.pathname;
        let fields = path.split('/');
        sessionStorage.setItem('reference_id', hashRefId.replace(/\//g, ''))
        if (fields.length > 2) {
            checkLink(hashRefId)
        }
    }, [])

    /**
     * The function "checkLink" calls another function "checkLinkVisitor" with a parameter "hashRefId".
     */
    const checkLink = (hashRefId) => {
        checkLinkVisitor(hashRefId)
    }


    return (
        <ChakraProvider className='container homepage_wrapper'
            theme={theme} resetCss={false}
        >
            <MainHeader userId1={userId1}
                onClose={onClose} onOpencart={onOpencart} handleCallback={handleCallback}
            />

            <div>
                <Flex flexDirection='column'
                    p='10'
                    className='home_main_header'>

                    <StoreBackground
                        backgroundProfile={backgroundProfile}
                        getStoreInformation={getStoreInformation}
                        fontsetting={fontsetting}
                    />

                    <StoreInformation
                        storeDetails={storeDetails}
                        getClosestore={getClosestore}
                        logo={logo}
                        backgroundProfile={backgroundProfile}
                    />

                    <Flex flexDirection={{
                        sm: "column",
                        md: "row",
                    }}
                        mt={{ sm: '0', md: '0', lg: '10' }}
                        // gap='5'
                        className='home_category_detail'
                    >
                        <Categories
                            getcatDetails={getcatDetails}
                            flag={flag}
                            firstenter={firstenter}
                            getuserProduct={getuserProduct}
                            fontsetting={fontsetting}
                            selectedId={selectedId}
                            openList={openList}
                            getalldetails={getalldetails}
                            getListofCategory={getListofCategory}
                        />
                        <SimpleGrid
                            columns={3}
                            className='home_category'
                        >
                            <Flex className='home_wrapper_product'>
                                {flag === false ?
                                    <SimpleGrid
                                        columns={{ xs: 1, sm: 2, md: 2, lg: 3 }}
                                        justifyContent={"center"}
                                        spacing={{ sm: "10px", md: "10px", lg: '24px' }}
                                        mb='20px'
                                        mt='10px'
                                        >
                                        {
                                            catwiselist.length > 0 && flag === false ? catwiselist.map((ele) => {
                                                return (
                                                    <ProductList
                                                        ele={ele}
                                                        currencyExchange={currencyExchange}
                                                        currencyIcon={currencyIcon}
                                                    />
                                                )
                                            }) : ""

                                        }
                                    </SimpleGrid>
                                    :
                                    ""
                                }
                                <SimpleGrid columns={{ xs: 1, sm: 2, md: 2, lg: 3 }} justifyContent={"center"} spacing={{ sm: "10px", md: "10px", lg: '1rem' }} mt='10px'>
                                    {
                                        flag === true && getuserProduct.length > 0 ? getuserProduct.map(ele => {
                                            return (
                                                <ProductList
                                                    ele={ele}
                                                    currencyExchange={currencyExchange}
                                                    currencyIcon={currencyIcon}
                                                />
                                            )
                                        })
                                            :
                                            ""
                                    }
                                </SimpleGrid>

                                {
                                    loader ?
                                        <Flex
                                            justifyContent={'center'}
                                            w={{ sm: '90vw', md: '90vw', lg: '55vw' }}
                                        >
                                            <Loading />
                                        </Flex>
                                        :
                                        ""
                                }


                                {/* {
                                        flag === true && getuserProduct.length > 0 ? getuserProduct.map(ele => (
                                            ""
                                        ))
                                        :
                                        <Flex
                                            w='60vw'
                                            display='flex'
                                            justifyContent='center'
                                            margin='0 auto'
                                        >
                                            <Text
                                                color={'#219691'}
                                                fontWeight='bold'
                                                fontSize={{sm:'sm',lg:'lg'}}
                                            >
                                                "No products found".
                                            </Text>
                                        </Flex>
                                           

                                } */}

                            </Flex>

                        </SimpleGrid>

                    </Flex>


                    <AddToCart userId={userId}
                        store_id={store_id}
                        onOpencart={onOpencart}
                        cart={cart}
                        onClose={onClose}
                        currencyExchange={currencyExchange}
                        currencyIcon={currencyIcon}
                    />

                </Flex>
                <Text
                    fontWeight='bold'
                    ml='5'
                    fontSize={{ sm: "18px", md: '20px', lg: "24px" }}
                >
                    {/* { Translate("DISCOVER MY BOUTIQ GALLERY") ? Translate("DISCOVER MY BOUTIQ GALLERY") : "DISCOVER MY BOUTIQ GALLERY"} */}
                    DISCOVER MY BOUTIQ GALLERY
                </Text>
                <ProductGallery userId={userId} backgroundProfile={backgroundProfile} />
                <NewsLetter />
                <StoreFooter />
            </div>

        </ChakraProvider>
    )
}

export default Homepage
