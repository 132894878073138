import React, { useState, useRef } from "react";
import {
    Box, Flex, Button, FormControl, Heading, Input, useToast, ChakraProvider,
} from "@chakra-ui/react";
import { forgotpassword, } from "../../components/API/AuthAPI";
import { Link, useHistory } from 'react-router-dom';
import AuthNavbar1 from "components/Navbars/SearchBar/AuthNavbar1";
import theme from "theme/theme";
import { forgotpasswordText1, resetPassword, enterValidEmail, resetPasswordLinkText, enteremail } from "assets/constants/strings";


function ForgotPassword1() {
    const [email, setEmail] = useState();
    const [submitted, setSubmitted] = useState(false);
    let history = useHistory();
    const toast = useToast();

    /**
     * The function handles the submission of a forgot password form and displays a message based on
     * the response from the server.
     */
    const handleChange = (e) => {
        e.preventDefault();
        if (!email) {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        {enteremail}
                    </Box>
                ),
            })
        }
        else {
            setSubmitted(true);
            forgotpassword(email)
                .then((responseJson) => {
                    if (responseJson) {
                        if (responseJson["message"] == 'Password reset e-mail has been sent.') {
                            setSubmitted(false)
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        {resetPasswordLinkText}
                                    </Box>
                                ),
                            })
                        } else if (responseJson["message"] == "User not found.") {
                            setSubmitted(false)
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        {enterValidEmail}
                                    </Box>
                                ),
                            })
                        }

                    }
                })
        }
    }


    return (
        <ChakraProvider theme={theme} resetCss={false} w="100%">
            <Flex
                overflowY="scroll"
                mb='40px'
                pt="45px"
                h="100%"
                w="100%"
            >
                <AuthNavbar1 />
                <Flex
                    w='100%'
                    mx='auto'
                    justifyContent='center'
                    mb='30px'
                    pt={{ sm: "10vh", md: "0px", lg: "5vh" }}
                >
                    <Flex
                        display="flex"
                        flexWrap={"nowrap"}
                        alignItems='center'
                        justifyContent='center'
                        w={{ base: "100%", md: "50%", lg: "42%" }}
                    >
                        <Flex
                            direction='column'
                            w='100%'
                            maxWidth={"350px"}
                            background='#FFFFFF'
                            border={{ sm: "none", md: "1px solid #EEE", lg: "1px solid #EEE" }}
                            borderRadius={{ sm: "none", md: "10px", lg: "10px" }}
                            p='25px'
                            mt={{ md: "100px", lg: "80px" }}>
                            <Heading
                                fontSize='lg'
                                mb='50px'
                            >
                                {forgotpasswordText1}
                            </Heading>
                            <form onSubmit={handleChange}>
                                <FormControl>
                                    <Input
                                        borderRadius='15px'
                                        bg="#F4F5F9"
                                        color="#4f5d77"
                                        mb='15px'
                                        fontSize='sm'
                                        type='text'
                                        placeholder='Email'
                                        _placeholder={{ color: 'inherit' }}
                                        textAlign="center"
                                        size='lg'
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    {
                                        !submitted ?
                                            <Button
                                                fontSize='sm'
                                                type='submit'
                                                bg='#219691'
                                                w='100%'
                                                h='45'
                                                mb='20px'
                                                color='white'
                                                mt='20px'
                                                _hover={{
                                                    bg: "#219691",
                                                }}
                                                _active={{
                                                    bg: "teal.400",
                                                }}
                                                onClick={handleChange}
                                            >
                                                {resetPassword}
                                            </Button>
                                            :
                                            <Button
                                                fontSize='sm'
                                                type='submit'
                                                isLoading
                                                loadingText='please wait....'
                                                colorScheme='teal'
                                                variant='solid'
                                                bg='#219691'
                                                w='100%'
                                                h='45'
                                                mb='20px'
                                                color='white'
                                                mt='20px'
                                                _hover={{
                                                    bg: "#219691",
                                                }}
                                                _active={{
                                                    bg: "teal.400",
                                                }}
                                                onClick={handleChange}
                                            >
                                                {resetPassword}
                                            </Button>
                                    }
                                </FormControl>
                            </form>
                        </Flex>
                    </Flex>

                </Flex>
            </Flex>
        </ChakraProvider>
    );
}

export default ForgotPassword1;

