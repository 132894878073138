import { ChakraProvider, Box, Text, Image, Flex, SimpleGrid, Grid, Button, Popover, PopoverContent, PopoverHeader, PopoverArrow, PopoverCloseButton, useDisclosure, useToast, AspectRatio } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineHeart } from "react-icons/ai";
import { BsSearch, BsBag } from 'react-icons/bs'
import theme from 'theme/theme.js';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import "./styles.css";
import { galleryBanner, categoryDetails, updateWishlist, getWishlist, getListedCart, galleyCategoryDetails } from 'components/API/AuthAPI';
import { MainHeader } from 'components/header/MainHeader';
import { AddToCart } from 'components/header/AddToCart';
import cutLogo from "../../assets/svg/cut-logo.svg";
import { addedToWishlistText, removefromWishlistText, proceedToLogin } from 'assets/constants/strings';
import PlaceholderLoading from 'components/PlaceholderLoading/PlaceholderLoading';
import GalleryBanner from './GalleryBanner';
import GalleryFooter from './GalleryFooter';
// import './gallerypage.css';

function GetGalleryCategory(props) {
    const { onToggle, onClose } = useDisclosure()
    const history = useHistory()
    const [categoryProd, setCategoryProd] = useState();
    const [currency, setCurrency] = useState('€');
    const [selectedCurrency, setSelelctedCurrency] = useState(1);
    const [isEmpty, setIsEmpty] = useState();
    const [wishlist, setWishlist] = useState([])
    const [token, setToken] = useState();
    const toast = useToast();
    let location = useLocation()
    const [id] = useState(location ? location.state : "");
    const [userId1] = useState()
    const [currencyIcon, setCurrencyIcon] = useState();
    const [bannerText, setBannerText] = useState();
    const [userId] = useState();
    const [store_id] = useState([])
    const [getCart] = useState([])
    const [cart, setCart] = useState(false)
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState({
        headerdata: ""
    })

    const onClose1 = () => {
        setCart(false)
    }

    const onOpencart = () => {
        setCart(true)
    }

    const handleCallback = (childData) => {
        setState({ headerdata: childData })
    }

    useEffect(() => {
        setCurrencyIcon(localStorage.getItem("changeUser"))
    }, [state])

    useEffect(() => {
        const checkLogin = () => {
            let userToken = localStorage.getItem("loginToken");
            if (userToken) {
                setToken(userToken);
            }
        }
        checkLogin();
    }, [token])

    useEffect(() => {
        onToggle()
        getCategory();
        Banner();
        checkCurrency();
    }, [])

    const checkCurrency = () => {
        let currencySymbol = localStorage.getItem("currency");
        let convetedCurr = localStorage.getItem("convertedCurrency")
        if (currencySymbol) {
            setCurrency(currencySymbol);
        }
        if (convetedCurr) {
            setSelelctedCurrency(convetedCurr);
        }
    }

    // useEffect(() => {
    //     getCategory();
    //     Banner();
    //     checkCurrency();
    // }, [categoryProd])

    /**
     * This function calls an API to get category details and updates the state based on the response.
     */
    const getCategory = () => {
        setLoader(true)
        galleyCategoryDetails(id).then((res) => {
            if (res) {
                categoryGet()
                let arr = res.data;
                if (Array.isArray(arr) && arr.length) {
                    setIsEmpty(false)
                } else {
                    setIsEmpty(true)
                }
            }
        })
    }

    /**
     * The function retrieves category details and sets the category products state based on the response
     * data.
     */
    const categoryGet = () => {
        setLoader(true);
        categoryDetails(id).then((res) => {
            let arr = res.data;
            if (Array.isArray(arr) && arr.length) {
                setCategoryProd(res.data);
                setIsEmpty(false)
                setLoader(false);
            } else {
                setIsEmpty(true)
                setLoader(false)
            }
        })
    }

    //banner text
    /**
     * This function sets the banner text by retrieving data from a gallery banner.
     */
    const Banner = () => {
        galleryBanner().then((res) => {
            if (res) {
                setBannerText(res.data[0].pop_up_text);
            }
        })
    }

    /**
     * The function adds a product to the user's wishlist and displays a message accordingly, or
     * prompts the user to login if not already logged in.
     */
    const addtoWishlist = (prodId) => {
        if (token) {
            updateWishlist(token, prodId).then((res) => {
                if (res.message == 'Product add in your wishlist') {
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                            >
                                {addedToWishlistText}
                            </Box>
                        ),
                    })
                } else {
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                            >
                                {removefromWishlistText}
                            </Box>
                        ),
                    })
                }
            })
        } else {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                    >
                        {proceedToLogin}
                    </Box>
                ),
            })
        }
    }

    useEffect(() => {
        getWish();
    }, [wishlist, token]);

    /**
     * This function retrieves a user's wishlist using a token and sets it in the state.
     */
    const getWish = () => {
        getWishlist(token).then((res) => {
            if (res) {
                setWishlist(res.wishlist);
            }
        })
    }


    return (
        <ChakraProvider theme={theme} resetCSS={false}>
            <Flex
                flexDirection={'column'}
                w='100%'
                h='100%'
            >
                <MainHeader userId1={userId1}
                    onClose={onClose} onOpencart={onOpencart} handleCallback={handleCallback}
                />
                <GalleryBanner 
                    bannerText={bannerText} 
                    disp = {true}    
                />
                {loader ?
                    <PlaceholderLoading />
                    :
                    ""
                }

                {!isEmpty ?
                    <SimpleGrid columns={{ sm: 2, md: 2, lg: 2 }} justifyContent={"center"} spacing='24px' mb='10%'>
                        {categoryProd ? categoryProd.map((res, index) => {
                            return (
                                <Flex
                                    position={'relative'}
                                    flexDirection='column' w='100%'
                                    padding={{ lg: "10px" }}
                                >
                                    <Link to={`/product-details/${res.id}`}>
                                        <Image
                                            w='100%'
                                            h={{ sm: "150px", md: "200px", lg: '300px' }}
                                            src={"https://adminmyboutiqe.progfeel.co.in" + res.product_image}
                                            alt='Dan Abramov'
                                            cursor={"pointer"}
                                        />
                                    </Link>
                                    <Flex
                                        flexDirection={'column'}
                                        justifyContent='space-between'
                                    >
                                        <Flex
                                            flexDirection='column'
                                        >
                                            <Text
                                                ml='10px'
                                                mt='5'
                                                fontWeight='bold'
                                                fontSize={{ sm: '18px', md: '18px', lg: '25px' }}
                                            >
                                                {res.product_name}
                                            </Text>
                                            <Text
                                                ml='10px'
                                                fontSize={{ sm: '16px', md: '16px', lg: '20px' }}
                                            >
                                                {res.brand_name}
                                            </Text>
                                            <Text
                                                ml='10px'
                                                color='#219691'
                                                fontWeight='bold'
                                                fontSize={{ sm: '17px', md: '16px', lg: '20px' }}
                                            >
                                                {parseFloat(res.PRDPrice * selectedCurrency).toFixed(2)} {currency}
                                            </Text>
                                            <Text
                                                ml='10px'
                                                textDecoration='line-through'
                                                fontSize={{ sm: '17px', md: '16px', lg: '20px' }}
                                            >
                                                {res.PRDDiscountPrice ? parseFloat(res.PRDDiscountPrice * selectedCurrency).toFixed(2) : ""} {res.PRDDiscountPrice ? currency : ""}
                                            </Text>
                                        </Flex>
                                        <Flex
                                            position={'absolute'}
                                            display='inline-flex'
                                            top={{ sm: '10px', md: "15px", lg: "20px" }}
                                            right={{ sm: '10px', md: "15px", lg: "20px" }}
                                            
                                        >
                                            <AiOutlineHeart
                                                color='black'
                                                fontWeight={'bold'}
                                                colorScheme='blue'
                                                size={'25px'}
                                                _hover={{filter:"brightness(120%)"}}
                                                cursor='pointer'
                                                onClick={() => {
                                                    addtoWishlist(res.id)
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            )
                        })
                            :
                            ""
                        }
                    </SimpleGrid>
                    :
                    <Flex flexDir='column'>
                        <Box
                            display='flex'
                            margin="0 auto"
                            justifyContent='center'
                            mt={{ sm: "100px", md: "20px", lg: '10%' }}
                            w={{ sm: '95%', md: "90%", lg: '90%' }}
                            h={{ sm: "100px", lg: '50px' }}
                            bg='transparent'
                        >
                            <Text
                                mt={{ sm: '25px', md: "10px", lg: "10px" }}
                                textAlign={'center'}
                                color={'#219691'}
                                fontWeight='bold'
                            >
                                Unfortunately there is no products in selected category.
                            </Text>
                        </Box>
                        <Grid
                            mt='20px'
                            justifyContent='center'
                        >
                            <Button
                                w='100%'
                                color='#FFFFFF'
                                bg='#219691'
                                colorScheme='none'
                                onClick={(e) => { history.push("/gallery") }}
                            >
                                Shop Now
                            </Button>
                        </Grid>
                    </Flex>
                }
                <GalleryFooter disp={true} />
                <AddToCart userId={userId}
                    getCart={getCart}
                    store_id={store_id}
                    onOpencart={onOpencart}
                    cart={cart}
                    onClose={onClose1}
                    currencyIcon={currencyIcon}
                />
            </Flex>
        </ChakraProvider>
    )
}


export default GetGalleryCategory;