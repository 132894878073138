import { Flex, SimpleGrid, useColorModeValue, Text, Grid} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MiniStatistics from 'views/Dashboard/Dashboard/components/MiniStatistics';
import { DocumentIcon, CartIcon, PersonIcon, WalletIcon } from 'components/Icons/Icons';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getUserProfile, getDashboardByDate, getInitialDashboardValues } from "../API/AuthAPI";
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css"
import { Translate } from 'components/DeepL/Translate';
import Emitter from 'Emitter';


const AffiliateDashboard = () => {
  const now = new Date();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [username, setUsername] = useState();
  const [connectedSellers, setConnectedSellers] = useState('');
  const [revenue, setRevenue] = useState();
  const [transaction, setTransaction] = useState();
  const iconBoxInside = useColorModeValue("white", "white");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [visible, setVisible] = useState(true);
  const [language, setLanguage] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState("GB");
  const [lang, setLang] = useState();
  const [initialValues, setInitialValues] = useState();
  const [show, setShow] = useState(false);
  let history = useHistory();
  const [token, setToken] = useState();


  useEffect(() => {
    const checkUserLogin = () => {
      const userToken = localStorage.getItem("token");
      setToken(userToken);
      getUsername(userToken);
      getDashboardInfo(userToken);

      if (!userToken) {
        history.push({
          pathname: "/signin"
        })
      }
    }
    checkUserLogin();

  }, [token]);


  /**
   * The function retrieves user details from a server using a token and sets the first name, last
   * name, and username if available.
   */
  const getUsername = (token) => {
    getUserProfile(token)
      .then((responseJson) => {
        if (responseJson) {
          let userDetails = responseJson.user_details || {};
          setFirstname(userDetails.first_name || "");
          setLastname(userDetails.last_name || "");
          setUsername(userDetails.username || "")
        }
      }).catch(function () {
        // console.log("");
      });
  }

 /**
  * The function retrieves initial dashboard values using a token and sets them as initial values.
  * @param token - The `token` parameter is likely a string or object that represents an authentication
  * token used to access a protected resource or API endpoint. It is passed as an argument to the
  * `getDashboardInfo` function, which uses it to make an HTTP request to retrieve initial dashboard
  * values.
  */
  const getDashboardInfo = (token) => {
    getInitialDashboardValues(token).then((res) => {
      if (res) {
        let dashboard_data = res.data;
        dashboard_data?.map((item) => {
          setInitialValues(item);
        })
      }
    })
  }

  if (firstname) {
    const name = firstname.concat(' ', lastname);
    window.localStorage.setItem("display_name", name);
  }
  else {
    window.localStorage.setItem("display_name", username);
  }

  useEffect(() => {
    let lang = localStorage.getItem('language');
    setLanguage(lang);
    setDateRange([new Date(now.getFullYear(), now.getMonth(), 1), new Date()]);
  }, [])


  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000)  

  }, [visible]);

 /**
  * This function retrieves dashboard data within a specified date range and updates state variables
  * with the retrieved data.
  */
  const getRecords = (dateFrom, dateTo) => {
    getDashboardByDate(token, moment(dateFrom).format('YYYY-MM-DD'), moment(dateTo).format('YYYY-MM-DD'))
      .then((responseJson) => {
        if (responseJson.data) {
          let dash_data = responseJson.data
          if (dash_data.length > 0) {
            dash_data?.map((item) => {
              setShow(true);
              setConnectedSellers(item.total_connected_seller)
              setRevenue(item.total_revenue);
              setTransaction(item.total_tnx);
            })
          }

        }
      }).catch(function () {
        // console.log("");
      });
  }


  useEffect(() => {
    let language1 = localStorage.getItem('language');
    if (language1) {
      setSelectedLanguage(language1);
    } else {
      setSelectedLanguage("GB");
    }
  }, [lang])
  Emitter.on("LANGUAGE", (lang) => setLang(lang));



  return (
    <Flex
      className='dashboard_container'
    >
      <Grid pb="10px">
        <Text
          py={{ sm: "0px", md: "15px", lg: "30px", }}
          className="headerName"
        >
          {selectedLanguage !== "GB" ? Translate("AFFILIATE DASHBOARD") : "Affiliate Dashboard"}
        </Text>
      </Grid>
      <Grid className='dashboard_datepicker' >
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            setDateRange(update);
            getRecords(startDate, endDate);
          }}
          // value={dateTo}
          isClearable={false}
          monthsShown={2}
          showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="dd.MM.yyyy"
          showDateMonthYearPicker
          className="date-picker"
          shouldCloseOnSelect={visible}

        >

        </DatePicker>

      </Grid>
      {show ?
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} mt="10px" spacing={{ sm: "20px", md: "20px", lg: "24px" }}>

          <MiniStatistics
            title={"Total No. of connected sellers"}
            amount={connectedSellers ? connectedSellers : 0}
            percentage={55}
            icon={<PersonIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />

          <MiniStatistics

            title={"Total Revenue"}
            amount={parseFloat(Number(revenue ? revenue : 0)).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            percentage={5}
            icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />

          <MiniStatistics
            title={"Total No of Transactions"}
            amount={transaction}
            percentage={-14}
            icon={<DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />

          <MiniStatistics

            title={"Average Order Value"}
            amount={parseFloat(Number(revenue ? revenue / 2 : 0)).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            percentage={8}
            icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />


        </SimpleGrid>
        :
        <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} mt="10px" spacing={{ sm: "20px", md: "20px", lg: "24px" }}>

          <MiniStatistics
            title={"Total No. of connected sellers"}
            amount={initialValues ? initialValues?.total_connected_seller : 0}
            percentage={55}
            icon={<PersonIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />

          <MiniStatistics

            title={"Total Revenue"}
            amount={parseFloat(initialValues ? initialValues?.total_revenue : 0).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            percentage={5}
            icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />

          <MiniStatistics
            title={"Total No of Transactions"}
            amount={initialValues ? initialValues?.total_no_of_tnx : 0}
            percentage={-14}
            icon={<DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />

          <MiniStatistics

            title={"Average Order Value"}
            amount={parseFloat(initialValues ? initialValues?.total_revenue / 2 : 0).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            percentage={8}
            icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
          />
        </SimpleGrid>
      }
    </Flex>
  )
}

export default AffiliateDashboard