import { pwaTrackingListeners } from "./A2HS.js"
const isBrowser = typeof window !== "undefined";
if (isBrowser) {
  pwaTrackingListeners();
}
export default function Serviceworker() {

    let swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
    navigator.serviceWorker.register(swUrl).then((response) => {
        // console.log("response done:", response)
    })

}

let swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

if ('serviceWorker' in navigator) {

    window.addEventListener('load', function () {
        // console.log("Is Service worker registered?")
        navigator.serviceWorker.register(swUrl)
            .then(function (registration) {
                // console.log("registration is sucessfull", registration.scope);
            }, function (err) {
                // console.log("Failed")

            })
            .catch(function (err) {
                console.log(err)
            })

    })
}
else {
    console.log("Service worker are not supported");
} 
