import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { captureTransactionId, generateChargeID, getPlaceOrderDetails } from '../API/AuthAPI'
import "./CheckoutForm.css";
import Emitter from "Emitter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { Box, useToast } from "@chakra-ui/react";
import { clearCart } from "Slices/cartslice";
import { useDispatch, useSelector } from "react-redux";
import { CheckoutAddressFields } from "Slices/cartslice";


export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  let history = useHistory();
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cart, setCart] = useState();
  const [token, setToken] = useState();
  const [user_id, setUser_id] = useState();
  const toast = useToast();
  const dispatch = useDispatch();
  const tagging_token = sessionStorage.getItem('reference_id');

  useEffect(() => {
    const checkLogin = async () => {
      //buyer token
      let userToken = localStorage.getItem("loginToken");
      setToken(userToken);
      setUser_id(localStorage.getItem("loginUser_id"));
      //cartid
      let cartId = sessionStorage.getItem("idCart");
      setCart(JSON.parse(cartId));
    }
    checkLogin();
  }, [cart, stripe, token])

  let newCart;
  Emitter.on("CART_ID", (e) => newCart = e)
  
  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const getOrder = async () => {
    let ref_id;
    if(!tagging_token==''){
      ref_id = tagging_token;
    }else{
      ref_id = ''
    }
    try {
      let merchant_id, order_id, tx_id, amount, currency;
      let gateway = 'stripe';
      let status = 'success';
      let mode = 'card'
      
      const result1 = await getPlaceOrderDetails(token, cart, props.mail, props.first_name, props.last_name, props.location, props.address,
        props.city, props.code, props.country_code, props.number, props.comment).then(responseJson => {
          if (responseJson) {
            
            toast({
                      position: 'top',
                      render: () => (
                          <Box
                              color='white'
                              p={3} bg='#219691'
                              textAlign={'center'}
                              fontWeight='bold'
                          >
                              {responseJson['message']}
                          </Box>
                      ),
                  })
            order_id = responseJson.order_details.id;
            merchant_id = responseJson.order_details.merchant_order_id;
            handleClearCart();
          }
        }
        )
      const result2 = await generateChargeID(token, props.payment_id).then((response) => {
        // console.log(response)
        tx_id = response.data.charges.data[0].id;
        amount = response.data.charges.data[0].amount;
        currency = response.data.charges.data[0].currency;
        
      })

      const result3 = await captureTransactionId(token, user_id, order_id, gateway, status,
        tx_id, ref_id, currency, amount/100 , mode, merchant_id, props.first_name,
        props.address, props.address2, props.code).then((res) => {
          if (res['message']=='Payment captured successfully.') {
              sessionStorage.removeItem('reference_id');
          }
        })

    } catch (e) {
      // alert(e)
    }
  }

  const passArgumentsToSuccessPage=()=>{
    let arr = {"token":token, "card_id":cart, "email":props.mail, "first_name":props.first_name, "last_name":props.last_name, "location":props.location, "address_line1":props.address, "address_line2":props.address2,
    "city":props.city, "postal_code":props.code, "country_code":props.country_code, "contact_no":props.number, "comment":props.comment, "payment_id":props.payment_id, "country":props.country}
    dispatch(CheckoutAddressFields(arr))
    sessionStorage.setItem('checkout',JSON.stringify(arr));
  }

  const handleSubmit = async (e) => {
    if(props.mail && props.first_name && props.last_name && props.location && props.address &&
      props.city && props.code && props.country_code && props.number && props.comment){
    e.preventDefault();
    passArgumentsToSuccessPage();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded. 
      return;
    }

    setIsProcessing(true);

    const { paymentIntent, error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/pay`,
      },
    })
    if (error) {
      Swal.fire({
        icon: 'error',
        title: 'Opps',
        text: 'Your Transaction has been declined!',
        confirmButtonColor: '#219691',
      })
      setIsProcessing(false)
    } else {
      console.log('succeeded')
    }

    setIsProcessing(false);
    }else{
      e.preventDefault();
      setIsProcessing(true);
      toast({
        position: 'top',
        render: () => (
            <Box
                color='white'
                p={3} bg='#219691'
                textAlign={'center'}
                fontWeight='bold'
            >
                Please fill all the shipping address fields
            </Box>
        ),
    })
    setIsProcessing(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" className="payment-element" />
      <button disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "PROCESSING... " : "PAY NOW"}
        </span>
      </button>
    </form>
  );
}