/*eslint-disable*/
// chakra imports
import {
  Box, Grid, IconButton, useColorModeValue
} from "@chakra-ui/react";
import React, { useState } from "react";
import Sidebar1 from "./Sidebar1";
import SidebarContent2 from "./SidebarContent2";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sidebarFields } from "Slices/cartslice";


// FUNCTIONS

function Sidebar(props) {
  // to check for active links and opened collapses
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";
  const [navSize, changeNavSize] = useState("large");
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();


  const { logoText, routes, sidebarVariant } = props;

  let history = useHistory();
  let location = useLocation();

  const handleClick = () => {
    if (navSize == "small" && expand == false) {
      changeNavSize("large");
      setExpand(true);
      dispatch(sidebarFields("large"));
      history.replace({
        pathname: location.pathname,
        state: "large"
      })
    }
    else {
      changeNavSize("small");
      setExpand(false);
      dispatch(sidebarFields("small"));
      history.replace({
        pathname: location.pathname,
        state: "small"
      })
      
    }
    // history.push({
    //   pathname: '/admin',
    //   state: { navSize: navSize}
    // })
  }

  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  if (sidebarVariant === "opaque") {
    sidebarBg = useColorModeValue("white", "gray.700");
    sidebarRadius = "0px";
    sidebarMargins = "16px 0px 16px 16px";
  }
  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: "none", xl: "flex" }} position="fixed" zIndex={1}>
        <Box
          bg="#ffffff"
          // transition={variantChange}
          w={navSize == "small" && expand == false ? "100px" : "260px"}
          maxW="260px"
          // ms={{
          //   sm: "16px",
          // }}
          // my={{
          //   sm: "16px",
          // }}
          h="100vh"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
        
          <SidebarContent2 routes={routes}
            logoText={"MY BOUTIQ"}
            display="none"
            sidebarVariant={sidebarVariant}
            navSize={navSize}
            onClick={() => { setExpand(true) }}
            expand={expand}
            handleClick={handleClick}
          />
          {/* <Sidebar1
          />                                                                                                                                                   */}
        </Box>
      </Box>
    </Box>
  );
}




export default Sidebar;