import React, { useState, createContext, } from 'react'
import SellerTable from './SellerTable'





export const forSignupModal = createContext(null)

const ContextModal = ({ children }) => {





    // state for Search Functionality in winners




    const [categoryRender, setCategoryRender] = useState('')


    const [getLocalCart, setGetLocalCart] = useState([])

    return (
        <forSignupModal.Provider value={{
            categoryRender,
            setCategoryRender,

            getLocalCart,
            setGetLocalCart


        }}>
            {children}
        </forSignupModal.Provider>


    )
}

export default ContextModal