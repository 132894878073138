import React, { useEffect, useState } from 'react';
import {
    Box, Flex, Grid, Text, Image, Input, useToast, FormControl, Button, FormLabel, Select,
} from "@chakra-ui/react";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { getPayoutDetails, updatePayoutDetails, createPayoutDetails } from 'components/API/AuthAPI';
import { Translate } from 'components/DeepL/Translate';
import Emitter from 'Emitter';
import {
    detailsAddedmsg, bankDetails, IBAN, BIC, addressText, cityText, postCodeText, regionText, countryText, payoutFundmsg,
    paypalDetails, PayPalEmail, saveText, cancelText
} from 'assets/constants/strings';

const BankDetails = () => {
    let userId;
    const [token, setToken] = useState();
    const [id, setId] = useState();
    const [payoutid, setPayoutId] = useState();
    const [isEmpty, setIsEmpty] = useState(false);
    const toast = useToast();
    const [selectedLanguage, setSelectedLanguage] = useState("GB");
    const [lang, setLang] = useState();
    const [state, setState] = useState({
        iban: "",
        bic: "",
        email: "",
        city: "",
        post_code: "",
        username: "",
        phone: "",
        password: "",
        confirmpassword: "",
        region: "",
        country: "",
        payoutfund: "",
        paypalemail: "",
        address: "",
    })
    const { iban, bic, email, city, post_code, phone, password, username, country, region, payoutfund, paypalemail, address, confirmpassword } = state;
    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })

    }

  /**
   * This function handles the submission of payout details and either saves or updates the details
   * based on whether they are empty or not.
   * @param e - The "e" parameter is an event object that is passed to the function when the form is
   * submitted. It is used to prevent the default form submission behavior and handle the form
   * submission manually.
   */
    const handleSubmit = (e) => {
        e.preventDefault()
        if (isEmpty === false) {
            //save account details
            createPayoutDetails(token, id, iban, bic, address, city, post_code, region, country, paypalemail, payoutfund)
                .then((responseJson) => {
                    if (responseJson) {
                        setPayoutId(responseJson.id);
                        if (responseJson.id) {
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        {detailsAddedmsg}
                                    </Box>
                                ),
                            })
                            setIsEmpty(false)
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000)
                        }

                    }
                })
                .catch((err) => console.log(err));
        } else {
            //update account details
            updatePayoutDetails(token, payoutid, iban, bic, address, city, post_code, region, country, paypalemail, payoutfund)
                .then((responseJson) => {
                    if (responseJson) {
                        setPayoutId(responseJson.id);
                        if (responseJson.isSuccess = "true") {
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        {detailsAddedmsg}
                                    </Box>
                                ),
                            })
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000)
                        }

                    }
                })
                .catch((err) => console.log(err));
        }
    }

    useEffect(() => {
        let userToken = localStorage.getItem("token");
        userId = localStorage.getItem("affiliate_id");
        if (userToken || userId) {
            setToken(userToken);
            setId(userId);
        }
        getBankDetails(userToken, userId);
    }, [token])

    /**
     * The function retrieves bank details for a user using a token and user ID, and sets the state
     * with the retrieved details.
     */
    const getBankDetails = (token, userId) => {
        getPayoutDetails(token, userId)
            .then((responseJson) => {
                if (responseJson) {
                    if (responseJson.data) {
                        let details = responseJson.data;
                        if (details.length > 0) {
                            // console.log("true")
                            setIsEmpty(true);
                        } else {
                            // console.log("false")
                            setIsEmpty(false);
                        }
                    }
                    let details = responseJson.data;
                    details.map((item) => {
                        setState({
                            iban: item.account_number,
                            bic: item.swift_code,
                            address: item.address,
                            city: item.city,
                            post_code: item.post_code,
                            region: item.region,
                            country: item.country,
                            payoutfund: item.payout_of_funds,
                            paypalemail: item.paypal_email
                        })
                        setPayoutId(item.id);
                    });

                }
            })
    }

    useEffect(() => {
        let language1 = localStorage.getItem('language');
        if (language1) {
            setSelectedLanguage(language1);
        } else {
            setSelectedLanguage("GB");
        }
    }, [lang])
    Emitter.on("LANGUAGE", (lang) => setLang(lang));

    return (
        <Flex
            direction='column' pt={{ base: "0px", md: "10px" }}
        >
            <form onSubmit={handleSubmit}>
                <Card p='16px' mt='5px'>
                    <CardHeader>
                        <Grid
                            w={{ base: '100%', md: '100%', lg: '100%' }}
                        >
                            <Text
                                fontWeight="bold"
                                color="#219691"
                            >
                                {/* { selectedLanguage !== "GB" ?  Translate("Bank Details") : "Bank Details"} */}
                                {bankDetails}
                            </Text>
                            <FormControl
                                display="flex"
                                flexDirection={{
                                    sm: "column",
                                    md: "row",
                                }}
                                pt="30px"
                            >
                                <Input
                                    fontSize='sm'
                                    ms="4px"
                                    borderRadius='15px'
                                    onChange={handleChange}
                                    name="iban"
                                    value={iban || ""}
                                    required
                                    // pattern="^[A-Z]{2}\d{2} (?:\d{4} ){3}\d{4}(?: \d\d?)?$"
                                    // placeholder= { selectedLanguage !== "GB" ? Translate('IBAN') : 'IBAN'}
                                    placeholder={IBAN}
                                    mb={{ sm: "0px", md: "'24px'", lg: '24px' }}
                                    width={{
                                        sm: "100%", // base
                                        md: "50%", // 480px upwards
                                        lg: "45%", // 768px upwards
                                        xl: "45%", // 992px upwards
                                    }}
                                    h="50px"
                                />
                                &nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    onChange={handleChange}
                                    name="bic"
                                    value={bic || ""}
                                    required
                                    // pattern="([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)"
                                    // placeholder= { selectedLanguage !== "GB" ? Translate('BIC') : 'BIC'}
                                    placeholder={BIC}
                                    mb={{ sm: "24px", md: "'24px'", lg: '24px' }}
                                    width={{
                                        sm: "100%", // base
                                        md: "50%", // 480px upwards
                                        lg: "45%", // 768px upwards
                                        xl: "45%", // 992px upwards
                                    }}
                                    h="50px"
                                />
                            </FormControl>
                            <FormControl
                                display="flex"
                                w='100%'
                                flexDirection={{
                                    sm: "column",
                                    md: "row",
                                }}
                            >
                                <Input
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    type='text'
                                    // placeholder= { selectedLanguage !== "GB" ? Translate('Address') : 'Address'}
                                    placeholder={addressText}
                                    required
                                    name='address'
                                    value={address || ""}
                                    pattern='^[a-zA-Z ]{2,30}$'
                                    mb={{ sm: "0px", md: "'24px'", lg: '24px' }}
                                    width={{
                                        sm: "100%", // base
                                        md: "50%", // 480px upwards
                                        lg: "30%", // 768px upwards
                                        xl: "30%", // 992px upwards
                                    }}
                                    h="50px"
                                    onChange={handleChange}
                                />
                                &nbsp;&nbsp;&nbsp;

                                <Input
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    onChange={handleChange}
                                    name="city"
                                    value={city || ""}
                                    required
                                    pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                    // placeholder= { selectedLanguage !== "GB" ? Translate('City') : 'City'}
                                    placeholder={cityText}
                                    mb={{ sm: "0px", md: "'24px'", lg: '24px' }}
                                    width={{
                                        sm: "100%", // base
                                        md: "50%", // 480px upwards
                                        lg: "30%", // 768px upwards
                                        xl: "30%", // 992px upwards
                                    }}
                                    h="50px"
                                />
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    onChange={handleChange}
                                    name="post_code"
                                    value={post_code || ""}
                                    required
                                    // pattern="([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})"
                                    // placeholder= { selectedLanguage !== "GB" ? Translate('Post Code') : 'Post Code'}
                                    placeholder={postCodeText}
                                    mb={{ sm: "24px", md: "'24px'", lg: '24px' }}
                                    width={{
                                        sm: "100%", // base
                                        md: "50%", // 480px upwards
                                        lg: "30%", // 768px upwards
                                        xl: "30%", // 992px upwards
                                    }}
                                    h="50px"
                                />

                            </FormControl>
                            <FormControl
                                display="flex"
                                flexDirection={{
                                    sm: "column",
                                    md: "row",
                                }}
                            >
                                <Input
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    onChange={handleChange}
                                    name="region"
                                    value={region || ""}
                                    required
                                    // pattern="^[a-zA-Z ]{2,30}$"
                                    // placeholder= { selectedLanguage !== "GB" ? Translate('Region') : 'Region'}
                                    placeholder={regionText}
                                    mb={{ sm: "0px", md: "'24px'", lg: '24px' }}
                                    width={{
                                        sm: "100%", // base
                                        md: "50%", // 480px upwards
                                        lg: "30%", // 768px upwards
                                        xl: "30%", // 992px upwards
                                    }}
                                    h="50px"
                                />
                                &nbsp;&nbsp;&nbsp;

                                <Input
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    onChange={handleChange}
                                    name="country"
                                    value={country || ""}
                                    required
                                    pattern="^[a-zA-Z ]{2,30}$"
                                    // placeholder= { selectedLanguage !== "GB" ? Translate('Country') : 'Country'}
                                    placeholder={countryText}
                                    mb={{ sm: "0px", md: "'24px'", lg: '24px' }}
                                    width={{
                                        sm: "100%", // base
                                        md: "50%", // 480px upwards
                                        lg: "30%", // 768px upwards
                                        xl: "30%", // 992px upwards
                                    }}
                                    h="50px"
                                />
                                &nbsp;&nbsp;&nbsp;
                                <Input
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    onChange={handleChange}
                                    name="payoutfund"
                                    value={payoutfund || ""}
                                    required
                                    // pattern="^[0-9\b]+$"
                                    type='text'
                                    // placeholder= { selectedLanguage !== "GB" ? Translate('Payout of funds(in days)') : 'Payout of funds(in days)'}
                                    placeholder={payoutFundmsg}
                                    mb={{ sm: "0px", md: "'24px'", lg: '24px' }}
                                    width={{
                                        sm: "100%", // base
                                        md: "50%", // 480px upwards
                                        lg: "30%", // 768px upwards
                                        xl: "30%", // 992px upwards
                                    }}
                                    h="50px"
                                />
                            </FormControl>

                        </Grid>
                    </CardHeader>
                </Card>
                <Card p='16px' mt='24px'>
                    <CardHeader>
                        <Grid
                            w={{ base: '100%', md: '100%', lg: '100%' }}
                            py="10px"
                        >
                            <Text
                                fontWeight="bold"
                                color="#219691"
                            >
                                {/* { selectedLanguage !== "GB" ? Translate("PayPal Details") : "PayPal Details"} */}
                                {paypalDetails}
                            </Text>
                            <FormControl
                                display="flex"
                                pt="30px"
                                flexDirection={{
                                    sm: "column",
                                    md: "row",
                                }}
                            >
                                <Input
                                    fontSize='sm'
                                    ms='4px'
                                    borderRadius='15px'
                                    onChange={handleChange}
                                    name="paypalemail"
                                    value={paypalemail || ""}
                                    required
                                    // pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$"
                                    // placeholder= { selectedLanguage !== "GB" ? Translate('PayPal email') : 'PayPal email'}
                                    placeholder={PayPalEmail}
                                    mb={{ sm: "0px", md: "'24px'", lg: '24px' }}
                                    width={{
                                        sm: "100%", // base
                                        md: "50%", // 480px upwards
                                        lg: "30%", // 768px upwards
                                        xl: "30%", // 992px upwards
                                    }}
                                    h="50px"
                                />
                            </FormControl>

                        </Grid>
                    </CardHeader>
                </Card>
                <Box
                    mb={{ sm: "25px", md: "35px", xl: "10px" }}
                    w={{ base: '100%', md: '100%', lg: '100%' }}
                    borderRadius='25px'
                    py='0px'
                    px="20px"
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    align='center'>
                    <Box
                        // bg="gray.100"
                        borderRadius='25px'
                        w={{ base: '100%', md: '100%', lg: '100%' }}
                        h='100px'
                        display='flex'
                        justifyContent={{
                            sm: "right",
                            md: "left",
                        }}>

                        <Flex
                            display="flex"
                            direction='column'
                            // marginRight="50px"
                            justifyContent={'right'}
                            // margin='0 auto'
                            py="20px"
                        >

                            <Grid
                                display="flex"
                                py='10px'
                            >
                                <Button
                                    onClick={handleSubmit}
                                    colorScheme='none'
                                    variant='outline'
                                    p='8px 32px'
                                    bg="#219691"
                                    color="white"
                                >
                                    {/* { selectedLanguage !== "GB" ? Translate("Save") : "Save"} */}
                                    {saveText}
                                </Button>&nbsp;&nbsp;
                                <Button
                                    colorScheme='none'
                                    variant='outline'
                                    p='8px 32px'
                                    bg='#ffffff'
                                    border="1px solid #219691"
                                    color="#219691"

                                >
                                    {/* { selectedLanguage !== "GB" ? Translate("Cancel") : "Cancel"} */}
                                    {cancelText}
                                </Button>
                            </Grid>

                        </Flex>
                    </Box>
                </Box>
            </form>

        </Flex>
    )
}

export default BankDetails