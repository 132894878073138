import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Flex, Text, Image, Avatar,
    Center, Box, Link,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import React from 'react';
import { IoCall } from 'react-icons/io5';
import { MdEmail } from 'react-icons/md';
import { BiStore } from 'react-icons/bi';
import './homepage.css';


const StoreInformation = (props) => {
    return (
        <div>
            <Modal
                isOpen={props.storeDetails}
                className='home_first_modal'
                size='2xl'
                blockScrollOnMount={false}
            >
                <ModalOverlay />
                <ModalContent >

                    <ModalHeader mt='5'
                        className='hamburge_modal' >
                        <Image
                            src={props.logo}
                            width={{ sm: '50%', md: "25%", lg: "30%" }}
                            margin={'0 auto'}
                        />
                        <CloseIcon
                            cursor='pointer'
                            onClick={props.getClosestore} />
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <p className='store_line' ></p>
                            <Text display='flex' flex='auto'
                                justifyContent='center'
                                fontWeight='bold'
                                fontSize='xl' mt='5' mb='10'
                            // cursor='pointer'
                            >
                                {/* {Translate("Store Information") ? Translate("Store Information") : "Store Information"} */}
                                Store Information
                            </Text>

                            <Center className='home_wrapper_details'>
                                {
                                    props.backgroundProfile.map((ele, index) => {
                                        return (
                                            <Flex
                                                justifyContent='space-around'
                                                key={index}
                                            >
                                                <Box maxW='sm' >
                                                    <Avatar
                                                        // className='home_inner_img'
                                                        w={{ sm: "200px", md: "150px", lg: "200px" }}
                                                        h={{ sm: "200px", md: "150px", lg: "200px" }}
                                                        src={'https://adminmyboutiqe.progfeel.co.in' + ele.store_profile_image}
                                                        alt='Dan Abramov'

                                                        borderRadius='full'
                                                    />

                                                </Box>
                                                <Flex flexDirection='column' mt='15px' ml='20px'>
                                                    <Flex alignItems='center' h='10%' mt='30px'>
                                                        <BiStore size="30px"
                                                            className='store_images' />
                                                        <Text
                                                            fontSize={{ sm: "xl", md: "xl" }}
                                                            fontWeight='bold'
                                                            ml='25px'
                                                        >
                                                            {ele.store_name}
                                                        </Text>
                                                    </Flex>
                                                    <Flex alignItems='center' h='10%' mt='30px'>
                                                        <IoCall
                                                            size='30px'
                                                            className='store_images'
                                                        />
                                                        <Link
                                                            fontSize={{ sm: "xl", md: "xl" }}
                                                            fontWeight='bold'
                                                            ml='25px'
                                                        >
                                                            {ele.store_contact}
                                                        </Link>
                                                    </Flex>
                                                    <Flex
                                                        alignItems='center'
                                                        h='10%'
                                                        mt='30px'
                                                    >
                                                        <MdEmail
                                                            size='30px'
                                                            className='store_images'
                                                        />
                                                        <Link
                                                            fontSize={{ sm: "xl", md: "xl" }}
                                                            fontWeight='bold'
                                                            ml='25px'
                                                        >
                                                            {ele.store_email}
                                                        </Link>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        )
                                    })
                                }
                            </Center>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default StoreInformation