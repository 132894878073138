
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import {
    Flex, Text, Image, Center, Input, Button, Avatar, Select, InputGroup,
    useDisclosure, InputLeftAddon, Box, Checkbox, InputRightElement, ChakraProvider
} from '@chakra-ui/react'
import { BiShow, BiHide } from "react-icons/bi"
import { currencyList, register, forgotpassword, login, getAccountProfile, getuserupdateprofile } from '../API/AuthAPI'
import { AddIcon, MinusIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import './mainHeader.css'
import { FiCornerDownLeft } from 'react-icons/fi'
import { TbTruckDelivery } from 'react-icons/tb'
import { BsExclamationLg } from 'react-icons/bs'
import { Fade, ScaleFade, Slide, Collapse } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import hamer from 'assets/img/hamburger-menu.png'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@chakra-ui/react'
import { getReturnOrder } from '../API/AuthAPI'
import { dateFormat } from '../../components/helper/index'
import { Translate } from 'components/DeepL/Translate'



function EmptyReturnorder({ closeReturnItem, closeOrderDetails, getmyaccount, noorder, returnItem }) {


    // To Open Homepage Hamburge Dropdown 

    const [hamburge, setHamburge] = useState(false)


    const openHam = () => {
        setHamburge(true)
    }

    const closeHam = () => {
        setHamburge(false)
    }

    //3****** TO open Return Item menu --- Modal


    const [returnPro, setReturnPro] = useState(false)

    const openReturnPro = () => {
        setReturnPro(true)
        setOrders(false)
        setDropdown(false)
    }


    const closeReturnPro = () => {
        setReturnPro(false)
    }



    //2**** 3 Print Return Label ---- my orders -all

    const [printOrder, setPrintOrder] = useState(false)

    const printReturnOrder = () => {
        setPrintOrder(true)
        closeOrderDetails()
    }


    const closePrintReturnOrder = () => {
        setPrintOrder(false)
    }



    // onClick on My profile/order Details/order Overview --- hamburger

    const [overviewHamer, setOverviewHamer] = useState(false)

    const overviewHam = () => {
        setOverviewHamer(true)
    }




    const getMyorders = () => {
        setOverviewHamer(false)
        setOverview(false)
        setMyProfile(false)
        setOrderDetails(true)
    }








    return (
        <div>
            {/*2--- 2  My Orders -  --- onClick on order overView View Details button open ---- Order Details  - all */}



            <Modal size='5xl' blockScrollOnMount={false}
                isOpen={noorder}
            >
                <ModalOverlay />
                <ModalContent>
                    <Menu>
                        <ModalHeader display='flex' mt='5'>
                            <MenuButton
                                className='myprofile_header'
                                onClick={overviewHam} >


                                <Image ml='5'
                                    w='8%'
                                    h='8%'

                                    src={hamer}


                                />

                            </MenuButton>
                            <div
                                className='product_hamburger_inner_text'
                            >
                                <Text fontSize='2xl'
                                    color='#219691'
                                    fontWeight='bold'>
                                    MY BOUTIQ
                                </Text>
                                <CloseIcon mr='6'
                                    w='6%'
                                    h='50%'
                                    cursor='pointer'
                                    onClick={closeReturnItem}
                                />
                            </div>
                            <MenuList >
                                <MenuItem
                                    className='inner_hamer_login'>
                                    <Flex
                                        flexDirection='column'
                                        w='50vh' top='0' >
                                        <Flex
                                            justifyContent='space-between'
                                            mb='3' mt='5' >

                                            <Text
                                                fontSize='2xl'
                                                className='product_inner_hamer'
                                            >
                                                MY BOUTIQ
                                            </Text>
                                            <CloseIcon mt='1'
                                                onClick={closeHam}
                                            />
                                        </Flex>
                                        <Flex
                                            flexDirection='column'
                                            alignItems='center'
                                            mt='5'>
                                            <p className='home_line' ></p>
                                            <Text
                                                fontWeight='bold'
                                                fontSize='lg' mt='8'
                                                onClick={getmyaccount}
                                                cursor='pointer'>
                                                {/* {Translate ? Translate("My Account") : "My Account"} */}
                                                My Account
                                            </Text>
                                            <Text
                                                fontWeight='bold'
                                                fontSize='lg' mt='8'
                                                onClick={getMyorders}
                                                cursor='pointer'>
                                                {/* { Translate ? Translate("My Orders") : "Orders" } */}
                                                My Orders
                                            </Text>
                                            <Text
                                                fontWeight='bold'
                                                fontSize='lg' mt='8'
                                                onClick={openReturnPro}
                                                cursor='pointer'>
                                                {/* { Translate ? Translate("Logout") : "Logout" } */}
                                                Logout
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </MenuItem>
                            </MenuList>
                            {/* 
                                                <CloseIcon cursor='pointer' onClick={closeAccountOverview} /> */}
                        </ModalHeader>
                    </Menu>
                    <ModalBody
                        display='flex'
                        justifyContent='space-around'
                        flexDirection='column'
                    >

                        <Text fontSize='2xl' mt='5'>
                            {/* { Translate ? Translate("My Orders") : "My Orders" } */}
                            My Orders
                        </Text>
                        <Flex
                            flexDirection='column'
                            textAlign='center'>
                            <Text mt='10'>
                                {/* { Translate ? Translate("Unfortunately, you have not yet placed any order.") : "Unfortunately, you have not yet placed any order."} */}
                                Unfortunately, you have not yet placed any order
                            </Text>
                            <Button mt='5'
                                bg='gray'
                                colorScheme='none'
                                onClick={printReturnOrder}
                                color='white'>
                                SHOP NOW
                            </Button>
                        </Flex>

                    </ModalBody>


                    <Box bg='#219691'
                        colorScheme='none'
                        w='100%' p={20}
                        color='white'
                        mt='20'
                        display='flex'
                        gap='20'
                    >
                    </Box>

                </ModalContent>
            </Modal>

            {/*2---- 3 // Print Order Label Modal ----- Order Details onClick of --- Return all Items */}

            <Modal
                isOpen={printOrder}
                size='2xl'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader
                        onClick={closeHam}
                        className='hamburge_modal' mt='5'>
                        <Text
                            className='product_hamburger_text'>
                            Return Order
                        </Text>

                        <CloseIcon
                            cursor='pointer'
                            onClick={closePrintReturnOrder} />
                    </ModalHeader>

                    <ModalBody
                        display='flex'
                        justifyContent='center'
                        flexDirection='column'
                        alignItems='center'
                        gap='5'
                    >

                        <p className='home_line' ></p>
                        <Text
                            fontSize='xl'
                            mt='10'>
                            Return Created
                        </Text>
                        <Text>
                            Date : 01.01.2022
                        </Text>
                        <Text>
                            ID : 6878675445
                        </Text>
                        <Button
                            color='white'
                            mt='10'
                            w='50%'
                            colorScheme='none'
                            bg="#219691"
                            size='lg'
                        >
                            Print Return Label
                        </Button>
                        <Button
                            color='white'
                            mb='50'
                            w='50%'
                            colorScheme='none'
                            bg="#219691"
                            size='lg'
                        >
                            Print Withdrawal Form
                        </Button>
                    </ModalBody>


                </ModalContent>
            </Modal>

        </div>
    )
}

export default EmptyReturnorder;
