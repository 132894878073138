
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import {
    Flex, Text, Image, Center, Input, Button, Avatar, Select, InputGroup,
    useDisclosure, InputLeftAddon, Box, Checkbox, InputRightElement, ChakraProvider, Grid
} from '@chakra-ui/react'
import { BiShow, BiHide } from "react-icons/bi"
import { currencyList, register, forgotpassword, login, getAccountProfile, getuserupdateprofile, getPlace_order } from '../API/AuthAPI'
import { AddIcon, MinusIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import './mainHeader.css'
import { FiCornerDownLeft } from 'react-icons/fi'
import { TbTruckDelivery } from 'react-icons/tb'
import { BsExclamationLg } from 'react-icons/bs'
import { Fade, ScaleFade, Slide, Collapse } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import hamer from 'assets/img/hamburger-menu.png'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@chakra-ui/react'
import { getMyOrdersList } from '../API/AuthAPI'
import { dateFormat } from '../../components/helper/index'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'



function MyOrders({ orderDetails, closeOrderDetails, getmyaccount, openAccountOverview, orderInfo }) {


    

    const [hamburge, setHamburge] = useState(false)
    const [returnPro, setReturnPro] = useState(false)
    const [printOrder, setPrintOrder] = useState(false)
    const [overviewHamer, setOverviewHamer] = useState(false)
    const [loginToken, setLoginToken] = useState()
    const [getorderDeatils, setGetorderDeatils] = useState([])
    const [productInfo, setProductInfo] = useState([])
    const [shippingAddress, setShippingAddress] = useState();
    const [billingAddress, setBillingAddress] = useState();
    let history = useHistory();

// To Open Homepage Hamburge Dropdown 
    const openHam = () => {
        setHamburge(true)
    }

    const closeHam = () => {
        setHamburge(false)
    }

    //3****** TO open Return Item menu --- Modal


    

    const openReturnPro = () => {
        setReturnPro(true)
        setOrders(false)
        setDropdown(false)
    }


    const closeReturnPro = () => {
        setReturnPro(false)
    }



    //2**** 3 Print Return Label ---- my orders -all

    

    const printReturnOrder = () => {
        setPrintOrder(true)
        closeOrderDetails()
    }


    const closePrintReturnOrder = () => {
        setPrintOrder(false)
    }



    // onClick on My profile/order Details/order Overview --- hamburger

    

    const overviewHam = () => {
        setOverviewHamer(true)
    }




    const getMyorders = () => {
        setOverviewHamer(false)
        setOverview(false)
        setMyProfile(false)
        setOrderDetails(true)
    }




    
    // console.log('object', loginToken);

    useEffect(() => {
        let accToken = localStorage.getItem("token")
        setLoginToken(accToken)
    }, [loginToken])



    // console.log('array101',orderInfo)

   
    useEffect(() => {
        setGetorderDeatils(orderInfo)
        setShippingAddress(orderInfo.shipping_address);
        setBillingAddress(orderInfo.billing_address);
        setProductInfo(orderInfo.order_details)

    }, [orderInfo])

    //get currenct date
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = mm + '.' + dd + '.' + yyyy;

    const handleRedirectToStore=()=>{
        let storename = localStorage.getItem("storename");
        if(storename){
            history.push(`/${storename}`)
        }else{
            history.push('/sachin-store')
        }
    }

    return (
        <>
            {/*2--- 2  My Orders -  --- onClick on order overView View Details button open ---- Order Details  - all */}



            <Modal
                size='5xl'
                blockScrollOnMount={false}
                isOpen={orderDetails}
            >
                <ModalOverlay />
                <ModalContent>
                    <Menu>
                        <ModalHeader
                            display='flex' mt='5'>
                            <MenuButton
                                className='myprofile_header'
                                onClick={overviewHam} >


                                <Image ml='5'
                                    w='8%'
                                    h='8%'
                                    src={hamer}
                                />

                            </MenuButton>
                            <div
                                className='product_hamburger_inner_text'

                            >
                                <Text fontSize='2xl'
                                    color='#219691'
                                    fontWeight='bold'>
                                    MY BOUTIQ
                                </Text>
                                <CloseIcon mr='6'
                                    w='6%'
                                    h='50%'
                                    cursor='pointer'
                                    onClick={closeOrderDetails}
                                />
                            </div>
                            <MenuList>
                                <MenuItem
                                    className='inner_hamer_login'>
                                    <Flex
                                        flexDirection='column'
                                        w='50vh' top='0' >
                                        <Flex
                                            justifyContent='space-between'
                                            mb='3' mt='5' >

                                            <Text fontSize='2xl'
                                                className='product_inner_hamer'
                                            >
                                                MY BOUTIQ
                                            </Text>
                                            <CloseIcon mt='1' onClick={closeHam} />
                                        </Flex>
                                        <Flex
                                            flexDirection='column'
                                            alignItems='center' mt='5'>
                                            <p className='home_line' ></p>
                                            <Text
                                                fontWeight='bold'
                                                fontSize='lg' mt='8'
                                                onClick={getmyaccount}
                                                cursor='pointer'>
                                                My Account
                                            </Text>
                                            <Text
                                                fontWeight='bold'
                                                fontSize='lg' mt='8'
                                                onClick={getMyorders}
                                                cursor='pointer'>
                                                My Orders
                                            </Text>
                                            <Text
                                                fontWeight='bold'
                                                fontSize='lg' mt='8'
                                                onClick={openReturnPro}
                                                cursor='pointer'>
                                                Logout
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </MenuItem>
                            </MenuList>
                            {/* 
                                                <CloseIcon cursor='pointer' onClick={closeAccountOverview} /> */}
                        </ModalHeader>
                    </Menu>
                    <ModalBody
                        display='flex'
                        justifyContent='space-around'
                        flexDirection='column'
                    >
                        {
                            !orderInfo["message"]?

                                <>


                                    <Text
                                        fontSize='2xl' mt='5'>
                                        MY ORDER
                                    </Text>
                                    <Flex
                                        className='product_account_details'
                                        justifyContent='space-between'
                                        mt='10'>
                                        {/* <Flex flexDirection={{sm:'column', md:'row', lg:'row'}}
                                        > */}
                                        <Flex
                                            className='product_account_details'
                                            flexDirection='column'>
                                            <Text
                                                fontWeight='bold'
                                            >
                                                Order Information
                                            </Text>
                                            {/* <Text>
                                                Order Id: {orderInfo.id ? orderInfo.id : ""}
                                            </Text> */}
                                            <Text>
                                                Order Id: {getorderDeatils.merchant_order_id ? getorderDeatils.merchant_order_id : ""}
                                            </Text>
                                            <Text>
                                                Order Date : {dateFormat(getorderDeatils.order_date ? getorderDeatils.order_date : "")}
                                            </Text>
                                        </Flex>

                                        {shippingAddress && (
                                            <Flex
                                                flexDirection='column'>
                                                <Text
                                                    fontWeight='bold'
                                                >
                                                    Shipping Information
                                                </Text>
                                                <Text>
                                                    {shippingAddress.first_name ? shippingAddress.first_name : ""} {shippingAddress.last_name ? shippingAddress.last_name : ""}
                                                </Text>
                                                <Text>
                                                    {shippingAddress.address_line_1 ? shippingAddress.address_line_1 : ""}
                                                </Text>
                                                <Text>
                                                    {shippingAddress.address_line_2 ? shippingAddress.address_line_2 : ""}
                                                </Text>
                                                <Text>
                                                    {shippingAddress.zipcode ? shippingAddress.zipcode : ""} {""} {shippingAddress.city ? shippingAddress.city : ""}
                                                </Text>

                                            </Flex>
                                        )}
                                        {billingAddress && (
                                            <Flex
                                                flexDirection='column'>
                                                <Text
                                                    fontWeight='bold'
                                                >
                                                    Invoice Information
                                                </Text>
                                                <Text>
                                                    {billingAddress.first_name ? billingAddress.first_name : ""} {billingAddress.last_name ? billingAddress.last_name : ""}
                                                </Text>
                                                <Text>
                                                    {billingAddress.address_line_1 ? billingAddress.address_line_1 : ""}
                                                </Text>
                                                <Text>
                                                    {billingAddress.address_line_2 ? billingAddress.address_line_2 : ""}
                                                </Text>
                                                <Text>
                                                    {billingAddress.zipcode ? billingAddress.zipcode : ""} {""} {billingAddress.city ? billingAddress.city : ""}
                                                </Text>


                                            </Flex>
                                        )}

                                        <Flex
                                            className='product_account_details'
                                            flexDirection='column' ml='20'
                                            display={{ sm: "flex", md: "none", lg: "none" }}
                                        >
                                            <Button
                                                colorScheme='none'
                                                bg="#219691"
                                                color='white'

                                                onClick={openAccountOverview}
                                            >
                                                Buy again
                                            </Button>
                                            <Button
                                                mt='5'
                                                bg='gray'
                                                w='100%'
                                                colorScheme='none'
                                                onClick={printReturnOrder}
                                                color='white'
                                            >
                                                Return All Items
                                            </Button>
                                            {/* </Flex> */}
                                        </Flex>
                                        <Flex
                                            className='product_account_details'
                                            flexDirection='column' ml='20'
                                            display={{ sm: "none", md: "flex", lg: "flex" }}
                                        >
                                            <Button
                                                colorScheme='none'
                                                bg="#219691"
                                                color='white'
                                                size='lg'
                                                onClick={openAccountOverview}
                                            >
                                                Buy again
                                            </Button>
                                            <Button
                                                mt='5' bg='gray'
                                                colorScheme='none'
                                                onClick={printReturnOrder}
                                                color='white'>
                                                Return All Items
                                            </Button>
                                        </Flex>
                                    </Flex>
                                    <Box
                                        colorScheme='none'
                                        className='product_account_header'
                                        bg='#219691' w='100%' p={4}
                                        color='white'
                                        mt='10'

                                        gap='24'
                                        display={{ sm: "flex", md: "none", lg: "none" }}
                                        justifyContent='center'
                                    >
                                        <Text
                                            fontSize='xl'
                                            fontWeight='bold'
                                        >
                                            Product Information
                                        </Text>
                                    </Box>
                                    <Box
                                        colorScheme='none'
                                        className='product_account_header'
                                        bg='#219691' w='100%' p={4}
                                        color='white'
                                        mt='10'

                                        gap='24'
                                        display={{ sm: "none", md: "flex", lg: "flex" }}
                                    >
                                        <Text
                                            fontSize='xl'
                                            fontWeight='bold'
                                        >
                                            Product Image
                                        </Text>
                                        <Text
                                            fontSize='xl'
                                            fontWeight='bold'
                                        >
                                            Product Information
                                        </Text>
                                        <Text
                                            fontSize='xl'
                                            fontWeight='bold'
                                        >
                                            MY BOUTIQ
                                        </Text>
                                    </Box>

                                    {productInfo ? productInfo.map((response, index) => {
                                        return (
                                            <div key={index}>

                                                <Flex
                                                    className='product_account_details'
                                                    mt='10'
                                                    justifyContent='space-between'>

                                                    <Image

                                                        w={{ sm: "100%", md: "30%", lg: "20%" }}
                                                        h={{ sm: "10%", md: "10%", lg: "200px" }}
                                                        src={"https://adminmyboutiqe.progfeel.co.in" + response.product_details.product_image}
                                                        alt='Dan Abramov' />

                                                    <Flex
                                                        flexDirection='column'
                                                        gap='2'>

                                                        <Text
                                                            mt={{ sm: "30px", md: "0px", lg: "0px" }}
                                                            justifyContent={{ sm: "center" }}
                                                            fontSize='xl'
                                                            fontWeight='bold'
                                                        >
                                                            Product Name: {response.product_details.product_name ? response.product_details.product_name : ""}
                                                        </Text>


                                                        <>
                                                            <Text fontSize='xl'>
                                                                Quantity: {response.quantity ? response.quantity : ""}
                                                            </Text>
                                                            <Text fontSize='xl'>
                                                                Total: €{response.product_details.PRDPrice ? parseFloat(response.product_details.PRDPrice).toFixed(2) : ""}
                                                            </Text>
                                                        </>


                                                    </Flex>

                                                    <Flex
                                                        className='product_store_name'
                                                        flexDirection='column'
                                                        gap='5' ml='16'
                                                    >
                                                        <Text fontSize='2xl'>
                                                            {response.product_details.get_store_name[0].store_name}
                                                        </Text>
                                                    </Flex>
                                                    <Flex
                                                        className='product_account_details'
                                                        flexDirection='column' ml='16'>
                                                        <Button
                                                            colorScheme='none'
                                                            bg="#219691"
                                                            color='white'

                                                            onClick={openAccountOverview}
                                                        >
                                                            Buy again
                                                        </Button>
                                                        <Button
                                                            mt='5'
                                                            w='100%'
                                                            bg='gray'
                                                            colorScheme='none'
                                                            onClick={printReturnOrder}
                                                            color='white'>Return Item</Button>
                                                    </Flex>
                                                </Flex>
                                            </div>
                                        )
                                    })
                                        :
                                        ""
                                    }

                                </>

                                :
                                <Flex flexDir='column'>
                                     <Text
                                        fontSize='xl' mt='5'>
                                        MY ORDERS
                                    </Text>
                                    <Text mt='20'
                                        textAlign='center'
                                        fontSize='2xl'
                                        fontWeight='bold'
                                        color="#219691"
                                    >
                                        Unfortunately, you have not yet placed any orders.
                                    </Text>
                                    <Grid 
                                        mt='40px'
                                    >
                                    <Button
                                        color='#FFFFFF'
                                        bg='#219691'
                                        colorScheme={'none'}
                                        // w='xs'
                                        size={'md'}
                                        justifyContent={'center'}
                                        margin='0 auto'
                                        onClick={()=>{handleRedirectToStore()}}
                                    >
                                        SHOP NOW
                                    </Button>
                                    </Grid>
                                </Flex>
                        }

                    </ModalBody>


                    <Box bg='#219691'
                        w='100%' p={20}
                        color='white'
                        mt='20'
                        display='flex'
                        gap='20'
                    >
                    </Box>

                </ModalContent>
            </Modal>

            {/*2---- 3 // Print Order Label Modal ----- Order Details onClick of --- Return all Items */}

            <Modal
                isOpen={printOrder}
                size='2xl'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader
                        onClick={closeHam}
                        className='hamburge_modal' mt='5'>
                        <Text
                            className='product_hamburger_text'>
                            Return Order
                        </Text>

                        <CloseIcon
                            cursor='pointer'
                            onClick={closePrintReturnOrder}
                        />
                    </ModalHeader>

                    <ModalBody
                        display='flex'
                        justifyContent='center'
                        flexDirection='column'
                        alignItems='center'
                        gap='5'
                    >

                        <p className='home_line' ></p>
                        {
                            getorderDeatils ?
                                <>
                                    <Text
                                        fontSize='xl'
                                        mt='10'>
                                        Return Created
                                    </Text>
                                    <Text>
                                        Date : {today}
                                    </Text>
                                    <Text>
                                        ID : {getorderDeatils.id}
                                    </Text>
                                </>
                                :
                                ""
                        }
                        <Button
                            color='white'
                            mt='10'
                            w='50%'
                            colorScheme='none'
                            bg="#219691"
                            size='lg'
                        >
                            Print Return Label
                        </Button>
                        <Button
                            color='white'
                            mb='50'
                            w='50%'
                            colorScheme='none'
                            bg="#219691"
                            size='lg'
                        >
                            Print Withdrawal Form
                        </Button>

                    </ModalBody>


                </ModalContent>
            </Modal>

        </>
    )
}

export default MyOrders
