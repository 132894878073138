
export const dateFormat = (dateString, separator = "T") => {
    let newDateTimeString = dateString.split(separator);
    let newDateString = newDateTimeString[0].split("-");
    let newDateText = newDateString[2] + '.' + newDateString[1] + '.' + newDateString[0];
    return newDateText;
}
export const timeFormat = (dateString, separator = "T") => {
    // alert(dateString)
    let newDateTimeString = dateString !== "" ? dateString.split(separator) : [];
    if (newDateTimeString.length > 0) {
        let newTimeString = newDateTimeString[1].split(":");
        let newHours = parseInt(newTimeString[0]);
        let newMenutes = parseInt(newTimeString[1]);
        let ampm = newHours >= 12 ? 'PM' : 'AM';
        newHours = newHours % 12;
        newHours = newHours ? newHours : 12;
        newMenutes = newMenutes < 10 ? '0' + newMenutes : newMenutes;
        let newTimeText = newHours + ':' + newMenutes + '' + ampm;
        return newTimeText;
    } else {
        return "";
    }
}


export const timeFormatsetter = (dateString, separator = "T") => {
    // alert(dateString)
    let newDateTimeString = dateString !== "" ? dateString.split(separator) : [];
    if (newDateTimeString.length > 0) {
        let newTimeString = newDateTimeString[1].split(":");
        let newHours = parseInt(newTimeString[0]);
        let newMenutes = parseInt(newTimeString[1]);
        // let ampm = newHours >= 12 ? 'PM' : 'AM';
        // newHours = newHours % 12;
        // newHours = newHours ? newHours : 12;
        newMenutes = newMenutes < 10 ? '0' + newMenutes : newMenutes;
        let newTimeText = newHours + ':' + newMenutes
        return newTimeText;
    } else {
        return "";
    }
}

export const monthText = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const dayText = [
    'SUNDAY',
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
];

export const optionLabels = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
];

export const dateStringToDateGetTime = (dateString, separator = " ") => {
    let newDateTimeString = dateString.split(separator);
    let newDateString = newDateTimeString[0].split("-");
    let newTimeString = newDateTimeString[1].split(":");
    let newHours = parseInt(newTimeString[0]);
    let newMenutes = parseInt(newTimeString[1]);
    let dateObject = new Date(parseInt(newDateString[0]), parseInt(newDateString[1]) - 1, parseInt(newDateString[2]), newHours, newMenutes);
    return dateObject.getTime();
}

export const dateFormatMonthText = (dateString, separator = " ") => {
    let newDateTimeString = dateString.split(separator);
    let newDateString = newDateTimeString[0].split("-");
    let newDateText = newDateString[2] + ' ' + monthText[parseInt(newDateString[1]) - 1] + ' ' + newDateString[0];

    return newDateText;

}

export const dateFormatDayText = (dateString, separator = " ") => {
    let newDateTimeString = dateString.split(separator);
    let newDateString = newDateTimeString[0].split("-");
    let dateObject = new Date(parseInt(newDateString[0]), parseInt(newDateString[1]) - 1, parseInt(newDateString[2]));
    let newDateText = dayText[dateObject.getDay()];

    return newDateText;
}



export const dateFromDateObject = (dateObject = new Date()) => {
    let newYear = dateObject.getFullYear();
    let month = dateObject.getMonth() + 1;
    let date = dateObject.getDate();
    let newMonth = month < 10 ? "0" + month : month;
    let newDate = date < 10 ? "0" + date : date;
    let newDateText = newYear + "-" + newMonth + "-" + newDate;
    return newDateText;
}

export const timeFromDateObject = (dateObject = new Date()) => {
    let newHours = dateObject.getHours();
    let newMenutes = dateObject.getMinutes();
    let ampm = newHours >= 12 ? 'PM' : 'AM';
    newHours = newHours % 12;
    newHours = newHours ? newHours : 12;
    newMenutes = newMenutes < 10 ? '0' + newMenutes : newMenutes;
    let newTimeText = newHours + ':' + newMenutes + '' + ampm;
    return newTimeText;
}

export const timeForApiFromDateObject = (dateObject = new Date()) => {
    let newHours = dateObject.getHours();
    let newMenutes = dateObject.getMinutes();
    newHours = newHours < 10 ? '0' + newHours : newHours;
    newMenutes = newMenutes < 10 ? '0' + newMenutes : newMenutes;
    let newTimeText = newHours + ':' + newMenutes;
    return newTimeText;
}

export const dateStringToDateObject = (dateObject) => {
    let newArray = dateObject.split("-");
    let newDateObject = new Date();
    if (newArray.length === 3) {
        let newYear = parseInt(newArray[0]);
        let newMonth = parseInt(newArray[1]) - 1;
        let newDate = parseInt(newArray[2]);
        newDateObject = new Date(newYear, newMonth, newDate);
    }
    return newDateObject;
}



