import { Button, Flex, Grid, Input, Text, Textarea } from '@chakra-ui/react'
import { addCaption, postCaption } from 'assets/constants/strings';
import { Translate } from 'components/DeepL/Translate';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { selectCaptionText } from 'Slices/launchpadslice';

const ChooseText = (props) => {
  const [caption, setCaption] = useState();
  const dispatch = useDispatch();
  
  //add caption text
  const handleSelectCaption=()=>{
    dispatch(selectCaptionText(caption));
    props.onClick();
  }

  // useEffect(()=>{
  //   handleSelectCaption();
  //   props.onClick();
  // },[caption]);

  return (
    <Flex
      flexDirection="column"
    >
      <Text
        textAlign="center"
        fontWeight="bold"
        pt="20px"
      >
        {/* {Translate ? "Post Caption" :  Translate("Post Caption")} */}
        {postCaption}
      </Text>

      <Grid
        mt="20px"

      >
        <Textarea
          type="text"
          value={caption}
          onChange={(e) => {setCaption(e.target.value)}}
          placeholder="Caption"
          // h="80px"
        />
      <Button
        mt='10'
        color='#ffffff'
        bg='#219691'
        colorScheme={'none'}
        onClick={()=>{handleSelectCaption()}}
      >
        {addCaption}
      </Button>
      </Grid>
    </Flex>
  )
}

export default ChooseText