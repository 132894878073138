import React, { useState } from 'react';
import {
  Flex,
  Grid,
  Text,
  Divider,
  Portal,
  ChakraProvider,
  useDisclosure,
} from "@chakra-ui/react";
import '@inovua/reactdatagrid-community/index.css';
import "./styles.css";
import { useEffect } from 'react';
import { CalendarIcon } from '@chakra-ui/icons';
import moment from 'moment';
import Details from './Details';
import Sidebar from 'components/Sidebar';
import MainPanel from 'components/Layout/MainPanel';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import theme from 'theme/theme';
import routes from "routes.js";
import PanelContent from 'components/Layout/PanelContent';
import PanelContainer from 'components/Layout/PanelContainer';
import { useLocation } from 'react-router-dom';
import { getReturnOrderItem } from 'components/API/AuthAPI';
import { useSelector } from 'react-redux';

const OrderDetails = (props) => {
  const [fetchedData, setFetchedData] = useState([]);
  const [token, setToken] = useState();
  const [sidebarVariant] = useState("transparent");
  const [fixed] = useState(false);
  const [nav] = useState();
  const { onOpen } = useDisclosure();
  const { ...rest } = props;
  let location = useLocation();
  const [id] = useState(location ? location.state : "");
  const side = useSelector((state) => state.cart);


  useEffect(() => {
    let userToken = localStorage.getItem("token");
    if (userToken) {
      setToken(userToken);
      if (token) {
        getOrderDetails();
      }
    }

  }, [token])

 /**
  * This function retrieves return order item details using a token, ID, and email address.
  */
  const getOrderDetails = () => {
    getReturnOrderItem(token, id, 'test@yopmail.com').then((res) => {
      if (res) {
        setFetchedData(res);
      }
    })
  }
  // console.log(side.sidebar)

  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Sidebar
         routes={routes}
         display="none"
         sidebarVariant={sidebarVariant}
         {...rest}
      />
      <MainPanel
        w={{
          base: "100%",
          lg: nav == side.sidebar ? "calc(100% - 85px)" : "calc(100% - 245px)",
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            logoText={"My Boutiq"}
            fixed={fixed}
            {...rest}
            nav={nav}
          />
        </Portal>
        <PanelContent>
          <PanelContainer>
            <Flex
              pt="90px"
              pl="10px"
              flexDirection={"column"}
            >
              <Flex>
                <Text
                  color="#219691"
                  fontWeight={"bold"}
                  fontSize={"24px"}
                  py="20px"
                >
                  ORDER DETAILS
                </Text>
              </Flex>
              <Flex
                flexDirection="column"
                bg="#ffffff"
                p="20px"
                borderRadius={"20px"}
              >
                <Flex
                  flexDirection={"column"}
                  pl="10px"
                  pb="25px"
                >
                  <Grid display="flex">
                    <CalendarIcon />
                    {fetchedData ?

                      <Text ml='2'>{moment(fetchedData.order_date).format('DD-MMM-YYYY hh:mm A')}</Text>
                      :
                      ""
                    }
                  </Grid>
                  <Grid>
                    {fetchedData ?
                      <Text
                        color="gray.500"
                      >
                        Order ID: {fetchedData.merchant_order_id}
                      </Text>
                      :
                      ""
                    }
                  </Grid>
                </Flex>
                <Divider />
                <Flex
                >
                  <Details

                    captions={["Product", "Brand Name", "Quantity", "Original Price", "Discount Value", "Selling Price", "Shipping Fee", "SubTotal", "There.of.VAT", "Total Incl. VAT"]}
                    data={fetchedData}
                  />
                </Flex>
              </Flex>
            </Flex>
          </PanelContainer>
        </PanelContent>

      </MainPanel>
    </ChakraProvider>

  )
}

export default OrderDetails