import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Button, Grid, Modal,ModalOverlay,ModalContent,ModalHeader,ModalBody,SimpleGrid,
  ModalCloseButton,useDisclosure } from '@chakra-ui/react';
import { BiShareAlt } from "react-icons/bi";
import QRCode from "qrcode.react";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { FacebookShareButton,LinkedinShareButton,TwitterShareButton,TelegramShareButton,WhatsappShareButton,PinterestShareButton,
  RedditShareButton,FacebookIcon,TwitterIcon,TelegramIcon,WhatsappIcon,LinkedinIcon,PinterestIcon,RedditIcon,} from "react-share";
import '@inovua/reactdatagrid-community/index.css'
import "./styles.css"
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { getAffiliateProductDetails, sendLink } from 'components/API/AuthAPI';
import { shareproducts, view, download } from 'assets/constants/strings';


const ProductTable = ({ sellerId }) => {
  const [productList, setProductList] = useState("");
  const [token, setToken] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [url, setUrl] = useState();
  const [prdName, setprdName] = useState();
  const [shareLink, setShareLink] = useState();
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const storeBaseUrl = 'https://myboutique.progfeel.co.in';

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    if (userToken) {
      setToken(userToken);
    }
  }, [token])

  useEffect(() => {
      if(token && sellerId){
       getProductDetails();
      }
  }, [sellerId]);

  /**
   * The function retrieves product details from an API, parses the data, and sets the product list
   * state.
   */
  const getProductDetails = () => {
    getAffiliateProductDetails(token, sellerId[0].seller_store)
      .then((responseJson) => {
        if (responseJson) {
          const parsedData = responseJson.data.map(({
            PRDDiscountPrice="",
            PRDISSale="",
            PRDPrice="",
            PRDSKU="",
            PRDWeight="",
            additional_image_1="",
            additional_image_2="",
            additional_image_3="",
            additional_image_4="",
            additional_image_5="",
            available="",
            feedbak_average="",
            feedbak_number="",
            get_absolute_url="",
            get_store_name= [store_name=""],
            height="",
            id="",
            pieces="",
            product="",
            product_brand_name="",
            product_description="",
            product_id="",
            product_image="",
            product_name="",
            product_vendor="",
            product_video="",
            width="",

          }) => {
            return {            
              PRDDiscountPrice:PRDDiscountPrice?.toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              PRDISSale:PRDISSale?.toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              PRDPrice:PRDPrice?.toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              PRDSKU:PRDSKU,
              PRDWeight:PRDWeight,
              additional_image_1:additional_image_1,
              additional_image_2:additional_image_2,
              additional_image_3:additional_image_3,
              additional_image_4:additional_image_4,
              additional_image_5:additional_image_5,
              available: available?.toLocaleString(),
              feedbak_average:feedbak_average,
              feedbak_number:feedbak_number,
              get_absolute_url:get_absolute_url,
              get_store_name: get_store_name,
              height:height,
              id:id,
              pieces:pieces?.toLocaleString(),
              product:product,
              product_brand_name:product_brand_name,
              product_description:product_description,
              product_id:product_id,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
              product_image:product_image,
              product_name:product_name,
              product_vendor:product_vendor,
              product_video:product_video,
              width:width,
            }
          })
          setProductList(parseData(parsedData));
        }
      })
  }

 /**
  * The function downloads a QR code as a PNG image.
  */
  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${url}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };


  const Share = (slug) => {
    sendLink(token).then((res) => {
      if (res) {
        setShareLink(storeBaseUrl + slug + "/#" + res.your_link + "/");
      }
    })
  }

  const makeUserObj = (userObj, objectName) => {
    const keys = Object.keys(userObj)
    let resultObj = {}
    for (let i in keys) {
      const value = keys[i]
      resultObj[`${objectName}.${value}`] = userObj[value]
    }
    return resultObj
  }

  /**
   * The function `parseData` takes an array of objects as input, and returns a new array of objects
   * with a modified property if the original object had a specific property.
   */
  const parseData = (inputData) => {
    if (inputData.length === 0) {
      return []
    }

    let result = inputData.map((instance) => {
      if (instance.hasOwnProperty('get_store_name')) {
        const { get_store_name, ...rest } = instance
        var get_store_name1;
        var iterator = get_store_name.values();
        for (let elements of iterator) {
          get_store_name1 = elements;
        }
        return {
          ...rest,
          ...makeUserObj(get_store_name1, "get_store_name")
        }
      }
      return instance
    })
    return result
  }


  const customCellStyle = cellProps => {
    const { value, rowActive } = cellProps;
    return {
      color: rowActive
        ? '#e9ecf0'
        : value % 2 ? '#ff595e' : 'inherit',
    }
  };

  const columns = [
    {
      name: 'get_store_name.store_name', header: 'BoutiqStore', minWidth: 200, defaultFlex: 1, headerAlign: "center",
      headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'product_id', header: 'Product ID', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'product_name', header: 'Product Name', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'available', header: 'On Stock', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'pieces', header: 'Sold', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'PRDPrice', header: 'Selling Price', minWidth: 200, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'status', header: 'View Item', defaultFlex: 1, minWidth: 200, headerAlign: "center", headerProps: {
        className: "tableHeader"
      },
      style: customCellStyle,
      render: ({ value, data }) => {
        return <div style={{ display: 'inline-block', textAlign: "center", justifyContent: "center" }}>
          <Button
            colorScheme='none'
            borderColor='#ffffff'
            color='#ffffff'
            bg="#219691"
            variant='outline'
            fontSize='xs'
            p='8px 32px'
            onClick={() => {window.open(`${window.location.origin}${data.get_absolute_url}`, '_blank', 'noopener,noreferrer') }}
          >{view}</Button>
        </div>
      }
    },
    {
      name: 'status1', header: 'Share', defaultFlex: 1, minWidth: 200, headerAlign: "center",
      headerProps: {
        className: "tableHeader"
      },
      style: customCellStyle,
      render: ({ data }) => {
        return <div style={{ display: 'inline-block', textAlign: "center", justifyContent: "center" }}>
          <Button
            colorScheme='none'
            borderColor='#ffffff'
            color='#ffffff'
            bg="#219691"
            variant='outline'
            fontSize='xs'
            p='8px 32px'
            onClick={() => {
              onOpen()
              Share(data.get_absolute_url)
              setUrl(data.boutique_store_url)
              setprdName(data.product_name)
            }}
          >
            <BiShareAlt color="#ffffff" size="20px" />
          </Button>
        </div>
      }
    },
  ];
  const gridStyle = { minHeight: 550, maxWidth: 1700, textAlign: "center", fontSize: "14px" };
  const rowStyle = { fontSize: "15.5px", fontStyle: "normal" }
  return (
    <Box
      borderRadius='15px'
      py='30px'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      align='center'>
      <Card p='10px' mt='5px'
        maxHeight="600px"
        maxWidth="1700px"
      >
        <CardHeader>
          <ReactDataGrid
            idProperty="id"
            columns={columns}
            dataSource={productList}
            style={gridStyle}
            rowStyle={rowStyle}
            rowHeight={50}
            headerHeight={55}
            showCellBorders={false}
            pagination
            showZebraRows={false}
            defaultLimit={10}
          />
        </CardHeader>
      </Card>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"sm"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{shareproducts}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SimpleGrid columns={{ sm: 4, md: 4, xl: 4 }} spacing='24px'>

              <FacebookShareButton
                url={shareLink}
                quote={`${prdName}. Check out this item from My Boutiq! My Boutiq offers local and global treasures. Explore them all now!`}
                hashtag={'#myboutiq'}
              >
                <FacebookIcon size={40} round />
              </FacebookShareButton>
              <TelegramShareButton
                url={shareLink}
                title={`${prdName}. Check out this item from My Boutiq! My Boutiq offers local and global treasures. Explore them all now!`}
              >
                <TelegramIcon size={40} round />
              </TelegramShareButton>
              <PinterestShareButton
               description={`${prdName}. Check out this item from My Boutiq! ${shareLink}, My Boutiq offers local and global treasures. Explore them all now!`}
              >
                <PinterestIcon size={40} round />
              </PinterestShareButton>

              <RedditShareButton
                url={shareLink}
                title={`${prdName}. Check out this item from My Boutiq! My Boutiq offers local and global treasures. Explore them all now!`}
              >
                <RedditIcon size={40} round />
              </RedditShareButton>

              <WhatsappShareButton
                url={shareLink}
                title={`${prdName}. Check out this item from My Boutiq! My Boutiq offers local and global treasures. Explore them all now!`}
                separator=":: "
              >
                <WhatsappIcon size={40} round />
              </WhatsappShareButton>
              <TwitterShareButton
                url={shareLink}
                title={`${prdName}. Check out this item from My Boutiq! My Boutiq offers local and global treasures. Explore them all now!`}
              >
                <TwitterIcon size={40} round />
              </TwitterShareButton>
              <LinkedinShareButton 
                title={prdName}
                url={shareLink}
                summary={`${prdName}. Check out this item from My Boutiq! My Boutiq offers local and global treasures. Explore them all now!`}  
                source={shareLink}
              >
                <LinkedinIcon size={40} round />
              </LinkedinShareButton>
            </SimpleGrid>
            <Grid
              columns={{ sm: 1, md: 1, xl: 1 }}
              spacing='24px'
              pt="20px"
              justifyContent={"center"}
            >
              <QRCode
                id="qr-gen"
                value={shareLink}
                size={290}
                level={"Q"}
                includeMargin={true}

              />
              <Button
                bg="#219691"
                color="white"
                colorScheme="none"
                mt="20px"
                onClick={downloadQRCode}
              >
                {download}
              </Button>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default ProductTable