import { Flex, Select, Text } from '@chakra-ui/react'
import { account, selectSellerAccount } from 'assets/constants/strings';
import { getSellerStockDetails } from 'components/API/AuthAPI';
import { Translate } from 'components/DeepL/Translate';
import Emitter from 'Emitter';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { selectStore } from "Slices/launchpadslice";
import "./style.css"

const SelectAccount = (props) => {
  const [seller, setSeller] = useState();
  const [token, setToken] = useState();
  const [sellerAcc, setSellerAcc] = useState([]);
  const dispatch = useDispatch();

  //choose seller account
  const handleSelectAcc = (product) => {
    dispatch(selectStore(product));
  };

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    if (userToken) {
      setToken(userToken);
    }
    if (token) {
      getSellerList();
    }
  }, [token]);

  //load seller accounts list
  const getSellerList = () => {
    getSellerStockDetails(token)
      .then((responseJson) => {
        setSellerAcc(responseJson.data);
      })
  }

  useEffect(() => {
    sellerAcc.map((item) => {
      if (seller == item.store_name) {
        handleSelectAcc(item.store_name);
        Emitter.emit('SELLER_ID_FROM_LAUNCHPADPRODUCT', item.seller_store);
        Emitter.emit("ACCOUNT_NAME", item.store_name);
        sessionStorage.setItem("sellerAccout", item.seller_store);
      }
    })
  }, [seller]);

  return (
    <Flex
      flexDirection="column"
      pt="20px"
    >
      <Text
        textAlign="center"
        fontSize="md"
        fontWeight="bold"
      >
        {/* {Translate ? "Account" : Translate("Account")} */}
        {account}
      </Text>

      <Flex
        flexDir="column"
        display="flex"
        justify="center"
        pt="30px"
      >
        {sellerAcc ?
          <Select
            mt="20px"
            className='sellerList'
            onChange={(e) => { 
              setSeller(e.target.value) 
              props.onClick()
            }}
          >
            {/* <option>{Translate ? "Select Seller Account" : Translate("Select Seller Account")}</option> */}
            <option>{selectSellerAccount}</option>
            {sellerAcc.map((item) => {
              return <option key={item.seller_store} value={item.store_name}>{item.store_name}</option>
            })}
          </Select>
          :
          ""
        }
      </Flex>
    </Flex>
  )
}

export default SelectAccount