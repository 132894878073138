import { Search2Icon } from '@chakra-ui/icons';
import { Button, Flex, Grid, Icon, Image, Input, SimpleGrid, Text, InputGroup, InputRightElement, List } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import _ from "lodash";
import { users, images } from "./data";
import Emitter from 'Emitter';
import { Translate } from 'components/DeepL/Translate';
import { useDispatch } from 'react-redux';
import { selectElementText, selectElementImage } from 'Slices/launchpadslice';
import { elements, addText } from 'assets/constants/strings';

const ChooseElement = (props) => {
  const [searchValue, setSearchValue] = useState();
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [textBox, setTextBox] = useState(false);
  const dispatch = useDispatch();

  const handleSearchFilter = (e) => {
    setSearchValue(e.target.value);
  };

  //select Element 
  const handleChange = (src) => {
    dispatch(selectElementImage(src));
    Emitter.emit("SELECT_FROM_ELEMENT", src);
    props.onClick();
  }

  //add text box into launchpad canvas
  const handleCheck = () => {
    if (!textBox) {
      setTextBox(true);
      dispatch(selectElementText(true));
      props.onClick();
    }
    else {
      setTextBox(false)
    }
    Emitter.emit("SELECT_FROM_ELEMENT_TEXT", textBox);
    setTimeout(() => {
      dispatch(selectElementText(false));
      setTextBox(false);
    }, 1000)

  }

  
  useEffect(() => {
   /* This code is setting a timeout function that will execute after 500 milliseconds. The function
   will filter the `images` array based on whether the `searchValue` is included in any of the
   values of the `images` object. The filtered array is then set as the state of `filteredUsers`.
   The `clearTimeout` function is returned to clear the timeout if the component unmounts before the
   timeout function is executed. */
    const timeout = setTimeout(() => {
      const filter = _.filter(images, (images) => {
        return _.includes(
          _.lowerCase(JSON.stringify(_.values(images))),
          _.lowerCase(searchValue)
        );
      });
      setFilteredUsers(filter);
    }, 500);
    return () => clearTimeout(timeout);
  }, [searchValue]);

  return (
    <Flex flexDirection="column">
      <Grid
        display="flex"
        justifyContent="center"
        flexDir="column"
      >
        <Text
          fontWeight="bold"
          textAlign="center"
          pt="20px"
        >
          {/* {Translate ? "Elements" : Translate("Elements") } */}
          {elements}
        </Text>
        <Button
          bg="#219691"
          color="#ffffff"
          w="100%"
          mt="20px"
          colorScheme="none"
          onClick={handleCheck}
        >
          {/* {Translate ? "Add a text box" : Translate("Add a text box")} */}
          {addText}
        </Button>


        {/* </Box> */}
      </Grid>
      <Grid
        display="flex"
        flexDirection="row"
        pt="20px"
      >
        <InputGroup>
          <InputRightElement
            pointerEvents='none'
            children={<Search2Icon color='gray.300' />}
          />
          <Input type="search" value={searchValue}
            onChange={handleSearchFilter} />
        </InputGroup>
      </Grid>

      <SimpleGrid
        border={"2px solid black"}
        px="10px"
        py="20px"
        mt="20px"
        columns={{ sm: 3, md: 3, xl: 3 }}
        spacing='20px'


      >
        {_.map(filteredUsers, (user, index) => (
          <Flex
            flexDirection="column"
            key={index}
          >
            <List>
              <a>
                <Image
                  w={{ sm: "100px", lg: "125px" }}
                  h={{ sm: "150px", lg: "125px" }}
                  // pl="1"
                  borderRadius="20px"
                  // colorScheme={"teal"}
                  src={user.src}
                  alt="element"
                  onClick={() => { handleChange(user.src) }}
                />
              </a>
            </List>

          </Flex>
        ))}
      </SimpleGrid>

    </Flex>
  )
}

export default ChooseElement