import React, { useState, useRef } from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    useToast,
    ChakraProvider,
    Portal, 
    useDisclosure,
    Alert,
    AlertIcon,
    Center,
    Container,
    Image,
    Avatar,
    Text,
    SimpleGrid
} from "@chakra-ui/react";
import { FaInstagramSquare, FaTwitterSquare } from "react-icons/fa";
// Assets
import { Link, NavLink, Redirect, useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import storecover from "../../assets/img/storecover.jpg"
import profile from "../../assets/img/profile.png"
import { BsFacebook, BsInstagram, BsPinterest } from "react-icons/bs";
import { sellerinvitation } from "components/API/AuthAPI";
// Custom Chakra theme
import theme from "theme/theme.js";
import FixedPlugin from "../../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../../components/Layout/MainPanel";
import PanelContainer from "../../components/Layout/PanelContainer";
import PanelContent from "../../components/Layout/PanelContent";

function InviteSeller() {
    let history = useHistory();
    let location = useLocation();

    // console.log("path",location.pathname);

    let token = location.pathname;

    let fields = token.split('/');
    
    // console.log("new string",fields)

    let newToken = fields[2];

    // console.log("newToken",newToken)



    const handleAccept=()=>{
        history.push({
            pathname: "/signup",
            state: {newToken}
          })
    }


    return (
        <>
            <Center py="30px">
                <Heading
                    color="#219691"
                >
                    MY BOUTIQ
                </Heading>
            </Center>
            <Flex w="100%
            " h="100%"
            >
                <Container maxW='container.sm'>
                    <Image
                        src={storecover}
                        h={{ sm:"180px", md:"180px", lg:"250px" }}
                        w="70vh"
                    />
                    <Flex
                        pt="20px"
                        justify={"center"}
                    >
                        <Avatar
                            src={profile}
                            justifyContent="center"
                            size='2xl'
                        />
                    </Flex>
                    <Flex pt="20px" pl="10px">
                        <Text fontSize={"xl"}>
                            Michael has invited you to join his team on MY BOUTIQ as a business partner
                        </Text>

                    </Flex>
                    <Flex pt="15px" pl="10px">
                        <Text fontSize={"xl"}>
                            MY BOUTIQ is the world's first seller network platform, where you can onboard any compony
                        </Text>
                    </Flex>
                    <Flex
                        pt="40px"
                        justify={"center"}
                    >
                        {/* <Redirect to = "https://adminmyboutiqe.progfeel.co.in/seller-register/"> */}
                        <Button
                            bg="#219691"
                            color="#ffffff"
                            colorScheme="none"
                            w="md"
                            h="50px"
                            fontSize={"lg"}
                            onClick={handleAccept}
                        >
                            JOIN NOW AND START TODAY
                        </Button>
                        {/* </Redirect> */}
                    </Flex>
                    <Center display='flex' mt='10'>
                        <BsFacebook size='8%' />
                        <FaInstagramSquare size='8%' style={{ marginLeft: "10px" }} />
                        <BsPinterest size='8%' style={{ marginLeft: "10px" }} />
                        <FaTwitterSquare size='8%' style={{ marginLeft: "10px" }} />
                    </Center>

                    <Center display='flex' pt='40px'>
                        <Flex>
                            <Text>
                                This email was sent by Michael Peters.
                            </Text>
                        </Flex>
                    </Center>
                    <Center display='flex'>
                        <Flex>
                            <Text>
                                Imprint | Data Privacy
                            </Text>
                        </Flex>
                    </Center>
                    <Center display='flex'>
                        <Flex>
                            <Text as='u'>
                                Unsubscribe
                            </Text>
                        </Flex>
                    </Center>
                </Container>

            </Flex>
        
        </>
    );
}

export default InviteSeller
