import React, { useEffect, useRef, useState } from 'react'
import {
    ChakraProvider, Text, Flex, Image, Box, Button, Tabs, TabList, TabPanels, Tab, TabPanel, Table, TableCaption, Thead, Tr, Th, Tbody, Td, TableContainer, Tfoot, Grid, useDisclosure
    , Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody, Input, FormControl, InputGroup, InputRightElement, useToast,
} from '@chakra-ui/react'
import { BiShow, BiHide } from "react-icons/bi"
import Card from 'components/Card/Card'
import { HiOutlineArrowNarrowUp, HiOutlineArrowNarrowDown, } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { TbTruckDelivery } from 'react-icons/tb'
import { MdOutlineAssignmentReturn, MdOutlinePhoneCallback } from 'react-icons/md'
import { AiFillHeart, AiFillShop, AiOutlineHeart } from "react-icons/ai";
import { BsSearch, BsBag } from 'react-icons/bs'
import './gallerypage.css'
import { AddToCart, } from 'components/header/AddToCart'
import {
    getsellerProduct, getAddUpdateCart, currencyList, login, resetAccountpassword, register, getListedCart,
    deleteCartProduct, updateWishlist, getWishlist, getAllStoreproducts, getCategoryProduct, galleryBanner
} from 'components/API/AuthAPI'
import { MainHeader } from 'components/header/MainHeader'
import { CloseIcon } from '@chakra-ui/icons'
import { useDispatch } from 'react-redux'
import { addToCart as addProductToCart } from 'Slices/cartslice'
import cutLogo from "../../assets/svg/cut-logo.svg";
import logo from "../../assets/svg/logo1.png";
import theme from 'theme/theme'
import SingleProductLoader from 'components/PlaceholderLoading/SingleProductLoader'
import GalleryBanner from './GalleryBanner'
import GalleryFooter from './GalleryFooter'

function GalleryWishList(props) {
    const [currencyIcon, setCurrencyIcon] = useState();
    const [wishlist, setWishlist] = useState([])
    const [cart, setCart] = useState(false)
    const [token, setToken] = useState()
    const [setGetCurrency] = useState([])
    const [getsellerId, setGetsellerId] = useState()
    const [getName, setGetName] = useState()
    const [getuserProduct, setGetuserProduct] = useState([])
    const [accounttoken, setAccounttoken] = useState()
    const [uniqueorderId, setUniqueOrderId] = useState()
    const [userId] = useState()
    const [userId1] = useState()
    const [allProduct, setAllProduct] = useState([])
    const [store_id, setStore_id] = useState([])
    const [getCart, setGetCart] = useState([])
    const [deleteProduct, setDeleteProduct] = useState(0)
    const [addedProducts, setAddedProducts] = useState([]);
    const [loader, setLoader] = useState();
    const [isAdded, setIsAdded] = useState(1)
    const [getuser_name, setGetusername] = useState('')
    const [getpassword, setGetpassword] = useState("")
    const [user_Type] = useState('buyer')
    const [resetacc, setResetacc] = useState('')
    const [showold, setShowold] = useState(false);
    const [showAcc, setShowAcc] = useState(false)
    const [dropdown, setDropdown] = useState(false)
    const [forgetpass, setForgetpass] = useState(false)
    const [signup, setSignup] = useState(false)
    const [bannerText, setBannerText] = useState();
    const [registration, setRegistration] = useState({
        username: "",
        email: "",
        password: "",
        phone_number: "9422675973",
        user_type: "buyer"
    })
    const { onClose } = useDisclosure()
    const toast = useToast();
    const dispatch = useDispatch();

    const cat = ['WOMEN', 'MEN', 'KIDS'];
    useEffect(() => {
        let symbol = localStorage.getItem("currency");
        if (symbol) {
            setCurrencyIcon(symbol);
        }
    }, [currencyIcon]);

    const loginToCheckout = () => {
        setDropdown(true)
        setSignup(false)
    }

    const openDropdown = () => {
        setDropdown(true)
        setSignup(false)
    }

    const closeDropdown = () => {
        setDropdown(false)
    }


    const openSignup = () => {
        setSignup(true)
        setDropdown(false)
    }

    const closesignup = () => {
        setRequestSignin(false)
        setSignup(false)
    }

    const openForgetpass = () => {
        setForgetpass(true)
        setDropdown(false)
    }

    const closeForgetpass = () => {
        setForgetpass(false)
    }

    const { username, email, password, phone_number, user_type } = registration
    const RegAccountLogin = (e) => {
        setRegistration({ ...registration, [e.target.name]: e.target.value })
    }

    /**
     * The function `getRegistrationdetails` handles the registration process by calling the `register`
     * function and displaying a success or error message using the `toast` function.
     */
    const getRegistrationdetails = (e) => {
        e.preventDefault()
        register(username, password, email, phone_number, user_type)
            .then((responseJson) => {
                if (responseJson) {
                    if (responseJson["message"]) {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    Registration successful.
                                </Box>
                            ),
                        })
                    } else {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    You have already Registered.
                                </Box>
                            ),
                        })
                    }
                }
            })
            .catch((err) => (console.log("error", err)));
    }

    const RegAccountpass = (e) => {
        setResetacc(e.target.value)
    }

    const getResetPassword = (e) => {
        e.preventDefault()
        resetAccountpassword(resetacc).then(responseJson => {
            if (responseJson) {
                toast({
                    position: 'top',
                    render: () => (
                        <Box
                            color='white'
                            p={3} bg='#219691'
                            textAlign={'center'}
                            fontWeight='bold'
                        >
                            {responseJson['message']}
                        </Box>
                    ),
                })
            }
        }).catch(err => console.log(err))
    }

    const getRegisterDetails = (e) => {
        e.preventDefault()
        login(getuser_name, getpassword, user_Type)
            .then((responseJson) => {
                if (responseJson) {
                    if (responseJson['token']) {
                        if (responseJson["user_type"] == "buyer") {
                            localStorage.setItem("loginToken", responseJson['token'])
                            localStorage.setItem("loginUser_id", responseJson['user_id'])
                            setAccountToken(responseJson['token']);
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        {responseJson["message"]}
                                    </Box>
                                ),
                            })
                            setTimeout(() => {
                                getUserInfo(responseJson['token'])
                            }, 1000);

                            setDropdown(false)
                        } else {
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        Please enter valid credentials to login
                                    </Box>
                                ),
                            })
                        }

                    } else if (responseJson["invalid_login"]) {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {responseJson["invalid_login"]}
                                </Box>
                            ),
                        })
                    }
                }
            })
            .catch((err) => {
                // alert(err)
            })

    }


    const showOldPass = () => setShowold(!showold)
    const showAccPass = () => setShowAcc(!showAcc)




    const onClose1 = () => {
        setCart(false)
    }

    const onOpencart = () => {
        setCart(true)
    }

    const handleCallback = (childData) => {
        localStorage.setItem("changeUser", childData)
    }

    useEffect(() => {
        const checkUserLogin = async () => {
            let userToken;
            let Curency;
            let sellerId;
            let getStore;
            try {
                userToken = await localStorage.getItem("loginToken");
                setToken(userToken);
                sellerId = await JSON.parse(localStorage.getItem("storeId"))
                setGetsellerId(sellerId)
                Curency = await localStorage.getItem("currency")
                Banner()
                setGetCurrency(Curency)
                getprofilelist(userToken, sellerId)
                getThemeSettimg(userToken)
                getDeatils(userToken, sellerId)
                getStore = await localStorage.getItem("storename");
                setGetName(getStore);
                handleCallback1(getStore)
                handleCallback()
            } catch (e) { }
        }
        checkUserLogin();
    }, [setToken, getName, userId, getuserProduct])


    /**
     * The function retrieves seller product details and stores them in local storage.
     */
    const getDeatils = (token, getsellerId) => {
        getsellerProduct(token, getsellerId).then(responseJson => {
            if (responseJson) {
                setGetuserProduct(responseJson.data || [])
                localStorage.setItem("getorderList", JSON.stringify(responseJson.data))
                // let cart = []
                // for (let reps of responseJson.data) {
                //     cart.push(reps.id)
                // }
                // if (!localStorage.getItem("cart")) {
                //     localStorage.setItem("cart", "[]");
                // }
            }
        }).catch(err => console.log(err))
    }

    /**
     * This function sets the banner text by retrieving data from a gallery banner.
     */
    const Banner = () => {
        galleryBanner().then((res) => {
            if (res) {
                setBannerText(res.data[0].pop_up_text);
            }
        })
    }



    useEffect(() => {
        const checkUserLogin = async () => {
            let accToken;
            let getuniuqId;
            try {
                accToken = localStorage.getItem("loginToken")
                setAccounttoken(accToken)
                getAddedProductCart()
                getuniuqId = localStorage.getItem("productcatId")
                setUniqueOrderId(getuniuqId)
                let newcart = JSON.parse(localStorage.getItem("neworder"))
                setUser_cart(newcart)
            } catch (e) { }
        }
        checkUserLogin();
    }, [accounttoken, getsellerId, uniqueorderId, userId])


    const getAddedProductCart = () => {
        getListedCart(accounttoken).then(responseJson => {
            if (responseJson) {
                setGetCart(responseJson.data || [])
            }
        }).catch(err => console.log(err))
    }

    const deleteproductcart = (deleteId) => {
        setDeleteProduct(0)
        getAddUpdateCart(accounttoken, deleteId, deleteProduct)
        deleteCartProduct(deleteId, accounttoken, deleteProduct).then(responseJson => {
            if (responseJson) {
                //console.log("accounttoken", responseJson)
                getAddUpdateCart(accounttoken, deleteId, deleteProduct)
                getAddedProductCart()
            }
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        getWish();
        getAllCartDetails();
    }, [wishlist, accounttoken, isAdded]);

    const getWish = () => {
        setLoader(true)
        if (accounttoken) {
            getWishlist(accounttoken).then((res) => {
                if (res) {
                    setWishlist(res.wishlist);
                    setLoader(false)
                }
                setLoader(false)
            })
        }
    }

    const getAllCartDetails = () => {
        getAllStoreproducts().then(res => {
            setAllProduct(res?.data)
            localStorage.setItem("getallproducts", JSON.stringify(res.data))
            let getAllId = []
            for (let responseJson of res.data) {
                getAllId.push(responseJson.id)
            }
            setStore_id(getAllId)

        })
    }

    const checkProducts = () => {
        setLoader(true)
        let prd = [];
        if (wishlist?.length > 0) {
            wishlist.map((item) => {
                allProduct.map((ele) => {
                    if (item.favorite == ele.id) {
                        prd = [...prd, ele]
                    }
                })
                setAddedProducts(prd)
                setLoader(false)
            })
        }
        // setTimeout(() => {
        //     setLoader(false);
        // }, 10000)
    };

    useEffect(() => {
        checkProducts();
    }, [wishlist, setAddedProducts, isAdded])


    /**
     * The function adds a product to the user's wishlist and displays a success message using a toast.
     */
    const addtoWishlist = (token, prodId) => {
        updateWishlist(token, prodId).then((res) => {
            if (res) {
                setIsAdded(isAdded + 1)
                getWish();
                toast({
                    position: 'top',
                    render: () => (
                        <Box
                            color='white'
                            p={3} bg='#219691'
                            textAlign={'center'}
                            fontWeight='bold'
                        >
                            Added to wishlist.
                        </Box>
                    ),
                })
            }
        })
    }

    /**
     * The function adds a product to the cart by combining the product details with its variant and
     * dispatching it to the cart.
     */
    const addToCart = (sellerId, productId) => {
        getCategoryProduct(sellerId, productId).then(responseJson => {
            if (responseJson) {
                let combinedObject = { ...responseJson.data[0], "varient": responseJson.data[0].product_varient[0].id };
                dispatch(addProductToCart(combinedObject));
                onOpencart()
            }
        }).catch(err => console.log(err))
    }



    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <MainHeader userId1={userId1}
                onClose={onClose} onOpencart={onOpencart} handleCallback={handleCallback}
            />
            <GalleryBanner
                bannerText={bannerText}
                disp={true}
            />
            <Text textAlign='center'
                fontWeight='bold' fontSize='xl'
                mt='1' mb='1' className='Wish_List_Text'>
                Wish List
            </Text>
            <p style={{ border: "1px solid black", width: "100%" }}></p>

            <Flex w='100%'>
                <Tabs w='100%' isFitted>
                    <TabList>
                        {cat.map((item) => {
                            return (
                                <Tab _selected={{ color: '#219691', }}> {item}</Tab>
                            )
                        })}
                    </TabList>

                    <TabPanels>
                        {cat.map((item) => {
                            return (
                                <TabPanel
                                    p='0'
                                    w='100%'
                                >
                                    <p style={{ border: "1px solid black", width: "100%" }}></p>
                                    <Flex justifyContent='space-between'>
                                        {
                                        wishlist === true ?
                                            <Flex mt='100' flexDirection='column' justifyContent='center' alignItems='center'>
                                                <Image
                                                    width='20%'
                                                    src='https://icons.veryicon.com/png/o/commerce-shopping/fine-edition-mall-icon/wishlist-1.png'
                                                />
                                                <Text mt='5' fontWeight='bold' fontSize='2xl'>
                                                    Your wish list is empty
                                                </Text>
                                                <Card w='300px' mt='5'>
                                                    <Text fontSize='xl' textAlign='center'>
                                                        Use the heart icon to add a product to Your Wish List
                                                    </Text>
                                                </Card>
                                                <Text mt='10' p='3' fontSize='2xl' textDecoration='underline'>
                                                    View Shop
                                                </Text>
                                            </Flex>
                                            :
                                            <Flex flexDir='column' w='100vw'>
                                                {loader ?
                                                    <SingleProductLoader/>
                                                    :
                                                    ""
                                                }
                                                {addedProducts?.map((item) => {
                                                    return (
                                                        <>
                                                        <Flex
                                                            display={{ sm: 'flex', md: 'none', lg: 'none' }}
                                                            pl={2}
                                                            pr={2}
                                                        >
                                                            <Flex
                                                                mt='3'
                                                                mb='3'
                                                                pos={'relative'}
                                                            >
                                                                <Image
                                                                    w='150px'
                                                                    h='150px'
                                                                    // className='product_image_wishlist'
                                                                    src={'https://adminmyboutiqe.progfeel.co.in' + item.product_image}
                                                                    alt='Dan Abramov'
                                                                />
                                                                <Grid className='hearticon'>
                                                                    <AiFillHeart
                                                                        display={'flex'}
                                                                        justifyContent='right'
                                                                        color='black'
                                                                        colorScheme='blue'
                                                                        size={'25px'}
                                                                        cursor='pointer'
                                                                        onClick={() => { addtoWishlist(accounttoken, item.id) }}
                                                                    />
                                                                </Grid>
                                                                <Flex flexDir={'column'} ml='10px'>
                                                                    <Text fontWeight='bold' fontSize='xl'>
                                                                        {item.product_name}
                                                                    </Text>
                                                                    <Text>
                                                                        {item.product_brand_name}
                                                                    </Text>
                                                                    <Flex flexDir={'row'}>
                                                                        <Text color='#219691'>
                                                                            {currencyIcon ? currencyIcon : '€'} {parseFloat(Number(item.PRDDiscountPrice)).toFixed(2)}
                                                                        </Text>
                                                                        <Text textDecoration='line-through' ml='10px'>
                                                                            MRP  {currencyIcon ? currencyIcon : '€'} {parseFloat(Number(item.PRDPrice)).toFixed(2)}
                                                                        </Text>
                                                                    </Flex>
                                                                    <Button
                                                                        w={'50vw'}
                                                                        mt='5'
                                                                        bg='#219691'
                                                                        color='white'
                                                                        colorScheme='none'
                                                                        onClick={() => { addToCart(item.product_vendor, item.id) }}
                                                                    >
                                                                        Add to Cart
                                                                    </Button>
                                                                </Flex>
                                                            </Flex>
                                                                {/* <span className='product_wrapper_line_mobile'></span> */}
                                                        </Flex>
                                                        <span className='product_wrapper_line_mobile'></span>
                                                        </>
                                                    )
                                                })}
                                                <Flex
                                                    flexDirection='row'
                                                    className='gallery-wishlist-wrapper'
                                                    // display={{ sm: 'none', md: 'none', lg: 'flex' }}
                                                    display={{ sm: 'none', md: 'flex', lg: 'flex' }}
                                                    alignItems='center'
                                                >
                                                    <Flex mt='10'>

                                                        <TableContainer w='98vw'>
                                                            <Table>

                                                                <Thead>
                                                                    <Tr>
                                                                        <Th>Product</Th>
                                                                        <Th>MRP</Th>
                                                                        <Th isNumeric>Discount Price</Th>
                                                                        <Th></Th>
                                                                    </Tr>
                                                                </Thead>
                                                                {addedProducts?.length > 0 ? addedProducts?.map((item) => {
                                                                    // console.log("product",item)
                                                                    return (
                                                                        <Tbody>
                                                                            <Tr>
                                                                                <Td>
                                                                                    <Flex
                                                                                        alignItems={'center'}
                                                                                    >
                                                                                        <Image
                                                                                            //  w={{ sm: '40%', md: "40%", lg: "10%" }}
                                                                                            w="100px"
                                                                                            h='80px'
                                                                                            src={'https://adminmyboutiqe.progfeel.co.in' + item.product_image}
                                                                                            alt='Dan Abramov'
                                                                                        />
                                                                                        <Grid
                                                                                            flexDirection='column'
                                                                                            ml='10px'
                                                                                        >
                                                                                            <Text fontWeight='bold' fontSize='xl'>
                                                                                                {item.product_name}
                                                                                            </Text>
                                                                                            <Text>
                                                                                                {item.product_brand_name}
                                                                                            </Text>
                                                                                        </Grid>
                                                                                    </Flex>
                                                                                </Td>
                                                                                <Td>
                                                                                    <Text textDecoration='line-through'>
                                                                                        MRP {parseFloat(Number(item.PRDPrice)).toFixed(2)} {currencyIcon ? currencyIcon : '€'}                                                                            </Text>
                                                                                </Td>
                                                                                <Td isNumeric>
                                                                                    <Text color='#219695'>
                                                                                        {parseFloat(Number(item.PRDDiscountPrice)).toFixed(2)} {currencyIcon ? currencyIcon : '€'}
                                                                                    </Text>
                                                                                </Td>
                                                                                <Td>
                                                                                    <Flex
                                                                                        display='flex'
                                                                                        alignItems='center'
                                                                                        justifyContent='right'
                                                                                        mr='30%'
                                                                                    >
                                                                                        <Button

                                                                                            bg='#219695'
                                                                                            mr='5'
                                                                                            ml='5'
                                                                                            color='white'
                                                                                            fontSize='12px'
                                                                                            colorScheme='none'
                                                                                            onClick={() => { addToCart(item.product_vendor, item.id) }}
                                                                                        >
                                                                                            Add to Cart
                                                                                        </Button>
                                                                                        <AiFillHeart
                                                                                            color='black'
                                                                                            fontWeight={'bold'}
                                                                                            colorScheme='blue'
                                                                                            size={'25px'}
                                                                                            cursor='pointer'
                                                                                            onClick={() => { addtoWishlist(accounttoken, item.id) }}
                                                                                        />
                                                                                    </Flex>
                                                                                </Td>
                                                                            </Tr>

                                                                        </Tbody>
                                                                    )
                                                                })
                                                                    :
                                                                    <Flex
                                                                        display={'flex'}
                                                                        justifyContent='center'
                                                                        // margin={'0 auto'}
                                                                        w='100%'
                                                                        ml='50%'
                                                                    >
                                                                        {
                                                                            token ?
                                                                                <Text
                                                                                    textAlign={'center'}>
                                                                                    Your  Wish List is empty
                                                                                </Text>
                                                                                :
                                                                                <Flex
                                                                                    flexDir={'column'}
                                                                                >
                                                                                    <Text
                                                                                        color={"#219691"}
                                                                                        fontWeight='bold'
                                                                                    >
                                                                                        Please login to checkout your wishlist
                                                                                    </Text>
                                                                                    <Button
                                                                                        bg='#219691'
                                                                                        color={"#FFFFFF"}
                                                                                        colorScheme='none'
                                                                                        onClick={() => { loginToCheckout() }}
                                                                                    >
                                                                                        Click here to login
                                                                                    </Button>
                                                                                </Flex>
                                                                        }
                                                                    </Flex>
                                                                }
                                                            </Table>
                                                        </TableContainer>
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                    }
                                    </Flex>
                                    {/* <p style={{ border: "1px solid black", width: "100%" }}
                                    ></p>
                                     */}
                                </TabPanel>
                            )
                        })}

                    </TabPanels>
                </Tabs>
            </Flex>

            <Flex
                className='bottom_footer_widget'
                flexDirection='column'
                bg='lightgray'
                mt='5'
                // mb='1'
            >
                <Flex justifyContent='space-evenly' mt='10' >
                    <Flex
                        flexDirection='column'
                        alignItems='center'
                    >
                        <span
                            style={{
                                border: '1px solid black',
                                borderRadius: '100%',
                                width: '100px',
                                height: '100px',
                                lineHeight: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <TbTruckDelivery
                                size='50px'
                                h='100px'
                            />
                        </span>
                        <Text mt='5'>Fast Shipping</Text>
                    </Flex>
                    <Flex flexDirection='column'>
                        <span
                            style={{
                                border: '1px solid black',
                                borderRadius: '100%',
                                width: '100px',
                                height: '100px',
                                lineHeight: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <MdOutlineAssignmentReturn
                                size='50px'
                                h='100px'
                            />
                        </span>
                        <Text mt='5'>Free Returns</Text>
                    </Flex>

                </Flex>

                <Flex justifyContent='space-evenly' mt='10' mb='5'>
                    <Flex flexDirection='column' alignItems='center'>
                        <span
                            style={{
                                border: '1px solid black',
                                borderRadius: '100%',
                                width: '100px',
                                height: '100px',
                                lineHeight: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <MdOutlinePhoneCallback
                                size='50px'
                                h='100px'
                                borderRadius='full'
                            />
                        </span>
                        <Text mt='5'>Service</Text>
                    </Flex>
                    <Flex flexDirection='column'>
                        <span
                            style={{
                                border: '1px solid black',
                                borderRadius: '100%',
                                width: '100px',
                                height: '100px',
                                lineHeight: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <BsBag
                                size='50px'
                                h='100px'
                                borderRadius='full'
                            />
                        </span>
                        <Text mt='5'>Secure Shop</Text>
                    </Flex>
                </Flex>
            </Flex>
            <GalleryFooter disp={true}/>
            <AddToCart userId={userId}
                getCart={getCart}
                store_id={store_id}
                deleteproductcart={deleteproductcart}
                getAddedProductCart={getAddedProductCart}
                onOpencart={onOpencart}
                cart={cart}
                allProduct={allProduct}
                onClose={onClose1}
            />
            <Modal
                isOpen={dropdown}
                size='xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader
                        className='hamburge_modal' mt='5'
                    >
                        {/* <Text
                            className='product_hamburger_text'
                            color='#219691'
                        >
                            MY BOUTIQ
                        </Text> */}
                        <Image
                            src={logo}
                            width={{ sm: '50%', md: "25%", lg: "30%" }}
                            // width='25%'
                            margin={'0 auto'}
                        />

                        <CloseIcon
                            cursor='pointer'
                            onClick={closeDropdown}
                        />
                    </ModalHeader>

                    <ModalBody>

                        <div
                            className={dropdown ? "hamburgOpen" : "hamburgClose"}
                        >
                            <p className='home_line' ></p>
                            <Text
                                ml='14'
                                fontWeight='bold'
                                fontSize='lg' mt='5'
                                onClick={openDropdown}
                                cursor='pointer'>
                                Account Login
                            </Text>

                            <form action=""
                                onSubmit={getRegisterDetails}>
                                <Flex mt='5' m='3'
                                    alignItems='center'
                                    flexDirection='column'
                                >
                                    <Input
                                        text="email"
                                        required
                                        mt='5'
                                        size='lg'
                                        w='80%'
                                        onChange={(e) => setGetusername(e.target.value)}
                                        placeholder='Username'
                                    />
                                    <FormControl pt="20px">

                                        <InputGroup
                                            size='lg'
                                            w='80%'
                                            mt='5'
                                            margin='0 auto'
                                        >
                                            <Input
                                                required
                                                onChange={(e) => setGetpassword(e.target.value)}
                                                type={showold ? 'text' : 'password'}
                                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                                title="Must contain at least one number and one uppercase and 
                                                        lowercase letter, and at least 8 or more characters"
                                                placeholder='Password'
                                                mb='24px'
                                                h="50px"
                                            />
                                            <InputRightElement py="25px">
                                                <Button
                                                    h='1.75rem'
                                                    size='xs'
                                                    onClick={showOldPass}>
                                                    {showold ? <BiHide /> : <BiShow />}
                                                </Button>
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>
                                    <Button
                                        mt='10'
                                        w='80%'
                                        colorScheme='none'
                                        bg="#219691"
                                        type='submit'>
                                        Sign In
                                    </Button>
                                </Flex>
                            </form>
                            <Flex mt='5'
                                mb='20'
                                gap='10'
                                className='header_wrapper_member'
                            >
                                <Text
                                    onClick={openSignup}
                                    margin='0 auto'>
                                    Not a member ?
                                    <span
                                        style={{ textDecoration: "underline" }}>
                                        Sign up
                                    </span>
                                </Text>
                                <Text
                                    onClick={openForgetpass}
                                    margin='0 auto'
                                    textDecoration='underline'
                                >
                                    Forget Password
                                </Text>
                            </Flex>

                        </div>

                    </ModalBody>
                </ModalContent>
            </Modal>


            {/* Account Login - Forget password */}


            <Modal blockScrollOnMount={false}
                isOpen={forgetpass}
                size='2xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex
                            justifyContent='space-between'
                            mt='5'>
                            {/* <Text
                                textAlign='center'
                                flex='auto'
                                color='#219691'>
                                MY BOUTIQ
                            </Text> */}
                            <Image
                                src={logo}
                                width={{ sm: '50%', md: "25%", lg: "30%" }}
                                // width='25%'
                                margin={'0 auto'}
                            />
                            <CloseIcon
                                cursor='pointer'
                                mt='1'
                                onClick={closeForgetpass}
                            />
                        </Flex>

                    </ModalHeader>

                    <ModalBody>
                        <p className='home_line' ></p>
                        <Text
                            ml='14'
                            fontWeight='bold'
                            fontSize='lg' mt='5'
                            onClick={openDropdown}
                            cursor='pointer'>
                            Forget Password
                        </Text>
                        <form action=""
                            onSubmit={getResetPassword}
                        >

                            <Flex mt='5' m='3'
                                alignItems='center'
                                flexDirection='column'
                            >

                                <Input
                                    text="email"
                                    mr='3'
                                    required
                                    mt='5'
                                    size='lg'
                                    w='80%'
                                    // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                    // title="Must contain at least one number and one uppercase and 
                                    //             lowercase letter, and at least 8 or more characters"
                                    value={resetacc}
                                    name='resetacc'
                                    onChange={RegAccountpass}
                                    placeholder='Email'
                                />

                                <Button
                                    mt='10'
                                    w='80%'
                                    mb='10'
                                    pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                    title="Must contain at least one number and one uppercase and 
                                                        lowercase letter, and at least 8 or more characters"
                                    colorScheme='none'
                                    bg="#219691"
                                    type='submit'>
                                    Reset Password
                                </Button>


                            </Flex>
                        </form>
                    </ModalBody>

                </ModalContent>
            </Modal>

            {/* Account Login - signu up modal */}


            <Modal blockScrollOnMount={false}
                isOpen={signup}
                size='xl'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex
                            justifyContent='space-between'
                            mt='5'>
                            {/* <Text
                                textAlign='center' f
                                lex='auto'
                                color="#219691">
                                MY BOUTIQ
                            </Text> */}
                            <Image
                                src={logo}
                                width={{ sm: '50%', md: "25%", lg: "30%" }}
                                // width='25%'
                                margin={'0 auto'}
                            />
                            <CloseIcon
                                cursor='pointer'
                                mt='1'
                                onClick={closesignup}

                            />
                        </Flex>


                    </ModalHeader>

                    <ModalBody>
                        <p className='home_line' ></p>
                        <Text
                            ml='16'
                            fontWeight='bold'
                            fontSize='lg' mt='5'
                            onClick={openDropdown}
                            cursor='pointer'>
                            Sign up
                        </Text>
                        <form
                            onSubmit={getRegistrationdetails}>

                            <Flex
                                mt='5' m='3'
                                alignItems='center'
                                flexDirection='column'
                            >
                                <Input
                                    text="email"
                                    required
                                    mt='5'
                                    size='lg'
                                    w='80%'
                                    onChange={RegAccountLogin}
                                    value={username}
                                    name='username'
                                    placeholder='Username'
                                />
                                <Input
                                    text="email"
                                    required
                                    mt='5'
                                    size='lg'
                                    w='80%'
                                    onChange={RegAccountLogin}
                                    value={email}
                                    name='email'
                                    placeholder='Email'
                                />
                                <FormControl pt="20px">

                                    <InputGroup
                                        size='lg'
                                        w='80%'
                                        mt='5'
                                        margin='0 auto'
                                    >
                                        <Input
                                            text="email"
                                            required
                                            type={showAcc ? 'text' : 'password'}
                                            name='password'
                                            value={password}
                                            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                            title="Must contain at least one number and one uppercase and 
                                                        lowercase letter, and at least 8 or more characters"
                                            onChange={RegAccountLogin}
                                            placeholder='Password'
                                        />
                                        <InputRightElement py="25px">
                                            <Button h='1.75rem'
                                                size='xs'
                                                onClick={showAccPass}>
                                                {showAcc ? <BiHide /> : <BiShow />}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                </FormControl>
                                <Button
                                    mt='10'
                                    w='80%'
                                    mb='20'
                                    onClick={openDropdown}
                                    colorScheme='none'
                                    bg="#219691"
                                    type='submit'
                                >
                                    Sign Up
                                </Button>
                            </Flex>
                        </form>
                    </ModalBody>

                </ModalContent>
            </Modal>


        </ChakraProvider>
    )
}


export default GalleryWishList;
