import React, { useEffect, useState } from 'react';
import { Box, Flex, Button, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Text, ModalFooter, useDisclosure, useToast } from '@chakra-ui/react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { CloseIcon } from '@chakra-ui/icons'
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { getAffiliateTeamMember, removeAffiliateTeamMember } from 'components/API/AuthAPI';
import { Translate } from 'components/DeepL/Translate';
import { yes, no,removeTeamMemberConfirmation, teamMemberAdded,inactive, active } from 'assets/constants/strings';

const MemberList = () => {
  const [token, setToken] = useState();
  const [details, setDetails] = useState([]);
  const [id, setid] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const toast = useToast();


  useEffect(() => {
    const checkUserLogin = () => {
      const userToken = localStorage.getItem("token");
      setToken(userToken);
    }
    checkUserLogin();
    if(token){
      getTeamMemberDetails();
    }
  }, [token])


 /**
  * The function parses input data and creates a new object with user information if it exists.
  * @param userObj - An object representing a user, with keys and values for various user attributes
  * (e.g. name, email, age).
  * @param objectName - The objectName parameter is a string that will be used as a prefix for each key
  * in the resulting object created by the makeUserObj function. It is used to differentiate between
  * different objects that may have similar keys.
  * @returns The `parseData` function returns an array of objects where each object has the properties
  * of the original object in the input array, and if the original object had a `user` property, its
  * properties are added to the new object with a prefix of "user.". If the input array is empty, an
  * empty array is returned.
  */
  const makeUserObj = (userObj, objectName) => {
    const keys = Object.keys(userObj)
    let resultObj = {}
    for (let i in keys) {
      const value = keys[i]
      resultObj[`${objectName}.${value}`] = userObj[value]
    }
    return resultObj
  }
  const parseData = (inputData) => {
    if (inputData.length === 0) {
      return []
    }
    const result = inputData.map((instance) => {
      if (instance.hasOwnProperty('user')) {
        const { user, ...rest } = instance
        return {
          ...rest,
          ...makeUserObj(user, "user")
        }
      }
      return instance
    })
    return result
  }


 /**
  * This function retrieves team member details from an API and sets the parsed data to a state
  * variable.
  */
  const getTeamMemberDetails = () => {

    getAffiliateTeamMember(token)
      .then((responseJson) => {
        if (responseJson) {
          let affilliateDetails = responseJson.data || {};
          let data = parseData(affilliateDetails)
          setDetails(data);

        }
      })
  }

  /**
   * The function handles the removal of an affiliate team member and displays a success message using
   * a toast notification.
   */
  const handleRemove = (id, token) => {
    setIsSubmitted(true);
    removeAffiliateTeamMember(token, id)
      .then((responseJson) => {
        if (responseJson["message"] == "Team member deleted successfully") {
          if (token) {
            getTeamMemberDetails();
          }
          toast({
            position: 'top',
            render: () => (
              <Box
                color='white'
                p={3} bg='#219691'
                textAlign={'center'}
                fontWeight='bold'
              >
                {teamMemberAdded}
              </Box>
            ),
          })
          setIsSubmitted(false);
        }
      })
  }



  const customCellStyle = cellProps => {
    const { value, rowActive } = cellProps;
    return {
      color: rowActive
        ? '#e9ecf0'
        : value % 2 ? '#ff595e' : 'inherit',
    }
  };
  const columns = [
    {
      name: 'display_name', header: 'Name', minWidth: 100, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: "user.email", header: 'Email', minWidth: 100, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'status', header: 'Role', minWidth: 100, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'user.is_active', header: 'Status', minWidth: 200, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      },
      style: customCellStyle,
      render: ({ value, data }) => {
        //   const showCountry = () => inovua.notification.first.addNotification({content: 'Country code: ' + data.country});
        return <div style={{ display: 'flex', position: "fixed", alignItems: "center", marginLeft: "8%", top: "5px", justifyContent: "center" }}>
          <div>

            {value === true ? <Button
              colorScheme='none'
              borderColor='#ffffff'
              color='#ffffff'
              bg="#219691"
              variant='outline'
              cursor={"default"}
              fontSize='xs'
              p='8px 32px'>{active}</Button> :
              <Button
                bg="#D3D3D9"
                colorScheme='none'
                borderColor='#ffffff'
                color='#000000'
                variant='outline'
                cursor={"default"}
                fontSize='xs'
                p='8px 32px'>{inactive}</Button>}
          </div>
          <div style={{ marginLeft: "10px" }}>
            {data.status === "affiliate" ? <Button colorScheme="red" bg="#219691" size="xs" onClick={() => {
              onOpen()
              let userInfo = data.id;
              setid(userInfo);
            }} >
              <CloseIcon color="#000000" />
            </Button>
              :
              ""
            }
          </div>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size='xs'
            display='flex'
            justifySelf='center'
          >
            <ModalOverlay bg='none' />
            <ModalContent
              mt='20%'
              height={'200px'}
            >
              <ModalCloseButton />
              <ModalBody
                justifyContent='center'
                justifySelf={'center'}
              >
                <Text
                  pl='20px'
                  pr='20px'
                  pt='50px'
                  textAlign={'center'}
                  fontSize='lg'
                  fontWeight={'bold'}
                  noOfLines={2}
                >
                  {removeTeamMemberConfirmation}
                </Text>
              </ModalBody>

              <ModalFooter>
                <Flex
                  justifyContent={'space-between'}
                  margin='0 auto'
                >
                  {isSubmitted == false ?
                    <Button
                      variant='ghost'
                      color='white'
                      colorScheme={'none'}
                      bg='#219691'
                      ml='20px'
                      mr='20px'
                      w='100%'
                      onClick={() => { handleRemove(id, token); }}
                    >
                      {yes}
                    </Button>
                    :
                    <Button
                      colorScheme='teal'
                      variant='solid'
                      color='white'
                      isLoading
                      loadingText='please wait....'
                      bg='#219691'
                      ml='20px'
                      mr='20px'
                      w='100%'
                    >
                      {yes}
                    </Button>
                  }

                  <Button
                    colorScheme='none'
                    bg='#219691'
                    color='#ffffff'
                    ml='20px'
                    mr='20px'
                    w='100%'
                    onClick={() => { onClose() }}
                  >
                    {no}
                  </Button>

                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      }
    },
  ];

  const gridStyle = { minHeight: 580, maxWidth: 1700, textAlign: "center" };

  const rowStyle = { fontSize: "15.5px", fontStyle: "normal" }

  return (
    <Flex direction="column" pt={{ base: "10px", md: "10px" }}>
      <Card p='10px' mt='5px'
        maxHeight="600px"
        maxWidth="1700px"
      >
        <CardHeader>
          <ReactDataGrid
            idProperty="id"
            columns={columns}
            dataSource={details}
            style={gridStyle}
            rowStyle={rowStyle}
            rowHeight={50}
            headerHeight={55}
            showCellBorders={false}
            showZebraRows={false}
            pagination
            defaultLimit={10}
          />
        </CardHeader>
      </Card>
    </Flex>


  )
}

export default MemberList