import { galleryCarousel } from 'components/API/AuthAPI';
import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Flex, Heading, Image, Text } from '@chakra-ui/react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './GalleryCarousel.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const GalleryCarousel = () => {
    const sliderRef = useRef(null)
    const [progress, setProgress] = useState(0);
    const [divided, setDivided] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [storyIndex, setStoryIndex] = useState(0);
    const [isZoomed, setIsZoomed] = useState(false);
    const [loader, setLoader] = useState(false);
    const [currentIndex, setCurrentIndex] = useState();
    const [noOfProducts, setNoOfProducts] = useState(1);
    let history = useHistory();


    useEffect(() => {
        getCarousel()
        const intervalId = setInterval(() => {
            setIsZoomed(true);
            setTimeout(() => setIsZoomed(false), 300);
        }, 4000);
        return () => clearInterval(intervalId);
    }, []);

       /**
    * The function retrieves data from an API, filters and groups the data, sets various states, and
    * returns the grouped data.
    */
       const getCarousel = () => {
        setLoader(true)
        galleryCarousel().then((res) => {
            if (res) {
                let responseJson = res.data
                const result = responseJson.filter(responseJson => responseJson.carousel_store_name != null);
                let resp = res.data;
                const groupedItems = result.reduce((result, item) => {
                    const key = item.carousel_store_name.id;
                    if (!result[key]) {
                        result[key] = [];
                    }
                    result[key].push(item);
                    return result;
                }, []);
                var filtered = groupedItems.filter(function (el) {
                    return el != null;
                });
                setLoader(false);
                setDivided(filtered);
                const arrayCount = Object.values(groupedItems).filter(value => Array.isArray(value)).length;
                setProgress(arrayCount);
            }
        })
    }

    const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000*noOfProducts,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        lazyLoad: true,
        // swipe: false,
        arrows: false,
        pauseOnHover: false,
        beforeChange: (current, next) => {
            setCurrentSlide(next);
        },
        appendDots: (dots) => (
            <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                <span style={{ color: '#FFFFFF', fontSize: '18px', fontWeight: 'bold', position: 'absolute', right: '2%', top: '-20px' }}>
                    {currentSlide + 1}/{progress}
                </span>
            </div>
        ),
        customPaging: (i) => (
            <div></div>
        ),

    }

    const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        arrows: false,
        swipe: false,
        pauseOnHover: false,
        afterChange: current => {
            setStoryIndex(current)
            const el = document.getElementById(`image-${current}`);
        },
        beforeChange: (current, next) => {
            setCurrentIndex(next)
        },

    };
    /**
     * The function removes all HTML tags from a given string.
     */
    const removeTags = (str) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.toString().replace(/(<([^>]+)>)/ig, '');
    }

   /**
    * The function returns a class name for a progress bar based on the current story index.
    */
    function getProgressBarClassName(index) {
        if (index < storyIndex) {
            return "progress-bar progress-bar-finished";
        } else if (index == storyIndex) {
            return "progress-bar progress-bar-active";
        } else {
            return "progress-bar";
        }
    }

    useEffect(() => {
        if(divided && divided.length>0){
            setNoOfProducts(divided?.[currentSlide].length)
    }
      }, [currentSlide, divided]);

    return (
        <Flex flexDirection='column'
            position='relative'
            className='fill'
        >
            {loader ?
                <div className='carouselLoader' style={{ width: "96vw" }}>
                    <div class="ph-item">
                        <div class="ph-col-12">
                            <div class="ph-picture" style={{ height: "80vh" }}></div>
                            <div class="ph-row">
                                <div class="ph-col-6 empty"></div>
                                <div class="ph-col-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                ""
            }
            {!loader ?
                <div className="parent-slider">
                    <Slider {...settings2} ref={sliderRef}>
                        {divided.map((item, i) => {
                            return (
                                <Flex
                                    flexDirection={'column'} key={i}
                                >
                                    <Slider {...settings1} ref={sliderRef}>
                                        {divided[currentSlide].map((res, index) => {
                                            return (
                                                <Flex
                                                    flexDir='column'
                                                    className="slick-slide" key={index}
                                                    onClick={() => {
                                                        history.push(`/${res.carousel_store_name.slug}`)
                                                    }}
                                                >
                                                    <Image
                                                        id={`image-${index}`}
                                                        class={currentIndex==index ? "carouselimgZoom" : "carouselimg"}
                                                        w='100%'
                                                        src={'https://adminmyboutiqe.progfeel.co.in' + res.carousel_products.product_image}
                                                        backgroundSize="contain"
                                                        alt='Dan Abramov'
                                                    // onClick={()=>{history.push(`/${res.carousel_store_name.slug}`)}}
                                                    />
                                                    <Flex
                                                        flexDirection='column'
                                                        zIndex='2'
                                                        position='absolute'
                                                        top={{ sm: '80%', md: "70%", lg: "70%" }}
                                                        className='gallert_product_details'
                                                        justifyContent='left'
                                                        w='80%'
                                                    >
                                                        <Text
                                                            color='white'
                                                            fontWeight='bold'
                                                            fontSize='14px'
                                                            textAlign='left'
                                                            ml='10px'
                                                        >
                                                            {res.carousel_store_name.store_name}, {res.carousel_store_name.store_city}
                                                        </Text>
                                                        <Text
                                                            color='white'
                                                            fontWeight='bold'
                                                            fontSize='16px'
                                                            mt='2'
                                                            ml='10px'
                                                            noOfLines={2}
                                                            w='80vw'
                                                            textAlign='left'
                                                        >
                                                            {removeTags(res.carousel_products.product_description).toString().replace(/&nbsp;/g, '')}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            )
                                        })}
                                    </Slider>
                                    <div className='par'>
                                        <div className="progress-bars">
                                            {divided[currentSlide].map((res, index) => {
                                                return (
                                                    <div className="progress-bar-container">
                                                        <div style={{ animationDuration: "4s" }} className={getProgressBarClassName(index)}></div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </Flex>
                            )
                        })}
                    </Slider>
                </div>
                :
                ""
            }
        </Flex>
    )
}

export default GalleryCarousel