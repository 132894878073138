// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "../../Card/Card.js";
  import CardBody from "../..//Card/CardBody.js";
  import TablesProjectRow from "../../Tables/TablesProjectRow";
  import React from "react";
  
  const Details = ({ title, captions, data }) => {
    const textColor = useColorModeValue("gray.700", "white");
    // console.log("data0----",data);
    return (
      <Card my='22px' overflowX={{ sm: "scroll", xl: "scroll" }} whiteSpace={"nowrap"}>
        <CardBody>
          <Table variant='simple' color={textColor} maxW={"100%"} >
            <Thead bg="#f7f8f9">
              <Tr my='.8rem' pl='0px'>
                {captions.map((caption, idx) => {
                  return (
                    <Th color='gray.700' key={idx} ps={idx === 0 ? "10px" : null}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              
                  {data.order_details?.map((row,index) => {
                   
              return (
                  <TablesProjectRow
                    key={index}
                    name={row ? row?.product_details?.product_name: ""}
                    brand_name={row ? row?.product_details?.product_brand_name : ""}
                    quantity={row ? row?.quantity : ""}
                    original_price={row ? parseFloat(row?.product_details?.PRDPrice).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""}
                    discount_value={row ? parseFloat(row?.product_details?.PRDDiscountPrice).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ""}
                    shipping_fee={row ? row.shipping : ""}
                    selling_price={ row ? parseFloat(row?.product_details?.PRDPrice).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "Free Delivery"}
                    thereof_vat={parseFloat(0).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    total_vat={parseFloat(0).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    subtotal={row ? parseFloat(row?.product_details?.PRDPrice).toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "" } 
                  />
                  );
                })
              }
             
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    );
  };
  
  export default Details;
  