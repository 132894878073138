import React, { useState, useEffect } from 'react'
import {
    Flex, Text, Image, Input, Button, ChakraProvider, Grid, SimpleGrid, Spinner, Heading, useToast, Box, AspectRatio, Collapse,
}
    from '@chakra-ui/react'
import './productDetails1.css';
import { MainHeader } from 'components/header/MainHeader';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import theme from 'theme/theme.js';
import { getAddUpdateCart, getPlaceOrderDetails, getListedCart, checkLinkVisitor, captureTransactionId, getProductById } from '../API/AuthAPI'
import { ProductGallery } from 'components/header/ProductGallery';
import Footer from 'components/Footer/Footer';
import { StoreFooter } from 'components/header/StoreFooter';
import { NewsLetter } from 'components/header/NewsLetter';
import { AddToCart } from 'components/header/AddToCart';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Stripe from 'Stripe/Stripe';
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { addToCart as addProductToCart } from 'Slices/cartslice';
import Loading from 'components/PlaceholderLoading/Loading';
import { HiOutlineShoppingBag } from 'react-icons/hi';
import { FacebookIcon, PinterestIcon } from 'react-share';
import { RiYoutubeFill } from 'react-icons/ri';
import instagram from '../../assets/svg/instagram.svg';
import {
    addToBag, subscriptionPeriod, subscriptionFees
} from 'assets/constants/strings';
import ProductInfo from './ProductInfo';
import { CloseIcon } from '@chakra-ui/icons';
import { ImCross } from 'react-icons/im';

function ProductDetails() {
    let location = useLocation();
    const [selectedOption] = useState();
    const [proDetails, setProDetails] = useState(false)
    let hashRefId = location.hash.substring(1);
    const [productId, setProductId] = useState();
    const toast = useToast()
    const [varient, setVarient] = useState();
    const [user_id, set_user_id] = useState();
    const [loader, setLoader] = useState(false);
    let history = useHistory();
    const [accounttoken, setAccounttoken] = useState()
    const cartBag = useSelector((state) => state.cart);
    const [userId] = useState()
    const [userId1] = useState()
    const [store_id] = useState([])
    const [prticularproduct, setPrticularproduct] = useState([])
    const [getCart, setGetCart] = useState([])
    const [cart, setCart] = useState(false)
    const [currencyExchange, setCurrencyExchange] = useState(1);
    const [currencyIcon, setCurrencyIcon] = useState();
    const [proFactor, setProFactor] = useState(false)
    const [proDelivery, setProDelivery] = useState(false)
    const [varientPrice, setVarientPrice] = useState(0);
    const [option, setOption] = useState();
    const [state, setState] = useState({
        headerdata: ""
    })
    const [displayValues, setDisplayValues] = useState({
        btnbag: false,
        selectbtnvarient: false,
        cartbtn: true,
        prodImgSmallScr: false,
        mobileProductDisplay: true,
        paypalbtn: false,
        stripebtn: false
    });

    useEffect(() => {

        /* The above code is written in JavaScript and performs the following tasks:
        1. It splits the current URL's pathname by '/' and stores the resulting array in the 'fields'
        variable.
        2. It extracts the second element of the 'fields' array and stores it in the 'id' variable.
        3. It calls the 'getcatDetails' functions with the 'id' variable as an
        argument.
        4. It sets a session storage item with the key 'reference_id' and the value of 'hashRefId'
        with all forward slashes replaced by empty strings.
        */
        let fields = location.pathname.split('/');
        let id = fields[2];
        getcatDetails(id);
        sessionStorage.setItem('reference_id', hashRefId.replace(/\//g, ''))
        if (fields.length > 2) {
            checkLink(hashRefId)
        }
        /**
         * The function handles the display of certain elements based on the user's scrolling position.
         */
        function handleScroll() {
            const shouldDisplay = window.scrollY > 100;
            setDisplayValues({
                btnbag: shouldDisplay,
                selectbtnvarient: shouldDisplay,
                cartbtn: !shouldDisplay,
                prodImgSmallScr: shouldDisplay,
                mobileProductDisplay: !shouldDisplay,
                paypalbtn: shouldDisplay,
                stripebtn: shouldDisplay
            });
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let options;
    const dispatch = useDispatch();
    const handleToggleDetails = () => {
        setProDetails(!proDetails)
    }

    /**
     * The function handles adding a product to the cart and updates the cart quantity if the product
     * already exists in the cart.
     * @param product - an object containing two properties: "ele" and "varient". "ele" is another
     * object containing details about the product, while "varient" is a string representing the product
     * variant.
     */
    const handleAddToCart = (product) => {
        let combinedObject = { ...product.prticularproduct, "varient": product.varient };
        dispatch(addProductToCart(combinedObject));
        if (accounttoken) {
            cartBag.cartItems.length > 0 ? cartBag.cartItems.map((cartItem, i) => {
                if (cartItem.id == combinedObject.id && cartItem.varient == combinedObject.varient) {
                    if (accounttoken) {
                        getAddUpdateCart(accounttoken, combinedObject.id, cartItem.cartQuantity + 1, combinedObject.varient)
                    }
                } else if (accounttoken) {
                    getAddUpdateCart(accounttoken, combinedObject.id, 1, combinedObject.varient)
                }
            })
                :
                getAddUpdateCart(accounttoken, combinedObject.id, 1, combinedObject.varient)
        }
    };

    const onToggleFactor = () => {
        setProFactor(!proFactor)
    }

    const onToggleDelivery = () => {
        setProDelivery(!proDelivery)
    }

    useEffect(() => {
        const checkUserLogin = async () => {
            try {
                const userToken = await localStorage.getItem("loginToken");
                set_user_id(localStorage.getItem('loginUser_id'));
                setAccounttoken(userToken)
                getAddedProductCart()
            } catch (e) { }
            window.scrollTo(0, 0)
        }
        checkUserLogin();
    }, [accounttoken])


    /**
     * This function retrieves product details and options based on a given product ID.
     * @param productId - The ID of the product for which the details are to be fetched.
     */
    const getcatDetails = (productId) => {
        setLoader(true)
        if (productId) {
            getProductById(productId).then(responseJson => {
                if (responseJson) {
                    options = responseJson.data.product_varient.map(function (ele) {
                        return { value: { id: ele.id, price: ele.additional_price }, label: ele.product_values };
                    })
                    setOption(options)
                    if (options.length > 0) {
                        setPrticularproduct(responseJson.data)
                        setProductId(responseJson.data?.id)
                        setLoader(false)
                    }
                }
            }).catch(err => console.log(err))
        }
    }




    /**
     * This function retrieves the list of products added to the cart for a user with an account token.
     */
    const getAddedProductCart = () => {
        if (accounttoken) {
            getListedCart(accounttoken).then(responseJson => {
                if (responseJson) {
                    setGetCart(responseJson.data || [])
                }
            }).catch(err => console.log(err))
        }
    }

    const onOpencart = () => {
        setCart(true)
    }

    const onClose1 = () => {
        setCart(false)
    }

    const handleCallback = (childData) => {
        setState({ headerdata: childData })
    }

    useEffect(() => {
        let crr = localStorage.getItem('convertedCurrency')
        setCurrencyExchange(crr)
        setCurrencyIcon(localStorage.getItem("changeUser"))
    }, [state])

    const styles = {
        option: (styles) => ({
            ...styles,
            "&:hover": {
                backgroundColor: "#219691",
                color: "#FFFFFF"
            }
        })
    };


    /**
     * The function checks if a hash reference ID exists and calls another function to handle it.
     * @param hashRefId - The parameter `hashRefId` is a string that represents a reference ID in a URL
     * hash. The function `checkLink` takes this parameter and checks if it exists. If it does, it
     * removes any forward slashes in the string and passes the resulting reference ID to the
     * `checkLinkVisitor`
     */
    const checkLink = (hashRefId) => {
        if (hashRefId) {
            const ref_id = hashRefId.replace(/\//g, '')
            checkLinkVisitor(ref_id)
        }
    }

    /**
     * The function updates the state variables for variant and variant price based on the selected
     * option.
     */
    const handleChange = (selectedOption) => {
        setVarient(selectedOption?.value?.id)
        setVarientPrice(selectedOption?.value.price)
    }


    /**
     * The function captures details of a PayPal payment transaction and passes them as parameters to
     * another function called DirectCheckout.
     */
    const onApprove = (data, actions) => {
        return actions.order.capture().then((details) => {
            let email = details.payer.email_address;
            let first_name = details.payer.name.given_name;
            let last_name = details.payer.name.surname;
            let location = details.purchase_units[0].shipping.address.admin_area_2;
            let address = details.purchase_units[0].shipping.address.address_line_1;
            let city = details.purchase_units[0].shipping.address.admin_area_1;
            let zipcode = details.purchase_units[0].shipping.address.postal_code;
            let phone_number = '9876543210'
            let comment = 'no-comment'
            let status = details.status;
            let txId = details.id;
            let refId = details.purchase_units[0].reference_id;
            let currency = details.purchase_units[0].amount.currency_code;
            let amt = details.purchase_units[0].amount.value
            DirectCheckout(accounttoken, productId, varient ? varient : prticularproduct.product_varient[0].id, email, first_name, last_name, location, address,
                city, zipcode, phone_number, comment, status, txId, refId, currency, amt);
            // const name = details.payer.name.given_name;
            history.push("/pay")
        });
    }
    /**
     * The function handles errors during payment processing and displays a message to the user.
     */
    const onError = (data, actions) => {
        return actions.order.capture().then((details) => {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        Something went wrong.
                    </Box>
                ),
            })

        });
    }


    /**
     * The function DirectCheckout performs a series of API calls to add items to a cart, place an order,
     * and capture a payment transaction.
     */
    const DirectCheckout = async (accounttoken, productId, varient, email, first_name, last_name, location, address,
        city, zipcode, phone_number, comment, status, txId, refId, currency, amt) => {
        let cart, merchant_order_id, order_id
        let payment_status = 'success';
        let mode = 'card';
        let gateway = 'paypal';
        let tagging_token = ''
        let ref_id = sessionStorage.getItem('reference_id');
        if (!ref_id == '') {
            tagging_token = ref_id;
        } else {
            tagging_token = "";
        }
        let res1 = await getAddUpdateCart(accounttoken, productId, 1, varient)
            .then((responseJson) => {
                if (responseJson) {
                    cart = responseJson.data.id
                    cart = responseJson.data.id;

                }
            })
        let res2 = await getPlaceOrderDetails(accounttoken, cart, email, first_name, last_name, location, address,
            city, zipcode, phone_number, comment)
            .then((responseJson) => {
                if (responseJson) {
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {responseJson.message}
                            </Box>
                        ),
                    })
                    sessionStorage.setItem('merchant_order_id', responseJson?.order_details?.merchant_order_id);
                    order_id = responseJson.order_details.id
                    merchant_order_id = responseJson?.order_details?.merchant_order_id
                }
            })
        let res3 = captureTransactionId(accounttoken, user_id, order_id, gateway, payment_status, txId, tagging_token, currency, amt, mode, merchant_order_id, first_name,
            address, city, zipcode).then((res) => {
                if (res['message'] == 'Payment captured successfully.') {
                    sessionStorage.removeItem('reference_id');
                    // console.log('success', res['message'])
                }
            })
    }


    return (
        <ChakraProvider
            className='container product_wrapper_container'
            theme={theme}
            resetCss={false}>
            <Flex flexDir={'column'}>
                <MainHeader
                    store_id={store_id}
                    userId1={userId1}
                    onOpencart={onOpencart}
                    onClose={onClose1}
                    handleCallback={handleCallback} />
                {
                    prticularproduct && !loader ?

                        <Flex
                            justifyContent='space-between' p='1'
                            className='product_wrapper_details'>
                            <Flex
                                display={{ sm: "flex", md: "none", xl: "none" }}
                                pos='relative'
                                flexDirection='column' className='product_details_mobile'>
                                <Text
                                    fontSize={{ sm: "24px", md: "3xl", lg: '4xl' }}
                                    fontWeight='black'
                                >
                                    {prticularproduct.product_name}
                                </Text>
                                <Text fontSize='18px'>
                                    {prticularproduct.product_brand_name}
                                </Text>
                                {/* <Text fontSize='18px' >
                                            {parseFloat((ele.PRDPrice * (currencyExchange ? currencyExchange : 1))).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{currencyIcon ? currencyIcon : "€"}
                                        </Text> */}
                                {
                                    varientPrice > 0 ?
                                        <Text fontSize='18px'>
                                            {parseFloat(prticularproduct.PRDPrice + varientPrice).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{currencyIcon ? currencyIcon : "€"}
                                        </Text>
                                        :
                                        <Text fontSize='18px' >
                                            {parseFloat((prticularproduct.PRDPrice * (currencyExchange ? currencyExchange : 1))).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{currencyIcon ? currencyIcon : "€"}
                                        </Text>
                                }

                                <Flex 
                                    className='product_back_btn'
                                    onClick={()=>{window.history.go(-1); return false}}
                                >
                                    <ImCross/>
                                </Flex>
                            </Flex>
                            <Flex
                                flexDirection='column' mt='2'
                                gap='10'
                                // w='1300px'
                                className='total_product_space'
                                display={{ sm: "none", md: "flex", lg: "flex" }}
                                mr='20px'
                                p='50px'
                            >
                                <SimpleGrid
                                    columns={{ sm: 2, md: 2, lg: 2 }}
                                    justifyContent={"center"}
                                    spacing='24px'
                                >
                                    <Flex>
                                        <AspectRatio w='570px' ratio={1}>
                                            <Image
                                                className='product_cat_img'
                                                src={prticularproduct.product_image ? "https://adminmyboutiqe.progfeel.co.in" + prticularproduct.product_image : ""}
                                                alt='productImage'
                                                objectFit='fill'
                                            />
                                        </AspectRatio>
                                    </Flex>
                                    {
                                        prticularproduct.additional_image_1 ?
                                            <Flex>
                                                <AspectRatio w='570px' ratio={1}>
                                                    <Image
                                                        className='product_cat_img'
                                                        src={"https://adminmyboutiqe.progfeel.co.in" + prticularproduct.additional_image_1}
                                                        alt="productImage"
                                                        objectFit='fill'
                                                    />
                                                </AspectRatio>
                                            </Flex>
                                            : ''
                                    }
                                </SimpleGrid>
                                <SimpleGrid
                                    columns={{ sm: 2, md: 2, lg: 2 }}
                                    justifyContent={"center"}
                                    spacing='24px'
                                >
                                    {
                                        prticularproduct.additional_image_2 ?
                                            <Flex>
                                                <AspectRatio w='570px' ratio={1}>
                                                    <Image
                                                        className='product_cat_img'
                                                        src={"https://adminmyboutiqe.progfeel.co.in" + prticularproduct.additional_image_2}
                                                        objectFit='fill'
                                                    />
                                                </AspectRatio>
                                            </Flex>
                                            : ""
                                    }
                                    {
                                        prticularproduct.additional_image_3 ?
                                            <Flex>
                                                <AspectRatio w='570px' ratio={1}>
                                                    <Image
                                                        className='product_cat_img'
                                                        objectFit='fill'
                                                        src={"https://adminmyboutiqe.progfeel.co.in" + prticularproduct.additional_image_3}
                                                    />
                                                </AspectRatio>
                                            </Flex>
                                            : ""
                                    }
                                </SimpleGrid>
                                <SimpleGrid
                                    columns={{ sm: 2, md: 2, lg: 2 }}
                                    justifyContent={"center"}
                                    spacing='24px'
                                    mb='20px'
                                >
                                    {
                                        prticularproduct.additional_image_4 ?
                                            <Flex>
                                                <AspectRatio w='570px' ratio={1}>
                                                    <Image
                                                        className='product_cat_img'
                                                        src={"https://adminmyboutiqe.progfeel.co.in" + prticularproduct.additional_image_4}
                                                        objectFit='fill'
                                                    />
                                                </AspectRatio>
                                            </Flex>
                                            : ""
                                    }
                                </SimpleGrid>
                            </Flex>
                            <Flex
                                display={{ sm: "flex", md: "none", lg: "none" }}
                            >
                                <Flex
                                    flexDirection='column'
                                    display={{ sm: "flex", md: "none", xl: "none" }}
                                    marginTop={{ sm: "none", md: "none", xl: "12" }}
                                    id='mobileProductDisplay'
                                    justifyContent={"center"}
                                    margin={"0 auto"}
                                >
                                    <Carousel
                                        showArrows={false}
                                        showIndicators={true}
                                        showStatus={false}
                                        infiniteLoop={false}
                                        emulateTouch={true}
                                        showThumbs={false}>
                                        <Image
                                            className='category_product_info'
                                            src={"https://adminmyboutiqe.progfeel.co.in" + prticularproduct.product_image}
                                            alt='Dan Abramov' />
                                        <Image
                                            className='category_product_info'
                                            src={"https://adminmyboutiqe.progfeel.co.in" + prticularproduct.additional_image_1}
                                            alt='Dan Abramov' />
                                        <Image
                                            className='category_product_info'
                                            src={"https://adminmyboutiqe.progfeel.co.in" + prticularproduct.additional_image_2}
                                            alt='Dan Abramov'
                                        />
                                        <Image
                                            className='category_product_info'
                                            src={"https://adminmyboutiqe.progfeel.co.in" + prticularproduct.additional_image_3}
                                            alt='Dan Abramov' />
                                        <Image
                                            className='category_product_info'
                                            src={"https://adminmyboutiqe.progfeel.co.in" + prticularproduct.additional_image_4}
                                            alt='Dan Abramov' />
                                    </Carousel>
                                    <Flex
                                        display={{ sm: 'flex', md: 'none', lg: 'none' }}
                                    >
                                        <Grid
                                            id="cartbtn"
                                            style={{ display: displayValues.cartbtn ? 'inline-flex' : 'none' }}
                                            className='cartbtn'
                                            w='50px'
                                            h='50px'
                                            borderRadius={'50%'}
                                            bg='#219691'
                                            // pos={'absolute'}
                                            mt='-53px'
                                            ml='84%'
                                            zIndex={1}
                                            top='72vh'
                                            right={'6%'}
                                            onClick={() => {
                                                onOpencart()
                                                handleAddToCart(({ prticularproduct, "varient": varient ? varient : prticularproduct.product_varient[0].id }))
                                            }}
                                        >
                                            <HiOutlineShoppingBag
                                                style={{ width: '50px', height: '35px', marginTop: '5px', color: "#FFFFFF" }}
                                            // mt='5px'
                                            />
                                        </Grid>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <Flex
                                display={{ sm: 'flex', md: 'none', lg: 'none' }}
                                flexDir='column'
                                mt='20px'
                            >
                                <Grid
                                    id='selectbtnvarient'
                                    style={{ display: displayValues.selectbtnvarient ? 'grid' : 'none' }}
                                >
                                    {option ?
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={selectedOption}
                                            isSearchable={false}
                                            onChange={handleChange}
                                            placeholder='Varient 1'
                                            options={option}
                                            styles={styles}
                                        />
                                        :
                                        ""
                                    }
                                </Grid>
                                <Button
                                    style={{ display: displayValues.btnbag ? 'flex' : 'none' }}
                                    id="btnbag"
                                    size='lg'
                                    onClick={() => {
                                        onOpencart()
                                        handleAddToCart({ prticularproduct, "varient": varient ? varient : prticularproduct.product_varient[0].id })
                                    }}
                                >
                                    {addToBag}
                                </Button>
                                <Grid
                                    mt='20px'
                                    zIndex={0}
                                    id='paypalbtn'
                                    style={{ display: displayValues.paypalbtn ? 'grid' : 'none' }}
                                >
                                    <PayPalScriptProvider
                                        options={{
                                            "client-id": "AYTU778DORty76cZLH3SC3ul5AUT7LUOMdOu82fR1b4PKOaQa1NOEfUgQNxFOYnWDRxhFnAAPDhRFNsz",
                                        }}
                                    >
                                        <PayPalButtons
                                            style={{ layout: "horizontal", color: "white", label: 'paypal', zIndex: "0", tagline: "false" }}
                                            createOrder={(data, actions) => {
                                                return actions.order.create({
                                                    purchase_units: [
                                                        {
                                                            amount: {
                                                                value: (prticularproduct.PRDPrice * (currencyExchange ? currencyExchange : 1)),
                                                            },
                                                        },
                                                    ],
                                                });
                                            }}
                                            onApprove={onApprove}
                                            onError={onError}
                                        />
                                    </PayPalScriptProvider>
                                </Grid>
                                <Grid mt='10px'
                                    id='stripebtn'
                                    style={{ display: displayValues.stripebtn ? 'grid' : 'none' }}
                                >
                                    <Stripe
                                        accounttoken={accounttoken}
                                        productId={productId}
                                        qty={1}
                                    />
                                </Grid>
                            </Flex>
                            <Flex
                                display={{ sm: 'flex', md: 'none', lg: 'none' }}
                            >
                                <ProductInfo
                                    prticularproduct={prticularproduct}
                                    onToggleFactor={onToggleFactor}
                                    proFactor={proFactor}
                                    currencyIcon={currencyIcon}
                                    currencyExchange={currencyExchange}
                                    onToggleDelivery={onToggleDelivery}
                                    proDelivery={proDelivery}
                                    proDetails={proDetails}

                                />
                            </Flex>
                            <Text
                                w='100%'
                                fontWeight='bold'
                                fontSize='16px'
                                display={{ sm: 'flex', md: 'none', lg: 'none' }}
                            >
                                Follow & Like us
                            </Text>
                            <Flex
                                mt='10px'
                                mb='20px'
                                display={{ sm: 'flex', md: 'none', lg: 'none' }}
                            >

                                <FacebookIcon
                                    borderRadius={'50%'}
                                    width='40px'
                                    height='40px'
                                />
                                <Image
                                    ml='10px'
                                    w='40px'
                                    h='40px'
                                    src={instagram} />
                                <PinterestIcon
                                    style={{ marginLeft: '10px' }}
                                    width='40px'
                                    height='40px'
                                    borderRadius={'50%'}
                                />
                                <RiYoutubeFill
                                    color='#FF0000'
                                    style={{ width: '40px', height: '40px', marginLeft: '10px' }}
                                />
                            </Flex>
                            <Flex mt='10'
                                flexDirection='column' >
                                <Flex
                                    display={{ sm: "none", md: "flex", lg: "flex" }}
                                    flexDirection='column'>
                                    <Text
                                        fontSize={{ sm: "xl", md: "xl", lg: '4xl' }}
                                        fontWeight='bold'
                                        className='product_wrapper_heading'>
                                        {prticularproduct.product_name}
                                    </Text>
                                    <Text
                                        mt='3'
                                        fontSize='2xl'
                                    >
                                        {prticularproduct.product_brand_name}
                                    </Text>
                                    {
                                        varientPrice > 0 ?
                                            <Text
                                                fontSize='2xl'
                                                mt='3'
                                                mb='3'
                                            >
                                                {parseFloat(prticularproduct.PRDPrice + varientPrice * (currencyExchange ? currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{currencyIcon ? currencyIcon : "€"}
                                            </Text>
                                            :
                                            <Text
                                                fontSize='2xl'
                                                mt='3'
                                                mb='3'
                                            >
                                                {parseFloat(prticularproduct.PRDPrice * (currencyExchange ? currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{currencyIcon ? currencyIcon : "€"}
                                            </Text>
                                    }
                                    {
                                        prticularproduct.billing_period !== null ?
                                            <Flex
                                                flexDirection={'column'}
                                                mb='3'
                                            >
                                                <Flex
                                                    flexDirection={'row'}
                                                >
                                                    <Text
                                                        fontWeight={"semibold"}
                                                        fontSize='lg'
                                                    >
                                                        {subscriptionPeriod}:
                                                    </Text>
                                                    <Text
                                                        fontWeight={"semibold"}
                                                        fontSize='lg'
                                                        ml='1'
                                                    >
                                                        {prticularproduct.billing_period}
                                                    </Text>
                                                </Flex>
                                                <Flex flexDirection={'row'}>
                                                    <Text
                                                        fontWeight={"semibold"}
                                                        fontSize='lg'
                                                    >
                                                        {subscriptionFees}:
                                                    </Text>
                                                    <Text
                                                        fontSize='lg'
                                                        fontWeight={"semibold"}
                                                        ml='1'
                                                    >
                                                        {parseFloat(prticularproduct.PRDtags * (currencyExchange ? currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{currencyIcon ? currencyIcon : "€"}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                            :
                                            ""
                                    }
                                </Flex>
                                <Flex
                                    flexDir={'column'}
                                    display={{ sm: "none", md: "flex", lg: 'flex' }}
                                >
                                    {option ?
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            value={selectedOption}
                                            isSearchable={false}
                                            onChange={handleChange}
                                            placeholder='Varient 1'
                                            options={option}
                                            styles={styles}
                                        />
                                        :
                                        ""
                                    }
                                    <Button
                                        // onClick={onOpencart}
                                        onClick={() => {
                                            onOpencart()
                                            handleAddToCart({ prticularproduct, "varient": varient ? varient : prticularproduct.product_varient[0].id })
                                        }}
                                        colorScheme='none'
                                        bg="#219691"
                                        mt='5' color='white'
                                        size='lg'>
                                        {addToBag}
                                    </Button>
                                    <Grid
                                        mt='20px'
                                        zIndex={0}
                                    >
                                        <PayPalScriptProvider
                                            options={{
                                                "client-id": "AYTU778DORty76cZLH3SC3ul5AUT7LUOMdOu82fR1b4PKOaQa1NOEfUgQNxFOYnWDRxhFnAAPDhRFNsz",
                                            }}
                                        >
                                            <PayPalButtons
                                                style={{ layout: "horizontal", color: "white", label: 'paypal', zIndex: "0", tagline: "false" }}
                                                createOrder={(data, actions) => {
                                                    return actions.order.create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: (prticularproduct.PRDPrice * (currencyExchange ? currencyExchange : 1)),
                                                                },
                                                            },
                                                        ],
                                                    });
                                                }}
                                                onApprove={onApprove}
                                                onError={onError}
                                            />
                                        </PayPalScriptProvider>
                                    </Grid>
                                    <Grid mt='10px'>
                                        <Stripe
                                            accounttoken={accounttoken}
                                            productId={productId}
                                            qty={1}
                                        />
                                    </Grid>
                                    <Flex
                                        display={{ sm: 'none', md: 'flex', lg: 'flex' }}
                                    >
                                        <ProductInfo
                                            prticularproduct={prticularproduct}
                                            onToggleFactor={onToggleFactor}
                                            proFactor={proFactor}
                                            currencyIcon={currencyIcon}
                                            currencyExchange={currencyExchange}
                                            onToggleDelivery={onToggleDelivery}
                                            proDelivery={proDelivery}
                                            proDetails={proDetails}
                                            handleToggleDetails={handleToggleDetails}
                                        />
                                    </Flex>
                                    <span className='product_wrapper_line'></span>

                                    <Text
                                        mt='5'
                                        mb='3'
                                        w='100%'
                                        fontWeight='bold'
                                        fontSize='xl'
                                    >
                                        Follow & Like us
                                    </Text>
                                    <Flex>

                                        <FacebookIcon
                                            borderRadius={'50%'}
                                        />
                                        <Image
                                            ml='10px'
                                            w='64px'
                                            h='64px'
                                            src={instagram} />

                                        <PinterestIcon
                                            style={{ marginLeft: '10px' }}
                                            borderRadius={'50%'}
                                        />
                                        <RiYoutubeFill
                                            color='#FF0000'
                                            style={{ width: '64px', height: '64px', marginLeft: '10px' }}
                                        />

                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        :
                        ""
                }
                {
                    loader ?
                        <Flex
                            justifyContent={'center'}
                            w={{ sm: '96vw', md: '96vw', lg: '100vw' }}
                        >
                            <Loading />
                        </Flex>
                        :
                        ""
                }
                <Flex
                    flexDirection='column'
                    display={{ sm: 'none', md: 'flex', lg: 'flex' }}
                >
                    <ProductGallery />
                    <NewsLetter />
                </Flex>
                <AddToCart
                    userId={userId}
                    getCart={getCart}
                    onOpencart={onOpencart}
                    cart={cart}
                    onClose={onClose1}
                />
                <StoreFooter />
            </Flex>
        </ChakraProvider>
    )
}

export default ProductDetails;