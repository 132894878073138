import React, { useState, useRef, useEffect } from 'react';
import {
    Box, Flex, Grid, Text, Image, Input, SimpleGrid, FormHelperText, FormErrorMessage, useColorModeValue, FormControl, Button,
    FormLabel, Select, InputGroup, InputRightElement, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
    useDisclosure, useToast,
} from "@chakra-ui/react";
import { BiShow, BiHide } from "react-icons/bi"
import profile from "../../../assets/img/profile.png"
import 'image-upload-react/dist/index.css'
import { Link } from 'react-router-dom';
import { getUserProfile, updateprofile, changePass } from 'components/API/AuthAPI';
import { countryList as countryCodewithMobile } from 'assets/countryCodes/countrycode';
import Emitter from 'Emitter';

const Profile2 = () => {
    const [imageSrc, setImageSrc] = useState(null);
    const [profilepic, setProfilePic] = useState();
    const [bio, setBio] = useState();
    const [email, setEmail] = useState();
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [username, setUsername] = useState();
    const [phone, setPhone] = useState();
    const [image, setImage] = useState();
    const [token, setToken] = useState();
    const [visible, setVisible] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("GB");
    const [lang, setLang] = useState();
    const [countrycode, setCountrycode] = useState();
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();
    const [showold, setShowold] = useState(false);
    const [shownew, setShownew] = useState(false);
    const [showConfirm, setShowConfrim] = useState(false);
    const showOldPass = () => setShowold(!showold)
    const showNewPass = () => setShownew(!shownew)
    const imageref = useRef(null)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const initialRef = useRef(null);
    const finalRef = useRef(null);
    const showConfirmPass = () => setShowConfrim(!showConfirm);
    const toast = useToast();

    useEffect(() => {
        let user = localStorage.getItem('user_type');
        if (user == "affilate_manager") {
            setVisible(true)
        }
        const checkUserLogin = async () => {
            let userToken = localStorage.getItem("token");
            setToken(userToken);
        }
        checkUserLogin();
        if (token) {
            getUserInfo();
        }
    }, [token]);

    useEffect(() => {
        let language1 = localStorage.getItem('language');
        if (language1) {
            setSelectedLanguage(language1);
        } else {
            setSelectedLanguage("GB");
        }
    }, [lang])

    Emitter.on("LANGUAGE", (lang) => setLang(lang));

    /**
     * The function retrieves user information from an API response and sets it to corresponding state
     * variables.
     */
    const getUserInfo = () => {
        getUserProfile(token)
            .then((responseJson) => {
                if (responseJson) {
                    let userDetails = responseJson.user_details || {};
                    const [countryCode, number] = userDetails.user_profile.mobile_number.split(" ");
                    setBio(userDetails.user_profile.bio || "")
                    setFirstname(userDetails.first_name || "");
                    setLastname(userDetails.last_name || "");
                    setAddress(userDetails.user_profile.address || "");
                    setCity(userDetails.user_profile.city || "");
                    setCountry(userDetails.user_profile.country || "");
                    setUsername(userDetails.username || "");
                    setEmail(userDetails.email || "");
                    setPhone(number || "");
                    setCountrycode(countryCode || "");
                    setImage(userDetails.user_profile.image || "")
                }
            })
            .catch((err) => {
                // console.log("") 
            })
    }


    const onBioChange = (e) => {
        setBio(e.target.value);
    }
    const onFirstnameChange = (e) => {
        setFirstname(e.target.value);
    }
    const onLastnameChange = (e) => {
        setLastname(e.target.value);
    }
    const onAddressChange = (e) => {
        setAddress(e.target.value);
    }
    const onCityChange = (e) => {
        setCity(e.target.value)
    }
    const onCountryChange = (e) => {
        setCountry(e.target.value);
    }
    const onCountryCodeChange = (e) => {
        setCountrycode(e.target.value);
    }
    const onUsernameChange = (e) => {
        setUsername(e.target.value);
    }
    const onEmailChange = (e) => {

        setEmail(e.target.value);
    }
    const onPhoneChange = (e) => {
        setPhone(e.target.value);
    }

    /**
     * The function handles the submission of a form to update a user's profile information and
     * displays a success message if the update is successful.
     */
    const handleSubmit = (e) => {
        e.preventDefault()
        const completeNumber = [countrycode, phone].join(" ");
        updateprofile(token, firstname, lastname, bio, address, city, country, username, email, completeNumber, profilepic, imageSrc)
            .then((responseJson) => {
                if (responseJson) {
                    if (responseJson["user_details"]) {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    Profile updated successfully
                                </Box>
                            ),
                        })
                    }
                }
            })
            .catch((err) => (console.log("error", err)))
    }

    /**
     * This function handles the upload of an image file, checks if it is smaller than 2 MB, and sets
     * the image source and profile picture.
     */
    const handleImageUpload = e => {
        const [file] = e.target.files;
        if (e.target.files.length) {
            var Obj = {
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            };
            if (file.size > 2e+6) {
                toast({
                    position: 'top',
                    render: () => (
                        <Box
                            color='white'
                            p={3} bg='#219691'
                            textAlign={'center'}
                            fontWeight='bold'
                        >
                            Please upload a file smaller than 2 MB
                        </Box>
                    ),
                })
                return false;
            }
            else {
                setImageSrc(Obj.preview);
                console.log("obj from profilepic", Obj.preview);
                console.log("newimg", Obj.raw);
                setProfilePic(Obj.raw);
            }

        }
    };

   /**
    * This function handles the change of password by checking if the new password matches the
    * confirmed new password and then calling an API to change the password.
    */
    const handleChangePassword = (e) => {

        e.preventDefault()
        if (newPassword == confirmNewPassword) {

            changePass(token, oldPassword, confirmNewPassword)
                .then((responseJson) => {
                    if (responseJson) {
                        console.log("newRes", responseJson);
                        if (responseJson["message"]) {
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        Password changed
                                    </Box>
                                ),
                            })
                            setOldPassword("")
                            setNewPassword("")
                            setConfirmNewPassword("")
                        }
                        if (responseJson["old_password"]) {
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        Please enter correct old password
                                    </Box>
                                ),
                            })
                        }


                    }
                })
                .catch((err) => { console.log(err) });

        } else {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        Passwords not matched
                    </Box>
                ),
            })
        }
    }


    return (
        <Flex
            direction='column' pt={{ base: "0px", md: "10px" }}
            w='100%'
        >
            <form onSubmit={handleSubmit}>
                <Box
                    w={{ base: '100%', md: '100%', lg: '100%' }}
                    borderRadius='25px'
                    py='0px'
                    display={{ sm: "none", md: "flex", lg: 'flex' }}
                    flexDirection='column'
                >
                    <Box
                        borderRadius='25px'
                        w={{ base: '100%', md: '100%', lg: '100%' }}
                        h='100px'
                        display='flex'
                        justifyContent={{
                            sm: "right",
                            md: "space-between"
                        }}
                    >
                        <Text
                            color="#219691"
                            fontWeight={"bold"}
                            fontSize={{ lg: "24px" }}
                            py="20px"
                        >
                            {selectedLanguage !== "GB" ? Translate("AFFILIATE PROFILE") : "AFFILIATE PROFILE"}
                        </Text>
                        <Grid display="flex" py="20px"
                            pt={{ sm: "20px" }}
                        >
                            {visible ?

                                <Link to="/affiliate/affiliateteammember">
                                    <Button
                                        colorScheme='none'
                                        variant='outline'
                                        p='8px 32px'
                                        bg="#219691"
                                        color="white"
                                        fontSize={{ base: '10px', md: '15px', lg: '16px' }}
                                    >
                                        Team Members
                                    </Button>
                                </Link>
                                :
                                ""
                            }
                            &nbsp;&nbsp;
                            <Button
                                colorScheme='none'
                                variant='outline'
                                p='8px 32px'
                                bg="#219691"
                                color="white"
                                onClick={onOpen}
                                fontSize={{ base: '10px', md: '15px', lg: '16px' }}
                            >
                                {selectedLanguage !== "GB" ? Translate("Change Password") : "Change Password"}
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                type="submit"
                                colorScheme='none'
                                variant='outline'
                                p='8px 32px'
                                bg="#219691"
                                color="white"
                                fontSize={{ base: '10px', md: '15px', lg: '16px' }}
                            >
                                {selectedLanguage !== "GB" ? Translate("Save") : "Save"}

                            </Button>
                        </Grid>
                    </Box>
                </Box>

                <Grid
                    w={{ base: '100%', md: '100%', lg: '100%' }}
                    bg={{ lg: "#ffffff" }}
                    p={{ sm: '0px', md: '20px', lg: "20px" }}
                    borderRadius="20px"
                >
                    <Grid
                        w={{ base: '100%', md: '100%', lg: '100%' }}
                    >
                        <Text
                            fontWeight="bold"
                            color="#219691"
                        >
                            {selectedLanguage !== "GB" ? Translate("My Profile") : "My Profile"}
                        </Text>

                        <Flex
                            display="flex"
                            pt="30px"
                            flexDirection={{
                                sm: "column",
                                md: "column",
                                lg: "row"
                            }}
                        >
                            <FormControl>
                                <FormLabel ms="10px">{selectedLanguage !== "GB" ? Translate("Salutation") : "Salutation"}</FormLabel>
                                <Select
                                    id="bio"
                                    fontSize='sm'
                                    borderRadius='15px'
                                    placeholder={selectedLanguage !== "GB" ? Translate('Salutation') : 'Salutation'}
                                    onChange={onBioChange}
                                    value={bio || ""}
                                    mb='24px'
                                    h="50px"
                                >
                                    <option>{selectedLanguage !== "GB" ? Translate("Mr.") : "Mr."}</option>
                                    <option>{selectedLanguage !== "GB" ? Translate("Mrs.") : "Mrs."}</option>
                                </Select>
                            </FormControl>

                            <FormControl pl={{ sm: "0px", md: "0px", lg: "10px" }} >
                                <FormLabel ms="10px">{selectedLanguage !== "GB" ? Translate("First Name") : "First Name"}</FormLabel>
                                <Input
                                    id="change"
                                    fontSize='sm'
                                    borderRadius='15px'
                                    onChange={onFirstnameChange}
                                    name="firstname"
                                    value={firstname || ""}
                                    required
                                    pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                    placeholder={selectedLanguage !== "GB" ? Translate('First Name') : 'First Name'}
                                    mb='24px'
                                    h="50px"

                                />
                            </FormControl>


                            <FormControl pl={{ sm: "0px", md: "0px", lg: "10px" }} >
                                <FormLabel ms="10px">{selectedLanguage !== "GB" ? Translate("Last Name") : "Last Name"}</FormLabel>
                                <Input
                                    id="lastname"
                                    fontSize='sm'

                                    borderRadius='15px'
                                    onChange={onLastnameChange}
                                    name="lastname"
                                    value={lastname || ""}
                                    required
                                    pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                    placeholder={selectedLanguage !== "GB" ? Translate("Last Name") : "Last Name"}
                                    mb='24px'

                                    h="50px"

                                />
                            </FormControl>

                        </Flex>
                        <Flex display="flex"
                            flexDirection={{
                                sm: "column",
                                md: "column",
                                lg: "row"
                            }}>
                            <FormControl>
                                <FormLabel ms="10px">{selectedLanguage !== "GB" ? Translate("Address") : "Address"}</FormLabel>
                                <Input
                                    id="address"
                                    value={address || ""}
                                    onChange={onAddressChange}
                                    fontSize='sm'

                                    borderRadius='15px'
                                    type='text'
                                    placeholder={selectedLanguage !== "GB" ? Translate("Address") : "Address"}
                                    mb='24px'
                                    h="50px"
                                />
                            </FormControl>

                            <FormControl pl={{ sm: "0px", md: "0px", lg: "10px" }}>
                                <FormLabel ms="10px">{selectedLanguage !== "GB" ? Translate("City") : "City"}</FormLabel>
                                <Input
                                    id="city"
                                    fontSize='sm'
                                    borderRadius='15px'
                                    onChange={onCityChange}
                                    name="city"
                                    value={city || ""}
                                    required
                                    pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                    placeholder={selectedLanguage !== "GB" ? Translate("City") : "City"}
                                    mb='24px'

                                    h="50px"
                                />
                            </FormControl>

                            <FormControl pl={{ sm: "0px", md: "0px", lg: "10px" }}>
                                <FormLabel ms="10px">{selectedLanguage !== "GB" ? Translate("Country") : "Country"}</FormLabel>
                                <Select
                                    id="country"
                                    fontSize='sm'
                                    borderRadius='15px'
                                    value={country || ""}
                                    onChange={onCountryChange}
                                    placeholder={selectedLanguage !== "GB" ? Translate("Country") : "Country"}
                                    mb='24px'
                                    h="50px"
                                >
                                    {countryCodewithMobile.map(country => (
                                        <option key={country.code} value={country.name}>
                                            {country.name}
                                        </option>
                                    ))}

                                </Select>
                            </FormControl>

                        </Flex>
                        <Flex display="flex"
                            flexDirection={{
                                sm: "column",
                                md: "column",
                                lg: "row"
                            }}>
                            <FormControl>
                                <FormLabel ms="10px">{selectedLanguage !== "GB" ? Translate("Username") : "Username"}</FormLabel>
                                <Input
                                    id="username"
                                    fontSize='sm'
                                    borderRadius='15px'
                                    onChange={onUsernameChange}
                                    name="username"
                                    value={username || ""}
                                    required
                                    // pattern="^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$"
                                    placeholder={selectedLanguage !== "GB" ? Translate("Username") : "Username"}
                                    mb='24px'
                                    h="50px"
                                />
                            </FormControl>


                            <FormControl pl={{ sm: "0px", md: "0px", lg: "10px" }} >
                                <FormLabel ms="10px">{selectedLanguage !== "GB" ? Translate("Email") : "Email"}</FormLabel>
                                <Input
                                    id="email"
                                    fontSize='sm'
                                    readOnly
                                    borderRadius='15px'
                                    onChange={onEmailChange}
                                    name="email"
                                    value={email || ""}
                                    required
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$"
                                    placeholder={selectedLanguage !== "GB" ? Translate("Email") : "Email"}
                                    mb='24px'

                                    h="50px"

                                />
                            </FormControl>

                            <FormControl
                                pl={{ sm: "0px", md: "0px", lg: "10px" }}
                                mb="24px"
                            >
                                <FormLabel ms="10px">{selectedLanguage !== "GB" ? Translate("Phone") : "Phone"}</FormLabel>


                                <Flex>
                                    <Select
                                        placeholder='country code'
                                        width='30%'
                                        isRequired='true'
                                        id="countrycode"
                                        fontSize='sm'
                                        borderRadius='15px'
                                        name='countrycode'
                                        value={countrycode}
                                        onChange={onCountryCodeChange}
                                        mr='1'
                                        // mb='24px'
                                        h="50px"
                                    >
                                        {countryCodewithMobile.map(country => (
                                            <option key={country.code} value={country.dial_code}>
                                                {country.dial_code} {"("}{country.name}{")"}
                                            </option>
                                        ))}
                                    </Select>
                                    <Input
                                        id="phone"
                                        fontSize='sm'
                                        borderRadius='15px'
                                        onChange={onPhoneChange}
                                        name="phone"
                                        value={phone || ""}
                                        required
                                        pattern="(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?"
                                        type='text'
                                        placeholder={selectedLanguage !== "GB" ? Translate("Phone") : "Phone"}
                                        h="50px"
                                    />
                                </Flex>
                                <FormHelperText ps="20px">{selectedLanguage !== "GB" ? Translate("When My BOUTIQ needs to reach you.") : "When My BOUTIQ needs to reach you."}</FormHelperText>
                            </FormControl>
                        </Flex>

                    </Grid>

                    <Grid
                        w={{ base: '100%', md: '100%', lg: '100%' }}
                        pb={{ sm: "30px" }}
                    >
                        <Text
                            fontWeight="bold"
                            color="#219691"
                        >
                            {selectedLanguage !== "GB" ? Translate("Profile Picture") : "Profile Picture"}
                        </Text>

                        <Flex
                            display="flex"
                            flexDirection={{
                                sm: "column",
                                md: "column",
                                lg: "row"
                            }}
                        >
                            <Grid
                                display="flex"
                                flexDirection="column"
                                alignItems={{ sm: "center", md: "left", lg: "left" }}
                                justifyContent="center"
                                pt={{ sm: "30px", md: "30px", lg: "35px" }}

                            >
                                <Input
                                    type="file"
                                    accept=".png,.jpg,.jpeg"
                                    onChange={handleImageUpload}
                                    ref={imageref}
                                    display="none"
                                />
                                <Grid

                                    h="200px"
                                    w="200px"
                                    borderRadius="50%"
                                    cursor={'pointer'}

                                    onClick={() => imageref.current.click()}
                                >

                                    {image ?
                                        <Image
                                            src={!imageSrc ? "https://adminmyboutiqe.progfeel.co.in" + image : imageSrc}
                                            w="200px"
                                            h="200px"
                                            borderRadius="50%"
                                            position="absolute"
                                        />
                                        :
                                        <Image
                                            src={!imageSrc ? profile : imageSrc}
                                            w="200px"
                                            h="200px"
                                            borderRadius="50%"
                                            position="absolute"
                                        />
                                    }

                                </Grid>
                                <Text
                                    pt="10px"
                                    color="gray.400"
                                    fontSize={"sm"}
                                >
                                    {selectedLanguage !== "GB" ? Translate("Format: PNG, JPG Max. 2MB") : "Format: PNG, JPG Max. 2MB"}
                                </Text>
                            </Grid>

                            <Grid
                                display={{ sm: "flex", md: "none", lg: 'none' }}
                                pt={{ sm: "20px" }}
                                justifyContent='center'

                            >
                                {visible ?

                                    <Link to="/affiliate/affiliateteammember">
                                        <Button
                                            colorScheme='none'
                                            variant='outline'
                                            bg="#219691"
                                            color="white"
                                            fontSize={{ base: '12px', md: '15px', lg: '16px' }}
                                            w="100%"

                                        >
                                            Team Members
                                        </Button>
                                        &nbsp;&nbsp;
                                    </Link>

                                    :
                                    ""
                                }
                                <Button
                                    colorScheme='none'
                                    variant='outline'
                                    bg="#219691"
                                    color="white"
                                    onClick={onOpen}
                                    fontSize={{ base: '12px', md: '15px', lg: '16px' }}
                                    w="100%"

                                >
                                    {selectedLanguage !== "GB" ? Translate("Change Password") : "Change Password"}
                                </Button>
                                &nbsp;&nbsp;
                                <Button

                                    type="submit"
                                    colorScheme='none'
                                    variant='outline'
                                    bg="#219691"
                                    color="white"
                                    fontSize={{ base: '12px', md: '15px', lg: '16px' }}
                                    w="100%"
                                >
                                    {selectedLanguage !== "GB" ? Translate("Save") : "Save"}

                                </Button>
                            </Grid>

                        </Flex>

                    </Grid>


                </Grid>


                <Modal
                    initialFocusRef={initialRef}
                    finalFocusRef={finalRef}
                    isOpen={isOpen}
                    onClose={onClose}
                    size={"sm"}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                            display={'flex'}
                            justifyContent={'center'}
                        >
                            {selectedLanguage !== "GB" ? Translate("Change Password") : "Change Password"}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl pt="20px">
                                <FormLabel ms="4px">{selectedLanguage !== "GB" ? Translate("Old Password") : "Old Password"} <span style={{ color: "red" }}>*</span> </FormLabel>
                                <InputGroup>


                                    <Input
                                        id="oldpass"
                                        fontSize='sm'
                                        borderRadius='15px'
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        name="oldPassword"
                                        value={oldPassword}
                                        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                                        type={showold ? 'text' : 'password'}
                                        placeholder={selectedLanguage !== "GB" ? Translate('Password') : 'Password'}
                                        mb='24px'

                                        h="50px"

                                    />
                                    <InputRightElement py="25px">
                                        <Button h='1.75rem' size='xs' onClick={showOldPass}>
                                            {showold ? <BiHide /> : <BiShow />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>


                            <FormControl>
                                <FormLabel ms="4px">{selectedLanguage !== "GB" ? Translate("New Password") : "New Password"} <span style={{ color: "red" }}>*</span> </FormLabel>
                                <InputGroup>
                                    <Input
                                        id="newpassword"
                                        fontSize='sm'
                                        borderRadius='15px'
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        name="newpassword"
                                        value={newPassword}
                                        required
                                        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                                        type={shownew ? 'text' : 'password'}
                                        placeholder={selectedLanguage !== "GB" ? Translate('Confirm Password') : 'Confirm Password'}
                                        mb='24px'
                                        h="50px"
                                    />
                                    <InputRightElement py="25px">
                                        <Button h='1.75rem' size='xs' onClick={showNewPass}>
                                            {shownew ? <BiHide /> : <BiShow />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel ms="4px">{selectedLanguage !== "GB" ? Translate("Confirm New Password") : "Confirm New Password"} <span style={{ color: "red" }}>*</span> </FormLabel>
                                <InputGroup

                                >
                                    <Input
                                        id="confirmnewpassword"
                                        fontSize='sm'
                                        // ms='4px'
                                        borderRadius='15px'
                                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                                        name="confirmnewpassword"
                                        value={confirmNewPassword}
                                        required
                                        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                                        type={showConfirm ? 'text' : 'password'}
                                        placeholder={selectedLanguage !== "GB" ? Translate('Confirm Password') : 'Confirm Password'}
                                        mb='24px'

                                        h="50px"

                                    />
                                    <InputRightElement py="25px">
                                        <Button h='1.75rem' size='xs' onClick={showConfirmPass}>
                                            {showConfirm ? <BiHide /> : <BiShow />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                            </FormControl>
                            <Flex justifyContent={{ sm: "center", lg: "right" }} pt="20px">
                                <Button
                                    color="#ffffff"
                                    bg="#219691"
                                    colorScheme="none"
                                    w="100%"
                                    onClick={handleChangePassword}
                                >
                                    {selectedLanguage !== "GB" ? Translate("Update Password") : "Update Password"}
                                </Button>
                            </Flex>


                        </ModalBody>
                    </ModalContent>
                </Modal>
            </form>

        </Flex >
    )
}

export default Profile2