import { fabric } from "fabric";


class FBCanvas{
 stage=null;
constructor(canvas){
    this.stage=canvas
}

add(object){
    object.set({
        selected:false,
        
    })
    this.stage.add(object).requestRenderAll();
}
}

export default FBCanvas;