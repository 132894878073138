import { ChakraProvider, Text, Flex, Image, Box, useDisclosure, Button, } from '@chakra-ui/react'
import { MdArrowForwardIos } from 'react-icons/md';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './gallerypage.css';
import '../galleryDesktopView/gallery.css';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import theme from 'theme/theme';
import { MainHeader } from 'components/header/MainHeader';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { galleryCategory, galleryBanner, gallerySection, getBackgroundList,} from 'components/API/AuthAPI';
import { AddToCart } from 'components/header/AddToCart';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GalleryCarousel from './GalleryCarousel/GalleryCarousel';
import Loading from 'components/PlaceholderLoading/Loading';
import { exploreStoreText, viewMoreText, } from 'assets/constants/strings';
import GalleryBanner from './GalleryBanner';
import GalleryFooter from './GalleryFooter';


function GalleryPage(props) {
    const { onToggle, onClose } = useDisclosure()
    const history = useHistory()
    const [displayBanner, setDisplayBanner] = useState(false);
    const [displayFooter, setDisplayFooter] = useState(false);
    const [accounttoken, setAccounttoken] = useState()
    const [userId] = useState()
    const [userId1] = useState()
    const [getCart] = useState([])
    const [bannerText, setBannerText] = useState();
    const [sec, setSec] = useState([]);
    const [displayDesktopBanner, setDisplayDesktopBanner] = useState(false);
    const [getsellerId, setGetsellerId] = useState()
    const [getName, setGetName] = useState()
    const [cart, setCart] = useState(false)
    const [cat, setCat] = useState([]);
    useEffect(() => {
        function handleScroll() {
            const shouldDisplay = window.scrollY > 450;
            setDisplayBanner(shouldDisplay);
            const shouldDisplay1 = window.scrollY > 300;
            setDisplayDesktopBanner(shouldDisplay1);
            const shouldDisplayFooter = window.scrollY > 100;
            setDisplayFooter(shouldDisplayFooter);
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        onToggle()
    }, [])

    const onClose1 = () => {
        setCart(false)
    }

    const onOpencart = () => {
        setCart(true)
    }

    const handleCallback = (childData) => {
        localStorage.setItem("changeUser", childData)
    }

    useEffect(() => {
        const checkUserLogin = async () => {
            let sellerId;
            let getStore;
            let accToken;
            try {
                sellerId = await JSON.parse(localStorage.getItem("storeId"))
                setGetsellerId(sellerId)
                getStore = await localStorage.getItem("storename");
                setGetName(getStore);
                handleCallback1(getStore)
                handleCallback()
                accToken = localStorage.getItem("loginToken")
                setAccounttoken(accToken)
            } catch (e) { }
        }
        checkUserLogin();
    }, [getName, userId, accounttoken, getsellerId, userId,])


    /**
     * This function sets the banner text by retrieving data from a gallery banner API endpoint.
     */
    const Banner = () => {
        galleryBanner().then((res) => {
            if (res) {
                setBannerText(res.data[0].pop_up_text);
            }
        })
    }

    useEffect(() => {
        Banner();
        galleryCat();
        section();
    }, [bannerText]);

    /**
     * This function retrieves a list of categories with images and sets them as state.
     */
    const galleryCat = () => {
        galleryCategory().then((res) => {
            if (res) {
                let responseJson = res.data
                const result = responseJson.filter(responseJson => responseJson.category_image != null);
                setCat(result);
            }
        })
    }

    /**
     * The function "getToStore" retrieves a list of background data for a given store name, and if
     * successful, updates the browser's history, sets various items in local storage, and updates a
     * category render in a feature context.
     */
    const getToStore = (store_name) => {
        getBackgroundList(store_name).then(res => {
            if (res) {
                history.push({
                    pathname: (`/${store_name}`),
                    state: { params, userInfo }
                })
                let params = res.data[0].seller_store;
                localStorage.setItem('storeId', res.data[0].seller_store)
                let userInfo = res.data[0].slug
                localStorage.setItem("storename", res.data[0].store_name)
                // featureContext.setCategoryRender(res.data[0].slug)
                localStorage.setItem("storeIDname", res.data[0].store_name)

            }
        })
    }

    /**
     * This function calls the gallerySection API and sets the response data to the state variables.
     */
    const section = () => {
        gallerySection().then((res) => {
            if (res) {
                setSec(res.data);
            }
        })
    }

    //slider settings
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        // adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    centerPadding: "40px"
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    // initialSlid?e: 2
                    centerPadding: "40px",
                }
            },
            {
                breakpoint: 320,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    centerPadding: '40px'

                }
            }
        ]
    };

    return (
        <ChakraProvider theme={theme} resetCSS={false} >
            <Flex
                w='100%'
                h='auto'
                flexDir={'column'}
            >
                <MainHeader
                    userId1={userId1}
                    onClose1={onClose1}
                    onOpencart={onOpencart}
                    handleCallback={handleCallback}
                />
                <Flex
                    pos='relative'
                    flexDir={'cloumn'}
                >
                    <GalleryCarousel />
                </Flex>
                <Flex
                    display={{ sm: 'none', md: 'none', lg: 'flex' }}
                    flexDir='column'
                >
                    <Flex
                        id='desktopbanner'
                        // style={{ display: displayDesktopBanner ? 'flex' : 'none' }}
                    >
                        
                    <GalleryBanner
                        bannerText = {bannerText}
                        disp={displayDesktopBanner} 
                    />
                    </Flex>
                    {sec.length > 0 ? sec.map(res => (
                        <>
                            {res.section_store_names.length > 0 ?
                                <Text
                                    pl='10'
                                    pr='5'
                                    pt='0'
                                    fontWeight='bold'
                                    fontSize='xl'
                                    mt='20px'
                                >
                                    {res.section_name}
                                </Text>
                                :
                                ""
                            }
                            <Flex
                                flexDirection='column'
                                p='1'
                                className='gallery-page-wrapper'
                            >
                                <Carousel showArrows={false} autoPlay={false}
                                    showIndicators={false} showStatus={false} infiniteLoop={true} emulateTouch={true} showThumbs={false}>
                                    {res.section_store_names.map(response => {
                                        return (
                                            <Button
                                                bg='transparent'
                                                w='100%'
                                                colorScheme='none'
                                                borderRadius='30px'
                                                h='350px'
                                                onClick={(e) => { getToStore(response.slug) }}
                                            >
                                                <Image
                                                    w='100%'
                                                    h='350px'
                                                    borderRadius={'30px'}
                                                    backgroundSize="contain"
                                                    src={'https://adminmyboutiqe.progfeel.co.in' + response.store_background_image}
                                                    alt='Dan Abramov'
                                                />
                                            </Button>
                                        )
                                    })}
                                </Carousel>
                            </Flex>
                        </>
                    ))
                        :
                        ""
                    }
                    <Flex className='category-quick-link-selection'>
                    <Flex
                        margin='20px 0px 0px 40px'
                    >
                        <span className='exploreline'></span>
                        <Text
                            fontSize='20px'
                            fontWeight='bold'
                            display='display'
                            ml='5px'
                            mt='5px'
                            textAlign='left'
                        >
                            {exploreStoreText}
                        </Text>
                    </Flex>
                    <Flex
                        mb='30px'
                        pos={'relative'}
                    >
                        <div style={{ paddingLeft: "20px",paddingRight: "20px" }}>
                            <Slider
                                {...settings}
                            >
                                {
                                    cat.length > 0 ? cat.map(res => (
                                        <Flex
                                            flexDirection='column'
                                            className='categoryParent'
                                        >
                                            <Image
                                                justifyContent='center'
                                                margin='0 auto'
                                                className='gallery_profile_info'
                                                border='1px solid black'
                                                backgroundSize="contain"
                                                src={"https://adminmyboutiqe.progfeel.co.in/" + res.category_image}
                                                alt='Dan Abramov'
                                                cursor='pointer'
                                                onClick={(e) => { history.push({ pathname: "/gallery-category", state: res.category_name }) }}
                                            />
                                            <Text
                                                mt='3'
                                                fontWeight='bold'
                                                textAlign='center'
                                                cursor='pointer'
                                                onClick={(e) => { history.push({ pathname: "/gallery-category", state: res.category_name }) }}
                                            >
                                                {res.category_name_1}
                                            </Text>
                                        </Flex>
                                    )
                                    )
                                        :
                                        ""
                                }
                                {cat.length > 0 ?
                                    <Flex 
                                        flexDirection='column' 
                                        className='categoryParent'
                                        position='relative'>
                                        <Link to='/gallery-list'>
                                            <Flex
                                                className='gallery_profile_info'
                                                display='flex'
                                                margin='0 auto'
                                                justifySelf={'center'}
                                                border='1px solid black'
                                            >
                                                <MdArrowForwardIos
                                                    className='allcat'
                                                    size='40%'
                                                    margin='auto'
                                                />
                                            </Flex>
                                        </Link>
                                        <Text
                                            mt='3'
                                            fontWeight='bold'
                                            textAlign='center'
                                            cursor='pointer'
                                        >
                                            {viewMoreText}
                                        </Text>
                                    </Flex>
                                    :
                                    ""
                                }
                            </Slider>
                        </div>
                    </Flex>
                </Flex>
                </Flex>
                <Flex
                    display={{ sm: 'flex', md: 'flex', lg: 'none' }}
                    flexDir='column'
                >
                    <GalleryBanner
                        disp={displayBanner}
                        bannerText = {bannerText}
                    />
                    <Flex
                        flexDir={'row'}
                        mt='10px'
                        mb='5px'
                    >
                        <span className='exploreline'></span>
                        <Text
                            mt='12px'
                            fontWeight='black'
                            fontSize={'16px'}
                            pb='0'
                            ml='10px'
                        >
                            {exploreStoreText}
                        </Text>
                    </Flex>
                    <Flex
                        top="0"
                        bottom="0"
                        right="0"
                        left="0"
                        pos={'relative'}
                        mt='10px'
                    >
                        <div >
                            <Slider
                                className='categorySlider'
                                {...settings}
                            >
                                {
                                    cat.length > 0 ? cat.map(res => (
                                        <Flex
                                            flexDirection='column'
                                            ml='10px'
                                        >
                                            <Image
                                                borderRadius='full'
                                                boxSize='150px'
                                                className='gallery_profile_info'
                                                justifyContent='center'
                                                margin='0 auto'
                                                backgroundSize="contain"
                                                src={"https://adminmyboutiqe.progfeel.co.in/" + res.category_image}
                                                alt='Dan Abramov'
                                                cursor='pointer'
                                                onClick={(e) => { history.push({ pathname: "/gallery-category", state: res.category_name }) }}
                                            />
                                            <Text
                                                mt='3'
                                                fontSize={'14px'}
                                                fontWeight='black'
                                                textAlign='center'
                                                cursor='pointer'
                                                onClick={(e) => { history.push({ pathname: "/gallery-category", state: res.category_name }) }}
                                            >
                                                {res.category_name_1}
                                            </Text>
                                        </Flex>
                                    )
                                    )
                                        :
                                        ""
                                }
                                <Flex flexDirection='column' position='relative' >
                                    <Link to='/gallery-list'>
                                        <Flex
                                            className='gallery_view_background'
                                            display='flex'
                                            margin='0 auto'
                                            justifyContent='center'
                                        >
                                            <MdArrowForwardIos
                                                className='gallery_view_more'
                                                size='40%'
                                            />
                                        </Flex>
                                    </Link>
                                    <Text
                                        mt='3'
                                        fontSize={'14px'}
                                        fontWeight='black'
                                        textAlign='center'
                                    >
                                        {viewMoreText}
                                    </Text>
                                </Flex>
                            </Slider>
                        </div>
                    </Flex>
                    <Flex flexDirection={'column'} mb='3em'>
                    {sec.length > 0 ? sec.map(res => {
                        return (
                            <>
                                {res.section_store_names.length > 0 ?
                                    <Text
                                        fontWeight='bold'
                                        fontSize='lg'
                                        ml='20px'
                                    >
                                        {res.section_name}
                                    </Text>
                                    :
                                    ""
                                }
                                <Flex
                                    flexDirection='column'
                                    className='gallery-page-wrapper'
                                >
                                    <Carousel
                                        showArrows={false}
                                        autoPlay={false}
                                        showIndicators={false}
                                        showStatus={false}
                                        infiniteLoop={true}
                                        emulateTouch={true}
                                        showThumbs={false}
                                    >
                                        {res.section_store_names.map(response => {
                                            return (
                                                <Button
                                                    mb='1em'
                                                    mt='0.5em'
                                                    bg='transparent'
                                                    w='100%'
                                                    colorScheme='none'
                                                    h='200px'
                                                    onClick={(e) => { getToStore(response.slug) }}
                                                >
                                                    <Image
                                                        w='100%' h='200px'
                                                        borderRadius='30px'
                                                        backgroundSize="contain"
                                                        src={'https://adminmyboutiqe.progfeel.co.in' + response.store_background_image}
                                                        alt='Dan Abramov'
                                                        mb='10px'
                                                    />
                                                </Button>
                                            )
                                        })}
                                    </Carousel>
                                </Flex>
                            </>
                        )
                    })
                        :
                        ""
                    }
                    </Flex>
                </Flex>
                <GalleryFooter disp={displayFooter}/>
                <AddToCart userId={userId}
                    getCart={getCart}
                    onOpencart={onOpencart}
                    cart={cart}
                    onClose={onClose1}
                />
            </Flex>
        </ChakraProvider>
    )
}



export default GalleryPage