import { Box, Button, Flex, Grid, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, FormControl, FormLabel, Input, Select, Textarea, useToast } from '@chakra-ui/react'
import { getPayoutDetails, getPayoutTable, requestPayout } from 'components/API/AuthAPI';
import { Translate } from 'components/DeepL/Translate'
import React, { useEffect, useRef, useState } from 'react'
import { MdOutlineKeyboardBackspace } from 'react-icons/md';
import moment from 'moment';
import BankDetails from './BankDetails';
import Payouts from './Payouts';
import {
    enterAmount, choosePayementType, balance, available, availableBalance, lastrequested, request, requestSent, payoutSetting, accountNumber, PayPalEmail,
    payoutRequest, goBack, withdrawalAmount, chooseMethod, PayPal, bank, description, paymentRequest, affiliatePayout
} from 'assets/constants/strings';

const Header = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [accNo, setAccNo] = useState();
    const [swiftCode, setSwiftCode] = useState();
    const [country, setCountry] = useState();
    const [paypalemail, setPaypalemail] = useState();
    const [amount, setAmount] = useState();
    const [paymentType, setPaymentType] = useState();
    const [desc, setDesc] = useState();
    const [token, setToken] = useState();
    const initialRef = useRef(null);
    const finalRef = useRef(null);
    const [userId, setUserId] = useState();
    const toast = useToast();
    const [showSetting, setShowSetting] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState([]);
    useEffect(() => {
        const userToken = localStorage.getItem("token");
        const user_id = localStorage.getItem("affiliate_id");
        setUserId(user_id);
        setToken(userToken);
        if (token) {
            getBankDetails(user_id)
            getList(user_id);
        }

    }, [token])

    const sendRequest = () => {
        if (!amount) {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        {enterAmount}
                    </Box>
                ),
            })
        } else if (!paymentType) {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        {choosePayementType}
                    </Box>
                ),
            })
        }
        else {
            requestPayout(token, amount, desc, paymentType).then((res) => {
                if (res) {
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {requestSent}
                            </Box>
                        ),
                    })
                    getList(userId);
                    onClose()
                }
            })
        }
    }

    //get Bank details
    const getBankDetails = (user_id) => {
        getPayoutDetails(token, user_id)
            .then((responseJson) => {
                if (responseJson) {
                    let details = responseJson.data;
                    details.map((item) => {
                        setAccNo(item.account_number || "");
                        setSwiftCode(item.swift_code || "");
                        setCountry(item.country || "")
                        setPaypalemail(item.paypal_email || "");
                    });

                }
            })
    }


    //get payout list history and convert it into react data grid supported json data format
    const getList = (id) => {
        getPayoutTable(token, id).then((res) => {
            if (res) {
                const parsedData = res.map(({
                    date = '',
                    date_update = '',
                    description = '',
                    fee = '',
                    id = '',
                    method = '',
                    request_amount = '',
                    status = '',
                    vendor_profile = '',
                }) => {
                    let met = ''
                    if (method == "Paypal") {
                        met = "PayPal"
                    } else {
                        met = "Bank"
                    }
                    return {
                        date: moment(date).format('DD.MM.YYYY'),
                        date_update: moment(date_update).format('DD-MM-YYYY'),
                        description: description,
                        fee: fee.toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        id: id,
                        method: met,
                        request_amount: request_amount.toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        status: status,
                        vendor_profile: vendor_profile,
                    }
                })
                setPaymentDetails(parsedData)
            }
        })
    }


    return (
        <Flex flexDirection="column">
            <Flex
                flexDir={"row"}
                py='20px'
                justifyContent={'space-between'}
            >
                <Grid>
                    <Text
                        color="#219691"
                        fontWeight={"bold"}
                        fontSize={{ lg: "24px" }}
                    // py="20px"
                    >
                        {affiliatePayout}
                    </Text>
                </Grid>
                {!showSetting?
                    <Grid>
                        <Button
                            color={"#FFFFFF"}
                            bg="#219691"
                            colorScheme={"none"}
                            fontSize={{ sm: "15px" }}
                            mr={{ sm: '10px' }}
                            onClick={() => { setShowSetting(true) }}
                        >
                            {payoutSetting}
                        </Button>
                    </Grid>
                    :
                    ""
                }
            </Flex>
            {!showSetting?
                <Flex
                    flexDir={'column'}
                >
                    <Flex
                        flexDir={'row'}
                        flexWrap='wrap'
                        p='1.3rem 1.3rem'
                        border={'1px solid #eeeeee'}
                        bg="#FFFFFF"
                        borderRadius={"20px"}
                        mt='30px'
                    >
                        <Box
                            borderRadius={'0.25rem'}
                            border='1px solid #eeeeee'
                            w={{ sm: "100%", md: "100%", lg: '16.67%' }}
                            p='1rem'
                            flex='0 0 auto'
                            // rounded='xl'
                            bg='#FFFFFF'
                        >
                            <Text
                                ml='20px'
                                mt='10px'
                            >
                                {balance}:
                            </Text>
                            <Text
                                color="#219691"
                                fontWeight={"bold"}
                                ml='20px'
                            >
                                €10,000.00
                            </Text>
                            <Text
                                ml='20px'
                            >
                                {lastrequested}:
                            </Text>
                            <Text
                                color={"#219691"}
                                fontWeight="bold"
                                ml='20px'
                                mb='10px'
                            >
                                €0.00
                            </Text>
                        </Box>
                        <Box
                            ml={{ lg: '10px' }}
                            mt='20px'
                            w={{ sm: '100%', md: "50%", lg: '25%' }}
                        >
                            <Text>{accountNumber}:{accNo}</Text>
                            <Text
                                mt='10px'
                            >
                                {swiftCode}:{swiftCode}
                            </Text>
                        </Box>
                        <Box
                            // position={'absolute'}
                            mt='20px'
                            ml={{ sm: "0px", md: "0px", lg: '30px' }}
                            // left='600px'
                            w={{ sm: '100%', md: "50%", lg: '25%' }}
                        >
                            <Text>
                                Country: {country}
                            </Text>
                            <Text
                                mt='10px'
                            >
                                {PayPalEmail}: {paypalemail}
                            </Text>
                        </Box>
                        <Box
                            display={'flex'}
                            flex='0 0 auto'
                            justifyContent={{ lg: 'right' }}
                            textAlign='right'
                            width={{ sm: '100%', md: '50%', lg: '25%' }}

                        >
                            <Button
                                mt='5%'
                                bg='#219691'
                                fontSize={'14px'}
                                color="#FFFFFF"
                                fontWeight={"bold"}
                                colorScheme={'none'}
                                onClick={() => { onOpen() }}
                            >
                                {payoutRequest}
                            </Button>
                        </Box>
                    </Flex>
                    <Payouts paymentDetails={paymentDetails} />
                </Flex>
                :
                <Flex
                    flexDir={'column'}
                >
                    <Grid
                        w='10%'
                        cursor={"pointer"}
                        onClick={() => { setShowSetting(false) }}
                    >
                        <MdOutlineKeyboardBackspace
                            color='#219691'
                            size={"30px"}
                        />
                        <Text
                            color={"#219691"}
                            fontWeight='bold'
                        >
                            {goBack}
                        </Text>
                    </Grid>
                    <BankDetails />
                </Flex>
            }
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                size={"sm"}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader color="#219691">{paymentRequest}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Flex>
                            <Grid w='100%'>
                                <FormControl>
                                    <FormLabel>{availableBalance}</FormLabel>
                                    <Input type='text' isDisabled value={"€10,000.00"} />
                                </FormControl>
                                <FormControl mt='10px'>
                                    <FormLabel>{withdrawalAmount}</FormLabel>
                                    <Input
                                        type='number'
                                        placeholder='Amount'
                                        inputMode="numeric"
                                        required
                                        onChange={(e) => { setAmount(e.target.value) }}
                                        value={amount}

                                    />
                                </FormControl>
                                <FormControl mt='10px'>
                                    <FormLabel>{chooseMethod}</FormLabel>
                                    <Select
                                        placeholder='Select Payment Method'
                                        required
                                        onChange={(e) => { setPaymentType(e.target.value) }}
                                    >
                                        <option value='Paypal'>{PayPal}</option>
                                        <option value='Bank'>{bank}</option>
                                    </Select>
                                </FormControl>
                                <FormControl mt='10px'>
                                    <FormLabel
                                        display={'flex'}
                                        flexDir={'row'}
                                    >{description}
                                        <Text
                                            color='gray'
                                            ml='1'
                                        >
                                            (optional)
                                        </Text>
                                    </FormLabel>
                                    {/* <Input type='text' placeholder='enter description'/> */}
                                    <Textarea
                                        placeholder='Enter Description'
                                        onChange={(e) => { setDesc(e.target.value) }}
                                    />
                                </FormControl>
                                <Button
                                    mt='20px'
                                    color={"#FFFFFF"}
                                    bg='#219691'
                                    colorScheme={"none"}
                                    onClick={() => { sendRequest() }}
                                >
                                    {request}
                                </Button>
                            </Grid>
                        </Flex>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    )
}

export default Header