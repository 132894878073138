import { Button, Flex, Grid, Icon, Image, Text, Select } from '@chakra-ui/react'
import { getProductListInLaunchpad } from 'components/API/AuthAPI';
import Emitter from 'Emitter';
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { ProductLoad } from 'Slices/cartslice';
import { selectProductText, pleaseSelectSellerAcc, select } from 'assets/constants/strings';
import { AddSelectedImage, selectProduct } from 'Slices/launchpadslice';
import "./style.css"

const ChooseProduct = (props) => {
    const [selectedImage, setSelectedImage] = useState();
    const [token, setToken] = useState();
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [id, setId] = useState('');

    const dispatch = useDispatch();

    //store selected product in redux store
    const handleAddProduct = (product) => {
        dispatch(ProductLoad(product));
        dispatch(selectProduct(product));
    };

    Emitter.on('SELLER_ID_FROM_LAUNCHPADPRODUCT', (e) => setId(e));

    useEffect(() => {
        if (!id) {
            let sellerId = sessionStorage.getItem('sellerAccout')
            setId(sellerId);
        }
    }, [])

    useEffect(() => {
        let userToken = localStorage.getItem("token");
        if (userToken) {
            setToken(userToken);
        }
        if (token) {
            getProducts();
        }
    }, [id])


    useEffect(() => {
        products.map((item) => {
            if (item.id == selectedProduct) {
                handleAddProduct(item)
                setSelectedImage(item.product_image);
                Emitter.emit('PRODUCT_ID', item.id);
                sessionStorage.setItem('productId', item.id);
            }
        })
    }, [selectedProduct])

    //load product list
    const getProducts = () => {
        getProductListInLaunchpad(token, id)
            .then((responseJson) => {
                if (responseJson) {
                    setProducts(responseJson.data);
                }
            })
    }

    //select product and close drawer for mobile view
    const handleClick = () => {
        let combinedObject = { "image": selectedImage, "showProduct": true }
        dispatch(AddSelectedImage(combinedObject))
        Emitter.emit('INPUT_FROM_PRODUCT', selectedImage);
        props.onClick();

    }

    return (
        <Flex flexDirection="column">
            <Grid justifyContent="center">
                <Text
                    fontWeight="bold"
                    pt="20px"
                >
                    Products
                </Text>
            </Grid>
            <Grid>
                {id ?
                    <Select
                        mt="20px"
                        className='sellerList'
                        onChange={(e) => {
                            setSelectedProduct(e.target.value)

                        }}
                    >
                    <option>{selectProductText}</option>
                        {products.map((item) => {
                            return <option key={item.id} value={item.id}>{item.product_name}</option>
                        })}
                    </Select>
                    :
                    <Text
                        mt="50px"
                        fontWeight={"bold"}
                        color="red"
                        textAlign="center"
                    >
                        {pleaseSelectSellerAcc}!!!
                    </Text>
                }
            </Grid>
            <Grid
                display="flex"
                flexDirection="column"
                alignItems={{ sm: "center", md: "left", lg: "left" }}
                justifyContent="center"
                pt={{ sm: "30px", md: "30px", lg: "35px" }}

            >
                {selectedImage ?
                    <Grid

                        h="200px"
                        w="250px"
                        border="1px solid black"
                        borderRadius="20%"
                        mb="50px"
                    >
                        <Image
                            src={"https://adminmyboutiqe.progfeel.co.in" + selectedImage}
                            crossOrigin='anonymous'
                            h="198px"
                            w="248px"
                            borderRadius="20%"
                            mb="50px"
                            onClick={() => {
                                handleClick()
                            }
                            }
                        />
                    </Grid>
                    :
                    ""
                }
                {
                    selectedImage ?
                        <Grid>

                            <Button
                                mt="10px"
                                color={"#ffffff"}
                                bg="#219691"
                                colorScheme={"none"}
                                onClick={() => {
                                    handleClick()
                                }
                                }
                            >
                                {select}
                            </Button>
                        </Grid>
                        :
                        ""
                }
            </Grid>

        </Flex>
    )
}

export default ChooseProduct