import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    storeName: '',
    platform: '',
    background: '',
    product: '',
    elementText: '',
    elementImage: '',
    productPrice: 0,
    captionText: '',
    frequency: 0,
    period: '',
    addedImage:'',
    showProd: false,
    backgroundLoader: false,
  };

  const launchpadSlice = createSlice({
    name: "launchpad",
    initialState,
    reducers: {
      productLoad(state, action){
        // console.log("addeditems",action.payload)
        // state.productPrice = action.payload.PRDPrice;
      },
      selectStore(state,action){
        state.storeName = action.payload
      },
      selectPlatform(state,action){
        state.platform = action.payload;
      },
      selectBackground(state,action){
        state.background = action.payload;
      },
      addLoader(state,action){
        state.backgroundLoader = action.payload;
      },
      selectProduct(state,action){
        state.product = action.payload;
      },
      selectElementText(state,action){
        state.elementText = action.payload;
      },
      selectElementImage(state,action){
        state.elementImage = action.payload;
      },
      selectProductPrice(state,action){
        state.productPrice = action.payload;
      },
      selectCaptionText(state,action){
        state.captionText = action.payload;
      },
      selectFrequency(state,action){
        state.frequency = action.payload.postTimes;
        state.period = action.payload.period;

      },
      AddSelectedImage(state, action){
      console.log("selectedImage",action.payload);
        state.addedImage = action.payload.image;
        state.showProd = action.payload.showProduct;
    },
    },
  });
  
  export const { productLoad, selectStore, selectPlatform, selectBackground, selectProduct, selectElementText, selectElementImage,
  selectProductPrice,selectCaptionText, selectFrequency, AddSelectedImage, addLoader} =
  launchpadSlice.actions;
  
  export default launchpadSlice.reducer;