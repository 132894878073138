const fireTracking = (label) => {
  //Fire tracking here
  // console.log(label);
};

export const pwaTrackingListeners = () => {
  const fireAddToHomeScreenImpression = (event) => {
    event.preventDefault(); // Prevent the default "Add to Homescreen" prompt from showing

    // Create a custom pop-up
    const addToHomeScreenPopUp = document.createElement('div');
    addToHomeScreenPopUp.style.cssText = `
      position: fixed;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #FFF;
      color: #000;
      border-radius: 8px;
      padding: 16px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      z-index: 999;
    `;
  
    const popUpText = document.createElement('p');
    popUpText.innerText = 'Would you like to install this app?';
    const btns = document.createElement('div');
    btns.style.cssText = `
    display: flex;
    flex-direction: row;
    justify-content: right;
    margin-top: 5px;
  `;
    const cancelButton = document.createElement('button');
    cancelButton.style.cssText = `
      
      color: #FFFFFF;
      background-color: red;
      padding: 2px 5px 2px 5px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    `;
    cancelButton.innerText = 'Cancel';
    cancelButton.addEventListener('click', () => {
      addToHomeScreenPopUp.remove(); // Remove the custom pop-up if the user cancels
    });
  
    const installButton = document.createElement('button');
    installButton.style.cssText = `
    margin-left: 10px;
    color: #FFFFFF;
    background-color: #219691;
    padding: 2px 5px 2px 5px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  `;
    installButton.innerText = 'Install';
    installButton.addEventListener('click', () => {
      addToHomeScreenPopUp.remove(); // Remove the custom pop-up after the user clicks "Install"
      event.prompt(); // Show the default "Add to Homescreen" prompt
      event.userChoice.then((choiceResult) => {
        // Track the user's choice
        fireTracking(`User clicked ${choiceResult}`);
      });
    });
  
    addToHomeScreenPopUp.appendChild(popUpText);
    addToHomeScreenPopUp.appendChild(btns);
    btns.appendChild(cancelButton);
    btns.appendChild(installButton);
  
    document.body.appendChild(addToHomeScreenPopUp); // Add the custom pop-up to the DOM
    // fireTracking("Add to homescreen shown");
    // //will not work for chrome, untill fixed
    // event.userChoice.then((choiceResult) => {
    //   fireTracking(`User clicked ${choiceResult}`);
    // });
    // //This is to prevent `beforeinstallprompt` event that triggers again on `Add` or `Cancel` click
    // window.removeEventListener(
    //   "beforeinstallprompt",
    //   fireAddToHomeScreenImpression
    // );
  };
  window.addEventListener("beforeinstallprompt", fireAddToHomeScreenImpression);

  //Track web app install by user
  window.addEventListener("appinstalled", (event) => {
    fireTracking("PWA app installed by user!!! Hurray");
  });

  //Track from where your web app has been opened/browsed
  window.addEventListener("load", (event) => {
    let trackText;
    if (navigator && navigator.standalone) {
      trackText = "Launched: Installed (iOS)";
    } else if (matchMedia("(display-mode: standalone)").matches) {
      trackText = "Launched: Installed";
    } else {
      trackText = "Launched: Browser Tab";
    }
    fireTracking(trackText);
  });
};