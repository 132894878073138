import { Button, Flex, Grid, Text } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import LaunchpadTable1 from './Components/LauchpadTable1'
import LaunchpadTable from './LaunchpadTable'

const LauchpadDetails = () => {
  let history = useHistory();
  return (
    <Flex
      direction="column"
      pt={{ base: "70px", md: "75px", lg: "75px" }}
      pl='10px'
      pr='10px'
    >
      <Flex justifyContent={"space-between"}>
        <Grid>
      <Text
        color="#219691"
        fontWeight={"bold"}
        fontSize={{ lg: "24px" }}
        mt="20px"
      >
        LAUNCHPAD
      </Text>
      </Grid>
      <Grid>
        <Button 
          color='#ffffff'
          bg='#219691'
          mt='20px'
          mr='20px'
          colorScheme='none'
          onClick={(e)=>{history.push("/launchpad")}}
        >
          New Campaign
        </Button>
      </Grid>
      
      </Flex>
      <Grid
        templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
        gap='0px'
        mt='10px'  
      >

        <LaunchpadTable />
      </Grid>
    </Flex>
  )
}

export default LauchpadDetails