import React, { useState, useRef } from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    useToast,
    ChakraProvider,
    Portal,
    Alert,
    AlertIcon
} from "@chakra-ui/react";
// Assets
import { useHistory } from "react-router-dom";
import AuthNavbar1 from "components/Navbars/SearchBar/AuthNavbar1";


function VerifyEmail() {
    const [email, setEmail] = useState();
    let history = useHistory();
    const navRef = React.useRef();
    const wrapper = React.createRef();
    const toast = useToast();


    const handleChange = () => {
        toast({
            position: 'top',
            render: () => (
                <Box
                    color='white'
                    p={3} bg='#219691'
                    textAlign={'center'}
                    fontWeight='bold'
                >
                    Please Enter your email
                </Box>
            ),
        })
    }


    return (
        <ChakraProvider>

            <Box ref={navRef} w="100%" h="100%" bg="#f7f8f9">
                <Portal containerRef={navRef}>
                    <AuthNavbar1 />
                </Portal>
                <Box w="100%">
                    <Box ref={wrapper} w="100%">

                        <Flex>
                            <Flex
                                h="100vh"
                                w='100%'
                                maxW='1144px'
                                mx='auto'
                                justifyContent='center'
                            >
                                <Flex
                                    alignItems={{md:'center',lg:'center'}}
                                    justifyContent='center'
                                    userSelect='none'
                                    // h={{base:"0%"}}
                                    w={{ base: "100%", md: "50%", lg: "42%" }}>
                                    <Flex
                                        direction='column'
                                        w='100%'
                                        h='20rem'
                                        maxWidth={"380px"}
                                        background={{base:'#FFFFFF',md:"#FFFFFF",lg:'#FFFFFF'}}
                                        // boxShadow={{ sm: "none", md: "5px 5px 10px 5px #94D0CD", lg: "5px 5px 10px 5px #94D0CD" }}
                                        border={{ sm: "none", md: "1px solid #EEE", lg: "1px solid #EEE" }}
                                        borderRadius={{ base: "10px", md: "10px", lg: "10px" }}
                                        p='25px'
                                        // mt='10rem'
                                        mt={{ base:'10rem',md: "100px", lg: "80px" }}
                                        >

                                        <Heading
                                            fontSize='lg'
                                            mb='50px'
                                        >
                                            Verify Email
                                        </Heading>
                                        <FormControl>

                                            <Input
                                                borderRadius='15px'
                                                bg="#F4F5F9"
                                                color="#4f5d77"
                                                mb='15px'
                                                fontSize='sm'
                                                type='text'
                                                placeholder='Email'
                                                _placeholder={{ color: 'inherit' }}
                                                textAlign="center"
                                                size='lg'
                                                onChange={(e) => setEmail(e.target.value)}
                                            />

                                            <Button
                                                fontSize='sm'
                                                type='submit'
                                                bg='#219691'
                                                w='100%'
                                                h='45'
                                                mb='20px'
                                                color='white'
                                                mt='20px'
                                                _hover={{
                                                    bg: "#219691",
                                                }}
                                                _active={{
                                                    bg: "teal.400",
                                                }}
                                                onClick={handleChange}
                                            >
                                                Verify
                                            </Button>
                                        </FormControl>



                                    </Flex>
                                </Flex>


                            </Flex>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </ChakraProvider>
    );
}

export default VerifyEmail;
