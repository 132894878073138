import { CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

const GalleryBanner = (props) => {
    const [showbanner,setShowbanner] = useState(true);

    useEffect(()=>{
        const banner = sessionStorage.getItem("galleryBanner");
        if(banner){
            // console.log(banner);
            setShowbanner(false)
        }
    },[])
    
    const handleClose=()=>{
        setShowbanner(false);
        sessionStorage.setItem("galleryBanner",false);
    }
  return (
    <Flex
        display={showbanner ? 'flex' : 'none'}
    >
        <Flex
                id='pop_up_banner'
                style={{ display: props.disp ? 'flex' : 'none' }}
                position='fixed'
                top='80px'
                width={'100%'}
                zIndex={5}
            >
                <Box
                    className='banner_popup'
                    width='100%'
                    height='50px'
                    bg='#000000'
                    alignItems={'center'}
                    position='relative'
                >
                    <Text
                        color='#FFFFFF'
                        textAlign={'center'}
                        marginTop='10px'
                        fontWeight={'bold'}
                    >
                        {props.bannerText}
                    </Text>
                    <Flex
                        borderRadius='50%'
                        background="#FFFFFF"
                        w='20px'
                        h='20px'
                        position='absolute'
                        top='25%'
                        right='10px'
                        cursor='pointer'
                        zIndex={10}
                        onClick={()=>{handleClose()}}
                    >
                        <CloseIcon 
                            color='#00000' 
                            height={'10px'} 
                            margin={'0 auto'} 
                            mt='5px'     
                        />
                    </Flex>
                </Box>
            </Flex>
    </Flex>
  )
}

export default GalleryBanner