import React from 'react'
import { Center, Flex, Text, Image, Box, Button } from '@chakra-ui/react'
import { BsFacebook, BsPinterest } from 'react-icons/bs'
import { FaTwitterSquare, FaInstagramSquare } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { AiFillHeart } from 'react-icons/ai'
import './affiliateemailconformation.css'


function BuyerReturnConformation(props) {


    return (
        <div className='returConformation_wrapper' >
            <Center>

                <Flex direction='column' pt={{ base: "100px", md: "60px" }}>
                    <Flex fontSize='4xl' fontWeight='bold' flex='auto' justifyContent='center'
                        color='#219691'
                    >MY BOUTIQ</Flex>
                    <Text fontSize='2xl' fontWeight='bold' mt='10'>Return Conformation</Text>
                    <Text mt='5'>
                        Dear (buyer's first name),
                    </Text>
                    <Text>
                        Thank you for your order. We will process it immediately.
                    </Text>
                    <Text fontSize='2xl' fontWeight='bold' mt='10'>Order Information</Text>
                    <Text mt='5'>
                        Order Date:
                    </Text>
                    <Text>
                        Order ID:
                    </Text>
                    <Text>
                        Your Account: Log-in
                    </Text>
                    <p style={{ border: "1px solid black", width: "100%", marginTop: "20px" }}></p>


                    <Flex mt='5'
                        className='buyer_product_details'
                        gap={{ sm: "10", md: '20', lg: "64" }}
                    >
                        <Flex flexDirection='column' gap='5' mr='20'>
                            <Text
                                fontSize='xl'
                                fontWeight='bold'
                            >
                                Return order to
                            </Text>
                            <Text fontSize='xl' fontWeight='bold'>Seller Company Name</Text>
                            <Text fontSize='xl'>Address Line 1</Text>
                            <Text fontSize='xl'>Address Line 2</Text>
                            <Text fontSize='xl'>Post Code + City</Text>
                            <Text fontSize='xl'>Country</Text>
                        </Flex>

                        <Flex flexDirection='column' gap='5'>
                            <Text
                                fontSize='xl'
                                fontWeight='bold'
                            >
                                Your Shipping Address
                            </Text>
                            <Text fontSize='xl' fontWeight='bold'> First Name + Last Name</Text>
                            <Text fontSize='xl'>Address Line 1</Text>
                            <Text fontSize='xl'>Address Line 2</Text>
                            <Text fontSize='xl'>Post Code + City</Text>
                            <Text fontSize='xl'>Country</Text>
                        </Flex>
                        <Flex flexDirection='column'
                            gap='5'
                        >
                            <Text
                                fontSize='xl'
                                fontWeight='bold'
                            >
                                Your Payment Method
                            </Text>
                            <Text fontSize='xl' fontWeight='bold'>Name of payment method</Text>
                        </Flex>
                    </Flex>
                    <p style={{ border: "1px solid black", width: "100%", marginTop: "20px" }}></p>
                    <Text fontSize='2xl' fontWeight='bold' mt='5'>
                        Your Return Order
                    </Text>
                    <Flex mt='10' justifyContent='space-between' className='buyer_product_details'>
                        <Flex gap={{ sm: "5", md: "10", lg: "20" }}>
                            <Image
                                // w={{ sm: '100%', md: '100%', lg: '100%' }}
                                w='30%' h='100%'
                                src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeujuRNmNmq07rLZjcdSSYCW3DEXAycbGHkONmpeUx&s'
                            />
                            <Text>1X</Text>
                        </Flex>

                        <Flex flexDirection='column' justifyContent='center'
                            mr={{ sm: "10", md: "20", lg: "36" }}
                            className='buyer_product_details'>
                            <Text>
                                PRODUCT NAME
                            </Text>
                            <Text>
                                Brand name (if applicable)
                            </Text>
                            <Text>
                                Variant option (if applicable)
                            </Text>
                            <Text>
                                Product ID: 23456765
                            </Text>
                        </Flex>
                        <Text >
                            100,00 EUR
                        </Text>
                    </Flex>
                    <Flex mt='10' justifyContent='space-between' className='buyer_product_details'>
                        <Flex gap={{ sm: "5", md: "10", lg: "20" }}>
                            <Image
                                // w={{ sm: '100%', md: '100%', lg: '100%' }}
                                w='30%' h='100%'
                                src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeujuRNmNmq07rLZjcdSSYCW3DEXAycbGHkONmpeUx&s'
                            />
                            <Text>1X</Text>
                        </Flex>

                        <Flex flexDirection='column' justifyContent='center' mr={{ sm: "10", md: "20", lg: "36" }}>
                            <Text>
                                PRODUCT NAME
                            </Text>
                            <Text>
                                Brand name (if applicable)
                            </Text>
                            <Text>
                                Variant option (if applicable)
                            </Text>
                            <Text>
                                Product ID: 23456765
                            </Text>
                        </Flex>

                        <Text >
                            100,00 EUR
                        </Text>



                    </Flex>

                    <p style={{ border: "1px solid black", width: "100%", marginTop: "20px" }}></p>
                    <Flex justifyContent='flex-end' mt='10' gap='20' mb='50'>
                        <Text fontWeight='bold'>Total</Text>
                        <Text>20000 EUR</Text>
                    </Flex>
                    <p style={{ border: "1px solid black", width: "100%", marginTop: "50px" }}></p>
                    <Text mt='10' fontWeight='bold' fontSize='2xl'>
                        Thank you for your order!
                    </Text>
                    <Text mt='5' maxW='70rem' fontSize='xl'>
                        We do understand that sometimes people change their mind. If you have questions about your order, then contact the us: (Seller's info email)
                        If you wish to return your order click here.
                    </Text>
                    <Text mt='10' fontWeight='bold' color='#219691' fontSize='xl'>
                        Join our Newsletter. Sign up now!
                    </Text>
                    <Box maxW='30rem' mt='5' fontSize='xl'>
                        Get access to our best events, deals, tips & tricks.
                        No spam, we hate it more than you do.
                    </Box>
                    <Center display='flex' mt='20'>
                        <BsFacebook size='3%' />
                        <FaInstagramSquare size='3%' style={{ marginLeft: "10px" }} />
                        <BsPinterest size='3%' style={{ marginLeft: "10px" }} />
                        <FaTwitterSquare size='3%' style={{ marginLeft: "10px" }} />
                    </Center>

                    <Center display='flex' flexDirection='column' mt='10' mb='20'>
                        <Text>
                            Sent with <AiFillHeart style={{ display: "initial" }} /> from MY BOUTIQ
                        </Text>
                        <Text>
                            Terms & Conditions | Imprint | Data Privacy
                        </Text>

                    </Center>

                </Flex>

            </Center>


        </div>
    )
}


export default BuyerReturnConformation;