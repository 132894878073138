// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Drawer,
  Flex,
  Grid,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { MdLogout } from "react-icons/md"
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import routes from "routes.js";
import ReactFlagsSelect from "react-flags-select";
import Emitter from "Emitter";
import logo from "../../assets/svg/logo1.png"


export default function HeaderLinks(props) {
  const [selected, setSelected] = useState("GB");
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  useEffect(() => {
    localStorage.setItem("language", selected);
    Emitter.emit("LANGUAGE", selected);
  }, [selected])

  // Chakra Color Mode
  let navbarIcon = useColorModeValue("gray.500", "gray.200");

  if (secondary) {
    navbarIcon = "white";
  }

  const handleLogout = (e) => {
    localStorage.removeItem("token");
    localStorage.removeItem("display_name");
    window.location.reload();


  }

  return (
    <Flex
      pt={{ sm: "10px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >

      <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        {...rest}

      />
      <Flex
        display={{ sm: "flex", md: "flex", lg: "none" }}
        margin="0 auto"
      >
        <Image
          margin='0 auto'
          textAlign="center"
          justifyContent={'center'}
          h='40px'
          src={logo}
        />

      </Flex>





      <Image
        display={{ sm: "none", md: "none", lg: "flex" }}
        margin='0 auto'
        textAlign="center"
        justifyContent={'center'}
        h='50px'
        src={logo}
      />
      {/* </Flex> */}

      <Flex
        // position={"fixed"} 
        // display="inline-flex"
        right={"0px"} >
        <Grid
          w="135px"
          mr='30px'
          display={{ sm: "none", md: "none", lg: "flex" }}

        >
          <ReactFlagsSelect
            selected={selected}
            countries={["BG", "CN", "CZ", "DA", "NL", "US", "GB", "ET", "FI", "FR", "DE", "EL", "HU", "ID", "IT", "JA", "LV", "LT", "PL", "PT", "RO", "RU", "SK", "SL", "ES", "SV", "TS", "UK"]}
            customLabels={{ BG: "BG", CN: "CN", CZ: "CZ", DA: "DA", NL: "NL", US: "EN-US", GB: "EN-GB", ET: "ET", FI: "FI", FR: "FR", DE: "DE", EL: "EL", HU: "HU", ID: "ID", IT: "IT", JA: "JA", LV: "LV", LT: "LT", PL: "PL", PT: "PT", RO: "RO", RU: "RU", SK: "SK", SL: "SL", ES: "ES", SV: "SV", TS: "TS", UK: "UK" }}
            onSelect={(countries) => setSelected(countries)}
            placeholder="Select Language"
          />
        </Grid>
        <Grid>
          <Button
            color={navbarIcon}
            variant="transparent-with-icon"
            onClick={(e) => { handleLogout(e) }}
            ml="5px"
            leftIcon={
              document.documentElement.dir ? (
                <MdLogout color={navbarIcon} w="22px" h="22px" me="0px" />
              ) : (
                ""
              )
            }
          >
            <Link to='/signin'>
              <Text
                display={{ sm: "none", md: "none", lg: "flex" }}
              >
                Logout
              </Text>
            </Link>
          </Button>
        </Grid>
      </Flex>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
