import React, { useEffect, useState } from 'react'
import { Flex, Avatar, Button, Center, Image } from '@chakra-ui/react'
import './productgallery.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { viewGalleryText } from 'assets/constants/strings';

export function ProductGallery(props) {
    let history = useHistory();
    const [background, setBackground] = useState()

    useEffect(() => {
        if (props.backgroundProfile) {
            setBackground(props.backgroundProfile);
        }
        else {
            let image = localStorage.getItem('backgroundProfile');
            setBackground(JSON.parse(image));
        }
    }, [props.backgroundProfile])


    return (
        <>
            <Flex 
                mt='0' 
                position='relative'
                w='100%'    
            >
                <Flex
                    p='10'
                    className='gallery_wrapper'
                    direction={{ sm: "column", md: "row" }}
                    w='100%'
                >
                    {
                        background ? background.map((image, index) => {
                            return (
                                <Flex
                                    key={index}
                                    width='100%'
                                >
                                    <Image className='product_gallery'
                                        borderRadius='0px'
                                        src={'https://adminmyboutiqe.progfeel.co.in' + (image.store_background_image)}
                                        w='100%'
                                        h={{ sm: "200px", md: '300px', xl: "370px" }}
                                    />
                                    <Flex pos='absolute' top={{sm:"70%",md:"75%",lg:"80%"}} right={{sm:"35%",md:'45%',lg:"50%"}}>
                                    <Button
                                        colorScheme='none'
                                        bg="#219691"
                                        mb='3'
                                        margin="0 auto"
                                        borderRadius='20px'
                                        // className='product_gallery_img'
                                        onClick={() => { history.push("/gallery") }}
                                        fontSize={{ sm: '14px', md: '16px', lg: '18px' }}
                                    >
                                        {viewGalleryText}
                                    </Button>
                                    </Flex>
                                </Flex>
                            )
                        })
                            :
                            <Flex
                                mb='10'
                            >
                                <Button
                                    colorScheme='none'
                                    bg="#219691"
                                    mb='3'
                                    borderRadius='20px'
                                    className='product_gallery_img'
                                    onClick={(e) => { history.push("/gallery") }}
                                    fontSize={{ sm: '14px', md: '16px', lg: '18px' }}
                                >
                                    MY BOUTIQ GALLERY
                                </Button>
                            </Flex>
                    }
                </Flex>

            </Flex>
        </>
    )
}


