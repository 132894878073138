import React, { useState, useEffect } from 'react'
import { Center, Flex, Box, Text, Input, Button, useToast, FormControl, ChakraProvider } from '@chakra-ui/react'
import { newsLetter } from '../API/AuthAPI'
import { Translate } from 'components/DeepL/Translate';
import theme from 'theme/theme';

export function NewsLetter(props) {

    const [token, setToken] = useState();
    const [email, setEmail] = useState('')
    const toast = useToast();

    useEffect(() => {
        const checkUserLogin = () => {
            let userToken = localStorage.getItem("token");
            setToken(userToken);
        }

        checkUserLogin();
    }, [token]);




    // const { firstname, lastname, email, phoneno, city, houseno, postcode } = state

    const getNewsLettermail = (e) => {
        setEmail(e.target.value)
    }



    const getEmailFromNews = (e) => {
        e.preventDefault()
        newsLetter(token, email).then(responseJson => {
            if (responseJson) {
                if (responseJson.email) {
                    // alert("Please enter email")
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                Please enter your email-id
                            </Box>
                        ),
                    })
                }
                else if (responseJson['message'] == "subscription get successfully") {
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {responseJson['message']}
                            </Box>
                        ),
                    })
                }
                // alert(responseJson['message'])

                // console.log('responseJson', responseJson)
            }
        })

    }

    return (
        <ChakraProvider theme={theme} resetCSS={false}>
            <Flex>
                {/* <Center mt='5' > */}

                <Flex
                    w={{ sm: "100%", lg: "100%", xl: "100%" }}
                    justifyContent='center'
                >
                    <Box 
                        m='5' 
                        mb='20' 
                        w='400'
                    >
                        <Text
                            // textAlign={'center  '}
                            fontWeight='bold'
                            // ml='5'
                            fontSize={{ sm: '15px', md: '16px', lg: '20px' }}
                        // className='newsLetter_Heading'

                        >
                            Join our Newsletter
                        </Text>
                        <Text
                            mt='1'
                            // ml='5'
                            fontWeight='bold'
                            fontSize={{ sm: '13px', md: '14px', lg: '16px' }}
                        >
                            Get access to our best events, deals, tips & tricks
                        </Text>


                        <Flex
                            // gap='10' 
                            mt='3'
                            flexDir='row'
                        >
                            <form action="" onSubmit={getEmailFromNews}
                            >
                                <FormControl
                                    display='flex'
                                    flexDir='row'
                                >
                                    <Input
                                        onChange={getNewsLettermail}
                                    />
                                    <Button
                                        type='submit'
                                        ml='5'
                                        colorScheme='none'
                                        bg="#219691"
                                        size='lg'
                                        color='white'
                                        // className='product_join_newsletter'
                                        fontSize={{ sm: '10px', md: '12px', lg: '14px' }}
                                        width={{ sm: '70px', md: '80px', lg: '100px' }}
                                        height={{ sm: '30px', md: '35px', lg: '40px' }}
                                    >
                                        Join Now
                                    </Button>
                                </FormControl>
                                <Text
                                    mt='5'
                                    fontSize={{ sm: '14px', md: '14px', lg: '16px' }}
                                >
                                    No spam, we hate it more than you do.
                                </Text>
                            </form>

                        </Flex>

                    </Box>

                </Flex>

            </Flex>

        </ChakraProvider>
    )
}
