import { useEffect, useRef, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { getPaymentKeys } from "components/API/AuthAPI";
import { PaymentIntent } from "components/API/AuthAPI";
import ApplePay from "./ApplePay";
import GooglePay from "./GooglePay";
import { Flex } from "@chakra-ui/react";
import Emitter from "Emitter";
import { useDispatch, useSelector } from "react-redux";

function Payment(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [transactionIds, setTransactionIds] = useState([]);
  const [payment_id, setPayment_id] = useState();
  const oldIntent = useRef()
  const cart1 = useSelector((state) => state.cart);

  useEffect(() => {
    getPaymentKeys()
      .then((responseJson) => {
        if (responseJson) {
          console.log("res", responseJson)
          let publishableKey = responseJson.stripe.publicKey
          setStripePromise(loadStripe(publishableKey));
          let currency = localStorage.getItem("currencyName");
          paymentKey(cart1.cartTotalAmount, currency);
        }
      })

  }, []);


  const paymentKey = (amt, currency) => {

    PaymentIntent(amt, currency)
      .then((responseJson) => {
        if (responseJson) {
          let ck = responseJson.intent.client_secret;
          setClientSecret(ck);
          setTransactionIds(responseJson);
          console.log('payment intent', responseJson);
          oldIntent.current = payment_id;
          setPayment_id(responseJson.intent.id)
        }
      });

  }

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <Flex w='100%'>
            <CheckoutForm
              token={props.token}
              getCartId={props.getCartId}
              mail={props.mail}
              first_name={props.first_name}
              last_name={props.last_name}
              location={props.location}
              address={props.address}
              city={props.city}
              code={props.code}
              country_code={props.country_code}
              number={props.number}
              comment={props.comment}
              transactionIds={transactionIds}
              payment_id={payment_id}
              country={props.country}
            />
          </Flex>

          {/* <ApplePay/> */}
          {/* <GooglePay/> */}
        </Elements>
      )}
    </>
  );
}

export default Payment;