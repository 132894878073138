import React, { useEffect } from 'react';
import { Flex,Grid,Text} from "@chakra-ui/react";
import OrderTable from './components/OrderTable';
import { useHistory } from 'react-router-dom';


const AffiliateOrders = () => {
  let history = useHistory();

  useEffect(() => {
    checkUserLogin();
  }, [])

  const checkUserLogin = () => {
    let userToken = localStorage.getItem("token");

    if (!userToken) {
      history.push({
        pathname: "/"
      });
    }
  }

  return (
    <Flex
      direction='column'
      pt={{ base: "70px", md: "75px" }}
      pl='10px'
      pr='10px'
    >
      <Grid
        templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
        gap='0px'>

        <Flex direction='column' pt={{ base: "0px", md: "50px", lg: "0px" }}>
          <Grid
            display="flex"
            px="20px"
            justifyContent="left"
          >
            <Text
              color="#219691"
              fontWeight={"bold"}
              fontSize={{ lg: "24px" }}
              py="20px"
            >
              {/* { Translate("ORDERS") ?  Translate("ORDERS") : "ORDERS"} */}
              ORDERS
            </Text>
          </Grid>
        </Flex>

        <OrderTable />

      </Grid>

    </Flex>
  )
}

export default AffiliateOrders