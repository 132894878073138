import React from 'react'
import { Image, Center, Text, Flex, Box, Button, ChakraProvider } from '@chakra-ui/react'
import { BsFacebook, BsPinterest } from 'react-icons/bs'
import { FaTwitterSquare, FaInstagramSquare } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { AiFillHeart } from 'react-icons/ai'


function Registration(props) {


    return (
        <div className='registration_wrapper'>
            <Center>

                <Flex direction='column' pt={{ base: "100px", md: "60px" }}>
                    <Center>
                        <Text fontSize='3xl' fontWeight="bold">MY BOUTIQ</Text>
                    </Center>

                    <Center mt='10'>

                        <Image
                            // w={{ sm: '100%', md: '100%', lg: '100%' }}
                            w='70vh' h='250px'
                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeujuRNmNmq07rLZjcdSSYCW3DEXAycbGHkONmpeUx&s'
                        />
                    </Center>

                    <Center mt='10'>
                        <Box maxW='43rem'>
                            <Text mt='5' fontSize='2xl'>  Hi Michael,  </Text>
                            <Text fontSize='xl' mt='5'>
                                Welcome to MY BOUTIQ, glad you're here! The first thing you need to do is confirm your email address.
                                Please click on the following link to complete your registration:
                            </Text>

                        </Box>
                    </Center>
                    <Center>


                        <Link to='/affilateInvite'>
                            <Button
                                mt='16'
                                bg='#219691' size='lg'
                                colorScheme='none'
                                w={{ lg: "sm" }}
                            >
                                CONFIRM EMAIL
                            </Button>
                        </Link>



                    </Center>




                    <Center display='flex' mt='10'>
                        <BsFacebook size='3%' />
                        <FaInstagramSquare size='3%' style={{ marginLeft: "10px" }} />
                        <BsPinterest size='3%' style={{ marginLeft: "10px" }} />
                        <FaTwitterSquare size='3%' style={{ marginLeft: "10px" }} />
                    </Center>


                    <Center display='flex' flexDirection='column' mt='10'>
                        <Text>
                            Sent with <AiFillHeart style={{ display: "initial" }} /> from MY BOUTIQ
                        </Text>
                        <Text>
                            Imprint | Data Privacy
                        </Text>
                        <Text>
                            Unsubscribe
                        </Text>
                    </Center>


                </Flex>

            </Center>

        </div>
    )
}

export default Registration