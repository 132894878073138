import React, { useEffect, useState } from 'react'
import { Image, Center, Text, Flex, Box, Avatar, Button, ChakraProvider } from '@chakra-ui/react'
import { BsFacebook, BsPinterest } from 'react-icons/bs'
import { FaTwitterSquare, FaInstagramSquare } from 'react-icons/fa'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { AiFillHeart } from 'react-icons/ai'
import { acceptAffiliateInvitation } from 'components/API/AuthAPI';



function AffiliateInvitation() {
    const [affiliate_token, setAffiliate_token] = useState();
    let location = useLocation();
    let history = useHistory();

    useEffect(()=>{
        let token = location.pathname;

        let fields = token.split('/');
    
        let newToken = fields[2];
        console.log("newPath-----",newToken);
        setAffiliate_token(newToken);
    },[affiliate_token]);

    // const accept=()=>{
    //     acceptAffiliateInvitation(token)
    //     .then((responseJson)=>{
    //         if(responseJson){
    //             console.log(responseJson);
    //             if(responseJson["message"]){
    //                 alert(responseJson[message]);
    //             }
    //         }
    //     })
    // }

    const handleSubmit=()=>{
        history.push({
            pathname: "/signup",
            state: {affiliate_token}
          })
    }

    return (
        <div className='affiliate_invitation-wrapper'>
            <Center>

                <Flex direction='column' pt={{ base: "100px", md: "60px" }}>
                    <Center>
                        <Text fontSize='3xl' fontWeight="bold">MY BOUTIQ</Text>
                    </Center>

                    <Center mt='10' display='flex' flexDirection='column'>

                        <Image
                            // w={{ sm: '100%', md: '100%', lg: '100%' }}
                            w='70vh' h='250px'
                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeujuRNmNmq07rLZjcdSSYCW3DEXAycbGHkONmpeUx&s'
                        />
                        <Avatar src='https://bit.ly/broken-link'
                            mt='5'
                            w='9%'
                            h='9%'
                        />
                    </Center>


                    <Center mt='5'>
                        <Box maxW='43rem'>
                            <Text mt='5' fontSize='2xl'>Micheal has invited you to join his team on MY BOUTIQ as a business partner.  </Text>
                            <Text fontSize='2xl' mt='5'>
                                MY BOUTIQ is the world's first affiliate network platform,
                                where you can onboard any company of your choice to help grow their business.
                            </Text>

                        </Box>
                    </Center>
                    <Center>


                        {/* <Link to='/confirmEmail'> */}
                            <Button
                                mt='16'
                                colorScheme='none'
                                bg='#219691' size='lg'

                                w={{ lg: "sm" }}
                                onClick={handleSubmit}
                            >
                                JOIN NOW & START TODAY
                            </Button>
                        {/* </Link> */}



                    </Center>




                    <Center display='flex' mt='10'>
                        <BsFacebook size='3%' />
                        <FaInstagramSquare size='3%' style={{ marginLeft: "10px" }} />
                        <BsPinterest size='3%' style={{ marginLeft: "10px" }} />
                        <FaTwitterSquare size='3%' style={{ marginLeft: "10px" }} />
                    </Center>


                    <Center display='flex' flexDirection='column' mt='10'>
                        <Text>
                            Sent with <AiFillHeart style={{ display: "initial" }} /> from MY BOUTIQ
                        </Text>
                        <Text>
                            Imprint | Data Privacy
                        </Text>
                        <Text>
                            Unsubscribe
                        </Text>
                    </Center>


                </Flex>

            </Center>


        </div>
    )
}

export default AffiliateInvitation
