import { Image } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';

const LazyImage = ({ src, alt }) => {
    const [imageSrc, setImageSrc] = useState('');
    const imageRef = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setImageSrc(src);
                    observer.unobserve(imageRef.current);
                }
            });
        });

        observer.observe(imageRef.current);

        return () => {
            observer.unobserve(imageRef.current);
        };
    }, [src]);

    return (
        <Image
            src={imageSrc}
            alt={alt}
            ref={imageRef}
            w={{ sm: "100px", lg: "125px" }}
            h={{ sm: "150px", lg: "125px" }}
            // pl="1"
            borderRadius="20px"
            // colorScheme={"teal"}
            cursor={'pointer'}
        />
    );
};

export default LazyImage;