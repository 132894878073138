import translate from "deepl";
import Emitter from "Emitter";
import { useEffect, useState } from "react";

export const Translate = (input) => {
  const [translatedText, setTranslatedText] = useState();
  const [language, setLanguage] = useState("EN");
  const [selectedLanguage, setSelectedLanguage] = useState("EN");

  useEffect(()=>{
    setSelectedLanguage(localStorage.getItem('language'))
  },[language])

  Emitter.on("LANGUAGE", (lang) => setLanguage(lang));
  useEffect(()=>{
  translate({
    free_api: true,
    text: input,
    target_lang: selectedLanguage,
    auth_key: '3fc0b8d8-c005-8178-ab59-cb974faba127:fx',
    // 3fc0b8d8-c005-8178-ab59-cb974faba127:fx
  })
    .then(result => {
      setTranslatedText(result.data.translations[0].text)
      return result.data.translations[0].text;

    })
    .catch(error => {
      // console.error(error)
    });
  },[input])

  return translatedText

}
