import React from 'react';
import { Flex, Text, AspectRatio, Image, Button, Heading } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import './homepage.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IoHeartDislikeCircleOutline } from 'react-icons/io5';

const ProductList = (props) => {
    let history = useHistory();
    return (
        <div>
            <Flex flexDirection='column'
                maxW='550px'
            >
                {props.ele.available == 0 ?
                    <Text
                        position='absolute'
                        fontSize='xl'
                        textAlign='center'
                        className='product_sold_out'
                        zIndex={1}
                    >
                        SOLD OUT
                    </Text>
                    : ""
                }
                <Flex position={'relative'}>
                    <AspectRatio w='550px' ratio={9 / 10.5} >
                        <Image
                            className='homepageProduct'
                            src={"https://adminmyboutiqe.progfeel.co.in/" + props.ele.product_image}
                            alt='Dan Abramov'
                            borderRadius='none'
                            cursor={'pointer'}
                            onClick={() => {
                                let params = { selectedProduct: props.ele.id }
                                history.push({
                                    pathname: `/product-details/${props.ele.id}`,
                                    state: { params }
                                })
                            }
                            }
                        />
                    </AspectRatio>
                    {props.ele.product_tag ?
                        <Flex
                            position='absolute'
                            justifyContent="center"
                            alignItems={'center'}
                            zIndex={2}
                            bg='#bebebe'
                            border={'1px solid black'}
                            maxW={{ sm: "80px", lg: '100px' }}
                            w='100%'
                            h={{ sm: "30px", lg: '40px' }}
                            bottom="1px"
                            right='1px'
                        >
                            <Text
                                fontWeight={'bold'}
                                color="#219691"
                                fontSize={{ sm: "14px", lg: '18px' }}
                            >
                                {props.ele.product_tag}
                            </Text>
                        </Flex>
                        :
                        ""
                    }

                </Flex>
                {/* </Link> */}

                <Card w='480px'
                    bg='none'
                    className='home_card_container'>
                    <Flex
                        flexDirection='column'
                        className='home_wrapper_product'>
                        <Text fontWeight='bold'
                            className='home_product_name'
                        >
                            {props.ele.product_name}
                        </Text>
                        <Flex className='home_wrap_content'
                            justifyContent='space-between'
                            alignItems='center'>
                            <Flex
                                className='product_price_gap'
                                display={{ sm: "none", md: "none", lg: "flex" }}
                            >
                                {
                                    props.ele.PRDDiscountPrice ?
                                        <Text
                                            ml='1'
                                            textDecoration='line-through'
                                            fontWeight='bold'
                                            className='home_product_price'
                                        >
                                            {(Number(props.ele.PRDDiscountPrice) * (props.currencyExchange ? props.currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{props.currencyIcon ? props.currencyIcon : "€"}
                                        </Text>
                                        : ""
                                }
                                {
                                    props.ele.PRDDiscountPrice ?
                                        <Text
                                            ml='3'
                                            fontWeight='bold'
                                            className='home_product_price'

                                            color="#219691"
                                        >
                                            {(Number(props.ele.PRDPrice) * (props.currencyExchange ? props.currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} {" "}{props.currencyIcon ? props.currencyIcon : "€"}
                                        </Text>
                                        :
                                        <Text
                                            ml='3'
                                            fontWeight='bold'
                                            className='home_product_price'
                                        >
                                            {(Number(props.ele.PRDPrice) * (props.currencyExchange ? props.currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{props.currencyIcon ? props.currencyIcon : "€"}
                                        </Text>

                                }
                                <Flex

                                    display={'flex'}
                                    pos='absolute'
                                    right={0}
                                    mt='-5px'
                                    justifyContent='right'
                                >
                                    <Button
                                        borderRadius='20px'
                                        colorScheme='none'
                                        className='buy_now_btn'
                                        bg='#219691'
                                        fontSize={{ sm: "12px", md: "14px", lg: "16px" }}
                                        onClick={() => {
                                            history.push(`/product-details/${props.ele.id}`)
                                        }}
                                    >
                                        <span>
                                            Buy Now
                                        </span>
                                    </Button>
                                </Flex>
                            </Flex>
                            <Flex gap='10'
                                display={{ sm: "flex", md: "flex", lg: "none" }}
                            >
                                <Flex
                                    flexDir={'column'}
                                >
                                    {
                                        props.ele.PRDDiscountPrice ?
                                            <Text
                                                textDecoration='line-through'
                                                className='home_product_price'
                                            >
                                                {(Number(props.ele.PRDDiscountPrice) * (props.currencyExchange ? props.currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{props.currencyIcon ? props.currencyIcon : "€"}
                                            </Text>
                                            : ""
                                    }
                                    {
                                        props.ele.PRDDiscountPrice ?
                                            <Text
                                                className='home_product_price'
                                                color="#219691"
                                            >
                                                {(Number(props.ele.PRDPrice) * (props.currencyExchange ? props.currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} {" "}{props.currencyIcon ? props.currencyIcon : "€"}
                                            </Text>
                                            :
                                            <Text
                                                className='home_product_price'
                                            >
                                                {(Number(props.ele.PRDPrice) * (props.currencyExchange ? props.currencyExchange : 1)).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{props.currencyIcon ? props.currencyIcon : "€"}
                                            </Text>

                                    }
                                </Flex>
                                <Flex
                                    display={'flex'}
                                    pos='absolute'
                                    right={0}
                                    mt='-5px'
                                    justifyContent='right'
                                >
                                    <Button
                                        className='buy_now_btn'
                                        borderRadius='20px'
                                        mt='10px'
                                        colorScheme='none'
                                        fontSize={{ sm: "12px", md: "14px", lg: "16px" }}
                                        bg='#219691'
                                        onClick={() => {
                                            history.push(`/product-details/${props.ele.id}`)
                                        }}

                                    >
                                        <span>
                                            Buy Now
                                        </span>
                                    </Button>
                                </Flex>
                            </Flex>

                        </Flex>

                    </Flex>
                </Card>
            </Flex>
        </div >
    )
}

export default ProductList