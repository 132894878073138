import React, { useState, useRef } from "react";
import {
    Box, Flex, Button, FormControl, FormLabel, Heading, Input, useToast, ChakraProvider,
} from "@chakra-ui/react";
import { resetPassword } from "../../components/API/AuthAPI";
import { useLocation } from "react-router-dom";
import AuthNavbar1 from "components/Navbars/SearchBar/AuthNavbar1";
import theme from "theme/theme";
import { resetpasswordText, passwordText, confirmPasswordText, passwordsetText, linkExpireText } from "assets/constants/strings";

function ResetPassword() {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [submitted, setSubmitted] = useState(false);
    const toast = useToast();
    let location = useLocation();
    let path = location.pathname;
    let fields = path.split('/');
    let uid = fields[2];
    let token = fields[3];

    /**
     * This function handles the change of a password and displays a success or error message using a
     * toast notification.
     */
    const handleChange = () => {
        setSubmitted(true);
        resetPassword(token, uid, password, confirmPassword)
            .then((responseJson) => {
                if (responseJson.token[0] == "Invalid value") {
                    setSubmitted(false);
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {linkExpireText}
                            </Box>
                        ),
                    })
                }
                if (responseJson) {
                    setSubmitted(false);
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {passwordsetText}
                            </Box>
                        ),
                    })
                }
            })
    }


    return (
        <ChakraProvider theme={theme} resetCss={false} w="100%">
            <Flex
                overflowY="scroll"
                mb='40px'
                pt="45px"
                h="100%"
                w="100%"
            >
                <AuthNavbar1 />
                <Flex
                    w='100%'
                    mx='auto'
                    justifyContent='center'
                    mb='30px'
                    pt={{ sm: "10vh", md: "0px", lg: "5vh" }}
                >
                    <Flex
                        display="flex"
                        flexWrap={"nowrap"}
                        alignItems='center'
                        justifyContent='center'
                        w={{ base: "100%", md: "50%", lg: "42%" }}
                    >
                        <Flex
                            direction='column'
                            w='100%'
                            maxWidth={"350px"}
                            background='#FFFFFF'
                            border={{ sm: "none", md: "1px solid #EEE", lg: "1px solid #EEE" }}
                            borderRadius={{ sm: "none", md: "10px", lg: "10px" }}
                            p='25px'
                            mt={{ md: "100px", lg: "80px" }}>
                            <Heading
                                textAlign={"center"}
                                fontSize='xl'
                                mb='50px'
                            >
                                {resetpasswordText}
                            </Heading>
                            <form onSubmit={handleChange}>
                                <FormControl>
                                    <FormLabel mx="20px">{passwordText}</FormLabel>
                                    <Input
                                        borderRadius='15px'
                                        bg="#F4F5F9"
                                        color="#4f5d77"
                                        mb='15px'
                                        fontSize='sm'
                                        type='text'
                                        placeholder={passwordText}
                                        _placeholder={{ color: 'inherit' }}
                                        textAlign="center"
                                        size='lg'
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel mx="20px">{confirmPasswordText}</FormLabel>
                                    <Input
                                        borderRadius='15px'
                                        bg="#F4F5F9"
                                        color="#4f5d77"
                                        mb='15px'
                                        fontSize='sm'
                                        type='text'
                                        placeholder={confirmPasswordText}
                                        _placeholder={{ color: 'inherit' }}
                                        textAlign="center"
                                        size='lg'
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </FormControl>

                                {!submitted ?
                                    <Button
                                        fontSize='sm'
                                        type='submit'
                                        bg='#219691'
                                        w='100%'
                                        h='45'
                                        mb='20px'
                                        color='white'
                                        mt='20px'
                                        _hover={{
                                            bg: "#219691",
                                        }}
                                        _active={{
                                            bg: "teal.400",
                                        }}
                                        onClick={handleChange}
                                    >
                                        {resetpasswordText}
                                    </Button>
                                    :
                                    <Button
                                        fontSize='sm'
                                        type='submit'
                                        isLoading
                                        loadingText='please wait....'
                                        colorScheme='teal'
                                        variant='solid'
                                        bg='#219691'
                                        w='100%'
                                        h='45'
                                        mb='20px'
                                        color='white'
                                        mt='20px'
                                        _hover={{
                                            bg: "#219691",
                                        }}
                                        _active={{
                                            bg: "teal.400",
                                        }}
                                        onClick={handleChange}
                                    >
                                        {resetpasswordText}
                                    </Button>
                                }
                            </form>
                        </Flex>
                    </Flex>

                </Flex>
            </Flex>
        </ChakraProvider>
    );
}

export default ResetPassword;
