import React, { useEffect, useState } from 'react';
import {
  Button,
} from "@chakra-ui/react";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useHistory } from 'react-router-dom';
import "./styles.css";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { Translate } from 'components/DeepL/Translate';
import moment from 'moment';
import { AffiliateTaggingProducts } from 'components/API/AuthAPI';
import { viewDetails } from 'assets/constants/strings';


const OrderTable = () => {
  const [orderData, setOrderData] = useState([]);
  const [token, setToken] = useState();
  let history = useHistory();

  useEffect(() => {
       const userToken = localStorage.getItem("token");
        setToken(userToken);
        if(token){
          orderList();
        }
      if (!userToken) {
        history.push({
          pathname: "/"
        })
      }
  }, [token])

  /**
   * The function retrieves and parses data from an API endpoint for affiliate orders and sets the
   * parsed data in the state.
   */
  const orderList=()=>{
    AffiliateTaggingProducts(token).then((res)=>{
      if(res){
        let affiliateOrders = res.data;
        const parsedData = affiliateOrders.map(({
          amount= '',
          country= '',
          date_update = '',
          discount='',
          email_client= '',
          id='',
          is_finished='',
          merchant_order_id= '',
          order_date = '',
          order_id_paymob='',
          order_profile_id='',
          payment_id='',
          shipping='',
          status='',
          sub_total='',
          tracking_no='',
          trnx_id='',
          user_id='',
          weight='',
      }) => {
          return {
            amount: "€"+amount.toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            country: country,
            date_update: moment(date_update).format('DD.MM.YYYY'),
            discount: discount,
            email_client: email_client,
            id: id,
            is_finished: is_finished,
            merchant_order_id: merchant_order_id,
            order_date: moment(order_date).format('DD.MM.YYYY'),
            order_id_paymob: order_id_paymob,
            order_profile_id: order_profile_id,
            payment_id: payment_id,
            shipping: shipping,
            status: status,
            sub_total: sub_total+"€",
            tracking_no: tracking_no,
            trnx_id: trnx_id,
            user_id: user_id,
            weight: parseFloat(weight).toFixed(2),
            boutiq_name:'sachin-store'
          }
      })
      setOrderData(parsedData)
      }
    })
  }

  const customCellStyle = cellProps => {
    const { value, rowActive } = cellProps;
    return {
      color: rowActive
        ? '#e9ecf0'
        : value % 2 ? '#ff595e' : 'inherit',
    }
  };

  const columns = [
    {
      name: 'merchant_order_id', header: 'Order ID', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'email_client', header: 'Email', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'country', header: 'Country', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'weight', header: 'Weight (Kg)', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'amount', header: 'Total', minWidth: 100, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'status', header: 'Status', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'order_date', header: 'Order Date', minWidth: 120, defaultWidth: 80, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'tracking_no', header: '', defaultFlex: 1, minWidth: 160, headerAlign: "center", showHoverRow: "false", headerProps: {
        className: "tableHeader"
      },
      style: customCellStyle,
      render: ({ data }) => {
        return <div style={{ display: 'inline-block', zIndex: 128, textAlign: "center", justifyContent: "center", }}>

          <Button
            colorScheme='none'
            borderColor='#ffffff'
            color='#ffffff'
            bg="#219691"
            variant='outline'
            fontSize='xs'
            p='8px 32px'
            onClick={() => {
              history.push({
                pathname: "/orderDetails",
                state: data.merchant_order_id
              })
            }
            }
          >
            {viewDetails}
          </Button>
        </div>
      }
    },
  ];

  const gridStyle = { minHeight: 550, maxWidth: 1700, textAlign: "center", fontSize: "14px" };
  const rowStyle = { fontSize: "15.5px", fontStyle: "normal" }


  return (

    <Card p='10px' mt='5px'
      maxHeight="600px"
      maxWidth="1700px"
    >
      <CardHeader>
        <ReactDataGrid
          idProperty="id"
          columns={columns}
          dataSource={orderData}
          style={gridStyle}
          rowStyle={rowStyle}
          rowHeight={50}
          fontSize={14}
          headerHeight={55}
          showCellBorders={false}
          pagination
          showZebraRows={false}
          defaultLimit={10}
        />
      </CardHeader>
    </Card>

  )
}

export default OrderTable