import { generateCurrency } from "components/API/AuthAPI";
import { useEffect, useState } from "react";

export const CurrencyExchange = (input) => {
    
    const [currencyRate, setCurrencyRate] = useState();
        
    useEffect(() => {
        generateCurrency(input)
            .then((responseJson) => {
                if (responseJson)
                    setCurrencyRate(responseJson.conversion_rates)
                // console.log("currecyRate", responseJson);    
                localStorage.setItem("currencyExchange", JSON.stringify(responseJson.conversion_rates))

            })
    }, []);
    return currencyRate;
}