import React, { useState, useEffect } from 'react'
import {
    Flex, Text, Image, Button, Box
} from '@chakra-ui/react'
import './mainHeader.css'
import { ArrowBackIcon, ArrowForwardIcon, CloseIcon } from '@chakra-ui/icons'
import hamer from 'assets/img/hamburger-menu.png'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
} from '@chakra-ui/react'
import { getMyOrdersOverview, getOrderList } from '../API/AuthAPI'
import { dateFormat } from '../../components/helper/index'


export function OrderOverView({ overview, closeAccountOverview, getmyaccount, getMyorders }) {


    const [nextPage, setNextPage] = useState();
    const [previousPage, setPreviousPage] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [loginToken, setLoginToken] = useState()
    const [hamburge, setHamburge] = useState(false)
    const [returnPro, setReturnPro] = useState(false)
    const [overviewHamer, setOverviewHamer] = useState(false)
    const [getOverViewProduct, setGetOverviewProduct] = useState([])
    const [products, setProducts] = useState([])





    // To Open Homepage Hamburge Dropdown 



    const openHam = () => {
        setHamburge(true)
    }

    const closeHam = () => {
        setHamburge(false)
    }

    //3****** TO open Return Item menu --- Modal
    const openReturnPro = () => {
        setReturnPro(true)
        setOrders(false)
        setDropdown(false)
    }

    const closeReturnPro = () => {
        setReturnPro(false)
    }
    // onClick on My profile/order Details/order Overview --- hamburger


    const overviewHam = () => {
        setOverviewHamer(true)
    }

    // console.log('object', loginToken);

    useEffect(() => {
        const accToken = localStorage.getItem("token")
        setLoginToken(accToken)
        getOrderOverViewInfo(accToken)
    }, [loginToken, pageNo])


   
    const getOrderOverViewInfo = (loginToken) => {
        getOrderList(loginToken,pageNo).then(responseJson => {
            setNextPage(responseJson.next ? responseJson.next : "");
            setPreviousPage(responseJson.previous);
            setGetOverviewProduct([...responseJson.results] || [])
            setProducts(responseJson?.results.order_details?.product_details || [])
            // console.log("getMyOrdersOverview", responseJson)
        })
    }
    // console.log("previous",previousPage)



    return (
        <>
            {/*2 --- 1 My orders - onClick on SignIn Button open ---- Order Overview */}

            <Modal
                isOpen={overview}
                size='5xl'
                blockScrollOnMount={false}>
                <ModalOverlay />
                <ModalContent >
                    <Menu >
                        <ModalHeader
                            display='flex' mt='5'>
                            <MenuButton
                                className='myprofile_header'
                                onClick={overviewHam}>
                                <Image ml='5'
                                    w='8%'
                                    h='8%'
                                    src={hamer}
                                />

                            </MenuButton>
                            <div
                                className='product_hamburger_inner_text'

                            >
                                <Text fontSize='2xl'
                                    color='#219691'
                                    fontWeight='bold'>
                                    MY BOUTIQ
                                </Text>
                                <CloseIcon mr='6'
                                    w='6%'
                                    h='50%'
                                    cursor='pointer'
                                    onClick={closeAccountOverview}
                                />
                            </div>
                            <MenuList>
                                <MenuItem
                                    className='inner_hamer_login'>
                                    <Flex
                                        flexDirection='column'
                                        w='50vh' top='0'>
                                        <Flex
                                            justifyContent='space-between'
                                            mb='3' mt='5' >

                                            <Text
                                                fontSize='2xl'
                                                className='product_inner_hamer'
                                            >
                                                MY BOUTIQ
                                            </Text>
                                            <CloseIcon mt='1'
                                                onClick={closeHam}
                                            />
                                        </Flex>
                                        <Flex
                                            flexDirection='column'
                                            alignItems='center'
                                            mt='5'>
                                            <p className='home_line' ></p>
                                            <Text
                                                fontWeight='bold'
                                                fontSize='lg' mt='8'
                                                onClick={getmyaccount}
                                                cursor='pointer'>
                                                My Account
                                            </Text>
                                            <Text
                                                fontWeight='bold'
                                                fontSize='lg' mt='8'
                                                onClick={getMyorders}
                                                cursor='pointer'>
                                                My Orders
                                            </Text>
                                            <Text
                                                fontWeight='bold'
                                                fontSize='lg'
                                                mt='8' mb="20"
                                                onClick={openReturnPro}
                                                cursor='pointer'>
                                                Logout
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </MenuItem>
                            </MenuList>
                        </ModalHeader>
                    </Menu>
                    <ModalBody
                        display='flex'
                        justifyContent='space-around'
                        flexDirection='column'
                    >
                        <Text
                            fontSize='2xl'
                            mt='5'>
                            MY ORDERS
                        </Text>
                        {
                            getOverViewProduct.length > 0 ? getOverViewProduct.map((response, index) => {
                                return (
                                    <div key={index}>



                                        <Box bg='#219691'
                                            w='100%' p={4}
                                            color='white'
                                            mt='10'
                                            display='flex'
                                            gap='14'
                                            className='product_order_details'
                                        >
                                            <Text
                                                fontSize='xl'
                                                fontWeight='bold'
                                            >
                                                Order Date: {dateFormat(response.order_date)}
                                            </Text>
                                            <Text
                                                fontSize='xl'
                                                fontWeight='bold'
                                            >
                                                Product Information
                                            </Text>
                                            <Text
                                                fontSize='xl'
                                                fontWeight='bold'
                                            >
                                                Shipping Information
                                            </Text>
                                        </Box>
                                        <Flex mt='5'
                                            justifyContent='space-between'
                                            className='product_order_overview'
                                        >
                                            
                                             <Image
                                                w={{ sm: "30%", md: "30%", lg: "20%" }}
                                                h={{ sm: "10%", md: "10%", lg: "200px" }}
                                                src={"https://adminmyboutiqe.progfeel.co.in" + response.order_details.length > 0 ? response.order_details[0].product_details.product_image : products.product_image}
                                                alt='product_image' />

                                            <Flex flexDirection='column' gap='5'>

                                                <Text fontSize='2xl'>
                                                    Product Name: {response.order_details.length > 0 ? response.order_details[0].product_details.product_name : ""}
                                                </Text>
                                                <Text fontSize='2xl'>
                                                    Quantity: {response.order_details.length > 0 ? response.order_details[0].quantity : ""}
                                                </Text>
                                                <Text fontSize='2xl'>
                                                    Total: {response.order_details.length > 0 ? response.order_details[0].product_details.PRDPrice : ""}
                                                </Text>
                                                <Text fontSize='2xl'>
                                                    ordered Items
                                                </Text>
                                                <Text
                                                    fontSize='xl'
                                                // fontWeight='bold'
                                                >
                                                    Order ID: {response.id}
                                                </Text>
                                            </Flex>
                                            <Flex flexDirection='column' gap='5' ml='5'>
                                                <Text fontSize='2xl'>
                                                    {response ? response.shipping_address.first_name : ""} {response ? response.shipping_address.last_name : ""}
                                                </Text>
                                                <Text fontSize='2xl'>
                                                    {response.shipping_address.address_line_1}
                                                </Text>
                                                <Text fontSize='2xl'>
                                                    {response.shipping_address.address_line_1}
                                                </Text>
                                                <Text fontSize='2xl'>
                                                    {response.shipping_address.zipcode} {""} {response.shipping_address.city}
                                                </Text>
                                                {/* <Text fontSize='2xl'>
                                                    {response.order.country}
                                                </Text> */}
                                            </Flex>
                                            <Flex
                                                flexDirection='column' ml='16'
                                                className='product_overview_btn'
                                            >
                                                <Button
                                                    colorScheme='none'
                                                    bg="#219691"
                                                    color='white'
                                                    size='lg'

                                                >
                                                    View Details
                                                </Button>
                                                <Button mt='5'
                                                    bg='gray'
                                                    color='white'>
                                                    Return All Items
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </div>
                                )
                            }) : <Text mt='20'
                                textAlign='center'
                                fontSize='2xl'
                                fontWeight='bold'>
                                No Product Found
                            </Text>
                        }
                        <Flex 
                            display='flex'
                            justifyContent='center'
                            mt='30px'
                        >
                            {previousPage == null ?

                                <Button
                                    disabled
                                    bg="transparent"
                                    colorScheme='none'
                                    mr='20px'
                                >
                                    <ArrowBackIcon w='40px' h='40px' color={'#000000'} />
                                </Button>

                                :

                                <Button
                                    bg="transparent"
                                    colorScheme='none'
                                    mr='20px'
                                    onClick={(e)=>{setPageNo(pageNo-1)}}
                                >
                                    <ArrowBackIcon w='40px' h='40px' color={'#000000'} />
                                </Button>
                            }
                            {nextPage == null  || nextPage == "" ?
                                <Button
                                    bg="transparent"
                                    colorScheme='none'
                                    mr='20px'
                                    disabled
                                >
                                    <ArrowForwardIcon w='40px' h='40px' color='#000000' />
                                </Button>
                                :
                                <Button
                                    bg="transparent"
                                    colorScheme='none'
                                    mr='20px'
                                    onClick={(e)=>{setPageNo(pageNo+1)}}
                                >
                                    <ArrowForwardIcon w='40px' h='40px' color='#000000' />
                                </Button>
                            }
                        </Flex>
                    </ModalBody>

                    <Box bg='darkcyan'
                        w='100%' p={20}
                        color='white'
                        mt='10px'
                        display='flex'
                        gap='20'
                    >
                    </Box>

                </ModalContent>
            </Modal>
        </>
    )
}
