import FreeDelivery from "../../../assets/img/Launchpad/BestService/FreeDelivery.png";
import HugeSale from "../../../assets/img/Launchpad/BestService/HugeSale.png";
import LevelUp from "../../../assets/img/Launchpad/BestService/LevelUp.png";
import Wow from "../../../assets/img/Launchpad/BestService/Wow.png";
import ComingSoon from "../../../assets/img/Launchpad/BuyMe/ComingSoon.png";
import HappyHour from "../../../assets/img/Launchpad/BuyMe/HappyHour.png";
import NowOpen from "../../../assets/img/Launchpad/BuyMe/NowOpen.png";
import Sale from "../../../assets/img/Launchpad/BuyMe/Sale.png";

export const users = [
    {
      id: 1,
      firstName: "Claudio",
      lastName: "Hoopper",
      email: "choopper0@yolasite.com",
      age: 33
    },
    {
      id: 2,
      firstName: "Roxine",
      lastName: "Midford",
      email: "rmidford1@washington.edu",
      age: 57
    },
    {
      id: 3,
      firstName: "Brook",
      lastName: "O'Gaven",
      email: "bogaven2@pen.io",
      age: 29
    },
    {
      id: 4,
      firstName: "Dedra",
      lastName: "Hoggin",
      email: "dhoggin3@cam.ac.uk",
      age: 54
    },
    {
      id: 5,
      firstName: "Darice",
      lastName: "Gandrich",
      email: "dgandrich4@zimbio.com",
      age: 23
    },
    {
      id: 6,
      firstName: "Florida",
      lastName: "Wringe",
      email: "fwringe5@pbs.org",
      age: 38
    },
    {
      id: 7,
      firstName: "Tybi",
      lastName: "Moyne",
      email: "tmoyne6@nih.gov",
      age: 39
    },
    {
      id: 8,
      firstName: "Julita",
      lastName: "Snary",
      email: "jsnary7@census.gov",
      age: 41
    },
    {
      id: 9,
      firstName: "Say",
      lastName: "Kaasmann",
      email: "skaasmann8@furl.net",
      age: 36
    },
    {
      id: 10,
      firstName: "Haroun",
      lastName: "Leatt",
      email: "hleatt9@facebook.com",
      age: 76
    },
    {
      id: 11,
      firstName: "Paige",
      lastName: "Bendley",
      email: "pbendleya@hugedomains.com",
      age: 27
    },
    {
      id: 12,
      firstName: "Sada",
      lastName: "Critoph",
      email: "scritophb@domainmarket.com",
      age: 24
    },
    {
      id: 13,
      firstName: "Jessa",
      lastName: "Loxton",
      email: "jloxtonc@google.es",
      age: 69
    },
    {
      id: 14,
      firstName: "Reginauld",
      lastName: "Garroway",
      email: "rgarrowayd@irs.gov",
      age: 67
    },
    {
      id: 15,
      firstName: "Oswald",
      lastName: "Teggart",
      email: "oteggarte@yahoo.com",
      age: 24
    },
    {
      id: 16,
      firstName: "Francesca",
      lastName: "Jeratt",
      email: "fjerattf@infoseek.co.jp",
      age: 47
    },
    {
      id: 17,
      firstName: "Deane",
      lastName: "Blanning",
      email: "dblanningg@go.com",
      age: 39
    },
    {
      id: 18,
      firstName: "Link",
      lastName: "Maxworthy",
      email: "lmaxworthyh@nhs.uk",
      age: 63
    },
    {
      id: 19,
      firstName: "Belva",
      lastName: "Bouttell",
      email: "bbouttelli@goo.ne.jp",
      age: 58
    },
    {
      id: 20,
      firstName: "Cristobal",
      lastName: "Ormesher",
      email: "cormesherj@themeforest.net",
      age: 42
    },
    {
      id: 21,
      firstName: "Cristina",
      lastName: "Geer",
      email: "cgeerk@mozilla.com",
      age: 84
    },
    {
      id: 22,
      firstName: "Rosalinde",
      lastName: "Kurt",
      email: "rkurtl@xinhuanet.com",
      age: 73
    },
    {
      id: 23,
      firstName: "Mart",
      lastName: "Giraudat",
      email: "mgiraudatm@mozilla.org",
      age: 35
    },
    {
      id: 24,
      firstName: "Morrie",
      lastName: "Goodbarr",
      email: "mgoodbarrn@dailymotion.com",
      age: 58
    },
    {
      id: 25,
      firstName: "Linet",
      lastName: "Stanway",
      email: "lstanwayo@si.edu",
      age: 44
    },
    {
      id: 26,
      firstName: "Jackson",
      lastName: "Fleckno",
      email: "jflecknop@uiuc.edu",
      age: 89
    },
    {
      id: 27,
      firstName: "Gunner",
      lastName: "Seel",
      email: "gseelq@nih.gov",
      age: 65
    },
    {
      id: 28,
      firstName: "Dorthy",
      lastName: "Gilchrist",
      email: "dgilchristr@hud.gov",
      age: 59
    },
    {
      id: 29,
      firstName: "Kathryne",
      lastName: "Hurst",
      email: "khursts@dot.gov",
      age: 94
    },
    {
      id: 30,
      firstName: "Nerti",
      lastName: "Zottoli",
      email: "nzottolit@wikia.com",
      age: 18
    },
    {
      id: 31,
      firstName: "Judy",
      lastName: "Corradini",
      email: "jcorradiniu@bluehost.com",
      age: 30
    },
    {
      id: 32,
      firstName: "Luelle",
      lastName: "Prowting",
      email: "lprowtingv@about.com",
      age: 70
    },
    {
      id: 33,
      firstName: "Theodore",
      lastName: "Jacobowicz",
      email: "tjacobowiczw@aol.com",
      age: 78
    },
    {
      id: 34,
      firstName: "Jacquie",
      lastName: "Wailes",
      email: "jwailesx@fema.gov",
      age: 73
    },
    {
      id: 35,
      firstName: "Marcel",
      lastName: "Vasiljevic",
      email: "mvasiljevicy@yelp.com",
      age: 38
    },
    {
      id: 36,
      firstName: "Joli",
      lastName: "Schuelcke",
      email: "jschuelckez@bluehost.com",
      age: 27
    },
    {
      id: 37,
      firstName: "Hermie",
      lastName: "Luetchford",
      email: "hluetchford10@yelp.com",
      age: 54
    },
    {
      id: 38,
      firstName: "Sigvard",
      lastName: "Lesurf",
      email: "slesurf11@desdev.cn",
      age: 99
    },
    {
      id: 39,
      firstName: "Prinz",
      lastName: "Tugman",
      email: "ptugman12@canalblog.com",
      age: 81
    },
    {
      id: 40,
      firstName: "Gordon",
      lastName: "Bello",
      email: "gbello13@mail.ru",
      age: 92
    },
    {
      id: 41,
      firstName: "Lewie",
      lastName: "Willis",
      email: "lwillis14@who.int",
      age: 70
    },
    {
      id: 42,
      firstName: "Hyacintha",
      lastName: "Etheredge",
      email: "hetheredge15@studiopress.com",
      age: 67
    },
    {
      id: 43,
      firstName: "Kelsi",
      lastName: "Liptrot",
      email: "kliptrot16@chron.com",
      age: 38
    },
    {
      id: 44,
      firstName: "Aurel",
      lastName: "Wreakes",
      email: "awreakes17@examiner.com",
      age: 33
    },
    {
      id: 45,
      firstName: "Efren",
      lastName: "Insworth",
      email: "einsworth18@webeden.co.uk",
      age: 64
    },
    {
      id: 46,
      firstName: "Jobyna",
      lastName: "Caulwell",
      email: "jcaulwell19@altervista.org",
      age: 29
    },
    {
      id: 47,
      firstName: "Gwenni",
      lastName: "Harrod",
      email: "gharrod1a@prnewswire.com",
      age: 89
    },
    {
      id: 48,
      firstName: "Lindy",
      lastName: "Habbes",
      email: "lhabbes1b@a8.net",
      age: 35
    },
    {
      id: 49,
      firstName: "Eunice",
      lastName: "Preuvost",
      email: "epreuvost1c@nih.gov",
      age: 43
    },
    {
      id: 50,
      firstName: "Wesley",
      lastName: "Thomerson",
      email: "wthomerson1d@yahoo.co.jp",
      age: 99
    },
    {
      id: 51,
      firstName: "Carmella",
      lastName: "Divis",
      email: "cdivis1e@canalblog.com",
      age: 79
    },
    {
      id: 52,
      firstName: "Lydia",
      lastName: "Janic",
      email: "ljanic1f@flickr.com",
      age: 38
    },
    {
      id: 53,
      firstName: "Elisha",
      lastName: "Wornum",
      email: "ewornum1g@google.it",
      age: 81
    },
    {
      id: 54,
      firstName: "Geneva",
      lastName: "Rable",
      email: "grable1h@ox.ac.uk",
      age: 32
    },
    {
      id: 55,
      firstName: "Fredi",
      lastName: "Forker",
      email: "fforker1i@reverbnation.com",
      age: 78
    },
    {
      id: 56,
      firstName: "Erny",
      lastName: "Ireson",
      email: "eireson1j@barnesandnoble.com",
      age: 18
    },
    {
      id: 57,
      firstName: "Cinda",
      lastName: "Cherry",
      email: "ccherry1k@squarespace.com",
      age: 70
    },
    {
      id: 58,
      firstName: "Stoddard",
      lastName: "Chainey",
      email: "schainey1l@baidu.com",
      age: 88
    },
    {
      id: 59,
      firstName: "Kelly",
      lastName: "McGeouch",
      email: "kmcgeouch1m@globo.com",
      age: 87
    },
    {
      id: 60,
      firstName: "Klement",
      lastName: "Harcombe",
      email: "kharcombe1n@yandex.ru",
      age: 81
    },
    {
      id: 61,
      firstName: "Siffre",
      lastName: "Tebbut",
      email: "stebbut1o@free.fr",
      age: 43
    },
    {
      id: 62,
      firstName: "Jermayne",
      lastName: "Petkovic",
      email: "jpetkovic1p@xing.com",
      age: 64
    },
    {
      id: 63,
      firstName: "Jobie",
      lastName: "Crossdale",
      email: "jcrossdale1q@nydailynews.com",
      age: 61
    },
    {
      id: 64,
      firstName: "Cedric",
      lastName: "Topham",
      email: "ctopham1r@gnu.org",
      age: 60
    },
    {
      id: 65,
      firstName: "Norton",
      lastName: "Kingstne",
      email: "nkingstne1s@yolasite.com",
      age: 76
    },
    {
      id: 66,
      firstName: "Gussi",
      lastName: "Neild",
      email: "gneild1t@japanpost.jp",
      age: 82
    },
    {
      id: 67,
      firstName: "Shelly",
      lastName: "Negro",
      email: "snegro1u@vk.com",
      age: 97
    },
    {
      id: 68,
      firstName: "Sharyl",
      lastName: "Corsan",
      email: "scorsan1v@pbs.org",
      age: 40
    },
    {
      id: 69,
      firstName: "Lacie",
      lastName: "Mollnar",
      email: "lmollnar1w@reuters.com",
      age: 46
    },
    {
      id: 70,
      firstName: "Carleton",
      lastName: "Kettlestringe",
      email: "ckettlestringe1x@bloomberg.com",
      age: 57
    },
    {
      id: 71,
      firstName: "Briggs",
      lastName: "Fosdick",
      email: "bfosdick1y@infoseek.co.jp",
      age: 93
    },
    {
      id: 72,
      firstName: "Josephina",
      lastName: "Ferrao",
      email: "jferrao1z@quantcast.com",
      age: 96
    },
    {
      id: 73,
      firstName: "Freddie",
      lastName: "Sansum",
      email: "fsansum20@gov.uk",
      age: 40
    },
    {
      id: 74,
      firstName: "Rowney",
      lastName: "Ewert",
      email: "rewert21@vimeo.com",
      age: 96
    },
    {
      id: 75,
      firstName: "Fraser",
      lastName: "Servis",
      email: "fservis22@360.cn",
      age: 52
    },
    {
      id: 76,
      firstName: "Caryl",
      lastName: "Backsal",
      email: "cbacksal23@epa.gov",
      age: 20
    },
    {
      id: 77,
      firstName: "Monro",
      lastName: "Purcer",
      email: "mpurcer24@stanford.edu",
      age: 52
    },
    {
      id: 78,
      firstName: "Bradan",
      lastName: "Dziwisz",
      email: "bdziwisz25@admin.ch",
      age: 78
    },
    {
      id: 79,
      firstName: "Teodora",
      lastName: "Geertsen",
      email: "tgeertsen26@nasa.gov",
      age: 47
    },
    {
      id: 80,
      firstName: "Janice",
      lastName: "Prestland",
      email: "jprestland27@canalblog.com",
      age: 78
    },
    {
      id: 81,
      firstName: "Madelyn",
      lastName: "Lanchbery",
      email: "mlanchbery28@sakura.ne.jp",
      age: 39
    },
    {
      id: 82,
      firstName: "Carlin",
      lastName: "Adrian",
      email: "cadrian29@photobucket.com",
      age: 58
    },
    {
      id: 83,
      firstName: "Larine",
      lastName: "Stubbings",
      email: "lstubbings2a@prweb.com",
      age: 29
    },
    {
      id: 84,
      firstName: "Lane",
      lastName: "Morrant",
      email: "lmorrant2b@wix.com",
      age: 52
    },
    {
      id: 85,
      firstName: "Bevin",
      lastName: "Shepstone",
      email: "bshepstone2c@state.tx.us",
      age: 49
    },
    {
      id: 86,
      firstName: "Lorene",
      lastName: "Escritt",
      email: "lescritt2d@abc.net.au",
      age: 59
    },
    {
      id: 87,
      firstName: "Lona",
      lastName: "Cockitt",
      email: "lcockitt2e@businessweek.com",
      age: 82
    },
    {
      id: 88,
      firstName: "Anabelle",
      lastName: "Auston",
      email: "aauston2f@stumbleupon.com",
      age: 38
    },
    {
      id: 89,
      firstName: "Laraine",
      lastName: "Birchenhead",
      email: "lbirchenhead2g@marriott.com",
      age: 46
    },
    {
      id: 90,
      firstName: "Milena",
      lastName: "Carnachen",
      email: "mcarnachen2h@archive.org",
      age: 56
    },
    {
      id: 91,
      firstName: "Arney",
      lastName: "Whaites",
      email: "awhaites2i@illinois.edu",
      age: 32
    },
    {
      id: 92,
      firstName: "Leonanie",
      lastName: "O'Bradane",
      email: "lobradane2j@oakley.com",
      age: 99
    },
    {
      id: 93,
      firstName: "Mickie",
      lastName: "Kob",
      email: "mkob2k@tamu.edu",
      age: 96
    },
    {
      id: 94,
      firstName: "Friederike",
      lastName: "Parkman",
      email: "fparkman2l@usa.gov",
      age: 55
    },
    {
      id: 95,
      firstName: "Hadria",
      lastName: "Gotcliff",
      email: "hgotcliff2m@google.com.hk",
      age: 34
    },
    {
      id: 96,
      firstName: "Vittorio",
      lastName: "Stairs",
      email: "vstairs2n@technorati.com",
      age: 22
    },
    {
      id: 97,
      firstName: "Daisi",
      lastName: "Forsdicke",
      email: "dforsdicke2o@jiathis.com",
      age: 54
    },
    {
      id: 98,
      firstName: "Joela",
      lastName: "Douthwaite",
      email: "jdouthwaite2p@rediff.com",
      age: 25
    },
    {
      id: 99,
      firstName: "Natassia",
      lastName: "Tichelaar",
      email: "ntichelaar2q@wikimedia.org",
      age: 95
    },
    {
      id: 100,
      firstName: "Clerc",
      lastName: "Wyvill",
      email: "cwyvill2r@scientificamerican.com",
      age: 23
    }
  ];

  export const images=[
    {
        id: 1,
        name: "FreeDelivery",
        category:"BestService",
        src:FreeDelivery
    },
    {
        id: 2,
        name: "HugeSale",
        category:"BestService",
        src:HugeSale
    },
    {
      id: 3,
      name: "LevelUp",
      category:"BestService",
      src:LevelUp
    },
    {
      id: 4,
      name: "Wow",
      category:"BestService",
      src:Wow
    },
    {
      id: 5,
      name: "ComingSoon",
      category:"BuyMe",
      src:ComingSoon
    },
    {
      id: 4,
      name: "HappyHour",
      category:"BuyMe",
      src:HappyHour
    },
    {
      id: 4,
      name: "NowOpen",
      category:"BuyMe",
      src:NowOpen
    },
    {
      id: 4,
      name: "Sale",
      category:"BuyMe",
      src:Sale
    }
  ];

  