import {
  Box, Button, Flex, FormControl, Grid, Input, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter,
  ModalBody, ModalCloseButton, useDisclosure, useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { inviteAffiliateMember, getAffiliateTeamMember } from 'components/API/AuthAPI';
import { Translate } from 'components/DeepL/Translate';
import Emitter from 'Emitter';
import { invite, reachedInvitationLimitmsg, enterValidEmail, enteremail, teamMember, myProfile, inviteTeamMember, userIsAlreadyRegistered } from 'assets/constants/strings';

const Invite = () => {
  const [number, setNumber] = useState();
  const [email, setEmail] = useState();
  const [token, setToken] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const toast = useToast();
  const [visible, setVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("GB");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [lang, setLang] = useState();


  let user;
  useEffect(() => {
    user = localStorage.getItem("user_type");
    if (user === 'affilate_manager') {
      setVisible(true);
    }
    let userToken = localStorage.getItem("token");
    setToken(userToken);
    if (token) {
      getTeamMemberDetails();
    }

  }, [token])

  //get list of conncted affiliate member
  const getTeamMemberDetails = () => {

    getAffiliateTeamMember(token)
      .then((responseJson) => {
        if (responseJson) {
          let affilliateDetails = responseJson.data || {};
          // console.log("users", affilliateDetails.length);
          setNumber(8 - affilliateDetails.length);
        }
      })
  }

  const limitMessage = () => {
    toast({
      position: 'top',
      render: () => (
        <Box
          color='white'
          p={3} bg='#219691'
          textAlign={'center'}
          fontWeight='bold'
        >
          {reachedInvitationLimitmsg}!
        </Box>
      ),
    });
  }

  //invite new affiliate member
  const sendInvitation = () => {
    setIsSubmitted(true);
    if (email) {
      inviteAffiliateMember(token, email)
        .then((responseJson) => {
          if (responseJson) {
            if (responseJson["message"]== "Invitation sent successfully") {
              toast({
                position: 'top',
                render: () => (
                  <Box
                    color='white'
                    p={3} bg='#219691'
                    textAlign={'center'}
                    fontWeight='bold'
                  >
                    {responseJson["message"]}
                  </Box>
                ),
              });
              setIsSubmitted(false);
            }else if(responseJson["email"][0] == "Duplicate email address."){
              toast({
                position: 'top',
                render: () => (
                  <Box
                    color='white'
                    p={3} bg='#219691'
                    textAlign={'center'}
                    fontWeight='bold'
                  >
                    {userIsAlreadyRegistered}
                  </Box>
                ),
              });
              setIsSubmitted(false);
            }else if(responseJson["email"][0] == "Enter a valid email address."){
              toast({
                position: 'top',
                render: () => (
                  <Box
                    color='white'
                    p={3} bg='#219691'
                    textAlign={'center'}
                    fontWeight='bold'
                  >
                    {enterValidEmail}
                  </Box>
                ),
              });
              setIsSubmitted(false);
            }
          }
        })  
    } else {
      toast({
        position: 'top',
        render: () => (
          <Box
            color='white'
            p={3} bg='#219691'
            textAlign={'center'}
            fontWeight='bold'
          >
            {enteremail}
          </Box>
        ),
      });
      setIsSubmitted(false);
    }
  }

  useEffect(() => {
    let language1 = localStorage.getItem('language');
    if (language1) {
      setSelectedLanguage(language1);
    } else {
      setSelectedLanguage("GB");
    }
    // alert(language1) 
  }, [lang])
  Emitter.on("LANGUAGE", (lang) => setLang(lang));






  return (
    <Flex direction="column" pt={{ base: "70px", md: "10px" }}>
      <Grid mt={{ sm: "10px", md: "75px", xl: "70px" }}

        display="flex"
        justifyContent="space-between"
        px="20px">
        <Text
          color="#219691"
          fontWeight={"bold"}
          fontSize={{ lg: "24px" }}
          py="20px"
        >
          {/* {selectedLanguage !== "GB" ? Translate("AFFILIATE TEAM MEMBER") : "AFFILIATE TEAM MEMBER"} */}
          {teamMember}
        </Text>
        <Grid display="flex" py="20px">
          <Link to="/affiliate/affiliateprofile">
            <Button
              colorScheme='none'
              variant='outline'
              // p='8px 32px'
              bg="#219691"
              color="white"
              fontSize={{ base: '12px', md: '15px', lg: '16px' }}
              w={{ base: '100%', md: '120px', lg: '150px' }}
            // h={{ base: '25px', md: '35px', lg: '40px' }}
            >
              {/* {selectedLanguage !== "GB" ? Translate("My Profile") : "My Profile"} */}
              {myProfile}
            </Button>
          </Link>
          &nbsp;&nbsp;
          {visible ?

            number !== 8 ?
              <Button
                colorScheme='none'
                variant='outline'
                // p='8px 32px'
                bg="#219691"
                color="white"
                fontSize={{ base: '12px', md: '15px', lg: '16px' }}
                w={{ base: '100%', md: '120px', lg: '150px' }}
                // h={{ base: '25px', md: '35px', lg: '40px' }}
                onClick={onOpen}
              >
                {invite}
              </Button>
              :
              <Button
                colorScheme='none'
                variant='outline'
                // p='8px 32px'
                bg="#219691"
                color="white"
                fontSize={{ base: '12px', md: '15px', lg: '16px' }}
                w={{ base: '100%', md: '120px', lg: '150px' }}
                onClick={limitMessage}
              >
                {invite}
              </Button>
            :
            ""
          }
        </Grid>
      </Grid>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
      >
        <ModalOverlay />
        {/* <form onSubmit={handleSubmit}> */}
        <ModalContent>
          {/* <form onSubmit={handleSubmit}> */}
          <ModalHeader>
            {/* {selectedLanguage !== "GB" ? Translate("Invite New Member") : "Invite New Member"} */}
            {inviteTeamMember}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction='column' pt={{ base: "10px", md: "10px" }} >
              {/* <Text>Invite New Affiliates</Text> */}
              <Grid
                display="flex"
                w={{ base: '100%', md: '100%', lg: '100%' }}
                px="30px"
              >
                {number === 8 ?
                  <Text
                    color="#219691"
                    fontSize={{ base: '12px', md: '15px', lg: '16px' }}
                    fontWeight="bold"
                  >
                    Invite New Affiliates- {number} Seats Available.
                  </Text>
                  :
                  <Text
                    color="#219691"
                    fontSize={{ base: '12px', md: '15px', lg: '16px' }}
                    fontWeight="bold"
                  >
                    Invite New Affiliates- {number} out of 8 Seats Available.
                  </Text>
                }
              </Grid>
              <Grid
                display="flex"
                w={{ base: '100%', md: '100%', lg: '100%' }}
                pt="15px"
              >
                <FormControl
                  display="flex"
                  flexDirection={{
                    sm: "row",
                    md: "row",
                  }}
                  px="10px"
                >

                  <Input
                    fontSize='sm'
                    ms='4px'
                    borderRadius='15px'
                    type='text'
                    placeholder='Email'
                    mb='24px'
                    onChange={(e) => { setEmail(e.target.value) }}
                    width={{
                      sm: "100%", // base
                      md: "50%", // 480px upwards
                      lg: "25%", // 768px upwards
                      xl: "100%", // 992px upwards
                    }}
                  />
                </FormControl>
              </Grid>
            </Flex>
          </ModalBody>

          <ModalFooter>
            {isSubmitted == false ?
              <Button
                w="100%"
                bg="#219691"
                colorScheme={"none"}
                // borderRadius="20px"
                // onClick={onClose}
                onClick={sendInvitation}
              >
                {/* {selectedLanguage !== "GB" ? Translate("Invite") : "Invite"} */}
                {invite}
              </Button>
              :
              <Button
                w="100%"
                bg="#219691"
                colorScheme='teal'
                variant='solid'
                isLoading
                loadingText='please wait....'
                onClick={sendInvitation}
              >
                {/* {selectedLanguage !== "GB" ? Translate("Invite") : "Invite"} */}
                {invite}
              </Button>
            }
          </ModalFooter>
          {/* </form> */}
        </ModalContent>
        {/* </form> */}
      </Modal>
    </Flex>

  )
}

export default Invite