import React, { useRef, useState, useEffect, useContext } from 'react';
import {
  Box,Grid, Button,SimpleGrid, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure
} from "@chakra-ui/react";
import {
  FacebookShareButton, LinkedinShareButton, TwitterShareButton, TelegramShareButton, WhatsappShareButton, PinterestShareButton, RedditShareButton,
  FacebookIcon, TwitterIcon, TelegramIcon, WhatsappIcon, LinkedinIcon, PinterestIcon, RedditIcon,
} from "react-share";
import { BiShareAlt } from "react-icons/bi";
import QRCode from "qrcode.react";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import Card from 'components/Card/Card';
import "./styles.css";
import CardHeader from 'components/Card/CardHeader';
import { useHistory } from 'react-router-dom';
import { getSellerStockDetails, sendLink } from 'components/API/AuthAPI';
import { forSignupModal } from './ContextModal';
import { Translate } from 'components/DeepL/Translate';
import { shareboutiques, download, view } from 'assets/constants/strings';


const SellerTable = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const [token, setToken] = useState();
  const [sellerDetails, setSellerDetails] = useState([]);
  const [shareLink, setShareLink] = useState();
  const [url, setUrl] = useState();
  let storeLink = 'https://myboutique.progfeel.co.in/';
  const featureContext = useContext(forSignupModal)
  const history = useHistory()


  useEffect(() => {
    const checkUserLogin = () => {
      const userToken = localStorage.getItem("token");
      setToken(userToken);
      if (token) {
        getSellerInfo();
      }
      if (!userToken) {
        history.push({
          pathname: "/"
        })
      }
    }
    checkUserLogin()

  }, [token])



 /**
  * The function retrieves seller information and formats it before setting it in the state.
  */
  const getSellerInfo = () => {
    setTimeout(() => {
      getSellerStockDetails(token)
        .then((responseJson) => {
          if (responseJson) {
            const sellerInfo = responseJson?.data || {};
            const parsedData = sellerInfo.map(({
              boutique_store_url = '',
              id = '',
              items_sold = '',
              no_of_items = '',
              order_value = '',
              seller_store = '',
              slug = '',
              store_name = '',

            }) => {
              return {
                boutique_store_url: boutique_store_url,
                id: id,
                items_sold: items_sold,
                no_of_items: no_of_items,
                order_value: order_value.toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                seller_store: seller_store,
                slug: slug,
                store_name: store_name,
              }
            })
            setSellerDetails(parsedData);
          }
        })
    }, 1000)
  }

  /**
   * The function "Share" generates a shareable link for a given slug using a token and a store link.
   * @param slug - The `slug` parameter is a string that represents a unique identifier for a specific
   * resource or page on a website. It is often used in URLs to help identify and locate specific
   * content. In this code snippet, the `slug` parameter is used to construct a share link for the
   * resource/page.
   */
  const Share = (slug) => {
    sendLink(token).then((res) => {
      if (res) {
        setShareLink(storeLink + slug + "/#" + res.your_link + "/");
      }
    })
  }


  /**
   * The function downloads a QR code as a PNG image.
   */
  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrValue}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

 /**
  * The function returns a custom cell style based on the value and rowActive status of a cell.
  */
  const customCellStyle = cellProps => {
    const { value, rowActive } = cellProps;
    return {
      color: rowActive
        ? '#e9ecf0'
        : value % 2 ? '#ff595e' : 'inherit',
    }
  };

  const columns = [
    {
      name: 'store_name', header: 'Boutiq Store', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'no_of_items', header: 'No of Items', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'items_sold', header: 'Items Sold', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'order_value', header: 'Order Value', minWidth: 130, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'seller_store', header: 'Seller User ID', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'slug', header: 'Share', defaultFlex: 1, minWidth: 120, headerAlign: "center", showHoverRow: "false",
      headerProps: {
        className: "tableHeader"
      },
      style: customCellStyle,
      render: ({ data }) => {
        return <div style={{ display: 'inline-block', textAlign: "center", justifyContent: "center" }}>
          <Button colorScheme='none'
            borderColor='#ffffff'
            color='#219691'
            bg="#219691"
            variant='outline'
            fontSize='xs'
            p='8px 32px'
            onClick={() => {
              Share(data.slug)
              onOpen()
              setUrl(data.boutique_store_url)
            }
            }><BiShareAlt color="#ffffff" size="20px" /></Button>
        </div>
      }
    },
    {
      name: 'boutique_store_url', header: 'View Boutiq', defaultFlex: 1, minWidth: 120, headerAlign: "center", showHoverRow: "false", headerProps: {
        className: "tableHeader"
      },
      style: customCellStyle,
      render: ({ data }) => {
        const getuser = () => {
          localStorage.setItem("storeIDname", data.store_name)
        }
        return <div style={{ display: 'inline-block', textAlign: "center", justifyContent: "center" }}>
          <Button colorScheme='none'
            borderColor='#ffffff'
            color='#ffffff'
            bg="#219691"
            variant='outline'
            fontSize='xs'
            onClick={() => {
              localStorage.setItem('storeId', data.seller_store)
              let userInfo = data.slug
              localStorage.setItem("storename", data.slug)
              featureContext.setCategoryRender(data.slug)
              getuser()
              window.open(`${window.location.origin}/${userInfo}`, '_blank', 'noopener,noreferrer')
            }}
            p='8px 32px'>{view}</Button>
        </div>
      }
    },
  ];

  const gridStyle = { minHeight: 580, maxWidth: 1700, textAlign: "center" };
  const qrValue = url;
  const rowStyle = { fontSize: "15.5px", fontStyle: "normal" }
  return (
    <Box
      borderRadius='15px'
      py='30px'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      align='center'>
      <Card 
        p='10px' 
        mt='5px'
        maxHeight="600px"
        maxWidth="1700px"
      >
        <CardHeader>
          <ReactDataGrid
            idProperty="id"
            columns={columns}
            dataSource={sellerDetails}
            style={gridStyle}
            rowStyle={rowStyle}
            rowHeight={50}
            headerHeight={55}
            showCellBorders={false}
            pagination
            showZebraRows={false}
            defaultLimit={10}
          />
        </CardHeader>
      </Card>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size={"sm"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{shareboutiques}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SimpleGrid
              columns={{ sm: 4, md: 4, lg: 4 }}
              justifyContent={"center"}
              spacing='20px'
            >
              <FacebookShareButton
                url={shareLink}
                quote={`My Boutiq offers local and global treasures. Explore them all now!`}
                hashtag={'#myboutiq'}
              >
                <FacebookIcon size={40} round />
              </FacebookShareButton>

              <TelegramShareButton
                url={shareLink}
                title={`My Boutiq offers local and global treasures. Explore them all now!`}
              >
                <TelegramIcon size={40} round />
              </TelegramShareButton>

              <PinterestShareButton
               description={`${shareLink}, My Boutiq offers local and global treasures. Explore them all now!`}
               >
                <PinterestIcon size={40} round />
              </PinterestShareButton>
              <RedditShareButton
                url={shareLink}
                title={`My Boutiq offers local and global treasures. Explore them all now!`}
              >
                <RedditIcon size={40} round />
              </RedditShareButton>

              <WhatsappShareButton
                url={shareLink}
                title={`My Boutiq offers local and global treasures. Explore them all now!`}
                separator=":: "
              >
                <WhatsappIcon size={40} round />
              </WhatsappShareButton>

              <TwitterShareButton
                url={shareLink}
                title={`My Boutiq offers local and global treasures. Explore them all now!`}
              >
                <TwitterIcon size={40} round />
              </TwitterShareButton>
              <LinkedinShareButton 
                url={shareLink}
                summary={`My Boutiq offers local and global treasures. Explore them all now!`}  
                source={shareLink}
                >
                <LinkedinIcon size={40} round />
              </LinkedinShareButton>
            </SimpleGrid>
            <Grid
              columns={{ sm: 1, md: 1, lg: 1 }}
              spacing='24px'
              pt="20px"
              justifyContent={"center"}
            >
              <QRCode
                id="qr-gen"
                value={shareLink}
                size={290}
                level={"Q"}
                includeMargin={true}

              />
              <Button
                bg="#219691"
                color="white"
                mt="20px"
                colorScheme="none"
                onClick={downloadQRCode}
              >
                {download}
              </Button>
            </Grid>
          </ModalBody>
        </ModalContent>
      </Modal>

    </Box>
  )
}

export default SellerTable