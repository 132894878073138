import { Flex, Grid, Select, Text } from '@chakra-ui/react';
import Emitter from 'Emitter';
import React, { useEffect, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { selectFrequency } from 'Slices/launchpadslice';

const SetRegularity = () => {
    const [postTimes, setPostTimes] = useState();
    const [period, setPeriod] = useState();
    const dispatch = useDispatch();
    let posts = [];
    for (let i = 1; i <= 10; i++) {
        posts.push(i);
    }
    // console.log(postTimes,period);

    useEffect(()=>{
        Emitter.emit("SELECT_FROM_POSTS", postTimes);
        Emitter.emit("SELECT_FROM_PERIOD",period);
        let combinedObject = { "postTimes":postTimes, "period": period };
        dispatch(selectFrequency(combinedObject));
    },[period,postTimes]);

    return (
        <Flex 
            flexDirection="column"
        >
            <Grid
                display="flex"
                margin="0 auto"
            >
                <Text
                    fontWeight={"bold"}
                    pt="20px"
                >
                    Regularity
                </Text>
            </Grid>
            <Grid>
                <Flex 
                    flexDirection="column"
                    pt="50px"
                >
                    <Text
                        textAlign="center"
                    >
                        How many Times
                    </Text>
                    
                    <Select onChange={(e)=>setPostTimes(e.target.value)}
                        icon={<MdArrowDropDown />}
                        placeholder="Choose how many time"
                    >
                        {posts.map((item) => {
                            return <option key={item} value={item}>{item}</option>
                        })}
                    </Select>
                </Flex>
                <Flex 
                    flexDirection="column"
                    mt="20px"
                >
                    <Text
                        textAlign="center"
                    >
                        Select Period
                    </Text>
                    <Select 
                        onChange={(e)=>setPeriod(e.target.value)}
                        icon={<MdArrowDropDown />}
                        placeholder="Choose period"
                    >
                        <option>Daily</option>
                        <option>Weekly</option>
                        <option>Monthly</option>
                    </Select>
                </Flex>
            </Grid>

        </Flex>
    )
}

export default SetRegularity