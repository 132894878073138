import {
  Flex, Grid, Icon, Text,
  Input,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton, useDisclosure, Select,
  useRadio, useRadioGroup, HStack, Box
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import ChooseElement from './ChooseElement';
import ChoosePlatform from './ChoosePlatform';
import ChoosePrice from './ChoosePrice';
import ChooseProduct from './ChooseProduct';
import ChooseText from './ChooseText';
import SelectAccount from './SelectAccount';
import SelectBackground from './SelectBackground';
import SetRegularity from './SetRegularity';
import "./style.css"

const MenuItem = ({ title, icon }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isPlatform, onOpenPlatform, onClosePlatform } = useDisclosure();
  const [state, setState] = useState();
  const sellers = ['ivo_store', 'bhushan_store', 'sachin_store'];
  // console.log {isAccount, Account, onClose} = useDisclosure();
  const btnRef = useRef()

  const [value, setValue] = useState('1')

  function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
      <Box as='label'>
        <input {...input} />
        <Box
          {...checkbox}
          cursor='pointer'
          borderWidth='1px'
          borderRadius='md'
          boxShadow='md'
          _checked={{
            bg: '#219691',
            color: 'white',
            borderColor: 'teal.600',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          px={3}
          py={3}
          w="120px"
          h="60px"

        >
          {props.children}
        </Box>
      </Box>
    )
  }

  
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'react',
    onChange: console.log,
  })

  const scrollbarColor = 'gray.500'

  const group = getRootProps()
  const handleClick = (title) => {
    onOpen()
    setState(title);
  }

  return (
    <Flex>
      <Grid
        border="0.5px solid black"
        overflow="hidden"
        borderRadius="50%"
        background={"#219691"}
        w='20'
        h='20'
        mt='10px'
        flexDir='column'
        justifyContent="center"
        display="flex"
        m="5px"
        onClick={() => { handleClick(title) }}
        key={title}
      >
        <Icon
          as={icon}
          display="flex"
          margin="0 auto"
          color="#FFFFFF"
        // onClick={handleClick(title)}
        />
        <Text
          color='#FFFFFF'
          fontWeight={"bold"}
          textAlign="center"
          whiteSpace='nowrap'
          overflow='hidden'
          textOverflow='ellipsis'
        >
          {title}
        </Text>

      </Grid>
      <Drawer
        isOpen={isOpen}
        placement='top'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w="90%"
          maxW="250px"
          ms={{
            sm: "5%",
          }}
          my={{
            sm: "120px",
          }}
          borderRadius="16px"
        >
          <DrawerCloseButton />
          {state == "Account" ?
            <DrawerHeader
              display="flex"
              justifyContent="center"
            >
              Select Account
            </DrawerHeader>
            :
            ""
          }
          {state == "Platform" ?
            <DrawerHeader
              display="flex"
              justifyContent="center"
            >
              Select Platform
            </DrawerHeader>
            :
            ""
          }
          {state == "Background" ?
            <DrawerHeader
              display="flex"
              justifyContent="center"
            >
              Select Background
            </DrawerHeader>
            :
            ""
          }
          {state == "Product" ?
            <DrawerHeader
              display="flex"
              justifyContent="center"
            >
              Select Product
            </DrawerHeader>
            :
            ""
          }
          {state == "Element" ?
            <DrawerHeader
              display="flex"
              justifyContent="center"
            >
              Select Element
            </DrawerHeader>
            :
            ""
          }
          {state == "Text" ?
            <DrawerHeader
              display="flex"
              justifyContent="center"
            >
              Choose Text
            </DrawerHeader>
            :
            ""
          }
          {state == "Price" ?
            <DrawerHeader
              display="flex"
              justifyContent="center"
            >
              Choose Price
            </DrawerHeader>
            :
            ""
          }
          {state == "Regularity" ?
            <DrawerHeader
              display="flex"
              justifyContent="center"
            >
              Choose Regularity
            </DrawerHeader>
            :
            ""
          }
          <DrawerBody
            mb='10%'
          >

            {state == "Account" ?
              <SelectAccount 
                onClick={onClose}
              />
              :
              ""
            }
            {state == "Platform" ?
              <ChoosePlatform 
                onClick={onClose}
              />
              :
              ""
            }
            {state == "Background" ?
              <SelectBackground 
                onClick={onClose}
              />
              :
              ""
            }
            {state == "Product" ?
              <ChooseProduct
                onClick={onClose}
              />
              :
              ""
            }
            {state == "Element" ?
              <ChooseElement 
                onClick={onClose}
              />
              :
              ""
            }
            {state == "Price" ?
              <ChoosePrice 
                onClick={onClose}
              />
              :
              ""
            }
            {state == "Text" ?
              <ChooseText 
                onClick={onClose}
              />
              :
              ""
            }
            {state == "Schedule" ?
              <SetRegularity />
              :
              ""
            }
          </DrawerBody>

        </DrawerContent>
      </Drawer>
    </Flex>
  )
}

export default MenuItem