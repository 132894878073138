import { Avatar, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'

const StoreBackground = (props) => {

    return (
        <div>
            <Flex position='relative'
                direction={{ sm: "column", md: "row" }}
                w='100%'
            >
                {
                    props.backgroundProfile.length > 0 ? props.backgroundProfile.map((image, index) => {
                        return (
                            <Flex
                                key={index}
                                w='100%'
                            >
                                <Image
                                    className='home_background_img'
                                    borderRadius='0px'
                                    src={'https://adminmyboutiqe.progfeel.co.in' + (image.store_background_image)}
                                    w={{ sm: "100%", md: '100%', xl: "100%" }}
                                    h={{ sm: "200px", md: '300px', xl: "370px" }}
                                />
                                <Avatar
                                    className='home_inner_img'

                                    src={'https://adminmyboutiqe.progfeel.co.in' + image.store_profile_image}
                                    w={{ sm: "110px", md: '220px', xl: "240px" }}
                                    h={{ sm: "110px", md: '220px', xl: "240px" }}

                                    borderRadius='full'
                                />
                            </Flex>
                        )
                    }) : ''
                }
            </Flex>
            <>
                <Flex flexDirection="column" maxWidth='100%'>
                    {
                        props.backgroundProfile.map((ele, index) => (
                            ele ?
                                <Flex
                                    flexDir={'column'}
                                    key={index}
                                >
                                    <Text zIndex='1' noOfLines={[1, 2, 3]}
                                        className='home_wrapper_description'
                                        style={{ fontSize: props.fontsetting }}
                                        display='inline' mt='4'
                                        justifyContent='left'
                                    >
                                        {ele.store_description.replace(/(<([^>]+)>)/ig, '')
                                        }
                                    </Text>
                                    <Text
                                        onClick={props.getStoreInformation}
                                        cursor='pointer'
                                        color='blue.600'
                                        zIndex='1' noOfLines={[1, 2, 3]}
                                        className='home_wrapper_description'
                                        style={{ fontSize: props.fontsetting }}
                                        display='inline' mt='4'
                                    >
                                        Store Information
                                    </Text>
                                </Flex>
                                :
                                <>
                                    <Text
                                        onClick={props.getStoreInformation}
                                        zIndex='1'
                                        noOfLines={[1, 2, 3]}
                                        className='home_wrapper_description'
                                        style={{ fontSize: props.fontsetting }}
                                        display='inline' mt='4'
                                        cursor='pointer'
                                        color='blue.600'
                                    >
                                        {/* {Translate("Store Information") ? Translate("Store Information") : "Store Information"} */}
                                        Store Information
                                    </Text>
                                </>
                        ))
                    }
                </Flex>
            </>
        </div>
    )
}

export default StoreBackground