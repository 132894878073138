import { mode } from "@chakra-ui/theme-tools";

const font =  "'Quicksand', sans-serif";

export const globalStyles = {
  colors: {
    gray: {
      700: "#1f2733",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode("#f8f9fa")(props),
        fontFamily: font,
      },
      html: {
        fontFamily: font,
      }
    }),
  },
};
