import { Box, Checkbox, Flex, Grid, HStack, Icon, Image, Radio, RadioGroup, SimpleGrid, Stack, Text, useRadio, useRadioGroup } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BsYoutube } from 'react-icons/bs'
import { FaTiktok, } from 'react-icons/fa'
import { SiGooglemybusiness } from 'react-icons/si'
import instagram from "../../../assets/img/instagram.png";
import { FacebookIcon, LinkedinIcon, PinterestIcon, RedditIcon, TelegramIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import Emitter from 'Emitter';
import { Translate } from 'components/DeepL/Translate'
import { useDispatch } from 'react-redux';
import { selectPlatform } from 'Slices/launchpadslice';
import { getConnectedAccounts } from 'components/API/AuthAPI'
import { platforms } from 'assets/constants/strings'

const ChoosePlatform = (props) => {
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [plt, setPlt] = useState();
  const dispatch = useDispatch();

  function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    const activeAccountLists = () => {
      getConnectedAccounts().then((res) => {
        if (res) {
          console.log('ele101', res);
          // setAccounts(res.activeSocialAccounts);
        }
      })
    }

    return (
      <Box as='label'>
        <input {...input} />
        <Box
          {...checkbox}
          cursor='pointer'
          borderWidth='1px'
          borderRadius='md'
          boxShadow='md'
          _checked={{
            bg: '#219691',
            color: 'white',
            borderColor: 'teal.600',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          px={1}
          py={3}
          w={{ sm: "120px", md: "120px", lg: "120px" }}
          h={{ sm: "60px", md: "60px", lg: "80px" }}
        >
          {props.children}
        </Box>
      </Box>
    )
  }

  const facebook_options = ['Facebook', 'Facebook_Group'];
  const instagram_options = ['Instagram'];
  const tiktok_options = ['TikTok'];
  const pinterest_options = ['Pinterest'];
  const twitter_options = ['Twitter'];
  const linkedIn_options = ['LinkedIn'];
  const reddit_options = ['Reddit'];
  const google_options = ['GoogleMyBusiness'];
  const youtube_options = ['YouTube'];
  const telegram_options = ['Telegram'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'react',
    onChange: setSelectedPlatform,
  })

  useEffect(() => {
    const expr = selectedPlatform;
    switch (expr) {
      case 'Facebook':
        setPlt('facebook');
        break;

      case 'Facebook_Group':
        setPlt('fbg');
        break;

      case 'Instagram':
        setPlt('instagram');
        break;

      case 'LinkedIn':
        setPlt('linkedin')
        break;

      case 'Twitter':
        setPlt('twitter');
        break;

      case "Pinterest":
        setPlt('pinterest');
        break;

      case "Reddit":
        setPlt('reddit');
        break;

      case "Telegram":
        setPlt('telegram');
        break;

      case "TikTok":
        setPlt('tiktok');
        break;

      case "YouTube":
        setPlt('youtube');
        break;

      case "GoogleMyBusiness":
        setPlt('gmb');
        break;

      default:
    }

    Emitter.emit('SELECTED_PLATFORM', plt);

  }, [selectedPlatform]);

  const handleSelectPlatform = () => {
    if (plt) {
      dispatch(selectPlatform(plt))
      if (window.matchMedia("(max-width: 768px)").matches) {
        props.onClick();
      }
    }
  }
  useEffect(() => {
    handleSelectPlatform()
  }, [plt])


  const scrollbarColor = 'gray.500'

  const group = getRootProps()

  return (
    <Flex
      flexDirection={"column"}
    >
      <Grid>
        <Text
          textAlign={"center"}
          fontWeight="bold"
        >
          {/* {Translate ? "Elements" : Translate("Elements")} */}
          {platforms}
        </Text>
      </Grid>
      <Box
        // overflowX="auto"
        flexWrap={"wrap"}
        maxW="100vw"
        maxH={{ sm: "40vh", md: "40vh", lg: "88vh" }}
        // whiteSpace="nowrap"
        pb="17px"
        pt="15px"
        //  color="white"

        px="0px"
        sx=
        {
          {
            '&::-webkit-scrollbar': {
              height: '2px',
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              height: '2px',
              width: '2px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: scrollbarColor,
              borderRadius: '24px',
            },
          }
        }
      >
        <HStack
          flexDirection="row"
          pb="20px"
          {...group}
        >
          {facebook_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Icon
                  as={FacebookIcon}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack>

        <HStack
          flexDirection="row"
          pb="20px"
        >
          {instagram_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Image src={instagram}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack>

        <HStack
          flexDirection="row"
          pb="20px"
        >
          {pinterest_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Icon
                  as={PinterestIcon}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack>

        {/* <HStack
          flexDirection="row"
          pb="20px"
        >
          {tiktok_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Icon
                  as={FaTiktok}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack> */}

        {/* <HStack
          flexDirection="row"
          pb="20px"
        >
          {youtube_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Icon
                  as={BsYoutube}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                  color="#FF0000"
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack> */}

        <HStack
          flexDirection="row"
          pb="20px"
        >
          {twitter_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Icon
                  as={TwitterIcon}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack>

        <HStack
          flexDirection="row"
          pb="20px"
        >
          {telegram_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Icon
                  as={TelegramIcon}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack>

        <HStack
          flexDirection="row"
          pb="20px"
        >
          {google_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Icon
                  as={SiGooglemybusiness}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack>

        <HStack
          flexDirection="row"
          pb="20px"
        >
          {linkedIn_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Icon
                  as={LinkedinIcon}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack>

        <HStack
          flexDirection="row"
          pb="20px"
        >
          {reddit_options.map((value) => {
            const radio = getRadioProps({ value })
            return (
              <RadioCard key={value} {...radio}>
                <Icon
                  as={RedditIcon}
                  display="flex"
                  margin="auto"
                  w={{ sm: "20px", md: "20px", lg: "25px" }}
                  h={{ sm: "20px", md: "20px", lg: "30px" }}
                />
                <Text
                  textAlign={"center"}
                  fontSize={{ sm: "xs", md: "xs", lg: "sm" }}
                >
                  {value}
                </Text>
              </RadioCard>
            )
          })}
        </HStack>
      </Box>
    </Flex>
  )
}


// return (
//   <Flex
//     flexDirection={"column"}
//     // justifyContent={"center"}
//     w="100%"
//     h={{ sm: "70%", lg: "100vh" }}
//     backgroundSize='cover'
//     backgroundRepeat='no-repeat'
//     pt="20px"
//     pl="20px"
//     pr="20px"
//     pb="80px"
//   >
//     <Grid
//       pt="0px"
//       pb="20px"
//     >
//     </Grid>

//     <RadioGroup

//       onChange={setValue}
//       value={value}
//     >
//       <SimpleGrid
//         columns={{ sm: 3, md: 3, xl: 3 }}
//         spacing="24px"
//       >
//         <Grid >
//           <Icon
//             as={FaFacebookSquare}
//             margin="auto"
//             w="50px"
//             h="50px"
//           />
//           {/* <Text 
//                 textAlign={"center"}
//               >
//                 Facebook Post
//               </Text> */}
//           <Radio
//             value="facebook"
//             textAlign="center"
//           >
//             FacebookPost
//           </Radio>
//         </Grid>
//         <Grid>
//           <Icon
//             as={FaFacebookSquare}
//             margin="auto"
//             w="50px"
//             h="50px"
//           />
//           <Text>
//             Facebook Group
//           </Text>
//         </Grid>
//         <Grid>
//           <Icon
//             as={FaFacebookSquare}
//             margin="auto"
//             w="50px"
//             h="50px"
//           />
//           <Text>
//             Facebook Story
//           </Text>
//         </Grid>
//       </SimpleGrid>

{/* <SimpleGrid
          // flexDirection={{sm:"column",lg:"row"}}
          border={"2px solid black"}
          px="10px"
          py="20px"
          columns={{ sm: 3, md: 3, xl: 3 }}
          spacing='20px'
        // justifyContent={"center"}

        >
          

          <Grid justifyContent="center">
            <FaFacebookSquare
              size="40px"
            />
            <Radio
              value="facebook"
              textAlign="center"
            >
              facebook
            </Radio>
          </Grid>

          <Grid justifyContent="center">
            <BsInstagram
              size="40px"
            />
            <Radio
              value="instagram"
              justifyContent="center"
            >
              instagram
            </Radio>
          </Grid>

          <Grid justifyContent="center">
            <BsTelegram
              size="40px"
            />
            <Radio
              value="telegram"
              justifyContent="center"
            >
              telegram
            </Radio>
          </Grid>

          <Grid justifyContent="center">
            <FaPinterestSquare
              size="40px"
            />
            <Radio
              value="Pinterest"
              justifyContent="center"
            >
              Pinterest
            </Radio>
          </Grid>

          <Grid justifyContent="center">
            <FaTwitterSquare
              size="40px" />
            <Radio
              value="twitter"
              justifyContent="center"
            >
              twitter
            </Radio>
          </Grid>

          <Grid justifyContent="center">
            <FaTiktok
              size="40px"
            />
            <Radio
              value="tiktok"
              justifyContent="center"
              fontSize="xl"
            >
              tiktok
            </Radio>
          </Grid>

        </SimpleGrid> */}

// </RadioGroup>
{/* <Stack direction="row" mb="40px">
        
          <Flex pt="20px">
            <SimpleGrid  
              columns={{ sm: 3, md: 3, xl: 3 }}
              spacing="24px"
              >
              <Grid >
                <Icon 
                  as={FaFacebookSquare}
                  margin="auto"
                  w="20px"
                  h="20px"  
                />
                <Text 
                  textAlign={"center"}
                >
                  Facebook Post
                </Text>
              </Grid>
              <Grid>
                <Icon 
                  as={FaFacebookSquare}
                  margin="auto"
                  w="20px"
                  h="20px"  
                />
                <Text>
                  Facebook Group
                </Text>
              </Grid>
              <Grid>
                <Icon 
                  as={FaFacebookSquare}
                  margin="auto"
                  w="20px"
                  h="20px"
                />
                <Text>
                  Facebook Story
                </Text>
              </Grid>
            </SimpleGrid>
          </Flex>
        
      </Stack> */}


// </Flex>
// )
// }

export default ChoosePlatform