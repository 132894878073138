import React, { useEffect } from 'react';
import { Box, Flex, Grid,} from "@chakra-ui/react";
import Header from './components/Header';
import { useHistory } from 'react-router-dom';


const AffiliateProducts = () => {
    let history = useHistory();

    const checkUserLogin=()=>{
        let userToken = localStorage.getItem("token");
        if(!userToken){
            console.log("empty")
            history.push({
                pathname: "/"
            });
        }
    }

    useEffect(()=>{
        checkUserLogin();
    },[]);

    return (
        <Flex 
            direction='column' 
            pt={{ base: "70px", md: "75px", lg:"70px" }}
            pl="10px"
            pr='10px'    
        >
        <Grid templateColumns={{ sm: "1fr", lg: "1fr", xl:"1fr"}} templateRows='2fr'>
            <Box>
                <Grid  templateColumns={{
                        sm: "1fr",
                        md: "1fr",
                        lg: "1fr",
                        xl: "1fr",
                    }}
                    templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}>
                <Header/>
                </Grid>
                <Grid
                    templateColumns={{
                        sm: "1fr",
                        md: "1fr 1fr",
                        lg: "1fr 1fr 1fr 1fr",
                        xl: "2fr 2fr 2fr 2fr",
                    }}
                    templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
                    gap='40px'>
                    
                </Grid>
            </Box>
    
        </Grid>
    
    </Flex>
    )
}

export default AffiliateProducts