export const invite = "Invite";
export const cancel = "Cancel";
export const enteremail = "Please enter the email!";
export const affiliateboutiqs = "AFFILIATE BOUTIQS";
export const New = "New";
export const view = "View";
export const shareboutiques = "Share Boutiq Store"
export const download = "Download";
export const shareproducts = "Share Product";
export const selectStore = "Please select store!";
export const viewStore = "View Store";
export const qrCode = "QR Code";
export const storeLink = "Store Link"
export const copied = "Link copied";
export const yes = "Yes";
export const no = "No";
export const  removeTeamMemberConfirmation = "Do you want to remove the team-member ?";
export const teamMemberAdded = "Team member deleted successfully";
export const active = "Active";
export const inactive = "Inactive";
export const affiliatePayout = "AFFILIATE PAYOUT";
export const payoutSetting = "Payout Settings";
export const balance = "Balance";
export const lastrequested = "Last Requested";
export const accountNumber = "Account Number";
export const swiftCode = "Swift Code";
export const payoutRequest = "Payout Request";
export const PayPal = "PayPal";
export const PayPalEmail = "PayPal Email";
export const bank = "Bank";
export const bankAccout = "Bank Account";
export const goBack = "Go Back";
export const availableBalance = "Available Balance";
export const withdrawalAmount = "Amount to Withdraw";
export const chooseMethod = "Choose Method";
export const description = "Description";
export const optional = "optional";
export const request = "Request";
export const choosePayementType = "Please Select Payment Type";
export const enterAmount = "Please Enter Amount";
export const requestSent = "Request Sent";
export const paymentRequest = "Payment Request"
export const selectSellerAccount = "Select Seller Account";
export const account = "Account";
export const platforms = "Platforms";
export const backgroundImages = "Background Images";
export const selectProductText = "Select Product";
export const pleaseSelectSellerAcc = "Please Select Seller Account";
export const select = "Select";
export const elements = "Elements";
export const addText = "Add a text box";
export const showPrice = "Show Price On Image";
export const chooseProductText = "Please Choose Product";
export const priceText = "Price";
export const postCaption = "Post Caption";
export const addCaption = "Add Caption";
export const addPage = "Add Page";
export const removePage = "Remove Page";
export const postText = "Post";
export const postLaterText = "Post Later";
export const schedulePostText = "Schedule Post";
export const noOfTimesText = "How many Times";
export const selectPeriod = "Select Period";
export const daily = "Daily";
export const weekly = "Weekly";
export const monthly = "Monthly";
export const startDateText = "Start Date";
export const autoScheduleText = "Auto schedule set successfully";
export const alreadyScheduledText = "Already scheduled";
export const enterTextField = "Please enter text field";
export const somethingWentWrong = "Something went wrong";
export const postUploadedText = "Post uploaded successfully";
export const pageText = "Page";
export const detailsAddedmsg = "Account details updated successfully";
export const bankDetails = "Bank Details";
export const IBAN = "IBAN";
export const BIC = "BIC";
export const addressText = "Address";
export const cityText = "City";
export const postCodeText = "Post Code";
export const regionText = "Region";
export const countryText = "Country";
export const payoutFundmsg = "Payout of funds(in days)";
export const paypalDetails = "PayPal Details";
export const saveText = "Save";
export const cancelText = "Cancel";
export const reachedInvitationLimitmsg = "You have reached the invitation limit";
export const enterValidEmail = "Please enter a valid email address";
export const teamMember = "AFFILIATE TEAM MEMBER";
export const myProfile = "My Profile";
export const inviteTeamMember = "Invite New Member";
export const userIsAlreadyRegistered = "This user is already registered on myboutiq!";
export const addToBag = "Add to Bag";
export const viewAllProductDetails = "View all Product Details";
export const ProductDetailsText = "Product Details";
export const deliveryText1 = "This item dispatches in 1-3 business days";
export const deliveryText2 = "14 days free returns worldwide";
export const deliveryText3 = "view our full devlivery & policies return";
export const uniqueSellingPoint = "Unique Selling Point";
export const product_description = "Product Description";
export const deliveryText = "Delivery & Returns";
export const subscriptionPeriod = "Subscription Billing Period";
export const subscriptionFees = "Subscription Fees";
export const myCart = "My Cart";
export const checkout = "Checkout";
export const SubTotal = "SubTotal";
export const enterPasswordText = "Please enter your password";
export const enterValidCredentialsText = "Please enter valid credentials to SignIn";
export const enterEmailAndPassoword = "Please enter your email and password";
export const entervalidEmailandPassword = "Please enter valid email and password";
export const enterEmailandUsername = "Please enter your email and username";
export const enteremailText = "Please enter your email."
export const usernameAlreadyExist = "Username is already exist";
export const somethingWentWrongMsg = "Something went wrong, Please try again later.";
export const enterUsername = "Please enter your username"
export const alreadyVerifiedEmailmsg = "Email is already varified.";
export const emailverified = "Email verified successfully.";
export const signInText = "Sign in";
export const usernameOrEmail = "Username or Email";
export const passwordText = "Password";
export const rememberMe = "Remember Me";
export const forgotpasswordText = "Forgot Password?";
export const loginText = "Login";
export const continueWith = "or continue with";
export const signInwithGoogle = "Sign in using Google";
export const signInwithFacebook = "Sign in using Facebook";
export const donothaveacc = "Don't have account?";
export const signUpText = "Sign up";
export const invitationAccepted = "Invitation accepted.";
export const registermsg="Your are registered on MyBoutiq.";
export const alreadyRegisteredText = "You are already registered!";
export const registerText = "Register";
export const alreadyHaveAccount = "Already have an account?";
export const emailText = "Email";
export const usernameText = "Username";
export const createAccount = "Create an Account";
export const forgotpasswordText1 = "Forgot Password";
export const resetPassword = "Reset Password";
export const resetpasswordText = "Reset Password";
export const resetPasswordLinkText = "Reset password link has been sent on your email";
export const confirmPasswordText = "Confirm Password";
export const passwordsetText = "Password set successfully.";
export const linkExpireText = "Link is expired please try again!";
export const exploreStoreText = "EXPLORE OUR STORES";
export const viewMoreText = "View More";
export const galleryText = "Gallery";
export const categoriesText = "Categories";
export const wishlistText = "Wish List";
export const addedToWishlistText = "Added to wishlist";
export const removefromWishlistText = "Removed from wishlist";
export const proceedToLogin = "Please login to proceed."
export const viewDetails = "View Details";
export const viewGalleryText = "VIEW GALLERY";
