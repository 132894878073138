import React from 'react'
import { Image, Center, Text, Flex, Box, Button, ChakraProvider } from '@chakra-ui/react'
import { BsFacebook, BsPinterest } from 'react-icons/bs'
import { FaTwitterSquare, FaInstagramSquare } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { AiFillHeart } from 'react-icons/ai'




function ReturnOrder(props) {


    return (
        <div className='returnorder_wrapper'>
            <Center>

                <Flex direction='column' pt={{ base: "100px", md: "60px" }}>
                    <Center>
                        <Flex fontSize='4xl' fontWeight='bold' flex='auto' justifyContent='center'
                            color='#219691'
                        >MY BOUTIQ</Flex>
                    </Center>

                    <Center mt='10'>

                        <Image
                            // w={{ sm: '100%', md: '100%', lg: '100%' }}
                            w='50vh' h='400px'
                            src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeujuRNmNmq07rLZjcdSSYCW3DEXAycbGHkONmpeUx&s'
                        />
                    </Center>

                    <Center mt='10'>
                        <Box maxW='30rem'>

                            <Text fontSize='xl' mt='5'>
                                You are receiving this email because your customer has requested to cancel/return his order.
                            </Text>

                        </Box>
                    </Center>
                    <Center >


                        <Link to='/affilateInvite'>
                            <Button
                                mt='16'
                                bg='#219691' size='lg'
                                colorScheme='none'
                                w={{ lg: "sm" }}
                            >
                                VIEW RETURN ORDER
                            </Button>
                        </Link>



                    </Center>




                    <Center display='flex' mt='20'>
                        <BsFacebook size='3%' />
                        <FaInstagramSquare size='3%' style={{ marginLeft: "10px" }} />
                        <BsPinterest size='3%' style={{ marginLeft: "10px" }} />
                        <FaTwitterSquare size='3%' style={{ marginLeft: "10px" }} />
                    </Center>


                    <Center display='flex' flexDirection='column' mt='10'>
                        <Text>
                            Sent with <AiFillHeart style={{ display: "initial" }} /> from MY BOUTIQ
                        </Text>
                        <Text>
                            Imprint | Data Privacy
                        </Text>
                        <Text>
                            Unsubscribe
                        </Text>
                    </Center>


                </Flex>

            </Center>

        </div>
    )
}


export default ReturnOrder;