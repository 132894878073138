import { ayrshareBaseUrl, baseUrl, bearerToken, currencyKey, guestToken, PayPalAccessToken, paypalBaseUrl, PayPalPass, PayPalUser } from "./APIconst";

export const register = (username, password, email, phone_number, user_type) => {
    let params = {
        username: username,
        password: password,
        email: email,
        phone_number: phone_number,
        user_type: user_type,
    }

    return fetch(baseUrl + "/api/v0/auth/register/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,

        },
        body: JSON.stringify(params),
    }).then((res) => res.json())

};

export const login = (username, password, user_type) => {
    let params = {
        username_or_email: username,
        password: password,
        user_type: user_type,

    }
    // console.log(params);
    return fetch(baseUrl + "/api/v0/auth/login/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,

        },
        body: JSON.stringify(params),
    }).then((res) => res.json())

};

export const loginWithSDK = (email, user_type, accesstoken, uid, loginType, name) => {
    let params = {
        username_or_email: email,
        password: "",
        user_type: user_type,
        access_token: accesstoken,
        uid: uid,
        login_type: loginType,
        name: name
    }
    // console.log(params);
    return fetch(baseUrl + "/api/v0/auth/login/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,

        },
        body: JSON.stringify(params),
    }).then((res) => res.json())

}

export const changePass = (token, old_password, new_password) => {
    let params = {
        old_password: old_password,
        new_password: new_password,
    }
    //console.log("params", params);
    return fetch(baseUrl + "/api/v0/auth/change-password/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const forgotpassword = (email) => {
    let params = {
        email: email,
    }
    return fetch(baseUrl + "/api/v0/auth/forgot-password/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};


export const resetAccountpassword = (email) => {
    let params = {
        email: email,
    }
    return fetch(baseUrl + "/api/v0/buyer/buyer_forget_password/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};


export const confirmresetpassword = (token, uid, new_password1, new_password2) => {
    let params = {
        token: token,
        uid: uid,
        new_password1: new_password1,
        new_password2: new_password2,
    }
    //console.log("params---", params)
    return fetch(baseUrl + "/api/v0/buyer/buyer_forgot_password/confirm/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const resetPassword = (token, uid, new_password1, new_password2) => {
    let params = {
        token: token,
        uid: uid,
        new_password1: new_password1,
        new_password2: new_password2,
    }
    return fetch(baseUrl + "/api/v0/auth/forgot-password/confirm/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
}

export const dashboard = (token) => {

    return fetch(baseUrl + "/api/v0/dashboard/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json())

};

export const getDashboardByDate = (token, dateFrom, dateTo) => {

    return fetch(baseUrl + "/api/v0/dashboard/?record_from_date=" + dateFrom + "&record_to_date=" + dateTo, {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        }
    }).then((res) => res.json());
};

export const getInitialDashboardValues = (token) => {
    return fetch(baseUrl + "/api/v0/dashboard/affiliateallrevaune/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }).then((res) => res.json());
};

export const getUserProfile = (token) => {

    return fetch(baseUrl + "/api/v0/accounts/user-profile/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json())
};

export const updateprofile = (loginToken, firstname, lastname, bio, address, city, country, username, email, phone, image) => {
    const fd = new FormData();
    // console.log(token, firstname, lastname, bio, address, city, country, username, email, phone, image, imageLink );
    fd.append('first_name', firstname)
    fd.append('last_name', lastname)
    if (image) {
        // console.log("true");
        fd.append('user_profile.image', image)
    }
    fd.append('username', username)
    fd.append('user_profile.bio', bio)
    fd.append('user_profile.mobile_number', phone)
    fd.append('user_profile.address', address)
    fd.append('user_profile.city', city)
    fd.append('user_profile.country', country)
    // fd.append('user_profile.email', email)



    return fetch(baseUrl + "/api/v0/accounts/update-profile/308/", {
        method: "PUT",
        headers: {
            Authorization: "Token " + loginToken,
            // "Content-Type": "multipart/form-data"
        },
        body: fd,
    }).then((res) => res.json())

}

export const getuserupdateprofile = (loginToken, firstname, lastname, bio, address, city, country, email, phone, username, code, id) => {
    const fd = new FormData();
    // console.log(token, firstname, lastname, bio, address, city, country, username, email, phone, image, imageLink );
    fd.append('first_name', firstname)
    fd.append('last_name', lastname)

    fd.append('username', username)
    fd.append('user_profile.bio', bio)
    fd.append('user_profile.mobile_number', phone)
    fd.append('user_profile.address', address)
    fd.append('user_profile.city', city)
    fd.append('user_profile.country', country)
    fd.append('user_profile.code', code)
    // fd.append('user_profile.email', email)



    return fetch(baseUrl + "/api/v0/buyer/buyer_update_profile/" + id + "/", {
        method: "PUT",
        headers: {
            Authorization: "Token " + loginToken,
            // "Content-Type": "multipart/form-data"
        },
        body: fd,
    }).then((res) => res.json())

}


export const InviteMember = (token, first_name, last_name, email) => {
    let params = {
        first_name: "-",
        last_name: "_",
        email: email
    }
    //console.log("Params----", params)
    return fetch(baseUrl + "/api/v0/accounts/affiliate-seller/invite/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json())
}

export const sellerinvitation = (token, user_type, password) => {
    let params = {
        token: token,
        user_type: "seller",
        password: password
    }
    //console.log("params", params)
    return fetch(baseUrl + "/api/v0/accounts/affiliate-seller/accept-invite/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,
        },
        body: JSON.stringify(params),
    }).then((res) => res.json())
}

export const getAffiliateProductDetails = (token, id) => {
    // console.log("token", token)
    return fetch(baseUrl + "/api/v0/product/affiliate/list/" + id + "/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    }).then((res) => res.json());
};

export const getAffiliateOrders = (token) => {
    return fetch(baseUrl + "/api/v0/accounts/order-list/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};

export const affiliateOrderDetails = (token, id) => {
    // console.log("id", id);
    return fetch(baseUrl + "/api/v0/affiliate/order-list-by-id/" + id + "/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    }).then((res) => res.json());
};

export const getPayoutDetails = (token, id) => {

    return fetch(baseUrl + "/api/v0/affiliate/payout-settings/" + id + "/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};

export const createPayoutDetails = (token, id, iban, bic, address, city, post_code, region, country, paypalemail, payoutfund) => {
    let params = {
        account_number: iban,
        swift_code: bic,
        country: country,
        paypal_email: paypalemail,
        address: address,
        city: city,
        post_code: post_code,
        region: region,
        payout_of_funds: payoutfund,
        vendor_profile: id
    }
    return fetch(baseUrl + "/api/v0/affiliate/payout/create/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
}

export const updatePayoutDetails = (token, id, iban, bic, address, city, post_code, region, country, paypalemail, payoutfund) => {
    let params = {
        account_number: iban,
        swift_code: bic,
        country: country,
        paypal_email: paypalemail,
        address: address,
        city: city,
        post_code: post_code,
        region: region,
        payout_of_funds: payoutfund,
    }
    return fetch(baseUrl + "/api/v0/affiliate/payout/"+id+"/", {
        method: "PUT",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const getAffiliateTeamMember = (token) => {
    return fetch(baseUrl + "/api/v0/affiliate/team_member/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};

export const removeAffiliateTeamMember = (token, id) => {
    return fetch(baseUrl + "/api/v0/affiliate/team_member/remove/" + id + "/", {
        method: "DELETE",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};



export const getBackgroundList = (storeName) => {
    // console.log("response101", storeName)
    return fetch(baseUrl + "/api/v0/store/get_home/" + storeName + "/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};


export const getsellerProduct = (id) => {
    return fetch(baseUrl + "/api/v0/store/get_seller_product/" + id + "/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};

export const getProductById=(id)=>{
    return fetch(baseUrl + "/api/v0/store/product_details/" + id + "/",{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res)=> res.json());
};



export const getCategoryProduct = (id, prouctId) => {
    return fetch(baseUrl + "/api/v0/store/get_seller_product/" + id + "/" + prouctId + "/", {
        method: "GET",
        headers: {
            // Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};




export const currencyList = (token) => {
    return fetch(baseUrl + "/api/v0/order/currency-list/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};



export const sellerCurrencyList = (id) => {
    return fetch(baseUrl + "/api/v0/store/seller_currency/" + id + "/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};


export const themeSetting = (token) => {
    return fetch(baseUrl + "/api/v0/order/theme-settings/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};


export const newsLetter = (token, email) => {
    let params = {
        email: email
    }
    return fetch(baseUrl + "/api/v0/affiliate/news_letter_create/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const accountRegister = (email, password, username, user_type) => {
    let params = {

        email: email,
        password: password,
        username: username,
        user_type: user_type
    }
    //console.log("params----", params)
    return fetch(baseUrl + "/api/v0/auth/register/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};


export const getSellerStockDetails = (token) => {
    return fetch(baseUrl + "/api/v0/affiliate/seller/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}


export const inviteAffiliateMember = (token, email) => {
    let params = {
        email: email,
    }
    //console.log("parmas-------", email, token)
    return fetch(baseUrl + "/api/v0/affiliate/invite-affiliate/create/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res).then(res => {

        if (res.status == 200) {

            return res.json();

        } else if (res.status == 403) {

            return res.json();

        }
    })
};

export const acceptAffiliateInvitation = (token, user_type, password) => {
    let params = {
        token: token,
        user_type: "affiliate",
        password: password
    }
    return fetch(baseUrl + "/api/v0/affiliate/accept-affiliate-invitation/", {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": "application/json",
            "GUEST-AUTH-TOKEN": guestToken,
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};


export const getAccountProfile = (loginToken) => {
    //console.log("loginToken", loginToken)
    return fetch(baseUrl + "/api/v0/buyer/get_buyer_profile/", {
        method: "GET",
        headers: {
            Authorization: "Token " + loginToken,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}



export const getAllStoreproducts = () => {
    return fetch(baseUrl + "/api/v0/store/get_product/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}



export const getCheckoutProduct = (token, id, quantity) => {
    let params = {
        product: JSON.stringify(id),
        quantity: quantity,
        token: token
    }
    // console.log("params---", params)
    return fetch(baseUrl + "/api/v0/order/add_to_cart/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: 'application/json',
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};





export const getCartDetails = (getId, token) => {

    return fetch(baseUrl + "/api/v0/order/get_cart/" + getId + "/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};







export const deleteCartProduct = (getId, token) => {

    return fetch(baseUrl + "/api/v0/order/delete_cart/" + getId + "/", {
        method: "DELETE",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};


export const updateCartProduct = (getId, cartid, quantity, varient, token) => {

    let params = {
        product: cartid,
        quantity: quantity,
        varient: varient,
        token: token
    }
    // console.log("params-----------", quantity)
    return fetch(baseUrl + "/api/v0/order/update_cart/" + getId + "/", {
        method: "PUT",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};



export const getMyOrdersList = (token, orderId, email) => {

    let params = {
        order_id: JSON.stringify(orderId),
        email_client: email,
    }
    // console.log("email_client-----------", params)
    return fetch(baseUrl + "/api/v0/buyer/buyer_order_post/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};


export const getMyOrdersOverview = (token) => {

    return fetch(baseUrl + "/api/v0/buyer/buyer_order_details/1/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },

    }).then((res) => res.json());
};




export const getReturnOrderItem = (token, order, email) => {
    let params = {
        merchant_order_id: order,
        email_client: email,
    }
    return fetch(baseUrl + "/api/v0/buyer/buyer-reserve-order/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json())
};



export const getOrderSummary = (token) => {

    return fetch(baseUrl + "/api/v0/order/get_checkout/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },

    }).then((res) => res.json());
};

export const getProductListInLaunchpad = (token, id) => {
    //console.log("token",token,id)
    return fetch(baseUrl + "/api/v0/store/get_seller_product/" + id + "/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};





export const getAddUpdateCart = (token, product, quantity, varient) => {
    let params = {
        product: product,
        quantity: quantity,
        varient: varient,
        token: token
    }

    // console.log("res---", params)
    return fetch(baseUrl + "/api/v0/cart/add-update-product/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};





export const getListedCart = (token) => {
    return fetch(baseUrl + "/api/v0/cart/my-cart/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then(res => res.json())
        .catch((error) => {
            console.log(error)
        });

};

export const placeOrderWithoutLogin = ( product, country, email, first_name, last_name, location, address,
    city, zipcode, country_code, phone_number, comment) => {
    let params = {
        products: product,
        country: country,
        email: email,
        shipping_address: {
            first_name: first_name,
            last_name: last_name,
            location: location,
            address_line_1: address,
            address_line_2: address,
            city: city,
            zipcode: zipcode,
            country_code: country_code,
            phone_number: phone_number,
            comment: comment
        },
        billing_address: {
            first_name: first_name,
            last_name: last_name,
            location: location,
            address_line_1: address,
            address_line_2: address,
            city: city,
            zipcode: zipcode,
            country_code: country_code,
            phone_number: phone_number,
            comment: comment
        }
    }
    return fetch(baseUrl + "/api/v0/order/place-order-non-token/", {
        method: "POST",
        headers: {
            "GUEST-AUTH-TOKEN": guestToken,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
}


export const getPlaceOrderDetails = (token, cart, email, first_name, last_name, location, address,
    city, zipcode, country_code, phone_number, comment
) => {

    let params = {
        cart: cart,
        email: email,
        shipping_address: {
            first_name: first_name,
            last_name: last_name,
            location: location,
            address_line_1: address,
            address_line_2: address,
            city: city,
            zipcode: zipcode,
            country_code: country_code,
            phone_number: phone_number,
            comment: comment
        },
        billing_address: {
            first_name: first_name,
            last_name: last_name,
            location: location,
            address_line_1: address,
            address_line_2: address,
            city: city,
            zipcode: zipcode,
            country_code: country_code,
            phone_number: phone_number,
            comment: comment
        }
    }
    // console.log('params', params)
    return fetch(baseUrl + "/api/v0/order/place-order/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const SaveTransaction = () => {
    let params = {

    }
    return fetch(baseUrl + "/api/v0/order/payment/capture/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};




export const getPlace_order = (token, id, getClient_email) => {
    // console.log("token", token, id, getClient_email)
    return fetch(baseUrl + "/api/v0/order/order-details/" + id + "/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};

export const getPaymentKeys = () => {

    return fetch(baseUrl + "/api/v0/accounts/sdk-details/", {
        method: "GET",
        headers: {

            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }).then((res) => res.json());
};


//launchpad ayrshare Api
export const postOnSocial = (caption, platform, mediaUrl) => {
    let params = {
        'post': caption,
        'mediaUrls': mediaUrl,
        'platforms': platform,
    }
 
    return fetch(ayrshareBaseUrl + "/api/post", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + bearerToken,
            Accept: "application/json",
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const AutoSchedulePostOnSocial = (caption, platform, mediaUrl) => {
    let params = {
        'post': caption,
        'mediaUrls': mediaUrl,
        'platforms': platform,
        "autoSchedule": {
            "schedule": true,
            "title": caption
        }
    }

    return fetch(ayrshareBaseUrl + "/api/post", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + bearerToken,
            Accept: "application/json",
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const getConnectedAccounts = () => {
    return fetch(ayrshareBaseUrl + "/api/user", {
        method: "GET",
        headers: {
            Authorization: "Bearer " + bearerToken,
            Accept: "application/json",
            "Content-Type": 'application/json',
        },
    }).then((res) => res.json());
};


export const schedulePost = (caption, date) => {
    let params = {
        schedule: ["13:05Z", "20:14Z"],
        title: caption,
        setStartDate: date,
    }
    return fetch(ayrshareBaseUrl + "/api/auto-schedule/set", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + bearerToken,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(params),
    }).then((res) => res.json())
}

export const postBulkOnSocial = () => {
    const fd = new FormData();
    fd.append('post', post)
    fd.append('mediaUrls', media)
    fd.append('platforms', platforms)
    return fetch(ayrshareBaseUrl + "/api/post/bulk", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + bearerToken,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: fd,
    }).then((res) => res.json());
};

export const deletePostFromSocial = (id) => {
    let params = {
        id: id
    }
    return fetch(ayrshareBaseUrl + "/api/post", {
        method: "DELETE",
        headers: {
            Authorization: "Bearer " + bearerToken,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const PaymentIntent = (amount, currency, payment) => {
    let token = "c770f236abdc94c60314b6dc6d9775a2410f1a50"
    let params = {
        "request_type": "auto",
        "amount": amount * 100,
        "currency": currency,
        "payment_method_types": ["card"]
    };
    return fetch(baseUrl + "/api/v0/order/payment/stripe/payment-intent/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const CanvasGeneratedImage = (token, canvasImage) => {
    let formData = new FormData()
    formData.append('canvas_image', canvasImage);
    return fetch(baseUrl + "/api/v0/settings/canvas-image-generate/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,

        },
        body: formData
    }).then((res) => res.json());
};

export const GetLauchpadDetails = (token) => {

    return fetch(baseUrl + "/api/v0/settings/launch-pad/list/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    }).then((res) => res.json())
};

export const LaunchpadEntry = (token, active, acc_name, platform, post_type, posts, freq, id) => {
    let params = {
        active: active,
        account_name: acc_name,
        channel: platform,
        post_type: post_type,
        postings: posts,
        frequency: freq,
        post_id: id
    }
    // console.log('params', params);
    return fetch(baseUrl + "/api/v0/settings/launch-pad/create/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    }).then((res) => res.json())
};

export const DeleteLaunchpadEntry = (token, id) => {
    let params = {
        id: id
    }
    // console.log('alp', token, id)
    return fetch(baseUrl + "/api/v0/settings/launch-pad/delete/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params)
    }).then((res) => res.json())
};

//currency-conversion
export const generateCurrency = (currency) => {
    // console.log("currency", currency);
    return fetch("https://v6.exchangerate-api.com/v6/" + currencyKey + "/latest/" + currency, {
        method: "GET",
        headers: {
            Accept: "application/json",
            credentials: 'include',
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};


//get order list
export const getOrderList = (token, page) => {
    // console.log("currency", token,page);
    return fetch(baseUrl + "/api/v0/order/order-list/?page=" + page, {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};


//deepL translate

export const Translate = () => {
    let params = {
        text: ["Hello"],
        target_lang: "DE",
    }
    return fetch("https://api-free.deepl.com/v2/translate", {
        method: "POST",
        headers: {
            "access-control-allow-origin": "*",
            Authorization: "DeepL-Auth-Key 3fc0b8d8-c005-8178-ab59-cb974faba127:fx",
            "Content-Type": "application/json",
            credentials: 'include',

            Accept: "application/json",
        },

        body: JSON.stringify(params)
    }).then((res) => res.json());
};

export const transl = () => {
    let params = {
        text: ["Hello"],
        target_lang: "DE",
    }
    return fetch("https://api-free.deepl.com/v2/translate", {
        method: "POST",
        headers: {
            "access-control-allow-origin": "*",
            Authorization: "DeepL-Auth-Key 3fc0b8d8-c005-8178-ab59-cb974faba127:fx",
            "Content-Type": "application/json",
            credentials: 'include',
            Accept: "application/json",
        },
        cache: "reload",
        body: JSON.stringify(params)
    }).then((response) => response.json());

}

export const galleryBanner = () => {
    return fetch(baseUrl + "/api/v0/gallery/banner/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((response) => response.json());
};

export const galleryCategory = () => {
    return fetch(baseUrl + "/api/v0/gallery/category/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((response) => response.json());
};

export const galleyCategoryDetails = (id) => {
    return fetch(baseUrl + "/api/v0/gallery/category/" + id + "/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};

export const gallerySection = () => {
    return fetch(baseUrl + "/api/v0/gallery/section", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }).then((res) => res.json());
};

export const galleryCarousel = () => {
    return fetch(baseUrl + "/api/v0/gallery/gallery/", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }).then((res) => res.json());
};

export const categoryDetails = (id) => {
    // console.log("id", id)
    return fetch(baseUrl + "/api/v0/store/category_details/" + id, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};


export const categoriesList =()=>{
    return fetch(baseUrl + "/api/v0/store/get_category/",{
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
         }
    }).then((res)=> res.json());
};

export const PaypalRefund = (value, currency) => {
    let params = {
        value: 2323.00,
        currency: "USD",
    }
    // console.log("paypal", params);
    return fetch(paypalBaseUrl + "/v2/payments/captures/97L63077LX8260829/refund", {
        method: "POST",
        headers: {
            Authorization: "Bearer " + PayPalAccessToken,
            "PayPal-Request-Id": "123e4567-e89b-12d3-a456-426655440028",
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const GeneratePaypalAuthKey = () => {
    var details = {
        client_id: PayPalUser,
        client_secret: PayPalPass,
        grant_type: 'client_credentials',
    };

    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return fetch(paypalBaseUrl + "/v1/oauth2/token", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formBody
    }).then((res) => res.json());
};

export const captureTransactionId = (token, user_id, order_id, payment_gateway, payment_status, transaction_id, reference_id,
    currency_code, amount, payment_mode, merchant_id, shipping_name, shipping_address_line_1, shipping_address_line_2, shipping_postal_code, email) => {
    let params = {
        user_id: user_id,
        order_id: order_id,
        payment_gateway: payment_gateway,
        payment_status: payment_status,
        transaction_id: transaction_id,
        reference_id: reference_id,
        currency_code: currency_code,
        amount: amount,
        payment_mode: payment_mode,
        merchant_id: merchant_id,
        shipping_name: shipping_name,
        shipping_address_line_1: shipping_address_line_1,
        shipping_address_line_2: shipping_address_line_2,
        shipping_postal_code: shipping_postal_code,
        billing_name: shipping_name,
        billing_address_line_1: shipping_address_line_1,
        billing_address_line_2: shipping_address_line_2,
        billing_postal_code: shipping_postal_code,
        merchant_email: email,
    }
    return fetch(baseUrl + "/api/v0/order/payment/capture/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",

        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const saveTransactionIdForWOLogin=( order_id, payment_gateway, payment_status, transaction_id, reference_id,
    currency_code, amount, payment_mode, merchant_id, shipping_name, shipping_address_line_1, shipping_address_line_2, shipping_postal_code, email)=>{
    let params = {
        order_id: order_id,
        payment_gateway: payment_gateway,
        payment_status: payment_status,
        transaction_id: transaction_id,
        reference_id: reference_id,
        currency_code: currency_code,
        amount: amount,
        payment_mode: payment_mode,
        merchant_id: merchant_id,
        shipping_name: shipping_name,
        shipping_address_line_1: shipping_address_line_1,
        shipping_address_line_2: shipping_address_line_2,
        shipping_postal_code: shipping_postal_code,
        billing_name: shipping_name,
        billing_address_line_1: shipping_address_line_1,
        billing_address_line_2: shipping_address_line_2,
        billing_postal_code: shipping_postal_code,
        merchant_email: email
    }
    return fetch(baseUrl+"/api/v0/order/payment/capture/anyuser/",{
        method: "POST",
        headers:{
            "GUEST-AUTH-TOKEN": guestToken,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then(res=>res.json());
};

export const StoreInfo = (storeName) => {
    // console.log("id", storeName)
    return fetch(baseUrl + "/api/v0/store/get_home/" + storeName, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

        },
    }).then((res) => res.json());
};

export const returnProductbyId = (id) => {

    return fetch(baseUrl + "/api/v0/order/returnorderid/" + id + "/", {
        method: "POST",
        headers: {
            // Authorization: "Token " +token,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }).then((res) => res.json());
};

export const sendLink = (token) => {
    return fetch(baseUrl + "/api/v0/accounts/invitaionlink/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }).then((res) => res.json());
};

export const checkLinkVisitor = (id) => {
    let params = {
        ref_id: id
    }
    return fetch(baseUrl + "/api/v0/accounts/invitaionlink/click/", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const shippingFees = () => {

    return fetch(baseUrl + "/api/v0/vatfee/shippiglist", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }).then((res) => res.json());
};

export const vatFees = () => {
    return fetch(baseUrl + "/api/v0/vatfee/vatlist", {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};

export const getWishlist = (token) => {
    return fetch(baseUrl + "/api/v0/whshlist/wishlist/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
};

export const updateWishlist = (token, product_id) => {
    return fetch(baseUrl + "/api/v0/whshlist/addwishlist/" + product_id + "/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }).then((res) => res.json());
};

export const generateChargeID = (payment_intent) => {
    let params = {
        payment_intent_id: payment_intent
    }
    return fetch(baseUrl + "/api/v0/order/getchrgeid/", {
        method: "POST",
        headers: {
            "GUEST-AUTH-TOKEN": guestToken,
            Accept: "application/json",
            "Content-Type": "application/json"

        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const getPayoutTable = (token, id) => {
    return fetch(baseUrl + "/api/v0/affiliate/affiliate/payout/get/" + id + "/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }).then((res) => res.json());
};

export const requestPayout = (token, amount, desc, method) => {
    let params = {
        request_amount: amount,
        description: desc,
        method: method,
        user_balance: 1000
    }
    return fetch(baseUrl + "/api/v0/affiliate/affiliate/payout/create/", {
        method: "POST",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    }).then((res) => res.json());
};

export const returnSigleProduct = (token, orderId, productId) => {
    return fetch(baseUrl + "/api/v0/order/returnorderproduct/" + orderId + "/" + productId + "/", {
        method: "POST",
        headers: {
            Authorization: "Token" + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    }).then((res) => res.json())
}

export const AffiliateTaggingProducts = (token) => {

    return fetch(baseUrl + "/api/v0/affiliate/affiliate-order/", {
        method: "GET",
        headers: {
            Authorization: "Token " + token,
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    }).then((res) => res.json());
};
