import { ChakraProvider, Flex, Button, Text, Grid, Portal, useDisclosure, Box, useToast, Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Select } from '@chakra-ui/react';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar';
import React, { useEffect, useState } from 'react';
import theme from 'theme/theme';
import routes from "routes.js";
import SimpleSidebar from './components/SimpleSidebar';
import Emitter from 'Emitter';
import MobileSidebar from './components/MobileSidebar';
import Canvas1 from './components/Canvas1';
import { postOnSocial, deletePostFromSocial, schedulePost, AutoSchedulePostOnSocial, postBulkOnSocial, CanvasGeneratedImage, LaunchpadEntry } from 'components/API/AuthAPI';
import { Translate } from 'components/DeepL/Translate'
import PanelContent from 'components/Layout/PanelContent';
import PanelContainer from 'components/Layout/PanelContainer';
import MainPanel from 'components/Layout/MainPanel';
import { useDispatch, useSelector } from "react-redux";
import { AddSelectedImage, selectFrequency } from 'Slices/launchpadslice';
import { MdArrowDropDown } from 'react-icons/md';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  addPage, removePage, postText, postLaterText, schedulePostText, noOfTimesText, selectPeriod, daily, weekly, monthly,
  startDateText, autoScheduleText, alreadyScheduledText, enterTextField, somethingWentWrong, postUploadedText, pageText
} from 'assets/constants/strings';



const Launchpad = (props) => {
  const [sidebarVariant] = useState("transparent");
  const [fixed] = useState(false);
  const [nav] = useState("small");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { ...rest } = props;
  const [value, setValue] = useState("");
  const [newv, setNewv] = useState()
  const [price, setPrice] = useState();
  const [ele, setEle] = useState("");
  const [isVisible, setIsVisible] = useState("");
  const [selectedImage, setSelectedImage] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [text, setText] = useState();
  const [canvas, setCanvas] = useState();
  const [pages, setPages] = useState(1);
  const [period, setPeriod] = useState();
  const [times, setTimes] = useState();
  const [platform, setPlatform] = useState();
  const [token, setToken] = useState();
  const [id, setId] = useState();
  const [postUrl, setPostUrl] = useState();
  const [usedPlatform, setUsedPlatform] = useState();
  const [selectedCanvas, setSelectedCanvas] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [acc_name, setAcc_name] = useState();
  const [load, setLoad] = useState(false);
  const [loader, setLoader] = useState(false);
  const toast = useToast();
  const cart1 = useSelector((state) => state.cart);
  const launch = useSelector((state) => state.launchpad)
  const [postTimes, setPostTimes] = useState();
  const [duration, setDuration] = useState();

  let posts = [];
  for (let i = 1; i <= 10; i++) {
    posts.push(i);
  }
  // console.log(postTimes,period);

  useEffect(() => {
    Emitter.emit("SELECT_FROM_POSTS", postTimes);
    Emitter.emit("SELECT_FROM_PERIOD", period);
    let combinedObject = { "postTimes": postTimes, "period": duration };
    dispatch(selectFrequency(combinedObject));
  }, [period, postTimes]);
  const dispatch = useDispatch();
  Emitter.on('INPUT_FROM_MAIN', (newValue) => setNewv(newValue));
  Emitter.on('INPUT_FROM_PRODUCT', (img) => setSelectedImage(img));
  Emitter.on('INPUT_FROM_PRICE', (priceValue) => setPrice(priceValue));
  Emitter.on('INPUT_FROM_ISPRICEVISIBLE', (visible) => setIsVisible(visible));
  Emitter.on('SELECT_FROM_ELEMENT', (element) => setEle(element));
  Emitter.on('SELECT_FROM_ELEMENT_TEXT', (e) => setText(e));
  Emitter.on('INPUT_FROM_SELECTBACKGROUND', (NewImg) => setValue(NewImg));
  Emitter.on('SELECTED_PLATFORM', (e) => setPlatform(e));
  Emitter.on("SELECT_FROM_POSTS", (e) => setTimes(e));
  Emitter.on("SELECT_FROM_PERIOD", (e) => setPeriod(e));
  Emitter.on('ACCOUNT_NAME', (e) => setAcc_name(e));
  let mediaUrl;
  useEffect(() => {
    let userToken = localStorage.getItem('token');
    setToken(userToken);
  }, []);

  useEffect(() => {
    //load background image on canvas
    if (!launch.background == '') {
      fabric.util.loadImage(launch.background,
        function (img) {
          setLoader(true)
          var fab_image = new fabric.Image(img, {
            left: 0,
            top: 0,
            scaleX: selectedCanvas.stage.width / img.width,
            scaleY: selectedCanvas.stage.height / img.height,
            border: '1px solid #000',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          })
          selectedCanvas.stage.setBackgroundImage(fab_image).renderAll();
          selectedCanvas.stage.requestRenderAll();
        }, null, { crossOrigin: 'Anonymous' }

      )
      canvas?.stage.setDimensions({ width: "500", height: "500" })
      canvas?.stage.setBackgroundImage(value);
    }
    setLoader(false)


  }, [launch.background]);

  //addprice
  useEffect(() => {

    if (cart1.isVisible == true) {
      selectedCanvas?.stage.add(new fabric.IText(cart1.productPrice + "€", {
        left: 50,
        top: 100,
        fontFamily: 'helvetica neue',
        fill: '#000',
        stroke: '#fff',
        strokeWidth: .1,
        fontSize: 45
      }, null, {
        crossOrigin: 'anonymous'
      }));
    }

  }, [cart1.isVisible])


  // Add text onto the canvas
  useEffect(() => {

    if (launch.backgroundLoader == true) {
      setLoader(true);
    }

    //Add text
    if (launch.elementText == true) {
      selectedCanvas.stage.add(new fabric.IText('Tap and Type', {
        left: 50,
        top: 100,
        fontFamily: 'helvetica neue',
        fill: '#000',
        stroke: '#fff',
        strokeWidth: .1,
        fontSize: 45
      }, null, {
        crossOrigin: 'Anonymous'
      }));
    }

  }, [launch])


  // add element image on canvas
  useEffect(() => {
    if (ele) {

      fabric.util.loadImage(ele,
        function (img) {
          var fab_image = new fabric.Image(img);
          selectedCanvas.stage.add(fab_image);
          selectedCanvas.stage.renderAll();
        },
        {
          left: 10,
          top: 10,
        }, null, { crossOrigin: 'Anonymous' })
    }

  }, [ele])


  //load product image into the canvas
  useEffect(() => {
    if (launch.showProd === true) {
      fabric.util.loadImage("https://adminmyboutiqe.progfeel.co.in" + launch.addedImage,
        function (img) {
          var fab_image = new fabric.Image(img, {
            angle: 0,
            width: 800,
            height: 500,
            left: 140,
            top: 100,
            scaleX: 0.3,
            scaleY: 0.3,
          });
          selectedCanvas.stage.add(fab_image);
          selectedCanvas.stage.renderAll();
        }, null, {
        crossOrigin: 'Anonymous'
      })
      setTimeout(() => {
        let combinedObject = { "image": '', "showProduct": false }
        dispatch(AddSelectedImage(combinedObject))
      }, 1000)
    }
  }, [launch.addedImage]);



  // Do some initializing stuff
  fabric.Object.prototype.set({
    transparentCorners: true,
    cornerColor: '#22A7F0',
    borderColor: '#22A7F0',
    cornerSize: 12,
    padding: 5
  });

  // Delete icon
  var deleteIcon1 = "https://uxwing.com/wp-content/themes/uxwing/download/user-interface/trash-bin-icon.svg";
  var img = document.createElement('img');
  img.crossOrigin = "Anonymous"
  img.src = deleteIcon1;

  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerColor = 'blue';
  fabric.Object.prototype.cornerStyle = 'square';


  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.2,
    y: -0.8,
    offsetY: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: deleteObject,
    render: renderIcon,
    cornerSize: 24
  }, null, {
    crossOrigin: 'Anonymous'
  });


  /**
   * The function deletes an object from a canvas in JavaScript.
   * @param eventData - It is an object that contains information about the event that triggered the
   * function. This could include information such as the type of event, the target element, and any
   * additional data related to the event.
   * @param transform - `transform` is an object that represents the transformation of an object on the
   * canvas. It contains information about the object's position, rotation, scale, and other
   * properties. In this function, `transform.target` refers to the object that was clicked on or
   * selected by the user.
   */
  function deleteObject(eventData, transform) {
    var target = transform.target;
    var canvas = target.canvas;
    canvas.remove(target);
    canvas.requestRenderAll();
  }

  /**
   * This function renders an icon on a canvas context with a given position and rotation.
   * @param ctx - The canvas context on which the icon will be rendered.
   * @param left - The horizontal position of the icon's top-left corner relative to the canvas.
   * @param top - The `top` parameter is the vertical position of the icon's top-left corner relative
   * to the canvas.
   * @param styleOverride - It is not clear from the given code snippet what the `styleOverride`
   * parameter is. It is possible that it is defined elsewhere in the code or it may not be used at
   * all.
   * @param fabricObject - It is an object that represents a fabric.js object, which is a canvas
   * element that can be manipulated and rendered using the fabric.js library. The object contains
   * various properties such as position, size, angle, and style that define its appearance and
   * behavior on the canvas.
   */
  function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(img, -size / 2, -size / 2, size, size);
    ctx.restore();
  }


 /**
  * The function "Post" posts content on social media and updates the launch status accordingly.
  */
  const Post = () => {
    postOnSocial(launch.captionText ? launch.captionText : '#my-boutiq', launch.platform, mediaUrl)
      .then((responseJson) => {
        if (responseJson["status"]) {
          // console.log("status", responseJson.postIds[0])
          if (responseJson.status == "success") {
            setLoad(false)
            let id1 = responseJson.id
            toast({
              position: 'top',
              render: () => (
                <Box
                  color='white'
                  p={3} bg='#219691'
                  textAlign={'center'}
                  fontWeight='bold'
                >
                  {postUploadedText}!
                </Box>
              ),
            })
            setTimeout(() => {
              let active_status = true
              LaunchpadEntry(token, active_status, acc_name, launch.platform, launch.platform, times, period, id1)
                .then((res) => {
                  // alert("success",res)
                })
            }, 2000)
          }
          else {
            toast({
              position: 'top',
              render: () => (
                <Box
                  color='white'
                  p={3} bg='#219691'
                  textAlign={'center'}
                  fontWeight='bold'
                >
                  {somethingWentWrong}!
                </Box>
              ),
            })

            setLoad(false)
          }
        }
      })
  }

 /**
  * The function AutoSchedulePost schedules a post on social media platforms and displays a toast
  * message based on the success or failure of the scheduling process.
  */
  const AutoSchedulePost = () => {
    if (launch.captionText) {
      schedulePost(launch.captionText ? launch.captionText : '#my-boutiq', startDate.toISOString()).then((res) => {
        (res["message"])
        if (res["message"] == "Auto schedule set.") {
          AutoSchedulePostOnSocial(launch.captionText ? launch.captionText : '#my-boutiq', launch.platform, mediaUrl).then((responseJson) => {
            if (responseJson["status"] == "scheduled") {
              let id1 = responseJson.id
              toast({
                position: 'top',
                render: () => (
                  <Box
                    color='white'
                    p={3} bg='#219691'
                    textAlign={'center'}
                    fontWeight='bold'
                  >
                    {autoScheduleText}
                  </Box>
                ),
              })
              setTimeout(() => {
                let active_status = true
                LaunchpadEntry(token, active_status, acc_name, launch.platform, launch.platform, times, period, id1)
                  .then((res) => {
                    // alert("success",res)
                  })
              }, 2000)

            } else {
              toast({
                position: 'top',
                render: () => (
                  <Box
                    color='white'
                    p={3} bg='#219691'
                    textAlign={'center'}
                    fontWeight='bold'
                  >
                    {alreadyScheduledText}!
                  </Box>
                ),
              })
            }
          })
        } else {
          toast({
            position: 'top',
            render: () => (
              <Box
                color='white'
                p={3} bg='#219691'
                textAlign={'center'}
                fontWeight='bold'
              >
                {somethingWentWrong}
              </Box>
            ),
          })
        }
      })
    } else {
      toast({
        position: 'top',
        render: () => (
          <Box
            color='white'
            p={3} bg='#219691'
            textAlign={'center'}
            fontWeight='bold'
          >
            {enterTextField}
          </Box>
        ),
      })
    }
  }


  /**
   * The function `BulkPosts` posts content on social media platforms and displays a success or error
   * message based on the response.
   */
  const BulkPosts = () => {
    postBulkOnSocial()
      .then((responseJson) => {
        let postDetails = {
          post: launch.captionText,
          platforms: platform,
          mediaUrl: mediaUrl
        }
        if (responseJson["status"]) {
          if (responseJson.status == "success") {
            setId(responseJson.postIds.id);
            setPostUrl(responseJson.postIds.postUrl);
            setUsedPlatform(responseJson.postIds.platform);
            Swal.fire({
              icon: 'success',
              title: 'success',
              text: 'Post uploaded successfully'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Opps',
              text: 'Something went wrong!'
            })
          }
        }
      })
  }

 /**
  * The function converts a data URL to a File object.
  * @param url - The `url` parameter is a string that represents a data URL. A data URL is a URI scheme
  * that allows you to include data in-line in web pages as if they were external resources. The data
  * in the URL is encoded as base64 and can represent various types of data, such as images
  * @returns The function `urlToFile` returns a `File` object that represents a file downloaded from
  * the given URL. The file type and name are determined from the URL, and the file contents are
  * decoded from base64 and converted to a `Uint8Array` before being used to create the `File` object.
  */
  const urlToFile = (url) => {

    let arr = url.split(",")
    let mime = arr[0].match(/:(.*?);/)[1]
    let data = arr[1]
    let dataStr = atob(data)
    let n = dataStr.length
    let dataArr = new Uint8Array(n)
    while (n--) {
      dataArr[n] = dataStr.charCodeAt(n)
    }
    let file = new File([dataArr], 'File.jpg', { type: mime })
    return file

  }


 /**
  * The function captures an image from a canvas element, converts it to a file, sends it to a server,
  * and then posts it.
  */
  const post = () => {
    setLoad(true);
    const canvas = document.getElementById("my-canvas");
    try {
      let image = canvas.toDataURL();
      let fileImage = urlToFile(image);
      let token = localStorage.getItem("token")
      CanvasGeneratedImage(token, fileImage)
        .then((response) => {
          if (response) {
            let img = "https://adminmyboutiqe.progfeel.co.in" + response.canvas_image
            mediaUrl = img;
            setTimeout(() => {
              Post();
            }, 1000)
          }
        })
    } catch (err) {
      console.error(`Error: ${err}`);
    }

  }



  return (
    <ChakraProvider theme={theme} resetCss={false}>
      <Sidebar
        routes={routes}
        display="none"
        sidebarVariant={sidebarVariant}
        {...rest}
      />
      <MainPanel
        w={{
          base: "100%",
          lg: nav == cart1.sidebar ? "calc(100% - 85px)" : "calc(100% - 245px)",
        }}
        overflow='none'
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            logoText={"My Boutiq"}
            fixed={fixed}
            {...rest}
            nav={nav}
          />
        </Portal>
        <PanelContent>
          <PanelContainer>
            <Flex
              flexDirection="row"
              bg="#f7f8f9"
              position={'relative'}
            >
              <Flex
                width={{ lg: '40%' }}
                position='relative'
              >
                <SimpleSidebar />
              </Flex>
              <MobileSidebar />
              <Flex
                display={"flex"}
                flexDirection="column"
                // margin={"0 auto"}
                w='100%'
                margin={"0 auto"}
                position='relative'
              >

                <Flex
                  margin={'0 auto'}
                  mt={{ sm: "90px", md: "100px", lg: "20vh" }}
                  id="canvasWrapper"
                >

                  <div className='launchpad_mainScreen'>

                    {Array.from(Array(pages).keys()).map((item) => {
                      return (
                        <div key={item}>
                          <Text>
                            {/* {Translate ? "Page:" : Translate("Page:")} {item + 1} */}
                            {pageText}: {item + 1}
                          </Text>
                          <div
                            id='can-parent'
                            className='canvas-parent'
                          // className={
                          //   selectedIndex == item
                          //     ? "selected-canvas canvas-parent"
                          //     : "canvas-parent"
                          // }
                          >
                            {/* {
                              loader == true ?

                                <Spinner
                                  position="absolute"
                                  mt='200px'
                                  ml='200px'
                                  thickness='4px'
                                  speed='0.65s'
                                  emptyColor='gray.200'
                                  color='#219691'
                                  w='100px'
                                  h='100px'
                                  zIndex={1}
                                />
                                :
                                ""
                            } */}
                            <Canvas1
                              onSuccess={async (canvas) => {
                                await setCanvas(canvas);
                              }}
                              onChange={(index, canvas) => {
                                setSelectedCanvas(canvas);
                                setSelectedIndex(index);
                              }}
                              onClick={(index, canvas) => {
                                setSelectedCanvas(canvas);
                                setSelectedIndex(index);
                              }}
                              key={item}
                              index={item}
                            ></Canvas1>
                          </div>
                        </div>
                      );
                    })}

                    <Flex
                      flexDirection="row"
                      display="flex"
                      justifyContent="center"
                      mt={{ sm: '10%', md: '10%', lg: "2%" }}
                      gap='2'
                    >
                      <Button
                        // display="flex"
                        justifyContent="center"
                        justifyItems="center"
                        // margin="0 auto"
                        color={"#ffffff"}
                        bg={"#219691"}
                        colorScheme={"none"}
                        width={{ sm: "100%", md: "100%", lg: "100%" }}
                        // marginBottom="15px"
                        onClick={() => {
                          setPages(pages + 1);
                        }}
                      >
                        {addPage}
                      </Button>

                      {pages > 1 ?
                        <Button
                          justifyContent="center"
                          justifyItems="center"
                          color={"#ffffff"}
                          bg={"#219691"}
                          colorScheme={"none"}
                          width={{ sm: "100%", md: "100%", lg: "100%" }}
                          // marginBottom="15px"
                          onClick={() => {
                            setPages(pages - 1);
                          }}
                        >
                          {removePage}
                        </Button>
                        :
                        ""
                      }
                      {selectedImage ?

                        load == true ?
                          <>
                            <Button
                              isLoading
                              loadingText='Post'
                              bg="#219691"
                              width={{ sm: "100%", md: "100%", lg: "100%" }}
                              color={"#ffffff"}
                              colorScheme={'none'}
                              variant='solid'
                              spinnerPlacement='end'
                              onClick={post}
                            >
                              {postText}
                            </Button>
                            <Button
                              bg="#219691"
                              width={{ sm: "100%", md: "100%", lg: "100%" }}
                              color={"#ffffff"}
                              colorScheme={'none'}
                              variant='solid'
                              spinnerPlacement='end'
                              onClick={onOpen}
                            >
                              {postLaterText}
                            </Button>
                          </>
                          :
                          <>
                            <Button

                              loadingText='Post'
                              bg="#219691"
                              width={{ sm: "100%", md: "100%", lg: "100%" }}
                              color={"#ffffff"}
                              colorScheme={'none'}
                              variant='outline'
                              spinnerPlacement='end'
                              onClick={post}
                            >
                              {postText}
                            </Button>
                            <Button
                              bg="#219691"
                              width={{ sm: "100%", md: "100%", lg: "100%" }}
                              color={"#ffffff"}
                              colorScheme={'none'}
                              variant='outline'
                              onClick={onOpen}
                            >
                              {postLaterText}
                            </Button>
                          </>
                        :
                        ""
                      }
                    </Flex>
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </PanelContainer>
        </PanelContent>

      </MainPanel>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text
              textAlign={'center'}
            >
              {schedulePostText}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Grid>
              <Flex
                flexDirection="column"
                pt="50px"
              >
                <Text
                  textAlign="center"
                >
                  {noOfTimesText}
                </Text>

                <Select
                  onChange={(e) => setPostTimes(e.target.value)}
                  icon={<MdArrowDropDown />}
                  placeholder="Choose how many time"
                  textAlign={'center'}
                >
                  {posts.map((item) => {
                    return <option key={item} value={item}>{item}</option>
                  })}
                </Select>
              </Flex>
              <Flex
                flexDirection="column"
                mt="20px"
              >
                <Text
                  textAlign="center"
                >
                  {selectPeriod}
                </Text>
                <Select
                  onChange={(e) => setDuration(e.target.value)}
                  icon={<MdArrowDropDown />}
                  placeholder="Choose period"
                  textAlign={'center'}
                >
                  <option>{daily}</option>
                  <option>{weekly}</option>
                  <option>{monthly}</option>
                </Select>
              </Flex>
              <Flex
                flexDirection="column"
                mt="20px"
                className='chooseDate'
              >
                <Text
                  textAlign="center"
                >
                  {startDateText}
                </Text>
                <DatePicker
                  className="date-picker"
                  closeOnScroll={(e) => e.target === document}
                  selected={startDate}
                  dateFormat="dd.MM.yyyy"
                  onChange={(date) => setStartDate(date)}
                />
              </Flex>
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button
              w='100%'
              bg="#219691"
              color="#FFFFFF"
              colorScheme={'none'}
              onClick={() => { AutoSchedulePost() }}
            >
              {schedulePostText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  )
}

export default Launchpad;