import {
    Box, Button, ChakraProvider, Flex, FormControl, FormLabel, Heading, Input, InputGroup, InputRightElement,
    Text, useToast,
} from "@chakra-ui/react";
import { Link, useLocation, useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { acceptAffiliateInvitation, register, sellerinvitation } from "../../components/API/AuthAPI"
import AuthNavbar1 from "components/Navbars/SearchBar/AuthNavbar1";
import theme from "theme/theme";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
    invitationAccepted, registermsg, entervalidEmailandPassword, enterEmailAndPassoword, enterPasswordText, enterEmailandUsername,
    usernameAlreadyExist, enterUsername, somethingWentWrongMsg, enteremailText, alreadyRegisteredText, registerText, alreadyHaveAccount, signInText, passwordText, emailText, usernameText, createAccount
} from "assets/constants/strings";

function SignUp3() {
    let history = useHistory();
    const [username, setUsername] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [phone_number, setPhone_number] = useState();
    const [user_type, setUser_type] = useState("affiliate");
    const [show, setShow] = useState(false);
    let location = useLocation();
    const [token, setToken] = useState(location.state ? location.state.newToken : "");
    const [affiliateToken] = useState(location.state ? location.state.affiliate_token : "");
    const toast = useToast();
    const handleClick = () => setShow(!show);

    useEffect(() => {
        checkUserLogin();
    }, [])

    /**
     * The function handles user registration and affiliate invitation acceptance, displaying
     * appropriate messages based on the response received.
     */
    const handleRegister = () => {
        setIsSubmitted(true)
        if (affiliateToken) {
            acceptAffiliateInvitation(affiliateToken, user_type, password)
                .then((responseJson) => {
                    if (responseJson) {
                        if (responseJson["message"]) {
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        {invitationAccepted}
                                    </Box>
                                ),
                            })
                            setIsSubmitted(false)
                            history.push({
                                pathname: "/signin",
                                // state: {newToken}
                            })
                        } else {
                            setIsSubmitted(false)
                        }
                    }
                })
        } else if (!token) {
            register(username, password, email, phone_number, user_type)
                .then((responseJson) => {
                    if (responseJson["message"] == 'User saved successfully.') {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {registermsg}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                        history.push({
                            pathname: "/affiliate/dashboard"
                        })
                    } else if (responseJson['email'] == "This field is required." && responseJson["password"] == 'This field is required.' && responseJson["username"] == "This field is required.") {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {entervalidEmailandPassword}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                    else if (responseJson['email'] == "This field is required." && responseJson["password"] == 'This field is required.') {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {enterEmailAndPassoword}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                    else if (responseJson['email'] == "This field is required." && responseJson["username"] == "This field may not be blank.") {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {enterEmailandUsername}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                    else if (responseJson['password'] == "This field may not be blank.") {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {enterPasswordText}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                    else if (responseJson['username'] == "Duplicate username.") {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {usernameAlreadyExist}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                    else if (responseJson['email'] == "Duplicate email address." && responseJson['username'] == 'This field is required.') {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {enterUsername}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                    else if (responseJson['email'] == "This field may not be blank." && responseJson['username'] == 'This field is required.') {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {enterUsername}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    } else if (responseJson['message'] == 'Something went wrong. Please contact admin.') {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {somethingWentWrongMsg}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                    else if (responseJson['email'] == 'This field may not be blank.') {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {enteremailText}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                    else if (responseJson['email'] == 'This field is required.') {
                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {enteremailText}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }

                    else {

                        toast({
                            position: 'top',
                            render: () => (
                                <Box
                                    color='white'
                                    p={3} bg='#219691'
                                    textAlign={'center'}
                                    fontWeight='bold'
                                >
                                    {alreadyRegisteredText}
                                </Box>
                            ),
                        })
                        setIsSubmitted(false)
                    }
                })
                .catch((err) => (console.log("error", err)));
        }
        else {
            sellerinvitation(token, user_type, password)
                .then((responseJson) => {
                    if (responseJson) {
                        if (responseJson["message"]) {
                            toast({
                                position: 'top',
                                render: () => (
                                    <Box
                                        color='white'
                                        p={3} bg='#219691'
                                        textAlign={'center'}
                                        fontWeight='bold'
                                    >
                                        {invitationAccepted}
                                    </Box>
                                ),
                            })
                            window.location = "https://adminmyboutiqe.progfeel.co.in/"
                        }
                    }
                })
        }
    }

    /**
     * The function checks if a user is logged in and redirects them to the dashboard if they have a
     * token stored in local storage.
     */
    const checkUserLogin = () => {
        let userToken = localStorage.getItem("token")
        if (userToken) {
            history.push({
                pathname: "/affiliate/dashboard"
            })
        }
    }
    return (
        <ChakraProvider theme={theme} resetCss={false} w="100%">
            <Flex
                mb='40px'
                pt="45px"
                h="100%"
                w="100%"
            >
                <AuthNavbar1 />
                <Flex
                    w='100%'
                    mx='auto'
                    justifyContent='center'
                    mb='30px'
                    pt={{ sm: "10vh", md: "0px", lg: "5vh" }}
                >
                    <Flex
                        alignItems='center'
                        justifyContent='center'
                        w={{ base: "100%", md: "50%", lg: "42%" }}
                    >
                        <Flex
                            direction='column'
                            w='100%'
                            maxWidth={"350px"}
                            background='#FFFFFF'
                            border={{ sm: "none", md: "1px solid #EEE", lg: "1px solid #EEE" }}
                            borderRadius={{ sm: "none", md: "10px", lg: "10px" }}
                            p='25px'
                            mt={{ md: "100px", lg: "80px" }}
                        >
                            <Heading
                                color={"#383e50"}
                                fontSize='18px'
                                align="left"
                            >
                                {createAccount}
                            </Heading>
                            <FormControl
                                pt='24px'
                            >
                                <FormLabel
                                    color="#4f5d77"
                                    fontSize={"sm"}
                                    pl="10px"
                                >
                                    {usernameText}
                                </FormLabel>
                                <Input
                                    id="username"
                                    fontSize='sm'
                                    bg="#F4F5F9"
                                    color="#4f5d77"
                                    h="41px"
                                    ms='4px'
                                    borderRadius='5px'
                                    placeholder='Username'
                                    _placeholder={{ color: 'inherit' }}
                                    mb='24px'
                                    size='lg'
                                    textAlign={"left"}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                <FormLabel
                                    color="#4f5d77"
                                    fontSize={"sm"}
                                    pl="10px"
                                >
                                    {emailText}
                                </FormLabel>
                                <Input
                                    id="email"
                                    fontSize='sm'
                                    bg="#F4F5F9"
                                    color="#4f5d77"
                                    height='41px'
                                    ms='4px'
                                    borderRadius='5px'
                                    type='email'
                                    placeholder='Email'
                                    _placeholder={{ color: 'inherit' }}
                                    mb='24px'
                                    size='lg'
                                    textAlign={"left"}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <FormLabel
                                    color="#4f5d77"
                                    fontSize={"sm"}
                                    pl="10px"
                                >
                                    {passwordText}
                                </FormLabel>
                                <InputGroup>
                                    <Input
                                        id="password"
                                        fontSize='sm'
                                        bg="#F4F5F9"
                                        height='41px'
                                        color="#4f5d77"
                                        ms='4px'
                                        borderRadius='5px'
                                        pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                                        type={show ? '' : 'password'}
                                        placeholder='Password'
                                        _placeholder={{ color: 'inherit' }}
                                        mb='24px'
                                        size='lg'
                                        textAlign={"left"}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <InputRightElement width='4.5rem' py="18px">
                                        <Button h='1.75rem' bg="transparent" colorScheme="none" color="#000000" size='sm' onClick={handleClick}>
                                            {show ? <ViewOffIcon /> : <ViewIcon />}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>

                                <Text
                                    fontSize={"xs"}
                                    color={"#ADB5BD"}
                                    textAlign="center"
                                    mb="15px"
                                >
                                    By signing up, you confirm that you’ve read and accepted our User Notice and Privacy Policy.
                                </Text>
                                {
                                    !isSubmitted ?

                                        <Button
                                            type='submit'
                                            bg='#219691'
                                            fontSize='sm'
                                            color='white'
                                            fontWeight='bold'
                                            borderRadius={'5px'}
                                            w='100%'
                                            h='45'
                                            mb='24px'
                                            _hover={{
                                                bg: "#219691",
                                            }}
                                            _active={{
                                                bg: "teal.400",
                                            }}
                                            onClick={handleRegister}>
                                            {registerText}
                                        </Button>
                                        :
                                        <Button
                                            type='submit'
                                            bg='#219691'
                                            isLoading
                                            loadingText='please wait....'
                                            colorScheme='teal'
                                            variant='solid'
                                            fontSize='sm'
                                            color='white'
                                            fontWeight='bold'
                                            borderRadius={'5px'}
                                            w='100%'
                                            h='45'
                                            mb='24px'
                                            _hover={{
                                                bg: "#219691",
                                            }}
                                            _active={{
                                                bg: "teal.400",
                                            }}
                                            onClick={handleRegister}>
                                            {registerText}
                                        </Button>
                                }
                            </FormControl>
                            <Flex
                                flexDirection='column'
                                justifyContent='center'
                                alignItems='center'
                                maxW='100%'
                                mt='0px'>
                                <Text
                                    color={"#4F5D77"}
                                    fontWeight='medium'
                                    fontSize={'sm'}
                                >
                                    {alreadyHaveAccount}
                                    <Link to="/signin">
                                        <Text
                                            color="#219691"
                                            as='span'
                                            ms='5px'
                                            href="#"
                                            fontWeight='normal'
                                        >
                                            {signInText}
                                        </Text>
                                    </Link>
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </ChakraProvider>
    );
}
export default SignUp3;
