import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Collapse, Container, Flex, Grid, Image, Text } from '@chakra-ui/react'
import { viewAllProductDetails, uniqueSellingPoint, deliveryText, deliveryText1, deliveryText2, deliveryText3, product_description, ProductDetailsText } from 'assets/constants/strings'
import React from 'react'
import { BsExclamationLg } from 'react-icons/bs'
import { FiCornerDownLeft } from 'react-icons/fi'
import { TbTruckDelivery } from 'react-icons/tb'
import './productDetails1.css';

const ProductInfo = (props) => {
    return (
        <>
            <Flex
                flexDirection='column'
                mt='5'
                gap='5'
                mb='15px'
                display={{ sm: 'flex', md: 'none', lg: 'none' }}
                w='100%'
            >
                {props.prticularproduct.product_description ?
                    <>
                        <Flex

                            flexDir={'column'}
                        >
                            <Text
                                w='100%'
                                fontWeight='bold'
                                fontSize='14px'
                            >
                                {ProductDetailsText}
                            </Text>
                            <Text>
                                {props.prticularproduct.product_description.replace(/(<([^>]+)>)/ig, '')}
                            </Text>
                        </Flex>
                        <Collapse mt={4} in={props.proDetails}>
                            <Container className='product_describe_data'>
                                <Text>
                                    {props.prticularproduct.product_description.replace(/(<([^>]+)>)/ig, '')}
                                </Text>
                            </Container>
                        </Collapse>
                    </>
                    :
                    ""
                }
                <span className='product_wrapper_line'></span>
                <Flex
                    onClick={props.onToggleFactor}
                    className='product_delivery_item'>
                    <Text w='100%'
                        fontWeight='bold'
                        fontSize='14px'
                    >
                        {viewAllProductDetails}
                    </Text>
                    {
                        !props.proFactor ?
                            <span className='product_wrapper_content_down'>
                                <ChevronDownIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                            :
                            <span className='product_wrapper_content_focus'>
                                <ChevronUpIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                    }
                </Flex>
                <Collapse in={props.proFactor}>
                    <Container className='product_describe_data'>
                        <Flex flexDirection={'column'}>
                            <Flex
                                mt='10px'
                                flexDirection={'row'}
                                w='100%'
                            >
                                <Image
                                    w='50%'
                                    h='120px'
                                    src={"https://adminmyboutiqe.progfeel.co.in" + props.prticularproduct.product_image}
                                    alt='Dan Abramov' />
                                <Grid
                                    display='flex'
                                    flexDirection={'column'}
                                    w='60%'
                                    ml='10px'
                                    mt='10px'
                                >
                                    <Text
                                        fontWeight='black'
                                    >
                                        {props.prticularproduct.product_name}
                                    </Text>
                                    <Text
                                    >
                                        {props.prticularproduct.product_brand_name}
                                    </Text>

                                    {
                                        props.varientPrice > 0 ?
                                            <Text>
                                                {parseFloat(props.prticularproduct.PRDPrice + props.varientPrice).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{props.currencyIcon ? props.currencyIcon : "€"}
                                            </Text>
                                            :
                                            <Text
                                            >
                                                {parseFloat((props.prticularproduct.PRDPrice * (props.currencyExchange ? props.currencyExchange : 1))).toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}{props.currencyIcon ? props.currencyIcon : "€"}
                                            </Text>
                                    }
                                </Grid>
                            </Flex>
                            <Flex
                                mt='10px'
                                flexDir={'column'}
                            >
                                <Text
                                    w='100%'
                                    fontWeight='bold'
                                    fontSize='14px'
                                >
                                    {uniqueSellingPoint}
                                </Text>
                                {
                                    props.prticularproduct?.content ?
                                        <Text>
                                            {props.prticularproduct?.content.replace(/(<([^>]+)>)/ig, '')}
                                        </Text>
                                        :
                                        ""
                                }
                                <Text
                                    w='100%'
                                    fontWeight='bold'
                                    fontSize='14px'
                                    mt='10px'
                                >
                                    {product_description}
                                </Text>
                                {
                                    props.prticularproduct?.product_description ?
                                        <Text>
                                            {props.prticularproduct.product_description.replace(/(<([^>]+)>)/ig, '')}
                                        </Text>
                                        :
                                        ""
                                }
                            </Flex>
                        </Flex>
                    </Container>
                </Collapse>
                <span className='product_wrapper_line'></span>
                <Flex
                    onClick={props.onToggleDelivery}
                    className='product_delivery_item'>
                    <Text w='100%'
                        fontWeight='bold'
                        fontSize='14px'
                    // mb='2'
                    >
                        {deliveryText}
                    </Text>
                    {
                        !props.proDelivery ?
                            <span className='product_wrapper_content_down'>
                                <ChevronDownIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                            :
                            <span className='product_wrapper_content_focus'>
                                <ChevronUpIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                    }
                </Flex>
                <Collapse in={props.proDelivery}>
                    <Container className='product_describe_data'>
                        <Flex>
                            <TbTruckDelivery size='5%' />
                            <Text ml='5'>
                                {deliveryText1}
                            </Text>
                        </Flex>

                        <Flex mt='3' >
                            <FiCornerDownLeft size='5%' />
                            <Text ml='5' >{deliveryText2}</Text>
                        </Flex>
                        <Flex mt='3' >
                            <BsExclamationLg size='5%' />
                            <Text ml='5'>
                                {deliveryText3}
                            </Text>
                        </Flex>
                    </Container>
                </Collapse>
                <span className='product_wrapper_line'></span>
            </Flex>

            <Flex
                flexDirection='column'
                display={{ sm: 'none', md: 'flex', lg: 'flex' }}
                mt='5' 
                gap='5'
            >
                <span className='product_wrapper_line'></span>
                <Flex
                    onClick={props.handleToggleDetails}
                    className='product_delivery_item'>
                    <Text
                        w='100%'
                        fontWeight='bold'
                        fontSize='xl'
                    >
                        {ProductDetailsText}
                    </Text>
                    {
                        !props.proDetails ?
                            <span className='product_wrapper_content_down'>
                                <ChevronDownIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                            :
                            <span className='product_wrapper_content_focus'>
                                <ChevronUpIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                    }
                </Flex>
                {props.prticularproduct?.product_description ?
                    <Collapse mt={4} in={props.proDetails}>
                        <Container className='product_describe_data'>
                            <Text>
                                {props.prticularproduct?.product_description.replace(/(<([^>]+)>)/ig, '')}
                            </Text>
                        </Container>
                    </Collapse>
                    :
                    ""
                }
                <span className='product_wrapper_line'></span>
                <Flex
                    onClick={props.onToggleFactor}
                    className='product_delivery_item'>
                    <Text w='100%'
                        fontWeight='bold'
                        fontSize='xl'
                    >
                        Product's Wow-Factor
                    </Text>
                    {
                        !props.proFactor ?
                            <span className='product_wrapper_content_down'>
                                <ChevronDownIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                            :
                            <span className='product_wrapper_content_focus'>
                                <ChevronUpIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                    }
                </Flex>
                {props.prticularproduct?.content ?
                    <Collapse in={props.proFactor} >
                        <Container className='product_describe_data'>
                            <Text mt='3'>
                                {props.prticularproduct?.content.replace(/(<([^>]+)>)/ig, '')}
                            </Text>
                        </Container>
                    </Collapse>
                    :
                    ""
                }
                <span className='product_wrapper_line'></span>
                <Flex
                    onClick={props.onToggleDelivery}
                    className='product_delivery_item'>
                    <Text w='100%'
                        fontWeight='bold'
                        fontSize={"xl"}
                        mb='5'
                    >
                        Delivery & Returns
                    </Text>
                    {
                        !props.proDelivery ?
                            <span className='product_wrapper_content_down'>
                                <ChevronDownIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                            :
                            <span className='product_wrapper_content_focus'>
                                <ChevronUpIcon
                                    className='descriptionArrow'
                                    color={"#219691"}
                                    w='10'
                                    h='7'
                                />
                            </span>
                    }
                </Flex>
                <Collapse in={props.proDelivery}>
                    <Container className='product_describe_data'>
                        <Flex>
                            <TbTruckDelivery size='5%' />
                            <Text ml='5'>
                                {deliveryText1}
                            </Text>
                        </Flex>

                        <Flex mt='3' >
                            <FiCornerDownLeft size='5%' />
                            <Text ml='5' >{deliveryText2}</Text>
                        </Flex>
                        <Flex mt='3' >
                            <BsExclamationLg size='5%' />
                            <Text ml='5'>
                                {deliveryText3}
                            </Text>
                        </Flex>
                    </Container>
                </Collapse>
            </Flex>
        </>
    )
}

export default ProductInfo