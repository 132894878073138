import { Flex, Grid, Switch, Text } from '@chakra-ui/react';
import { showPrice, priceText, chooseProductText } from 'assets/constants/strings';
import { getProductListInLaunchpad } from 'components/API/AuthAPI';
import Emitter from 'Emitter';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PriceVisible } from 'Slices/cartslice';
import { productLoad } from 'Slices/launchpadslice';

const ChoosePrice = (props) => {
    const [isPriceVisible, setIsPriceVisible] = useState(false);
    const [price, setPrice] = useState();
    const [id, setId] = useState();
    const [productId, setProductId] = useState();
    const [token, setToken] = useState();
    const dispatch = useDispatch();

    //store selected product price
    const handleCheckPrice = (product) => {
        dispatch(PriceVisible(product));
        dispatch(productLoad(product))
        props.onClick();

    };
    Emitter.on('SELLER_ID_FROM_LAUNCHPADPRODUCT', (e) => setId(e));
    Emitter.on('PRODUCT_ID', (e) => setProductId(e));

    useEffect(() => {
        let userToken = localStorage.getItem("token");
        if (userToken) {
            setToken(userToken);
        }
        getProducts(userToken);
    }, [id]);


    useEffect(() => {
        if (!id) {
            let productId = sessionStorage.getItem('productId');
            setId(productId);
        }
    }, [productId]);

    // load selected product price
    const getProducts = (token) => {
        if (id) {
            getProductListInLaunchpad(token, id)
                .then((responseJson) => {
                    if (responseJson) {
                        if (responseJson.data) {
                            let img = responseJson.data.map((item) => {
                                if (item.id == productId) {
                                    setPrice(item.PRDPrice);
                                    Emitter.emit('INPUT_FROM_PRICE', item.PRDPrice);
                                }
                            })
                        }
                    }
                })
        }
    }

    //add price onto launchpad canvas
    const handleChange = () => {
        if (!isPriceVisible) {
            setIsPriceVisible(true);
            // handleClick()
            handleCheckPrice(true)
            // alert("true");
            setTimeout(() => {
                Emitter.emit('INPUT_FROM_ISPRICEVISIBLE', isPriceVisible);
            }, 500)
        } else {
            setIsPriceVisible(false);
            handleCheckPrice(false)
            // alert("false")
            setTimeout(() => {
                Emitter.emit('INPUT_FROM_ISPRICEVISIBLE', isPriceVisible);
            }, 500)
        }
    }


    return (
        <Flex
            flexDirection="column"
        >
            <Grid justifyContent="center">
                <Text
                    fontWeight="bold"
                    pt="20px"
                >
                    {priceText}
                </Text>
            </Grid>
            {id ?
                <Flex
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    margin="0 auto"
                    mt="20px"
                    alignItems="center"
                    border="1px solid black"
                    borderRadius="20px"
                    w="230px"
                    h="40px"
                >
                    <Switch
                        size="md"
                        onChange={handleChange}
                    />
                    <Text
                        pl="10px"
                    >
                        {showPrice}
                    </Text>
                </Flex>
                :
                <Text
                    mt="50px"
                    fontWeight={"bold"}
                    color="red"
                    textAlign="center"
                >
                    {chooseProductText}!!!
                </Text>
            }
        </Flex>
    )
}

export default ChoosePrice