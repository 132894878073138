import React, { useEffect, useState } from 'react';
import { Image, Center, Text, Flex, Box, Button, ChakraProvider, Heading, Container } from '@chakra-ui/react';
import { BsFacebook, BsPinterest } from 'react-icons/bs';
import { FaTwitterSquare, FaInstagramSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { AiFillHeart } from 'react-icons/ai';
import "./styles.css";


function SellerInvitation(props) {
    const [firstname,setFirstname] = useState("Michael");
    const [lastname, setLastname] = useState("");
    const [initial, setInitial] = useState();
    
    // function(){
    //     var firstName = $('#firstName').text();
    //     var lastName = $('#lastName').text();
    //     var intials = $('#firstName').text().charAt(0) + $('#lastName').text().charAt(0);
    //     var profileImage = $('#profileImage').text(intials);
    //   }

    useEffect(()=>{
        dispName();
    },[])

    const dispName =()=> {
        let initial_letters = firstname.charAt(0) + lastname.charAt(0);
        setInitial(initial_letters);
    }


    return (
        <div className='registration_wrapper'>
            <Center>

                <Flex direction='column' pt={{ base: "20px", md: "50px" }}>
                    <Center>
                        <Text 
                            fontSize='3xl' 
                            fontWeight="bold"
                            color={"#219691"}
                        >
                            MY BOUTIQ
                        </Text>
                    </Center>

                    <Container
                        mt="3" 
                        maxW='lg'
                    >

                        <Image
                            // w={{ sm: '100%', md: '100%', lg: '100%' }}
                            h={{ sm:"280px", md:"300px", lg:"350px" }}
                            w="70vh"
                            src='https://cdn.create.vista.com/api/media/medium/296406986/stock-photo-top-view-shopping-cart-presents?token='
                        />

                        <div id="profileImage">{initial}</div>
                    

                    
                        <Box maxW='43rem'>
                            <Text 
                                mt='5' 
                                fontSize='md' 
                                textAlign={"center"}
                            > 
                                Michael has invited you to join MY BOUTIQ.
                            </Text>
                            <Text 
                                fontSize='md' 
                                mt='5'
                                textAlign={"center"}
                            >
                                MY BOUTIQ is the world's most popular affiliate network platform, where
                                you can onboard any business of your choice. Sign up new business and help 
                                them grow and reach new customers around the globe.
                            </Text>

                        </Box>
                    
                    <Center>


                        <Link to='/affilateInvite'>
                            <Button
                                mt='10'
                                bg='#219691' size='lg'
                                colorScheme='none'
                                w={{ lg: "sm" }}
                            >
                                JOIN NOW
                            </Button>
                        </Link>



                    </Center>

                    <Center mt="10">
                        <Box maxW='43rem'>
                            <Heading 
                                fontSize="xl"
                                textAlign={"center"}
                            >
                                THERE IS SO MUCH TO DISCOVER
                            </Heading>
                            <Text 
                                fontSize='md' 
                                mt='5'
                                textAlign={"center"}
                            >
                                Discover hundreads of new and exciting products every day. Our Customer shop from big brands and local newcomers, 
                                splecialised and startups. Scroll through our Gallery of beautifully crafted items and shop now.
                            </Text>
                        </Box>
                    </Center>
                   
                    <Center display='flex' mt='10'>
                        <BsFacebook size='8%' />
                        <FaInstagramSquare size='8%' style={{ marginLeft: "10px" }} />
                        <BsPinterest size='8%' style={{ marginLeft: "10px" }} />
                        <FaTwitterSquare size='8%' style={{ marginLeft: "10px" }} />
                    </Center>

                    <Center display='flex' flexDirection='column' mt='10'>
                        <Text>
                            Sent with <AiFillHeart style={{ display: "initial" }} /> from MY BOUTIQ
                        </Text>
                        <Text>
                            Imprint | Data Privacy
                        </Text>
                        <Text   as='u'>
                            Unsubscribe
                        </Text>
                    </Center>

                </Container>


                </Flex>

            </Center>

        </div>
    )
}

export default SellerInvitation;