import React, { useEffect, useState } from 'react'
import {
  Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Input, Image
} from '@chakra-ui/react'
import NavItem from '../components/NavItem'
import { BsChatLeft, BsClock, BsPeopleFill } from 'react-icons/bs'
import { TbSocial } from 'react-icons/tb';
import { FcTemplate } from 'react-icons/fc';
import { BiShoppingBag } from 'react-icons/bi';
import { MdPriceChange } from 'react-icons/md';
import SelectAccount from './SelectAccount';
import SelectBackground from './SelectBackground';
import ChoosePlatform from './ChoosePlatform';
import Emitter from 'Emitter'
import ChooseText from './ChooseText'
import ChooseProduct from './ChooseProduct'
import ChoosePrice from './ChoosePrice'
import ChooseElement from './ChooseElement'
import { Translate } from 'components/DeepL/Translate'

export default function SimpleSidebar(props) {
  const [navSize, changeNavSize] = useState("large")

  return (
    <Flex
      display={{ sm: "none", md: "none", lg: "flex" }}
      pos="relative"
      // left="4"                                    
      h="100%"
      pt="55px"
      marginTop="2.5vh"
      boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
      w="100%"
      flexDir="column"
    >
      <Flex
        flexDir="column"        
      >
        
        <Tabs
          variant='enclosed'
          orientation="vertical"
          bg="#ffffff"
          h="92vh"
          w="30vw"
          isFitted="true"
          size="sm"
        >
          <TabList>
            <NavItem
              flexDir="row"
              navSize={navSize}
              icon={BsPeopleFill}
              title= {Translate ? "Account" : Translate("Account")}
            >
            </NavItem>
            <NavItem
              flexDir="row"
              navSize={navSize}
              icon={TbSocial}
              title= {Translate ? "Platform" : Translate("Platform")}
            />
            <NavItem
              flexDir="row"
              navSize={navSize}
              icon={FcTemplate}
              title= {Translate ?  "Background" : Translate("Background")}
            />
            <NavItem
              flexDir="row"
              navSize={navSize}
              icon={BiShoppingBag}
              title= {Translate ? "Product" :  Translate("Product")}
            />
            <NavItem
              flexDir="row"
              navSize={navSize}
              icon={BsChatLeft}
              title= {Translate ? "Element" : Translate("Element")}
            />
            <NavItem
              flexDir="row"
              navSize={navSize}
              icon={MdPriceChange}
              title= {Translate ?  "Price" : Translate("Price")}
            />
            <NavItem
              flexDir="row"
              navSize={navSize}
              icon={BsChatLeft}
              title= {Translate ? "Text" : Translate("Text")}
            />
          </TabList>
          <TabPanels >
            <TabPanel>
              <SelectAccount />
            </TabPanel>
            <TabPanel>
              <ChoosePlatform/>
            </TabPanel>
            <TabPanel>
              <SelectBackground />
            </TabPanel>
            <TabPanel >
                <ChooseProduct />
            </TabPanel>
              <TabPanel>
                <ChooseElement/>
              </TabPanel>
            <TabPanel >
              <ChoosePrice />
            </TabPanel>
            <TabPanel >
              <ChooseText/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      
      </Flex>
    </Flex>
  )
}