import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getPaymentKeys } from "components/API/AuthAPI";
import { PaymentIntent } from "components/API/AuthAPI";
import { Flex } from "@chakra-ui/react";
import ApplePay from "components/checkout/ApplePay";
import GooglePay from "./GooglePay";
import ApplePay1 from "./ApplePay1";

function Stripe(props) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [cartId,setCartId] = useState();

  

  useEffect(()=>{
    getPaymentKeys()
    .then((responseJson)=>{
      if(responseJson){
        // console.log("res",responseJson)
        let publishableKey = responseJson.stripe.publicKey
        setStripePromise(loadStripe(publishableKey), {stripeAccount:"acct_1LURWkKRACV8ss4t"});
        // setSecretKey(responseJson.stripe.secret_key);
        // console.log(publishableKey);
      }
    })

  },[]);

  // console.log("props",props.first_name)


  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <Flex w='100%'>          
            {/* <ApplePay/> */}
            {/* <GooglePay/> */}
            <ApplePay1
              accounttoken={props.accounttoken} 
              productId={props.productId} 
              qty={props.qty}
            />
            
          </Flex>
        </Elements>
      )}
    </>
  );
}

export default Stripe;