import {
    Button,
    Flex,
    Grid,
    Text,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    useDisclosure,
    Input,
    useToast,
} from '@chakra-ui/react'
import React, { useState, useRef, useEffect } from 'react';
import { InviteMember } from 'components/API/AuthAPI';
import { Translate } from 'components/DeepL/Translate';
import { invite, cancel, enteremail, affiliateboutiqs, New } from 'assets/constants/strings';

const Header = () => {
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [token, setToken] = useState("")
    const toast = useToast();
    const [state, setState] = useState({
        first_name: "",
        last_name: "",
        email: "",
    })
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)
    const finalRef = useRef(null)
    const { first_name, last_name, email } = state;

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    };

    const handleSubmit = (e) => {
        e.preventDefault()
    };

    const getAuthToken = () => {
        const userToken = localStorage.getItem("token")
        setToken(userToken)
    };

    useEffect(() => {
        getAuthToken()
    }, [token])


    /**
     * The InvitesellerLink function sends an invitation to a member with the provided email address
     * and displays a success or error message using a toast.
     */
    const InvitesellerLink = () => {
        setIsSubmitted(true)
        if (email) {
            InviteMember(token, first_name, last_name, email).then(responseJson => {
                if (responseJson["message"]) {
                    onClose();
                    setIsSubmitted(false);
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {responseJson['message']}
                            </Box>
                        ),
                    })

                } else {
                    setIsSubmitted(false)
                    onClose();
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {responseJson['email']}
                            </Box>
                        ),
                    })
                }
            }).catch(err)
            setState({ email: "" })
        } else {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        {enteremail}
                    </Box>
                ),
            })
            setIsSubmitted(false)
        }
    }
    return (
        <Flex direction='column' pt={{ base: "0px", md: "5px", lg: "0px" }}>

            <Flex
                display="flex"
                px={{ sm: "0px", md: "20px", lg: "20px" }}
                justifyContent="space-between"
            >
                <Text
                    color="#219691"
                    fontWeight={"bold"}
                    fontSize={{ lg: "24px" }}
                    py={{ sm: "30px", md: "20px", lg: "20px" }}
                >
                    {affiliateboutiqs}
                </Text>
                <Grid py="20px">
                    <Button
                        colorScheme='none'
                        variant='outline'
                        p='8px 32px'
                        bg="#219691"
                        color="white"
                        onClick={onOpen}
                    >
                        + {New}
                    </Button>
                </Grid>
                <Modal
                    initialFocusRef={initialRef}
                    finalFocusRef={finalRef}
                    isOpen={isOpen}
                    onClose={onClose}
                    size={"sm"}
                >
                    <ModalOverlay />
                    <form onSubmit={handleSubmit}>
                        <ModalContent border="2px solid #219691">
                            <ModalHeader>Invite New Seller</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody pb={6}>
                                <FormControl mt={4}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                        onChange={handleChange}
                                        name="email"
                                        value={email}
                                        required
                                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$"
                                        placeholder={"Email"}
                                    />
                                </FormControl>


                            </ModalBody>

                            <ModalFooter>
                                {
                                    !isSubmitted ?
                                        <Button
                                            type='submit'
                                            bg="#219691"
                                            colorScheme={'none'}
                                            color="white"
                                            mr={3}
                                            onClick={InvitesellerLink}
                                        >
                                            {invite}
                                        </Button>
                                        :
                                        <Button
                                            type='submit'
                                            bg="#219691"
                                            colorScheme='teal'
                                            variant='solid'
                                            isLoading
                                            loadingText='please wait....'
                                            color="white"
                                            mr={3}
                                            onClick={InvitesellerLink}
                                        >
                                            {invite}
                                        </Button>
                                }
                                <Button border="1px solid #219691" onClick={onClose}>{cancel}</Button>
                            </ModalFooter>
                        </ModalContent>
                    </form>
                </Modal>

            </Flex>
        </Flex>
    )
}

export default Header;