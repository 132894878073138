import { Collapse, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import './homepage.css';

const Categories = (props) => {

    return (
        <div>
            <Flex
                display={{ sm: "none", md: "none", lg: 'flex', xl: "flex" }}
                flexDirection='column'
                className='home_wrapper_category'
            >
                <Text
                    fontWeight='bold'
                    mt='5'
                    fontSize={'24px'}
                >
                    {/* {Translate("Categories") ? Translate("Categories") : "Categories"} */}
                    Categories
                </Text>
                {
                    props.flag === true && props.firstenter ?

                        <Text mb='2' w='250px' pl='6'
                            className='home_wrapper_Categories'
                            cursor={'pointer'}
                            // w={{ sm: "100%", md: "100%", xl: "160%" }}
                            style={{
                                fontSize: props.fontsetting,
                                backgroundColor: props.flag === true || false && props.selectedId ? "#219691" : "",
                                color: props.flag === true || false ? "white" : "",
                                borderRadius: "10px",
                                fontWeight: "bold"
                            }}
                        >
                            {/* {Translate("All") ? Translate("All") : "All"} */}
                            All
                        </Text>
                        : <Text mb='2' w='250px' pl='6'
                            className='home_wrapper_Categories'
                            cursor={'pointer'}
                            onClick={() => {
                                props.getalldetails()
                            }}
                            style={{
                                fontSize: props.fontsetting,
                                backgroundColor: props.flag === true || false && props.selectedId ? "#219691" : "",
                                color: props.flag === true || false ? "white" : "",
                                borderRadius: "10px"
                            }}
                        >
                            {/* {Translate("All") ? Translate("All") : "All"} */}
                            All
                        </Text>}
                {
                    props.getuserProduct.length > 0 ? props.getuserProduct.map((ele, index) => {
                        return (
                            <Text 
                                pl='5'
                                key={index}
                                className='home_wrapper_Categories'
                                cursor={'pointer'}
                                // w={{ sm: "100%", md: "100%", xl: "160%" }}
                                onClick={() => {
                                    props.getcatDetails(ele.id)
                                }}
                                style={{
                                    fontSize: props.fontsetting,
                                    backgroundColor: props.selectedId === ele.id && props.flag === false ? "#219691" : "",
                                    color: props.selectedId === ele.id && props.flag === false ? "white" : "",
                                    borderRadius: "10px",

                                }}
                            >
                                {ele.category.name}
                            </Text>
                        )
                    }) : ""
                }
            </Flex>
            <Flex
                display={{ sm: "flex", md: "flex", lg: "none", xl: "none" }}
                flexDirection='column'
                className='home_wrapper_category_small_screen'
            >
                <Text
                    textAlign='left'
                    fontWeight='bold' 
                    mt='5'
                    fontSize={'18px'}
                    // style={{ fontSize: props.fontsetting }}
                    onClick={props.getListofCategory}
                >
                    {/* {Translate("Categories") ? Translate("Categories") : "Categories"} */}
                    Categories
                </Text>

                {
                    props.flag === true && props.firstenter ?
                        <Collapse
                            in={props.openList}
                            animateOpacity>
                            <Text mb='2' pl='5'
                                className='home_wrapper_Categories'

                                style={{
                                    fontSize: props.fontsetting,
                                    backgroundColor: props.flag === true || false && props.selectedId ? "#219691" : "",
                                    color: props.flag === true || false ? "white" : "",
                                    borderRadius: "10px",
                                }}
                            >
                                {/* {Translate("All") ? Translate("All") : "All"} */}
                                All
                            </Text>
                        </Collapse>
                        :
                        <Collapse
                            in={props.openList}
                            animateOpacity>
                            <Text mb='2' pl='5'
                                className='home_wrapper_Categories'
                                // w={{ sm: "100%", md: "100%", xl: "160%" }}
                                onClick={() => {
                                    props.getalldetails()
                                }}
                                style={{
                                    fontSize: props.fontsetting,
                                    backgroundColor: props.flag === true || false && props.selectedId ? "#219691" : "",
                                    color: props.flag === true || false ? "white" : "",
                                    borderRadius: "10px",
                                    fontWeight: "bold"

                                }}
                            >
                                {/* {Translate ? Translate("All") : "All"} */}
                                All
                            </Text>
                        </Collapse>
                }
                {
                    props.getuserProduct.map((ele, index) => {
                        return (
                            <Collapse
                                key={index}
                                in={props.openList}
                                animateOpacity>
                                <Text pl='5'
                                    className='home_wrapper_Categories'
                                    cursor={'pointer'}
                                    onClick={() => {
                                        props.getcatDetails(ele.id)
                                    }}
                                    style={{
                                        fontSize: props.fontsetting,
                                        backgroundColor: props.selectedId === ele.id && props.flag === false ? "#219691" : "",
                                        color: props.selectedId === ele.id && props.flag === false ? "white" : "",
                                        borderRadius: "10px",
                                        fontWeight: "bold"

                                    }}
                                >
                                    {ele.category.name}

                                </Text>
                            </Collapse>
                        )
                    })
                }

            </Flex>

        </div>
    )
}

export default Categories