import React, { useEffect } from 'react';
import { Flex, Grid,} from "@chakra-ui/react";
import Profile2 from './components/Profile2';
import { useHistory } from 'react-router-dom';

const AffiliateProfile = () => {
  let history = useHistory();
  useEffect(() => {
    checkUserLogin();
  }, []);

  /**
   * The function checks if a user is logged in by retrieving their token from local storage and
   * redirecting them to the home page if the token is not found.
   */
  const checkUserLogin = () => {
    const userToken = localStorage.getItem("token");
    if (!userToken) {
      history.push({
        pathname: "/"
      });
    }
  }
  return (
    <Flex
      direction='column'
      pt={{ base: "120px", md: "75px" }}
      pl="10px"
      pr='10px'
    >
      <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} templateRows='1fr'>
        <Profile2 />
      </Grid>
    </Flex>
  )
}

export default AffiliateProfile