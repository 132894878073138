import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Text, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react';
import '@inovua/reactdatagrid-community/index.css';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { GetLauchpadDetails, DeleteLaunchpadEntry, deletePostFromSocial } from 'components/API/AuthAPI';
import { LaunchpadEntry } from 'components/API/AuthAPI';

const LaunchpadTable = () => {
  const [verticalBorders, setVerticalBorders] = useState(true)
  const [horizontalBorders, setHorizontalBorders] = useState(false)
  const [token, setToken] = useState();
  const [details, setDetails] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const toast = useToast();

  const handleCloseDelete = () => {
    setOpenDelete(false);
  }

  useEffect(() => {
    let userToken = localStorage.getItem("token");
    if (userToken) {
      setToken(userToken);
      getDetails(userToken);
    }
  }, []);

  const getDetails = (token) => {
    GetLauchpadDetails(token)
      .then((res) => {
        if (res) {
          // console.log("launch", res.results)
          setDetails(res.results);

        }
      })
  }

  const DeletePost = (id, postId) => {
    setIsSubmitted(true);
    DeleteLaunchpadEntry(token, id).then((res) => {
      if (res == "Deleted") {
        // console.log(res)
        deletePostFromSocial(postId).then((res1) => {
          if (res) {
            // console.log('res', res1)
          }
        })
        getDetails(token)
        toast({
          position: 'top',
          render: () => (
            <Box
              color='white'
              p={3} bg='#219691'
              textAlign={'center'}
              fontWeight='bold'
            >
              Post has been deleted successfully.
            </Box>
          ),
        })
        setIsSubmitted(false);
        handleCloseDelete();
      }
    })
  }

  const EditTable = (acc_name, platform, platform1, times, period, id1, id, active) => {
    if (active == true) {
      let active_status = false;
      setIsSubmitted(true);
      LaunchpadEntry(token, active_status, acc_name, platform, platform1, times, period, id1).then((res) => {
        if (res) {
          console.log(res);
          DeleteLaunchpadEntry(token, id).then((res) => {
            if (res == "Deleted") {
              // console.log(res);
              setIsDelete(false);
              setIsSubmitted(false);
            }
          })
        }
        getDetails(token);
        onClose()
        toast({
          position: 'top',
          render: () => (
            <Box
              color='white'
              p={3} bg='#219691'
              textAlign={'center'}
              fontWeight='bold'
            >
              Post has been Deactivated.
            </Box>
          ),
        })
      })
    } else if (active == false) {
      let active_status = true;
      setIsSubmitted(true)
      LaunchpadEntry(token, active_status, acc_name, platform, platform1, times, period, id1).then((res) => {
        if (res) {
          console.log(res);
          DeleteLaunchpadEntry(token, id).then((res) => {
            if (res == "Deleted") {
              // console.log(res);
              setIsDelete(false);
              setIsSubmitted(false)
            }
          })
        }
        getDetails(token)
        onClose();
        toast({
          position: 'top',
          render: () => (
            <Box
              color='white'
              p={3} bg='#219691'
              textAlign={'center'}
              fontWeight='bold'
            >
              Post has been Activated.
            </Box>
          ),
        })
      })
    }
  }

  const customCellStyle = cellProps => {
    const { value, rowActive, rowIndex } = cellProps;
    return {
      color: rowActive
        ? '#e9ecf0'
        : value % 2 ? '#ff595e' : 'inherit',
    }
  };

  let showCellBorders = false
  if (verticalBorders) {
    showCellBorders = 'vertical'
  }
  if (horizontalBorders) {
    showCellBorders = 'horizontal'
  }
  if (horizontalBorders && verticalBorders) {
    showCellBorders = true
  }

  const columns = [
    {
      name: 'active', header: 'Status', minWidth: 200, defaultFlex: 1, headerAlign: "center",
      headerProps: {
        className: "tableHeader"
      },
      render: ({ value, data }) => {
        // console.log(value)
        return <div style={{ display: 'inline-block', textAlign: "center", justifyContent: "center" }}>

          {value == true ?
            <Text>
              Active
            </Text>
            :
            <Text>
              Inactive
            </Text>
          }

        </div>
      }
    },
    {
      name: 'account_name', header: 'Account Name', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'channel', header: 'Channel', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'post_type', header: 'Post Type', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'postings', header: 'Postings', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'frequency', header: 'Frequency', minWidth: 200, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'action', header: 'Action', defaultFlex: 1, minWidth: 200, headerAlign: "center", headerProps: {
        className: "tableHeader"
      },
      style: customCellStyle,
      render: ({ value, data }) => {
        // console.log('store', data)

        return <div style={{ display: 'inline-block', textAlign: "center", justifyContent: "center" }}>
          {/* <Link =""> */}

          <Button
            colorScheme='none'
            borderColor='#ffffff'
            color='#ffffff'
            bg="#219691"
            variant='outline'
            fontSize='xs'
            p='8px 32px'
            onClick={() => {
              // console.log(data)
              onOpen()
            }}
          >Edit</Button>
          <Button
            colorScheme='none'
            borderColor='#ffffff'
            color='#ffffff'
            bg="#219691"
            variant='outline'
            fontSize='xs'
            p='8px 32px'
            onClick={() => {
              // DeletePost(data.id, data.post_id)
              setOpenDelete(true)
            }}
          >Delete</Button>
          {/* </Link> */}
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size='xs'
            display='flex'
            justifySelf='center'
          >
            <ModalOverlay bg='none' />
            <ModalContent
              mt='20%'
              height={'200px'}
            >
              {/* <ModalHeader>Modal Title</ModalHeader> */}
              <ModalCloseButton />
              <ModalBody
                justifyContent='center'
                justifySelf={'center'}
              >
                {data.active == true ?
                  <Text
                    pl='20px'
                    pr='20px'
                    pt='50px'
                    textAlign={'center'}
                    fontSize='lg'
                    fontWeight={'bold'}
                    noOfLines={2}
                  >
                    Do you want to deactivate the post ?
                  </Text>
                  :
                  <Text
                    pl='20px'
                    pr='20px'
                    pt='50px'
                    textAlign={'center'}
                    fontSize='lg'
                    fontWeight={'bold'}
                    noOfLines={2}
                  >
                    Do you want to activate the post ?
                  </Text>
                }
              </ModalBody>

              <ModalFooter>
                <Flex
                  justifyContent={'space-between'}
                  margin='0 auto'
                >
                  {isSubmitted == false ?
                    <Button
                      variant='ghost'
                      color='white'
                      colorScheme={'none'}
                      bg='#219691'
                      ml='20px'
                      mr='20px'
                      w='100%'
                      onClick={() => {
                        EditTable(data.account_name, data.channel, data.channel, data.postings, data.frequency, data.post_id, data.id, data.active)
                      }}
                    >
                      Yes
                    </Button>
                    :
                    <Button
                      colorScheme='teal'
                      variant='solid'
                      color='white'
                      isLoading
                      loadingText='please wait....'
                      bg='#219691'
                      ml='20px'
                      mr='20px'
                      w='100%'
                    >
                      Yes
                    </Button>
                  }

                  <Button
                    colorScheme='none'
                    bg='#219691'
                    color='#ffffff'
                    ml='20px'
                    mr='20px'
                    w='100%'
                    onClick={() => { onClose() }}
                  >
                    No
                  </Button>

                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal
            isOpen={openDelete}
            onClose={onClose}
            size='xs'
            display='flex'
            justifySelf='center'
          >
            <ModalOverlay bg='none' />
            <ModalContent
              mt='20%'
              height={'200px'}
            >
              {/* <ModalHeader>Modal Title</ModalHeader> */}
              <ModalCloseButton onClick={() => { handleCloseDelete() }} />
              <ModalBody
                justifyContent='center'
                justifySelf={'center'}
              >
                <Text
                  pl='20px'
                  pr='20px'
                  pt='50px'
                  textAlign={'center'}
                  fontSize='lg'
                  fontWeight={'bold'}
                  noOfLines={2}
                >
                  Do you want to delete the post ?
                </Text>

              </ModalBody>

              <ModalFooter>
                <Flex
                  justifyContent={'space-between'}
                  margin='0 auto'
                >
                  {isSubmitted == false ?
                    <Button
                      variant='ghost'
                      color='white'
                      colorScheme={'none'}
                      bg='#219691'
                      ml='20px'
                      mr='20px'
                      w='100%'
                      onClick={() => {
                        DeletePost(data.id, data.post_id)
                      }}
                    >
                      Yes
                    </Button>
                    :
                    <Button
                      colorScheme='teal'
                      variant='solid'
                      color='white'
                      isLoading
                      loadingText='please wait....'
                      bg='#219691'
                      ml='20px'
                      mr='20px'
                      w='100%'
                    >
                      Yes
                    </Button>
                  }

                  <Button
                    colorScheme='none'
                    bg='#219691'
                    color='#ffffff'
                    ml='20px'
                    mr='20px'
                    w='100%'
                    onClick={() => { handleCloseDelete() }}
                  >
                    No
                  </Button>

                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </div>
      }
    },

  ];

  const gridStyle = { minHeight: 550, maxWidth: 1700, textAlign: "center", fontSize: "14px" };

  const rowStyle = { fontSize: "15.5px", fontStyle: "normal" }


  return (
    <Flex w='100%'>
      <Card p='10px' mt='5px'
        maxHeight="600px"
        maxWidth="1700px"
      >
        <CardHeader>
          <ReactDataGrid
            idProperty="id"
            columns={columns}
            dataSource={details}
            style={gridStyle}
            rowStyle={rowStyle}
            rowHeight={50}
            headerHeight={55}
            showCellBorders={false}
            pagination
            showZebraRows={false}
            defaultLimit={10}
          />
        </CardHeader>
      </Card>

    </Flex>
  )
}

export default LaunchpadTable