/*eslint-disable*/
// chakra imports
import {
    Box,
    Button, Flex,
    IconButton,
    Link,
    Stack,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { Separator } from "components/Separator/Separator";
import { SidebarHelp } from "components/Sidebar/SidebarHelp";
import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { NavLink, useLocation } from "react-router-dom";

// this function creates the links and collapses that appear in the sidebar (left menu)


const SidebarContent2 = ({ logoText, routes, navSize, expand, onClick, handleClick }) => {

    // to check for active links and opened collapses
    let location = useLocation();
    // this is for the rest of the collapses
    const [state] = useState({});
    const [display_name, setDisplay_name] = useState();



    useEffect(() => {

        setDisplay_name(localStorage.getItem('display_name') || null)
        window.addEventListener('storage', storageEventHandler, false);
        window.addEventListener('storage1', storageEventHandler, false);

    }, []);

    const storageEventHandler =()=> {
        // console.log("hi from storageEventHandler")
        setDisplay_name(localStorage.getItem('display_name') || null)
    }

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };

    const createLinks = (routes) => {
        // Chakra Color Mode
        const activeBg = useColorModeValue("white", "gray.700");
        const inactiveBg = useColorModeValue("white", "gray.700");
        const activeColor = useColorModeValue("gray.700", "white");
        const inactiveColor = useColorModeValue("gray.400", "gray.400");

        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.category) {
                var st = {};
                st[prop["state"]] = !state[prop.state];
                return (
                    <div key={key}>
                        <Text
                            color={activeColor}
                            fontWeight="bold"
                            mb={{
                                xl: "12px",
                            }}
                            mx="auto"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"

                            display={navSize == "small" && expand == false ? "none" : "flex"}
                        >
                            {document.documentElement.dir === "rtl"
                                ? prop.rtlName
                                : prop.name}
                        </Text>
                        {createLinks(prop.views)}
                    </div>
                );
            }
            return (
                <NavLink to={prop.layout + prop.path} key={prop.name}>

                    {activeRoute(prop.layout + prop.path) === "active" ? (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            // bg={activeBg}
                            bg="#f7f8f9"
                            mb={{
                                xl: "12px",
                            }}
                            mx={{
                                xl: "auto",
                            }}
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                            borderRadius="15px"
                            _hover="#219691"
                            w={"100%"}
                            // w="100%"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                        >
                            <Flex>
                                {typeof prop.icon === "string" ? (
                                    <Icon>{prop.icon}</Icon>
                                ) : (
                                    <IconBox
                                        // bg="teal.300"
                                        // color="white"
                                        h="30px"
                                        w="30px"
                                        me="12px"
                                    >
                                        {prop.icon}
                                    </IconBox>
                                )}
                                <Text color={activeColor} my="auto" fontSize="sm" display={navSize == "small" && expand == false ? "none" : "flex"}>
                                    {document.documentElement.dir === "rtl"
                                        ? prop.rtlName
                                        : prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    ) : (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            // bg="transparent"
                            bg={inactiveBg}
                            mb={{
                                xl: "12px",
                            }}
                            mx={{
                                xl: "auto",
                            }}
                            py="12px"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            borderRadius="15px"
                            _hover="none"
                            w="100%"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                            onClick={onClick}
                        >
                            <Flex>
                                {typeof prop.icon === "string" ? (
                                    <Icon>{prop.icon}</Icon>
                                ) : (
                                    <IconBox
                                        bg={inactiveBg}
                                        // color="teal.300"
                                        h="30px"
                                        w="30px"
                                        me="12px"
                                    >
                                        {prop.icon}
                                    </IconBox>
                                )}
                                <Text color={inactiveColor} my="auto" fontSize="sm" display={navSize == "small" && expand == false ? "none" : "flex"}>
                                    {document.documentElement.dir === "rtl"
                                        ? prop.rtlName
                                        : prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    )}
                </NavLink>
            );
        });
    };

    const links = <>{createLinks(routes)}</>;

    return (
        <>
            <Box pt={"0px"} mb="0px"
                display="flex"
                justifyContent="space-between"

            // flexDirection="column"
            >
                <Flex
                    display="flex"
                    mb="30px"
                    fontWeight="normal"
                    fontSize="11px"
                >
                    {/* <CreativeTimLogo w="32px" h="32px" me="10px" /> */}
                    {display_name == 'undefined' ?
                        <Text 
                            fontSize="sm" 
                            width="100px" 
                            mt="8" 
                            ml="0" 
                            color="#000000" 
                            display={navSize == "small" && expand == false ? "none" : "flex"}>
                            User
                        </Text>
                        :
                        <Text fontSize="sm" width="100px" mt="8" ml="0" color="#000000" display={navSize == "small" && expand == false ? "none" : "flex"}>
                            {display_name}
                        </Text>
                    }
                </Flex>
                <IconButton
                    background="none"
                    mt={4}
                    _hover={{ background: 'none' }}
                    icon={navSize == "small" && expand == false ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                    onClick={handleClick}
                />


            </Box>
            <Separator display={navSize == "small" && expand == false ? "none" : "flex"}></Separator>
            <Stack direction="column" mb="40px">
                <Box>{links}</Box>
            </Stack>
            {/* <SidebarHelp /> */}
        </>
    )
}

export default SidebarContent2