export const baseUrl = "https://adminmyboutiqe.progfeel.co.in";

export const guestToken = "#up!7O@rh#55Fp3I1CpHQ!d5xs2J2c#FRc@x^bb@ZHTzK5@v5bCivAjSjAx^3Vhs";

// export const bearerToken = "649G285-W8GM9M1-PZRTH51-726PNW4";
export const bearerToken = 'MXTKZ5K-B384K5V-QKZZFCG-0P71W4B'

// export const currencyKey= "45a1e45e9130f07a515d0f4e" ;
export const currencyKey = "745b86228eb140998a47b62f";
// MXTKZ5K-B384K5V-QKZZFCG-0P71W4B

export const exchangeRateBaseUrl = "https://v6.exchangerate-api.com"

export const ayrshareBaseUrl = "https://app.ayrshare.com";  

export const paypalBaseUrl = "https://api-m.sandbox.paypal.com"

export const PayPalUser = "AYTU778DORty76cZLH3SC3ul5AUT7LUOMdOu82fR1b4PKOaQa1NOEfUgQNxFOYnWDRxhFnAAPDhRFNsz"

export const PayPalPass = "ED_BQTWAuqUd-kuvG75hOEA00ZHIJPIRhlnVSXtMCV3aqDbADZELnUs2KrpD5pLOtQu8Rn9yO5gHSmBa";

export const PayPalAccessToken = 'A21AALOokCdhMe5uL5PM5k36I7JjqUcI3YUrlynXevhsIfJrraTcKwV_odK6dyRScodz1NJu-25131tjON_VQgTc8CPD2U8TA';