import React, { useEffect, useState } from 'react'
import { ChakraProvider, Image, Text, Flex, Collapse, Box, useDisclosure } from '@chakra-ui/react';
import { BsSearch } from 'react-icons/bs'
import './gallerypage.css'
import { Link } from 'react-router-dom'
import { AiOutlineHeart } from 'react-icons/ai';
import { MainHeader } from 'components/header/MainHeader';
import cutLogo from "../../assets/svg/cut-logo.svg";
import { AddToCart } from 'components/header/AddToCart';
import { galleryCategory, categoriesList } from 'components/API/AuthAPI';
import theme from 'theme/theme';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import GalleryBanner from './GalleryBanner';
import GalleryFooter from './GalleryFooter';

function GalleryList(props) {
    const { isOpen, onToggle } = useDisclosure()
    const [disp, setDisp] = useState('flex');
    const [allCategories, setAllCategories] = useState();
    const [cat, setCat] = useState();
    const [userId] = useState()
    const [store_id] = useState([])
    const [getCart] = useState([])
    const [getName, setGetName] = useState()
    const [cart, setCart] = useState(false)
    let history = useHistory();


    const onClose1 = () => {
        setCart(false)
    }
    /**
     * The function sets the value of a variable called "cart" to true when the "onOpencart" event is
     * triggered.
     */
    const onOpencart = () => {
        setCart(true)
    }

    /**
     * The function handles a callback by setting an item in local storage and updating the state with
     * the received data.
     */
    const handleCallback = (childData) => {
        localStorage.setItem("changeUser", childData)
    }

    useEffect(() => {
        const checkUserLogin = async () => {
            let userToken;
            let getStore;
            try {
                userToken = await localStorage.getItem("token");
                getAllCategoriesList();
                getGalleryCategory();
                getStore = await localStorage.getItem("storename");
                setGetName(getStore);
                handleCallback1(getStore)
                handleCallback()
            } catch (e) { }
        }
        checkUserLogin();
    }, [getName])


    /**
     * This function retrieves a list of all categories and sets them in state.
     */
    const getAllCategoriesList = () => {
        categoriesList().then((res) => {
            setAllCategories(res.data)
        })
    }

    /**
     * This function retrieves a list of gallery categories and filters out any categories without an
     * image.
     */
    const getGalleryCategory = () => {
        galleryCategory().then((res) => {
            if (res) {
                let responseJson = res.data
                const result = responseJson.filter(responseJson => responseJson.category_image != null);
                setCat(result);
            }
        })
    }

    /**
     * The function toggles the display property of an element between "flex" and "none".
     */
    const displayHide = () => {
        if (disp == "flex") {
            setDisp('none')
        } else {
            setDisp('flex')
        }
    }
    return (
        <ChakraProvider resetCSS={false} theme={theme} >
            <Flex
                flexDir={'column'}
                h='85vh'
            >
                <MainHeader
                    onClose1={onClose1}
                    onOpencart={onOpencart}
                    handleCallback={handleCallback}
                />
                {/* <GalleryBanner/> */}
                <Flex>
                    <Image
                        position='absolute'
                        // className={isOpen ? 'gallery_list_image2 ' : 'gallery_list_image '}
                        w='100%'
                        h='100%'
                        src='https://media.istockphoto.com/photos/stylish-blue-headphones-on-multi-colored-duo-tone-background-lighting-picture-id1175355990?k=20&m=1175355990&s=612x612&w=0&h=LX5kcpZKWyJQA_Kh5Ub9EwDNpGtAimGr2AePNQJPYxE='
                        alt='Dan Abramov'
                    />
                    {cat ?
                        <Flex
                            flexDirection='column'
                            position='relative'
                            fontWeight='bold'
                            color='white'
                            mt='10%'
                            fontSize='2xl'
                            margin={'0 auto'}
                        // className='gallery_category_list'
                        >
                            {cat.map((item,i) => {
                                return (
                                    <Flex
                                        mt='10%'
                                        key={i}
                                    >
                                        <Text
                                            display={disp}
                                            margin={'0 auto'}
                                            onClick={() => { history.push({ pathname: "/gallery-category", state: item.category_name }) }}
                                        >
                                            {(item.category_name_1).toUpperCase()}
                                        </Text>
                                    </Flex>
                                )
                            })}
                            <Flex mt='10%'>
                                <Text
                                    mt='10%'
                                    margin={'0 auto'}
                                    onClick={() => {
                                        onToggle()
                                        displayHide()
                                    }}>
                                    ALL CATEGORIES
                                </Text>
                            </Flex>
                            <Collapse in={isOpen} animateOpacity>
                                <Flex flexDirection='column'
                                    position='absolute'
                                    color='white'
                                    // className='vieww_all_cate'
                                    fontWeight='bold'
                                >
                                    {allCategories && allCategories.map((item) => {
                                        return (
                                            <Flex
                                                mt='10%'
                                            >
                                                <Text
                                                    onClick={() => { history.push({ pathname: "/gallery-category", state: item.id }) }}
                                                >
                                                    {(item.name).toUpperCase()}
                                                </Text>
                                            </Flex>
                                        )
                                    })}
                                </Flex>
                            </Collapse>
                        </Flex>
                        :
                        ""
                    }
                </Flex>
                <GalleryFooter disp={true} />
                <AddToCart
                    userId={userId}
                    getCart={getCart}
                    store_id={store_id}
                    onOpencart={onOpencart}
                    cart={cart}
                    onClose={onClose1}
                />
            </Flex>
        </ChakraProvider>
    )
}


export default GalleryList;