import React from 'react'
import { Flex, Center, Text } from '@chakra-ui/react'
import './storefooter.css'
import { Translate } from 'components/DeepL/Translate'

export function StoreFooter(props) {


    return (
        <>
            <Flex
                pos='absolute'
                bottom='0'
                colorscheme='none'
                bg="#219691"
                size='lg' 
                mt='3'
                w='100%'
                className='store-footer'>
                <Center>
                    <Text
                        className='checkout_page_text'>
                        Imprint {"     "}{"     "}{"     "}&nbsp;&nbsp;|&nbsp;&nbsp;{"    "} Data Privacy
                        {"    "}{"    "}&nbsp;&nbsp;|&nbsp;&nbsp;{"    "}{"    "}{"    "}
                        Terms & Condition
                    </Text>
                </Center>
            </Flex>
        </>
    )
}
