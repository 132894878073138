import React, { useState, useRef } from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    useToast,
    ChakraProvider,
    Text,
    InputRightElement,
    InputGroup
} from "@chakra-ui/react";
import { confirmresetpassword } from "../../components/API/AuthAPI";
import { useLocation } from "react-router-dom";
import { BiShow, BiHide } from "react-icons/bi"
import { Translate } from "components/DeepL/Translate";

export function ResetAccPassword(props) {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const navRef = React.useRef();
    const wrapper = React.createRef();
    const toast = useToast();


    const [showNew, setShowNew] = useState(false);
    const showNewPass = () => setShowNew(!showNew)


    const [showConf, setShowConf] = useState(false);
    const showConfPass = () => setShowConf(!showConf)


    const [showAcc, setShowAcc] = useState(false)

    const showAccPass = () => setShowAcc(!showAcc)


    let location = useLocation();

    let path = location.pathname;

    let fields = path.split('/');

    // console.log("new string",fields)

    let uid = fields[3];

    let token = fields[4];

    // console.log("newToken", token)



    const handleChange = () => {
        confirmresetpassword(token, uid, password, confirmPassword)
            .then((responseJson) => {
                if (responseJson) {
                    let userEmail = {
                        token: token,
                        uid: uid,
                        new_password1: password,
                        new_password2: confirmPassword
                    }
                    //console.log("--------", responseJson)


                    alert(responseJson["message"])



                }
            })
    }


    return (
        <ChakraProvider>

            <Box ref={navRef}
                w="100%" h="100%"
                bg="#f7f8f9">

                <Box w="100%">
                    <Box ref={wrapper} w="100%">

                        <Flex>
                            <Flex
                                // h={{ sm: "initial", md: "75vh", lg: "85vh" }}
                                h="100vh"
                                w='100%'
                                maxW='1144px'
                                mx='auto'
                                justifyContent='center'
                            >
                                <Flex
                                    alignItems='center'
                                    justifyContent='start'
                                    flexDirection='column'
                                    style={{ userSelect: "none" }}
                                    w={{ base: "100%", md: "50%", lg: "42%" }}>
                                    <Text
                                        textAlign='center'
                                        fontWeight='bold'
                                        fontSize='2xl'
                                        mt='100'>
                                        MY BOUTIQ
                                    </Text>
                                    <Flex
                                        direction='column' mt='50'
                                        w='100%'
                                        background='transparent'
                                        border='1px solid black'
                                        borderRadius={{ sm: "none", md: "15px", lg: "15px" }}
                                        p='48px'
                                    // mt={{ md: "100px", lg: "80px" }}
                                    >

                                        <Heading
                                            textAlign={"center"}
                                            fontSize='xl'
                                            mb='50px'
                                        >
                                            {Translate ? Translate("Reset Password") : "Reset Password"}
                                        </Heading>
                                        <FormControl>
                                            <FormLabel mx="20px">{ Translate ? Translate("New Password"): "New Password" }</FormLabel>
                                            <InputGroup>
                                                <Input
                                                    borderRadius='15px'
                                                    bg="#94D0CD"
                                                    color="#000000"
                                                    mb='15px'
                                                    fontSize='sm'
                                                    type={showNew ? 'text' : 'password'}
                                                    placeholder='New Password'
                                                    _placeholder={{ color: 'inherit' }}
                                                    textAlign="center"
                                                    size='lg'
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <InputRightElement py="25px">
                                                    <Button h='1.75rem'
                                                        size='xs' onClick={showNewPass}>
                                                        {showNew ? <BiHide /> : <BiShow />}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel mx="20px">{Translate ? Translate("Confirm Password") : "Confirm Password"}</FormLabel>
                                            <InputGroup>
                                                <Input
                                                    borderRadius='15px'
                                                    bg="#94D0CD"
                                                    color="#000000"
                                                    mb='15px'
                                                    fontSize='sm'
                                                    type={showConf ? 'text' : 'password'}
                                                    placeholder='Confirm Password'
                                                    _placeholder={{ color: 'inherit' }}
                                                    textAlign="center"
                                                    size='lg'
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                                <InputRightElement py="25px">
                                                    <Button h='1.75rem' size='xs'
                                                        onClick={showConfPass}>
                                                        {showConf ? <BiHide /> : <BiShow />}
                                                    </Button>
                                                </InputRightElement>
                                            </InputGroup>
                                        </FormControl>
                                        <Button
                                            fontSize='sm'

                                            bg='#219691'
                                            w='100%'
                                            h='45'
                                            mb='20px'
                                            type='submit'
                                            color='white'
                                            mt='20px'
                                            _hover={{
                                                bg: "#219691",
                                            }}
                                            _active={{
                                                bg: "teal.400",
                                            }}
                                            onClick={handleChange}
                                        >
                                            { Translate ? Translate("Reset Password") : "Reset Password"}
                                        </Button>



                                    </Flex>
                                </Flex>


                            </Flex>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </ChakraProvider>
    )
}
