import { Flex, Spinner } from '@chakra-ui/react'
import React from 'react'

const Loading = () => {
  return (
    <Flex
      justifyContent={'center'}
      alignItems='center'
      height={'100%'}
    >
      <Spinner
        thickness='5px'
        speed='0.65s'
        emptyColor='gray.200'
        color='#219691'
        // size='xl'
        w='100px'
        h='100px'
        mt={{sm:'30px',lg:'100px'}}
        mb='100px'
      />
    </Flex>
  )
}

export default Loading