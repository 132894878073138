import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Grid,
  Image,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import BankDetails from './components/BankDetails';
import Header from './components/Header';
import { useHistory } from 'react-router-dom';
import Payouts from './components/Payouts';

const AffiliatePayout = () => {
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  let history = useHistory();

  useEffect(()=>{
    checkUserLogin();
  },[]);

  const checkUserLogin=()=>{
    let userToken = localStorage.getItem("token");

    if(!userToken){
      history.push({
        pathname: "auth/signin"
      })
    }

  }

  return (
    <Flex 
      direction='column' 
      pt={{ base: "70px", md: "70px", lg:"75px"}}
      pl="10px"
      pr='10px'  
    >
      <Grid templateColumns={{ sm: "1fr", lg: "1fr" }} templateRows='1fr'>
        <Header/>
        {/* <Payouts/>
        <BankDetails/> */}
      </Grid>
    </Flex>
  )
}

export default AffiliatePayout