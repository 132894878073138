import React, { useState, useEffect } from 'react';
import { Box, } from "@chakra-ui/react";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import { useHistory } from 'react-router-dom';

const Payouts = ({ paymentDetails }) => {
  const [token, setToken] = useState();
  const history = useHistory()

  useEffect(() => {
    checkUserLogin();
  }, [token])

  /**
   * This function checks if a user is logged in by retrieving their token from local storage and
   * redirecting them to the homepage if there is no token.
   */
  const checkUserLogin = async () => {
    let userToken;
    try {
      userToken = await localStorage.getItem("token");
      setToken(userToken);
    } catch (e) { }
    if (!userToken) {
      history.push({
        pathname: "/"
      })
    }
  }

  const columns = [
    {
      name: 'request_amount', header: 'Amount', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'fee', header: 'Fee', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'method', header: 'Method', minWidth: 120, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'status', header: 'Status ', minWidth: 130, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'date', header: 'Date', minWidth: 150, defaultFlex: 1, headerAlign: "center", headerProps: {
        className: "tableHeader"
      }
    },
    {
      name: 'description', header: 'Comment', defaultFlex: 1, minWidth: 120, headerAlign: "center", showHoverRow: "false",
      headerProps: {
        className: "tableHeader"
      },

    },

  ];

  const gridStyle = { minHeight: 580, maxWidth: 1700, textAlign: "center" };
  const rowStyle = { fontSize: "15.5px", fontStyle: "normal" }
  return (
    <Box
      borderRadius='15px'
      py='10px'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      align='center'>
      <Card p='10px' mt='5px'
        maxHeight="600px"
        maxWidth="1700px"
      >
        <CardHeader>
          <ReactDataGrid
            idProperty="id"
            columns={columns}
            dataSource={paymentDetails}
            style={gridStyle}
            rowStyle={rowStyle}
            rowHeight={50}
            fontSize={14}
            headerHeight={55}
            showCellBorders={false}
            pagination
            showZebraRows={false}
            defaultLimit={10}
          />
        </CardHeader>
      </Card>
    </Box>
  )
}

export default Payouts