import React, { useEffect, useState } from 'react'
import {
    Flex,
    Text,
    IconButton,
    Divider,
    Avatar,
    Heading,
    Icon,
    Tabs, TabList, TabPanels, Tab, TabPanel, Input, Image, Grid
} from '@chakra-ui/react'
import { IoColorPaletteSharp, IoPawOutline } from 'react-icons/io5'
import { BsChatLeft, BsClock, BsPeopleFill } from 'react-icons/bs'
import { TbSocial } from 'react-icons/tb';
import { FcTemplate } from 'react-icons/fc';
import { BiShoppingBag, BiText } from 'react-icons/bi';
import { MdPriceChange } from 'react-icons/md';
import MenuItem from './MenuItem'
import "./style.css"
import { RiShareForward2Fill } from 'react-icons/ri'

export default function MobileSidebar(props) {
    const [navSize, changeNavSize] = useState("large")
    const [state, setState] = useState();
   
    return (
        <Flex

            display={{ sm: "flex", md: "flex", lg: "none" }}
            h="100%"
            pt="30px"
            marginTop="2.5vh"
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            // borderRadius={navSize == "small" ? "15px" : "30px"}
            w="100%"
            flexDir="column"
            justifyContent="space-between"
        >
            <Flex
                // p="5%"
                className='launchpad_sticky_footer'
                flexDir="row"
                w="100%"
                as="nav"
                overflowX="auto"
                // maxW="400vw"
                maxH="20vh"
                whiteSpace="nowrap"
                pb="2px"
                //  color="white"
                px="0px"
                sx=
                {
                    {
                        '&::-webkit-scrollbar': {
                            height: '2px',
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            height: '2px',
                            width: '2px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: "#000000",
                            borderRadius: '24px',
                        },
                    }
                }
            >

                <MenuItem
                    title={"Account"}
                    icon={BsPeopleFill}
                />
                <MenuItem
                    title={"Platform"}
                    icon={TbSocial}
                />

                <MenuItem
                    title={"Background"}
                    icon={FcTemplate}
                />

                <MenuItem
                    title={"Product"}
                    icon={BiShoppingBag}
                />

                <MenuItem
                    title={"Element"}
                    icon={IoColorPaletteSharp}
                />

                <MenuItem
                    title={"Text"}
                    icon={BiText}
                />

                <MenuItem
                    title={"Price"}
                    icon={MdPriceChange}
                />
                {/* <MenuItem
                    title={"Schedule"}
                    icon={BsClock}
                /> */}


            </Flex>
        </Flex>
    )
}