import { useCallback,useRef } from "react";
import { fabric } from "fabric";


const useFabric=(onChange,options)=>{
const fabricRef=useRef(null);
const disposeRef=useRef(null);

return useCallback((node)=>{
    if(node){
        fabricRef.current=new fabric.Canvas(node,options);
        if(onChange){
            disposeRef.current=onChange(fabricRef.current)
        }
    }
    else if(fabricRef){
        fabricRef.current.dispose()
        if(disposeRef){
            disposeRef.current=undefined;
        }
    }
},[])
}
export default useFabric;