import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";

function DashboardTableRow(props) {
  const { logo, name, product, brand_name, quantity, original_price, selling_price, discount_value, shipping_fee, subtotal,thereof_vat, total_vat } = props;
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Tr>
      <Td  pl="0px">
        {/* <Flex alignItems="center" py=".8rem" minWidth="100%" flexWrap="nowrap"> */}
          {/* <Icon as={logo} h={"24px"} w={"24px"} me="18px" /> */}
          <Text
            fontSize="md"
            color={textColor}
            minWidth="100%"
            textAlign={"center"}
          >
            {name}
          </Text>
        {/* </Flex> */}
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} pb=".5rem" textAlign={"center"}>
          {brand_name}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} pb=".5rem" textAlign={"center"}>
          {quantity}
        </Text>
      </Td>
      <Td>
        <Flex direction="column">
          <Text
            fontSize="md"
            color={textColor}
            pb=".2rem"
            textAlign={"center"}
          >{original_price}</Text>
        
        </Flex>
      </Td>
      <Td>
      <Text fontSize="md" color={textColor} pb=".5rem" textAlign={"center"}>
          {discount_value}
        </Text>
      </Td>
      <Td>
      <Text fontSize="md" color={textColor} pb=".5rem" textAlign={"center"}>
          {selling_price}
        </Text>
      </Td>
      <Td>
      <Text fontSize="md" color={textColor} pb=".5rem" textAlign={"center"}>
          {shipping_fee}
        </Text>
      </Td>
      <Td>
      <Text fontSize="md" color={textColor} pb=".5rem" textAlign={"center"}>
          {subtotal}
        </Text>
      </Td>
      <Td>
      <Text fontSize="md" color={textColor}  pb=".5rem" textAlign={"center"}>
          {thereof_vat}
        </Text>
      </Td>
      <Td>
      <Text fontSize="md" color={textColor} pb=".5rem" textAlign={"center"}>
          {total_vat}
        </Text>
      </Td>
    </Tr>
  );
}

export default DashboardTableRow;
