import React, { useEffect } from 'react';
import { Flex, Grid, } from "@chakra-ui/react";
import Invite from './components/Invite';
import MemberList from './components/MemberList';
import { useHistory } from 'react-router-dom';

const AffiliateTeamMember = () => {
    let history = useHistory();

    useEffect(() => {
        checkUserLogin();
    }, []);

    const checkUserLogin = () => {
        const userToken = localStorage.getItem("token");

        if (!userToken) {
            history.push({
                pathname: "/signin"
            });
        }
    }
    return (
        <Flex
            direction='column'
            pl="10px"
            pr='10px'
        >
            <Grid
                templateRows={{ sm: "auto auto auto", md: "1fr auto", xl: "1fr" }}
                gap='0px'>
                <Invite />
                <MemberList />
            </Grid>

        </Flex>
    )
}

export default AffiliateTeamMember


