
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import {
    Flex, Text, Image, Center, Input, Button, Avatar, Select, InputGroup,
    useDisclosure, InputLeftAddon, Box, Checkbox, InputRightElement, ChakraProvider, ModalCloseButton, Grid, Table, TableCaption, Thead, Tr, Th, Tbody, TableContainer, Heading, useToast, CircularProgress
} from '@chakra-ui/react'
import { BiShow, BiHide } from "react-icons/bi"
import { currencyList, register, forgotpassword, login, getAccountProfile, getuserupdateprofile, getPlace_order, GeneratePaypalAuthKey, PaypalRefund, returnProductbyId, getAddUpdateCart, returnSigleProduct } from '../API/AuthAPI'
import { AddIcon, MinusIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import './mainHeader.css'
import { FiCornerDownLeft } from 'react-icons/fi'
import { TbTruckDelivery } from 'react-icons/tb'
import { BsExclamationLg } from 'react-icons/bs'
import { Fade, ScaleFade, Slide, Collapse } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import hamer from 'assets/img/hamburger-menu.png'
import {
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
} from '@chakra-ui/react'
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@chakra-ui/react'
import { getReturnOrderItem } from '../API/AuthAPI'
import { dateFormat } from '../../components/helper/index'
import EmptyReturnorder from './EmptyReturnOrder'
import { Separator } from 'components/Separator/Separator'
import { Bo, Td } from 'react-flags-select'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Emitter from 'Emitter'



function ReturnProduct({ closeReturnItem, closeOrderDetails, getmyaccount, openAccountOverview, returnItem, client_email, Return_orderId, signIn, getReturnDeatils }) {

    // To Open Homepage Hamburge Dropdown 

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [requestReturn, setRequestReturn] = useState(false);

    const [requestReturnSingleProduct,setRequestReturnSingleProduct] = useState(false)

    const [hamburge, setHamburge] = useState(false)

    const [logToken, setLogToken] = useState();

    const [withdrawForm, setWithdrawForm] = useState(false)

    const [closeForm, setCloseForm] = useState(false)

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [orderId,setOrderId] = useState();
    const [productId, setProductId] = useState();
    const [email, setEmail] = useState();
    const [merchant_id,setMerchant_id] = useState();

    let history = useHistory();

    const toast = useToast();

    const openWishdrawalForm = () => {
        setWithdrawForm(true);
    }

    const closeWithdrawalForm = () => {
        setWithdrawForm(false);
    }

    const openHam = () => {
        setHamburge(true)
    }

    const closeHam = () => {
        setHamburge(false)
    }

    const closeRequestPage = () => {
        setRequestReturn(false)
    }

    const closeRequestPageSingleProduct=()=>{
        setRequestReturnSingleProduct(false)
    }

    //CheckLogin

    const CheckLogin = () => {

        let token = localStorage.getItem("loginToken");
        setLogToken(token);

    }

    useEffect(() => {
        CheckLogin();
    }, [logToken]);

    //3****** TO open Return Item menu --- Modal


    const [returnPro, setReturnPro] = useState(false)

    const openReturnPro = () => {
        setReturnPro(true)
        setOrders(false)
        setDropdown(false)
    }


    const closeReturnPro = () => {
        setReturnPro(false)
    }

    let orderDet = getReturnDeatils.order_details;
    let newRes = []
    const BuyAgain = async () => {

        let token = localStorage.getItem("loginToken");
        if (token) {

            let newarray = orderDet.map((item) => {

                // console.log("mapped data", item);

                let prod = item.product;

                let qty = item.quantity;

                getAddUpdateCart(token, prod, qty);

                // console.log("101", prod, qty);

                newRes = [...newRes, [{ product: (item.product) }, { quantity: (item.quantity) }]]


            })
            // console.log("new arr", newRes);
            // await history.push({ pathname: '/checkout' })
        } else {
            toast({
                position: 'top',
                render: () => (
                    <Box
                        color='white'
                        p={3} bg='#219691'
                        textAlign={'center'}
                        fontWeight='bold'
                    >
                        Please Login!
                    </Box>
                ),
            })
            // alert("Please Login!")
        }
    }


    //2**** 3 Print Return Label ---- my orders -all

    const [printOrder, setPrintOrder] = useState(false)
    const [print_label, setPrintLabel] = useState(false);

    const printReturnOrder = () => {
        setPrintOrder(true)
        // closeOrderDetails()
    }


    const closePrintReturnOrder = () => {
        setPrintOrder(false)
    }



    // onClick on My profile/order Details/order Overview --- hamburger

    const [overviewHamer, setOverviewHamer] = useState(false)

    const overviewHam = () => {
        setOverviewHamer(true)
    }



    const RequestRefund = (id) => {

        setIsSubmitted(true);
        returnProductbyId(id).then(res => {
            if (res) {
                // console.log("request", res.message);
                setIsSubmitted(false);
                toast({
                    position: 'top',
                    render: () => (
                        <Box
                            color='white'
                            p={3} bg='#219691'
                            textAlign={'center'}
                            fontWeight='bold'
                        >
                            {res.message}.
                        </Box>
                    ),
                })
                printReturnOrder()
                setRequestReturn(false)
            }
        })

    }



    const getMyorders = () => {
        setOverviewHamer(false)
        setOverview(false)
        setMyProfile(false)
        setOrderDetails(true)
    }



    const [getorderDeatils, setGetorderDeatils] = useState([])

    const [productInfo, setProductInfo] = useState([])

    const [shippingAddress, setShippingAddress] = useState();
    const [billingAddress, setBillingAddress] = useState();
    useEffect(() => {
        setGetorderDeatils(getReturnDeatils);
        // console.log("productDetails",getReturnDeatils);
        setEmail(getReturnDeatils.email_client);
        setMerchant_id(getReturnDeatils.merchant_order_id);
        setShippingAddress(getReturnDeatils.shipping_address);
        setBillingAddress(getReturnDeatils.billing_address);
        setProductInfo(getReturnDeatils.order_details)

    }, [getReturnDeatils])


    const [noorder, setNoorder] = useState(false)

    const getReturnnoOrder = () => {
        setNoorder(true)
    }

    const Print = () => {
        //console.log('print');  
        let printContents = document.getElementById('withdrawal_form').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    const Refund = () => {
        let value = 2323.00
        let currency = "USD"
        PaypalRefund(value, currency).then(res => {
            if (res) {
                // console.log("refund", res)
                if (res.status === "COMPLETED") {
                    // alert("Refund Completed");
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                Refund Completed
                            </Box>
                        ),
                    })
                    requestUpdatedData();
                } else {
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                Something went wrong
                            </Box>
                        ),
                    })
                    requestUpdatedData();
                    // alert("Something went wrong");
                }
            }
        })
    }

    const returnProduct=(orderId, productId)=>{
        setIsSubmitted(true);
        returnSigleProduct(logToken,orderId,productId).then((res)=>{
            if(res['message']=='Your procuct already refunded'){
                // console.log(res);
                toast({
                    position: 'top',
                    render: () => (
                        <Box
                            color='white'
                            p={3} bg='#219691'
                            textAlign={'center'}
                            fontWeight='bold'
                        >
                            Your product is already refunded
                        </Box>
                    ),
                })
                setIsSubmitted(false);
                setRequestReturnSingleProduct(false);
                requestUpdatedData();

            }else if(res['message']=='Your return product request sent successfully'){
                toast({
                    position: 'top',
                    render: () => (
                        <Box
                            color='white'
                            p={3} bg='#219691'
                            textAlign={'center'}
                            fontWeight='bold'
                        >
                            Your return product request sent successfully
                        </Box>
                    ),
                })
                requestUpdatedData();
                setIsSubmitted(false);
                setRequestReturnSingleProduct(false);
            }
        })
    }

    const requestUpdatedData=()=>{
        getReturnOrderItem(logToken, merchant_id, email).then((res)=>{
            if(res){
                // console.log(res);
                setGetorderDeatils(res);
            }  
        })
    }


    return (
        <div>
            {/*2--- 2  My Orders -  --- onClick on order overView View Details button open ---- Order Details  - all */}



            <Modal
                size='5xl'
                blockScrollOnMount={false}
                isOpen={returnItem}
            >
                <ModalOverlay />
                <ModalContent>
                    <Menu>
                        <ModalHeader
                            display='flex' mt='5'>
                            <MenuButton
                                className='myprofile_header'
                                onClick={overviewHam} >
                                <Image ml='5'
                                    w='8%'
                                    h='8%'
                                    src={hamer}
                                />

                            </MenuButton>
                            <div
                                className='product_hamburger_inner_text'

                            >
                                <Text fontSize='2xl'
                                    color='#219691'
                                    fontWeight='bold'>
                                    MY BOUTIQ
                                </Text>
                                <CloseIcon mr='6'
                                    w='6%'
                                    h='50%'
                                    cursor='pointer'
                                    onClick={closeReturnItem}
                                />
                            </div>
                            <MenuList >
                                <MenuItem
                                    className='inner_hamer_login'>
                                    <Flex
                                        flexDirection='column'
                                        w='50vh' top='0' >
                                        <Flex
                                            justifyContent='space-between'
                                            mb='3' mt='5' >

                                            <Text
                                                fontSize='2xl'
                                                className='product_inner_hamer'
                                            >
                                                MY BOUTIQ
                                            </Text>
                                            <CloseIcon mt='1'
                                                onClick={closeHam}

                                            />
                                        </Flex>
                                        <Flex
                                            flexDirection='column'
                                            alignItems='center' mt='5'>
                                            <p className='home_line' ></p>
                                            {logToken ?
                                                <>
                                                    <Text
                                                        fontWeight='bold'
                                                        fontSize='lg' mt='8'
                                                        onClick={getmyaccount}
                                                        cursor='pointer'>
                                                        My Account
                                                    </Text>
                                                    <Text
                                                        fontWeight='bold'
                                                        fontSize='lg' mt='8'
                                                        onClick={getMyorders}
                                                        cursor='pointer'>
                                                        My Orders
                                                    </Text>
                                                    <Text
                                                        fontWeight='bold'
                                                        fontSize='lg' mt='8'
                                                        onClick={openReturnPro}
                                                        cursor='pointer'>
                                                        Logout
                                                    </Text>
                                                </>
                                                :
                                                <Text
                                                    fontWeight='bold'
                                                    fontSize='lg' mt='8'
                                                    // onClick={getmyaccount}
                                                    onClick={(e) => { Emitter.emit('SIGN_IN', true) }}
                                                    cursor='pointer'>
                                                    Account Login
                                                </Text>
                                            }
                                        </Flex>
                                    </Flex>
                                </MenuItem>
                            </MenuList>

                        </ModalHeader>
                    </Menu>
                    <ModalBody
                        display='flex'
                        justifyContent='space-around'
                        flexDirection='column'
                    >
                        {
                            getorderDeatils ?

                                <>


                                    <Text
                                        fontSize='2xl' mt='5'>
                                        MY ORDER
                                    </Text>
                                    <Flex
                                        className='product_account_details'
                                        justifyContent='space-between'
                                        mt='10'>
                                        {/* <Flex flexDirection={{sm:'column', md:'row', lg:'row'}}
                                        > */}
                                        <Flex
                                            className='product_account_details'
                                            flexDirection='column'>
                                            <Text
                                                fontWeight='bold'
                                            >
                                                Order Information
                                            </Text>
                                            {/* <Text>
                                                Order Id: {getorderDeatils.id ? getorderDeatils.id : ""}
                                            </Text> */}
                                            <Text>
                                                Order Id: {getorderDeatils.merchant_order_id ? getorderDeatils.merchant_order_id : ""}
                                            </Text>
                                            <Text>
                                                Order Date : {dateFormat(getorderDeatils.order_date ? getorderDeatils.order_date : "")}
                                            </Text>
                                        </Flex>

                                        {shippingAddress && (
                                            <Flex
                                                flexDirection='column'>
                                                <Text
                                                    fontWeight='bold'
                                                >
                                                    Shipping Information
                                                </Text>
                                                <Text>
                                                    {shippingAddress.first_name ? shippingAddress.first_name : ""} {shippingAddress.last_name ? shippingAddress.last_name : ""}
                                                </Text>
                                                <Text>
                                                    {shippingAddress.address_line_1 ? shippingAddress.address_line_1 : ""}
                                                </Text>
                                                <Text>
                                                    {shippingAddress.address_line_2 ? shippingAddress.address_line_2 : ""}
                                                </Text>
                                                <Text>
                                                    {shippingAddress.zipcode ? shippingAddress.zipcode : ""} {""} {shippingAddress.city ? shippingAddress.city : ""}
                                                </Text>

                                            </Flex>
                                        )}
                                        {billingAddress && (
                                            <Flex
                                                flexDirection='column'>
                                                <Text
                                                    fontWeight='bold'
                                                >
                                                    Invoice Information
                                                </Text>
                                                <Text>
                                                    {billingAddress.first_name ? billingAddress.first_name : ""} {billingAddress.last_name ? billingAddress.last_name : ""}
                                                </Text>
                                                <Text>
                                                    {billingAddress.address_line_1 ? billingAddress.address_line_1 : ""}
                                                </Text>
                                                <Text>
                                                    {billingAddress.address_line_2 ? billingAddress.address_line_2 : ""}
                                                </Text>
                                                <Text>
                                                    {billingAddress.zipcode ? billingAddress.zipcode : ""} {""} {billingAddress.city ? billingAddress.city : ""}
                                                </Text>


                                            </Flex>
                                        )}

                                        <Flex
                                            className='product_account_details'
                                            flexDirection='column' ml='20'
                                            display={{ sm: "flex", md: "none", lg: "none" }}
                                        >
                                            <Button
                                                colorScheme='none'
                                                bg="#219691"
                                                color='white'

                                            // onClick={openAccountOverview}
                                            >
                                                Buy again
                                            </Button>
                                            <Button
                                                mt='5'
                                                bg='gray'
                                                w='100%'
                                                colorScheme='none'
                                                onClick={() => {
                                                    setRequestReturn(true)
                                                    // RequestRefund(getorderDeatils.id)
                                                    printReturnOrder
                                                    // Refund()


                                                }}
                                                color='white'
                                            >
                                                Return All Items
                                            </Button>
                                            {/* </Flex> */}
                                        </Flex>
                                        <Flex
                                            className='product_account_details'
                                            flexDirection='column' ml='20'
                                            display={{ sm: "none", md: "flex", lg: "flex" }}
                                        >
                                            <Button
                                                colorScheme='none'
                                                bg="#219691"
                                                color='white'
                                                size='lg'
                                                onClick={() => { BuyAgain() }}
                                            >
                                                Buy again
                                            </Button>
                                            <Button
                                                mt='5' bg='gray'
                                                colorScheme='none'
                                                onClick={() => {
                                                    setRequestReturn(true)

                                                    // RequestRefund(getorderDeatils.id)
                                                    // printReturnOrder()
                                                    // Refund()
                                                }
                                                }
                                                color='white'>
                                                Return All Items
                                            </Button>
                                        </Flex>
                                    </Flex>
                                    <Box
                                        colorScheme='none'
                                        className='product_account_header'
                                        bg='#219691' w='100%' p={4}
                                        color='white'
                                        mt='10'

                                        gap='24'
                                        display={{ sm: "flex", md: "none", lg: "none" }}
                                        justifyContent='center'
                                    >
                                        <Text
                                            fontSize='xl'
                                            fontWeight='bold'
                                        >
                                            Product Information
                                        </Text>
                                    </Box>
                                    <Box
                                        colorScheme='none'
                                        className='product_account_header'
                                        bg='#219691' w='100%' p={4}
                                        color='white'
                                        mt='10'

                                        gap='24'
                                        display={{ sm: "none", md: "flex", lg: "flex" }}
                                    >
                                        <Text
                                            fontSize='xl'
                                            fontWeight='bold'
                                        >
                                            Product Image
                                        </Text>
                                        <Text
                                            fontSize='xl'
                                            fontWeight='bold'
                                        >
                                            Product Information
                                        </Text>
                                        <Text
                                            fontSize='xl'
                                            fontWeight='bold'
                                        >
                                            MY BOUTIQ
                                        </Text>
                                    </Box>

                                    {productInfo ? productInfo.map((response, index) => {
                                        // console.log("101", response.product_details.id)
                                        return (
                                            <div key={index}>

                                                <Flex
                                                    className='product_account_details'
                                                    mt='10'
                                                    justifyContent='space-between'>
                                                    {/* <Link to={`/product-details/${response.id}`}> */}
                                                    <Image

                                                        w={{ sm: "100%", md: "30%", lg: "20%" }}
                                                        h={{ sm: "10%", md: "10%", lg: "200px" }}
                                                        src={"https://adminmyboutiqe.progfeel.co.in" + response.product_details.product_image}
                                                        alt='Dan Abramov'
                                                        cursor={'pointer'}
                                                        onClick={() => { history.push(`/product-details/${response.product_details.id}`) }}
                                                    />
                                                    {/* </Link> */}

                                                    <Flex
                                                        flexDirection='column'
                                                        gap='2'>

                                                        <Text
                                                            mt={{ sm: "30px", md: "0px", lg: "0px" }}
                                                            justifyContent={{ sm: "center" }}
                                                            fontSize='xl'
                                                            fontWeight='bold'
                                                        >
                                                            Product Name: {response.product_details.product_name ? response.product_details.product_name : ""}
                                                        </Text>

                                                        <>
                                                            <Text fontSize='xl'>
                                                                Quantity: {response.quantity ? response.quantity : ""}
                                                            </Text>
                                                            <Text fontSize='xl'>
                                                                Total: €{response.product_details.PRDPrice ? parseFloat(response.product_details.PRDPrice).toFixed(2) : ""}
                                                            </Text>
                                                        </>


                                                    </Flex>

                                                    <Flex
                                                        className='product_store_name'
                                                        flexDirection='column'
                                                        gap='5' ml='16'
                                                    >
                                                        <Text fontSize='2xl'>
                                                            {response.product_details.get_store_name[0].store_name}
                                                        </Text>
                                                        {
                                                            JSON.stringify(response.product_details.is_returned) == "true" ?

                                                            <Text
                                                                color='red'
                                                                fontSize='xl'
                                                                // fontWeight={'bold'}
                                                            >
                                                                Return Created
                                                            </Text>
                                                            :
                                                            ""
                                                        }
                                                    </Flex>
                                                    <Flex
                                                        className='product_account_details'
                                                        flexDirection='column' ml='16'>
                                                        <Button
                                                            colorScheme='none'
                                                            bg="#219691"
                                                            color='white'
                                                            onClick={() => { history.push(`/product-details/${response.product_details.id}`) }}
                                                        >
                                                            Buy again
                                                        </Button>
                                                        <Button
                                                            mt='5'
                                                            w='100%'
                                                            bg='gray'
                                                            colorScheme='none'
                                                            onClick={()=>{
                                                                setRequestReturnSingleProduct(true)
                                                                setOrderId(response.order)
                                                                setProductId(response.product_details.id)
                                                                // console.log(response)
                                                                // printReturnOrder
                                                            }
                                                            }
                                                            color='white'>Return Item</Button>
                                                    </Flex>
                                                </Flex>
                                            </div>
                                        )
                                    })
                                        :
                                        ""
                                    }

                                </>

                                : <Text mt='20'
                                    textAlign='center'
                                    fontSize='2xl'
                                    fontWeight='bold'
                                    color="#219691"
                                >
                                    Unfortunately, you have not yet placed any orders.
                                </Text>
                        }


                    </ModalBody>


                    <Box bg='#219691'
                        w='100%' p={20}
                        color='white'
                        mt='20'
                        display='flex'
                        gap='20'
                    >
                    </Box>

                </ModalContent>
            </Modal>


            {/* confirm Modal */}
            <Modal
                isOpen={requestReturn}
                onClose={closeRequestPage}
                size='xs'
                display='flex'
                justifySelf='center'
            >
                <ModalOverlay />
                <ModalContent
                    mt='20%'
                    height={'200px'}
                >
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody
                        justifyContent='center'
                        justifySelf={'center'}
                    >
                        <Text
                            pl='20px'
                            pr='20px'
                            pt='50px'
                            textAlign={'center'}
                            fontSize='lg'
                            fontWeight={'bold'}
                            noOfLines={2}
                        >
                            Do you want to return the order ?
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Flex
                            justifyContent={'space-between'}
                            margin='0 auto'
                        >
                            {
                                isSubmitted == false ?

                                    <Button
                                        variant='ghost'
                                        color='white'
                                        colorScheme={'none'}
                                        bg='#219691'
                                        ml='20px'
                                        mr='20px'
                                        w='100%'
                                        onClick={() => { RequestRefund(getorderDeatils.id) }}
                                    >
                                        Yes
                                    </Button>
                                    :
                                    <Button
                                        color='white'
                                        isLoading
                                        loadingText='please wait....'
                                        colorScheme='teal'
                                        variant='solid'
                                        bg='#219691'
                                        ml='20px'
                                        mr='20px'
                                        w='100%'
                                        onClick={() => { RequestRefund(getorderDeatils.id) }}
                                    >
                                        Yes
                                    </Button>
                            }
                            <Button
                                colorScheme='none'
                                bg='#219691'
                                color='#ffffff'
                                ml='20px'
                                mr='20px'
                                w='100%'
                                onClick={closeRequestPage}
                            >
                                Close
                            </Button>

                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>



            <Modal
                isOpen={requestReturnSingleProduct}
                onClose={closeRequestPageSingleProduct}
                size='xs'
                display='flex'
                justifySelf='center'
            >
                <ModalOverlay />
                <ModalContent
                    mt='20%'
                    height={'200px'}
                >
                    {/* <ModalHeader>Modal Title</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody
                        justifyContent='center'
                        justifySelf={'center'}
                    >
                        <Text
                            pl='20px'
                            pr='20px'
                            pt='50px'
                            textAlign={'center'}
                            fontSize='lg'
                            fontWeight={'bold'}
                            noOfLines={2}
                        >
                            Do you want to return the order ?
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Flex
                            justifyContent={'space-between'}
                            margin='0 auto'
                        >
                            {
                                isSubmitted == false ?

                                    <Button
                                        // variant='ghost'
                                        color='white'
                                        colorScheme={'none'}
                                        bg='#219691'
                                        ml='20px'
                                        mr='20px'
                                        w='100%'
                                        onClick={() => { returnProduct(orderId,productId) }}
                                    >
                                        Yes
                                    </Button>
                                    :
                                    <Button
                                        // variant='ghost'
                                        color='white'
                                        isLoading
                                        loadingText='please wait....'
                                        colorScheme='teal'
                                        variant='solid'
                                        bg='#219691'
                                        ml='20px'
                                        mr='20px'
                                        w='100%'
                                        // onClick={() => { RequestRefund(getorderDeatils.id) }}
                                    >
                                        Yes
                                    </Button>
                            }
                            <Button
                                colorScheme='none'
                                bg='#219691'
                                color='#ffffff'
                                ml='20px'
                                mr='20px'
                                w='100%'
                                onClick={closeRequestPage}
                            >
                                Close
                            </Button>

                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/*2---- 3 // Print Order Label Modal ----- Order Details onClick of --- Return all Items */}

            <Modal Modal
                isOpen={printOrder}
                size='2xl' >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader
                        onClick={closeHam}
                        className='hamburge_modal' mt='5'>
                        <Text
                            className='product_hamburger_text'
                            display='flex'
                            justifyContent='center'
                            margin='0 auto'
                        >
                            Return Order
                        </Text>

                        <CloseIcon
                            cursor='pointer'
                            onClick={closePrintReturnOrder} />
                    </ModalHeader>

                    <ModalBody
                        display='flex'
                        justifyContent='center'
                        flexDirection='column'
                        alignItems='center'
                        gap='5'
                    >

                        <p className='home_line' ></p>
                        {
                            getorderDeatils ?
                                <>
                                    <Text
                                        fontSize='xl'
                                        mt='10'>
                                        Return Created
                                    </Text>
                                    <Text>
                                        Date : {dateFormat(getorderDeatils.date_update ? getorderDeatils.date_update : "    ")}
                                    </Text>
                                    <Text>
                                        ID : {getorderDeatils.id}
                                    </Text>
                                    <Button
                                        color='white'
                                        mt='10'
                                        w='100%'
                                        colorScheme='none'
                                        bg="#219691"
                                        // size='lg'
                                        onClick={onOpen}
                                    >
                                        Print Return Label
                                    </Button>
                                    <Button
                                        color='white'
                                        mb='50'
                                        w='100%'
                                        colorScheme='none'
                                        bg="#219691"
                                        onClick={openWishdrawalForm}
                                    // size='lg'
                                    >
                                        Print Withdrawal Form
                                    </Button>

                                </>
                                :
                                ""
                        }
                    </ModalBody>


                </ModalContent>
            </Modal>



            <Modal isOpen={isOpen} size="xxl" onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader display='flex' justifyContent='center'>Return Label</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <Lorem count={2} /> */}
                        <Flex flexDir={"column"} >
                            <Grid>
                                <Text>
                                    Best Secret GmbH
                                </Text>
                                <Text>Parsdorfer Straße 13</Text>
                                <Text>85586 Poing</Text>
                                <Text>Deutschland</Text>
                            </Grid>
                            <Flex mt='20px' flexDir="row" justifyContent='space-between'>
                                <Flex flexDir='column'>
                                    <Text>Retourenschein Nr. 2081457626-0</Text>
                                    <Text>Bestelldatum: 14.10.2022 / erhalten am (noch ergänzen)</Text>
                                </Flex>
                                <Flex flexDir='column'>
                                    <Text>29-Dec-2022</Text>
                                    <Text>KUNDENNUMMER</Text>
                                    <Text>105976016-819874</Text>
                                </Flex>
                            </Flex>
                            <Flex mt="20px">
                                <Text>Hiermit widerrufe ich den von mir abgeschlossenen Vertrag über den Kauf der
                                    beigepackten Waren.</Text>
                            </Flex>

                            <TableContainer>
                                <Table variant='simple'>
                                    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                                    <Thead>
                                        <Tr>
                                            <Th>ARTIKEL</Th>
                                            <Th>RETOURGRUND</Th>
                                            <Th>GRÖSSE </Th>
                                            <Th>FARBE </Th>
                                            <Th>PREIS €</Th>
                                            <Th>STK</Th>
                                            <Th>GESAMT €</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            <Td>inches</Td>
                                            <Td>millimetres (mm)</Td>
                                            <Td >25.4</Td>
                                        </Tr>

                                    </Tbody>

                                </Table>
                            </TableContainer>
                            <Flex mt='25px' flexDir='column'>
                                <Text>Sollten Sie für diese Bestellung Gutscheine verwendet haben, werden diese nach der Retourenbearbeitung
                                    verrechnet und angezeigt.</Text>
                                <Text mt='10px'>Bitte gehen Sie mit der Ware sehr sorgsam um und entfernen Sie keine Etiketten oder Anhänger der Ware.
                                    Um eine schnelle Retourenbearbeitung für Sie gewährleisten zu können, würden wir Sie bitten,
                                    Rücksendungen immer getrennt nach Bestellung, mit dem zugehörigen Retourenschein und
                                    Rücksendeetikett, an uns zurückzusenden. Sie können das Paket in jeder Postfliale aufgeben.</Text>
                                <Text mt='10px'>Die Rücksendung ist für Sie kostenfrei.</Text>
                                <Text mt='10px'>Nach Zusendung der einwandfreien Ware erhalten Sie innerhalb von 14 Tagen eine Gutschrift auf Ihr
                                    Zahlungsmittel.</Text>
                                <Grid justifyContent='right' flexDir='column'>
                                    <Text fontSize='xs'>A Best Secret GmbH</Text>
                                    <Text fontSize='xs'>Margaretha-Ley-Ring 27</Text>
                                    <Text fontSize='xs'>85609 Aschheim</Text>
                                    <Text fontSize='xs' mt='20px'>T +49 (0) 89 24600 000</Text>
                                    <Text fontSize='xs'>F +49 (0) 89 907 78 24 11</Text>
                                    <Text fontSize='xs'>E Info@bestsecret.com</Text>
                                    <Text fontSize='xs'>Geschäftsführer</Text>
                                    <Text fontSize='xs'>Dr. Moritz Hahn</Text>
                                    <Text fontSize='xs'>Axel Salzmann</Text>
                                    <Text fontSize='xs'>Dr. Andreas Reichhart</Text>
                                    <Text fontSize='xs'>Sitz Aschheim</Text>
                                    <Text fontSize='xs'>Handelsregister</Text>
                                    <Text fontSize='xs'>Amtsgericht München</Text>
                                    <Text fontSize='xs'>HRB 56240</Text>
                                    <Text fontSize='xs'>USt-ID DE 129473557</Text>
                                </Grid>
                            </Flex>

                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='none' bg="#219691" mr={3} onClick={onClose}>
                            Print
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={withdrawForm} onClose={closeWithdrawalForm} size="xxl" >
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader display='flex' justifyContent='center'>WITHDRAWAL FORM</ModalHeader> */}
                    <ModalCloseButton />
                    <ModalBody>
                        {/* <Lorem count={2} /> */}
                        <Flex
                            flexDir={"column"}
                            id="withdrawal_form"
                        >
                            <Heading
                                display={{ sm: 'none', md: 'flex', lg: "flex" }}
                                justifyContent={'center'}
                            >
                                WITHDRAWAL FORM
                            </Heading>
                            <Text
                                justifyContent={'center'}
                                textAlign={'center'}
                                display={{ sm: "flex", md: "none", lg: "none" }}
                                fontSize='md'
                                fontWeight={'bold'}
                                mb='20px'
                            >
                                WITHDRAWAL FORM
                            </Text>
                            <Grid>
                                <Text
                                    textAlign={'center'}
                                >
                                    (If you want to withdraw from the contact, please fill out this form and send it back)
                                </Text>
                                <Text>To</Text>
                                <Text>meierSupport GbR</Text>
                                <Text>Dachsweg 10</Text>
                                <Text>71063 Sindelfingen</Text>
                                <Text>info@iworkcase.com</Text>
                            </Grid>

                            <Flex mt='25px' flexDir='column'>
                                <Text>I/We (*) hereby give notice that I/we (*) withdraw from my/our (*) contract of sale of the following goods (*)for the provisionof the following service(*),</Text>
                                <Text>________________________________________________________________________________________________________</Text>
                                <Text>________________________________________________________________________________________________________</Text>
                                <Text>Ordered on _____________________________(*)/received on_______________________________________________________(*)</Text>
                                <Text>Name of consumer(s)___________________________________________</Text>
                                <Text>Address of consumer(s)</Text>
                                <Text>_______________________________________________________</Text>
                                <Text>_______________________________________________________</Text>
                                <Text>_______________________________________________________</Text>


                                <Flex
                                    flexDir={'row'}
                                    mt='100px'
                                >
                                    <Grid flexDir={'column'}>
                                        <Text>__________________</Text>
                                        <Text>Date</Text>
                                        <Text>(*) Delete as approproiate</Text>
                                    </Grid>
                                    <Grid
                                        flexDir={'column'}
                                        ml='50px'
                                    >
                                        <Text>____________________________</Text>
                                        <Text>Signature of consumer(s) (only if this form is notified on paper)</Text>
                                    </Grid>
                                </Flex>

                            </Flex>

                        </Flex>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='none' bg="#219691" mr={3} onClick={Print}>
                            Print
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>
            <EmptyReturnorder noorder={noorder} />
        </div>
    )
}

export default ReturnProduct;
