import {
  HomeIcon,
  PersonIcon,
  RocketIcon,
  CartIcon,
} from "components/Icons/Icons";
import { FaShoppingBag } from "react-icons/fa"
import { RiTeamFill } from "react-icons/ri"
import { SettingsIcon } from "@chakra-ui/icons";
import AffiliateDashboard from "components/AffiliateDashboard";
import AffiliateOrders from "components/AffiliateOrders";
import AffiliateSeller from "components/AffiliateSeller";
import AffiliateProducts from "components/AffiliateProduct";
import AffiliatePayout from "components/AffiliatePayout";
import AffiliateProfile from "components/AffiliateProfile";
import AffiliateTeamMember from "components/AffiliateTeam Member";
import { AiFillShop } from "react-icons/ai";
import LauchpadDetails from "components/LaunchpadTable";


var dashRoutes = [

  {
    // name: "AFFILIATE PAGES",
    category: "account",
    state: "pageCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: <HomeIcon color="inherit" />,
        component: AffiliateDashboard,
        layout: "/affiliate",
      },
      {
        path: "/affiliateorders",
        name: "Orders",
        icon: <CartIcon color="inherit" />,
        component: AffiliateOrders,
        layout: "/affiliate",
      },
      {
        path: "/affiliateseller",
        name: "Affiliate BOUTIQS",
        icon: <AiFillShop color="inherit" />,
        component: AffiliateSeller,
        layout: "/affiliate",
      },
      {
        path: "/affiliateproducts",
        name: "Affiliate Products",
        icon: <FaShoppingBag color="inherit" />,
        component: AffiliateProducts,
        layout: "/affiliate",
      },
      {
        path: "/affiliateprofile",
        name: "Profile",
        icon: <PersonIcon color="inherit" />,
        component: AffiliateProfile,
        layout: "/affiliate",
      },
      {
        path: "/affiliateteammember",
        name: "Team Members",
        icon: <RiTeamFill color="inherit" />,
        component: AffiliateTeamMember,
        layout: "/affiliate",
      },
      {
        path: "/affiliatepayout",
        name: "Payout",
        icon: <SettingsIcon color="inherit" />,
        component: AffiliatePayout,
        layout: "/affiliate",
      },
      {
        path: "/launchpadTable",
        name: "Launchpad",
        icon: <RocketIcon color="inherit" />,
        component: LauchpadDetails,
        layout: "/affiliate",
      },

    ]
  },
];
export default dashRoutes;


