import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
    Select, Input, Flex, Checkbox, Text, Button, Image, Switch, Radio, Collapse, useDisclosure, Box, Textarea, 
    useToast,  Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
} from '@chakra-ui/react';
import PaymentModal from './PaymentModal';
import Card from "components/Card/Card.js";
import './paymodal.css';
import { getData, getName } from 'country-list';
import { captureTransactionId, generateChargeID, getPlaceOrderDetails } from '../API/AuthAPI'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Payment from './Payment';
import { useHistory } from 'react-router-dom';
import Emitter from 'Emitter';
import Swal, { swal } from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import useGeoLocation from "react-ipgeolocation";
import { clearCart } from 'Slices/cartslice';
import { useDispatch, useSelector } from "react-redux";
import { countryList as countryCodewithMobile } from 'assets/countryCodes/countrycode';
import Paypal from './Paypal';



function Checkout() {
    const { isOpen, onToggle, onClose } = useDisclosure()
    const [countryList, setCountryList] = useState();
    const [user_id, setUser_id] = useState();
    const [billingDetails, setBillingDetails] = useState();
    const [list, setList] = useState([]);
    const [token, setToken] = useState()
    const [getCartId, setGetCartId] = useState()
    const [isVerified, setIsVerified] = useState(false)
    const [payStatus, setPayStatus] = useState();
    const [selectState, setSelectState] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [location] = useState("")
    const [shipping, setShipping] = useState({
        first_name: "",
        last_name: "",
        address: "",
        city: "",
        code: "",
        country_code: "",
        number: "",
        location: "",
        comment: ""
    })
    const [billing, setBilling] = useState({
        firstname: "",
        lastname: "",
        addr: "",
        city_: "",
        code_: "",
        countrycode: "",
        number_: "",
        location_: "",
        comment_: ""
    })

    const cart1 = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const oldCountry = useRef("")
    const Loc = useGeoLocation();

    const toast = useToast();
  
    let history = useHistory();

    let newCart;
    Emitter.on("CART_ID", (e) => newCart = e)

   /**
    * The function updates the country list and sets the select state to true when the country is
    * changed.
    */
    const onCountryChange = (e) => {
        oldCountry.current = countryList;
        setCountryList(e.target.value);
        setSelectState(true)
    }


  /**
   * The function "undo" sets the country list to its previous state and closes the drawer.
   */
    const undo = () => {
        setCountryList(oldCountry.current);
        isCloseDrawer()
    }

   
    /**
     * The function sets the state of a variable called "openDrawer" to true.
     */
    const isOpenDrawer = () => {
        setOpenDrawer(true)
    }

   /**
    * This function sets the state of openDrawer and selectState to false.
    */
    const isCloseDrawer = () => {
        setOpenDrawer(false)
        setSelectState(false)
    }

    const [email, setEmail] = useState({
        onChangedata: false,
        mail: ""
    })


    const { mail, onChangedata } = email

    const getEmailAddress = (e) => {
        setEmail({ ...email, [e.target.name]: e.target.value })
    }

    const submitEmail = (e) => {
        e.preventDefault()
        setIsVerified(true)
        setEmail({ mail: email.mail })

    }



    useEffect(() => {
        let countries = []
        getData().map(country => (
            countries = [...countries, country.name]
        ))
        setTimeout(() => {
            setCountryList(getName(Loc.country));
        }, 500)
        setList(countries.sort((a, b) => a.localeCompare(b)));

      /**
       * This function retrieves user login information and sets it to state variables.
       */
        const checkUserLogin = async () => {
            let userToken;
            let cartid;
            try {
                userToken = await localStorage.getItem("loginToken");
                setToken(userToken);
                u_id = await localStorage.getItem('loginUser_id')
                setUser_id(u_id)
                cartid = await localStorage.getItem("cart")
                setGetCartId(cartid)
            } catch (e) { }
        }
        checkUserLogin();
    }, [setToken, getCartId])


   

    const { first_name, last_name, address, address2, city, country_code, code, number, comment } = shipping

    const getorderDetials = (e) => {
        e.preventDefault();
        setShipping({ ...shipping, [e.target.name]: e.target.value })
    }

    // const {firstname, lastname, addr, addr2, city_, countrycode, code_, number_, comment_} = billing;

    // const getbillingdetails =(e)=>{
    //     e.preventDefault();
    //     setBilling({...billing, [e.target.name]: e.target.value})
    // }

    let amt, currency, txId, refId;
   /**
    * The function captures payment details and sets various variables based on the details.
    */
    const onApprove = (data, actions) => {
        return actions.order.capture().then((details) => {
            const name = details.payer.name.given_name;
            setBillingDetails(details)
            txId = details.id;
            refId = details.purchase_units[0].reference_id;
            currency = details.purchase_units[0].amount.currency_code;
            amt = details.purchase_units[0].amount.value
            let first_name = first_name;
            let last_name = last_name;
            let location = address;
            let address = address2;
            let city = city;
            let comment = comment
            let status = details.status;
            setPayStatus(status)
            setBillingDetails(details)

        });
    }

   /**
    * The function sets an error message and displays an error alert if there is an error with a
    * payment transaction.
    */
    const onError = (data, actions) => {
        setPaypalErrorMessage("Something went wrong");
        Swal.fire({
            icon: 'error',
            title: 'Opps',
            text: 'Something went wrong!'
        })
    }

    useEffect(() => {

        if (payStatus == "COMPLETED") {
            DirectCheckout(token, newCart, mail, first_name, last_name, location, address,
                city, code, country_code, number, comment, txId, refId, currency, amt)
        }
    }, [payStatus])

   /**
    * The function `getShippingDetails` captures transaction details and sends them to the server for
    * processing.
    */
    // const getShippingDetails = (e) => {
    //     e.preventDefault()
    //     getPlaceOrderDetails(token, newCart, mail, first_name, last_name, location, address,
    //         city, code, country_code, number, comment).then(responseJson => {
    //             if (responseJson) {
    //                 setGetOrder(responseJson)
    //                 captureTransactionId(token, user_id, responseJson.order_details.id, "paypal", billingDetails.status, billingDetails.id,
    //                     billingDetails.purchase_units[0].reference_id, billingDetails.purchase_units[0].amount.currency_code,
    //                     billingDetails.purchase_units[0].amount.value, responseJson.order_details.merchant_order_id, first_name,
    //                     address, address2, code)
    //             }
    //         }
    //         )
    // }

  /**
   * The function `handleClearCart` dispatches an action to clear the cart.
   */
    const handleClearCart = () => {
        dispatch(clearCart());
    };

    /**
     * The DirectCheckout function handles the process of placing an order, capturing transaction
     * details, and redirecting to the payment page.
   
     */
    const DirectCheckout = (accounttoken, cart, email, first_name, last_name, location, address,
        city, zipcode, country_code, phone_number, comment, txId, refId, currency, amt) => {
        let merchant_order_id, order_id
        let payment_status = 'success';
        let mode = 'card'
        let gateway = 'paypal'
        let ref_id = sessionStorage.getItem('reference_id');
        let tagging_token = '';
        if (!ref_id == '') {
            tagging_token = ref_id;
        } else {
            tagging_token = '';
        }
        getPlaceOrderDetails(accounttoken, cart, email, first_name, last_name, location, address,
            city, zipcode, country_code, phone_number, comment)
            .then((responseJson) => {
                if (responseJson) {
                    toast({
                        position: 'top',
                        render: () => (
                            <Box
                                color='white'
                                p={3} bg='#219691'
                                textAlign={'center'}
                                fontWeight='bold'
                            >
                                {responseJson.message}
                            </Box>
                        ),
                    })
                    sessionStorage.setItem('merchant_order_id', responseJson?.order_details?.merchant_order_id);
                    handleClearCart();
                    order_id = responseJson.order_details.id
                    merchant_order_id = responseJson.order_details.merchant_order_id
                    let user = localStorage.getItem("loginUser_id")
                    captureTransactionId(accounttoken, user, responseJson.order_details.id, gateway, payment_status, billingDetails.id, tagging_token, billingDetails.purchase_units[0].amount.currency_code, billingDetails.purchase_units[0].amount.value, mode, merchant_order_id, first_name,
                        address, city, zipcode).then((res) => {
                            if (res['message'] == 'Payment captured successfully.') {
                                sessionStorage.removeItem('reference_id');
                            }
                        })
                    history.push("/pay")
                }
            })
    }

    return (
        <div className='checkout_wrapper'>
            <form action="" >
                <Card

                    display='flex'
                    bg='#f2f2f2'
                    // p='20'
                    size='xl'
                    className='checkout_wrapper_cart'
                >
                    <Flex
                        mr='5'
                        w='100%'
                        flexDirection='column'
                    >
                        <Flex
                            flexDirection='column'
                            p='10'
                            w='100%'
                            bg='#fff'
                            borderRadius={'15px'}
                        >
                            <Text
                                className='checkout_page_headings'
                                fontWeight='bold'
                                fontSize='2xl'
                                color='#219691'
                            >
                                ENTER YOUR EMAIL
                            </Text>
                            <form action="" onSubmit={submitEmail}>
                                <Input
                                    mt='10'
                                    isRequired
                                    type='email'
                                    name='mail'
                                    value={mail}
                                    onChange={(e) => {
                                        getEmailAddress(e)
                                    }}
                                    placeholder='E-Mail Address'
                                />
                                {
                                    isVerified ?
                                        <Button
                                            className="checkout_hide"
                                            colorScheme='teal' size='lg' mt='5' type='submit'>
                                            CONTINUE
                                        </Button>
                                        : !onChangedata ?
                                            <>
                                                <Flex
                                                    mt='5'
                                                    gap='5'
                                                >
                                                    <Checkbox size='lg' colorScheme='teal'>

                                                    </Checkbox>
                                                    <Flex
                                                        display='flex'
                                                        flexDir='row'
                                                    >
                                                        <Text>
                                                            Sign up for style news & latest drops.<span style={{ textDecoration: 'underline' }}>Privacy Policy</span>
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                                <Button
                                                    className="checkout_show"
                                                    colorScheme='teal' size='lg' mt='5' type='submit'>
                                                    CONTINUE
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <Flex mt='5' gap='5'>
                                                    <Checkbox size='lg' colorScheme='red'>

                                                    </Checkbox>
                                                    <Text
                                                        fontSize='12px'
                                                        className='checkout_subHeadings'
                                                        display='flex'
                                                    >
                                                        Sign up for style news & latest drops. <Text textDecoration='underline'>Privacy Policy</Text>
                                                    </Text>
                                                </Flex>
                                                <Button

                                                    colorScheme='teal' size='lg' mt='5' type='submit'>
                                                    CONTINUE
                                                </Button>
                                            </>
                                }
                            </form>
                        </Flex>
                        {/* Shipping And Billing Methods  */}
                        {
                            isVerified ?
                                <Flex
                                    flexDirection='column'
                                    bg='#fff'
                                    mt='5'
                                    p='10'
                                    w='100%'
                                    borderRadius={'15px'}
                                >
                                    <Text
                                        className='checkout_page_headings'
                                        fontWeight='bold'
                                        fontSize='2xl'
                                        color='#219691'
                                    >
                                        SHIPPING ADDRESS
                                    </Text>
                                    <Flex mt='10' gap='5'>
                                        <Input
                                            required
                                            value={first_name}
                                            name='first_name'
                                            pattern="[a-zA-Z ]+(\.|')?[a-zA-Z ]+(\.|')?"
                                            onChange={getorderDetials}
                                            placeholder='First Name' size='lg' />
                                        <Input
                                            required
                                            value={last_name}
                                            name='last_name'
                                            pattern="([A-z])+(.?[a-zA-Z])*('?[a-zA-Z])*"
                                            onChange={getorderDetials}
                                            placeholder='Last Name' size='lg' />
                                    </Flex>
                                    <Select placeholder='Location' mt='5' size='lg' width='49%'
                                        isRequired='true'
                                        id="country"
                                        fontSize='sm'
                                        borderRadius='15px'
                                        name='countryList'
                                        value={countryList}
                                        onChange={onCountryChange}
                                        onClick={isOpenDrawer}
                                        mb='24px'
                                        h="50px"
                                    >
                                        {list.map(country => (
                                            <option key={country} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </Select>
                                    <Input
                                        required
                                        pattern='^[#.0-9a-zA-Z\s,-]+$'
                                        value={address}
                                        name='address'
                                        onChange={getorderDetials}
                                        placeholder='Address Line 1' size='lg' mt='3' />
                                    <Input
                                        required
                                        pattern='^[#.0-9a-zA-Z\s,-]+$'
                                        value={address2}
                                        name='address2'
                                        onChange={getorderDetials}
                                        placeholder='Address Line 2 (Optional)' size='lg' mt='5' />
                                    <Flex mt='10' gap='5'>
                                        <Input
                                            required
                                            //pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
                                            value={city}
                                            name='city'
                                            onChange={getorderDetials}
                                            placeholder='City' size='lg' />
                                        <Input
                                            required
                                            // pattern='^[0-9]{5}(?:-[0-9]{4})?$'
                                            value={code}
                                            name='code'
                                            onChange={getorderDetials}
                                            placeholder='Zip Code' size='lg' />
                                    </Flex>
                                    <Flex mt='10' gap='5'>
                                    <Select placeholder='country code' mt='5' size='xs' width='30%'
                                        isRequired='true'
                                        id="country_code"
                                        fontSize='sm'
                                        // borderRadius='15px'
                                        name='country_code'
                                        value={country_code}
                                        onChange={getorderDetials}
                                        mb='24px'
                                        h="50px"
                                    >
                                        {countryCodewithMobile.map(country => (
                                            <option key={country.code} value={country.dial_code}>
                                               {country.dial_code} {"("}{country.name}{")"}
                                            </option>
                                        ))}
                                    </Select>
                                    <Input
                                        type="number"
                                        required
                                        pattern="/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm"
                                        value={number}
                                        name='number'
                                        onChange={getorderDetials}
                                        placeholder='Phone Number' mt='5' size='lg' />
                                    </Flex>
                                    <Text mt='5' fontSize='lg' className='checkout_inform_msg'>We will only contact you for order related communications</Text>
                                    <Textarea placeholder='Comment' mt='5'
                                        value={comment}
                                        name='comment'
                                        onChange={getorderDetials}
                                    />
                                    <Flex alignItems='center' mt='10' gap='5'>
                                        <Switch id='email-alerts' size='lg' onChange={onToggle} colorScheme='teal' />
                                        <Text
                                            className='email-alerts'
                                            mb='0'
                                            fontSize='lg'
                                        >
                                            Billing address same as shipping address
                                        </Text>
                                    </Flex>
                                    <Collapse in={isOpen} animateOpacity>
                                        <Text
                                            className='checkout_page_headings'
                                            fontWeight='bold'
                                            fontSize='2xl'
                                            mt='10'
                                            color='#219691'
                                        >
                                            BILLING ADDRESS
                                        </Text>
                                        <Flex mt='10' gap='5'>
                                            <Input
                                                required
                                                value={first_name}
                                                name='first_name'
                                                pattern="[a-zA-Z ]+(\.|')?[a-zA-Z ]+(\.|')?"
                                                onChange={getorderDetials}
                                                placeholder='First Name' size='lg' />
                                            <Input
                                                required
                                                value={last_name}
                                                name='last_name'
                                                pattern="[a-zA-Z ]+(\.|')?[a-zA-Z ]+(\.|')?"
                                                onChange={getorderDetials}
                                                placeholder='Last Name' size='lg' />
                                        </Flex>
                                        <Select
                                            placeholder='Location' mt='5' size='lg' width='49%'
                                            id="country"
                                            fontSize='sm'
                                            borderRadius='15px'
                                            name='countryList'
                                            value={countryList}
                                            onChange={onCountryChange}
                                            onClick={isOpenDrawer}
                                            mb='24px'
                                            h="50px"
                                        >
                                            {getData().map(countryList => (
                                                <option key={countryList.code} value={countryList.code}>
                                                    {countryList.name}
                                                </option>
                                            ))}
                                        </Select>
                                        <Input
                                            required
                                            pattern='^[#.0-9a-zA-Z\s,-]+$'
                                            value={address}
                                            name='address'
                                            onChange={getorderDetials}
                                            placeholder='Address Line 1' size='lg' mt='3' />
                                        <Input
                                            required
                                            pattern='^[#.0-9a-zA-Z\s,-]+$'
                                            value={address2}
                                            name='address2'
                                            onChange={getorderDetials}
                                            placeholder='Address Line 2 (Optional)' size='lg' mt='5' />
                                        <Flex mt='5' gap='5'>
                                            <Input
                                                required
                                                pattern="^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$"
                                                value={city}
                                                name='city'
                                                onChange={getorderDetials}
                                                placeholder='City' size='lg' />
                                            <Input
                                                required
                                                // pattern='^[0-9]{5}(?:-[0-9]{4})?$'
                                                value={code}
                                                name='code'
                                                onChange={getorderDetials}
                                                placeholder='Zip Code' size='lg' />
                                        </Flex>
                                        <Input
                                            required
                                            type='number'
                                            pattern="/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm"
                                            value={number}
                                            name='number'
                                            onChange={getorderDetials}
                                            placeholder='Phone Number' mt='5' size='lg' />
                                        <Text
                                            mt='5'
                                            fontSize='lg'
                                            className='checkout_inform_msg'
                                        >
                                            We will only contact you for order related communications
                                        </Text>
                                    </Collapse>
                                </Flex>
                                :
                                <Switch disabled className='to_hide'>
                                    <Flex
                                        flexDirection='column'
                                        bg='#fff'
                                        // mt='5' 
                                        p='10'
                                        w='100%'
                                        opacity='0.6'
                                        borderRadius={'15px'}
                                    >
                                        <Text
                                            className='checkout_page_headings'
                                            fontWeight='bold'
                                            fontSize='2xl'
                                            color='#219691'
                                        >
                                            SHIPPING ADDRESS
                                        </Text>
                                        <Flex mt='10' gap='5'>
                                            <Input placeholder='First Name' size='lg' readOnly={true} />
                                            <Input placeholder='Last Name' size='lg' readOnly={true} />
                                        </Flex>
                                        <Select mt='5' size='lg'

                                            borderRadius='15px'
                                            value={countryList}
                                            onChange={onCountryChange}
                                        >
                                            {getData().map(countryList => (
                                                <option key={countryList.code} value={countryList.code}>
                                                    {countryList.name}
                                                </option>
                                            ))}
                                        </Select>
                                        <Input placeholder='Address Line 1' size='lg' mt='5' readOnly={true} />
                                        <Input placeholder='Address Line 2 (Optional)' size='lg' mt='5' readOnly={true} />
                                        <Flex mt='5' gap='5' >
                                            <Input placeholder='City' size='lg' readOnly={true} />
                                            <Input placeholder='Zip Code' size='lg' readOnly={true} />
                                        </Flex>
                                        <Input placeholder='Phone Number' mt='5' size='lg' readOnly={true} />
                                        <Text mt='5' fontSize='lg' className='checkout_inform_msg'>We will only contact you for order related communications</Text>
                                        <Flex alignItems='center' mt='10' gap='5'>
                                            <Switch id='isChecked' isReadOnly />
                                            <Text
                                                className='email-alerts'
                                                mb='0'
                                                fontSize='lg'
                                            >
                                                Billing address same as shipping address
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Switch>
                        }
                        {
                            isVerified ?
                                <>
                                    <Flex
                                        flexDirection='column'
                                        p='10'
                                        mt='5'
                                        w='100%'
                                        bg='#fff'
                                        borderRadius={'15px'}
                                    >
                                        <Text
                                            className='checkout_page_headings'
                                            fontWeight='bold'
                                            fontSize='2xl'
                                            color='#219691'
                                        >
                                            SELECT A PAYMENT METHOD
                                        </Text>
                                        <Flex ml='2' mr='2' mt='2'>
                                            <Payment
                                                token={token}
                                                getCartId={getCartId}
                                                mail={mail}
                                                first_name={first_name}
                                                last_name={last_name}
                                                location={location}
                                                address={address}
                                                address2={address2}
                                                city={city}
                                                code={code}
                                                country_code={country_code}
                                                number={number}
                                                comment={comment}
                                                user_id={user_id}
                                                country={countryList}
                                            />
                                        </Flex>
                                        <Text mt='5'>
                                            By clicking on "Pay Now" I agree to the
                                            <span style={{ textDecoration: "underline" }}> General Terms and Conditions.</span>
                                            I have taken note of the information on the right of withdrawal and the
                                            <span style={{ textDecoration: "underline", marginLeft: "4px" }}>Privacy Policy.</span>
                                        </Text>
                                        <Text mt='10' fontWeight='bold' fontSize='2xl'>
                                            OR CHECKOUT WITH
                                        </Text>
                                        <PayPalScriptProvider
                                            options={{
                                                "client-id": "AYTU778DORty76cZLH3SC3ul5AUT7LUOMdOu82fR1b4PKOaQa1NOEfUgQNxFOYnWDRxhFnAAPDhRFNsz",
                                            }}
                                        >
                                            <PayPalButtons
                                                style={{ layout: "horizontal", color: "white", label: 'paypal', zIndex: "0", tagline: "false" }}
                                                createOrder={(data, actions) => {
                                                    return actions.order.create({
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: cart1.cartTotalAmount,
                                                                },
                                                            },
                                                        ],
                                                    });
                                                }}
                                                onApprove={onApprove}
                                                onError={onError}
                                            />
                                        </PayPalScriptProvider>
                                        <Text mt='5'>Complete your purchase securely with Paypal.</Text>
                                </Flex>
                                </>
                                :
                                <Switch disabled className='to_hide'>
                                    <Flex
                                        flexDirection='column'
                                        p='10'
                                        // mt='5'
                                        w='100%'
                                        bg='#fff'
                                        opacity='0.6'
                                        borderRadius={'15px'}
                                    >
                                        <Text
                                            className='checkout_page_headings'
                                            fontWeight='bold'
                                            fontSize='2xl'
                                            color='#219691'
                                        >
                                            SELECT A PAYMENT METHOD
                                        </Text>
                                    </Flex>
                                </Switch>
                        }
                    </Flex>
                    <PaymentModal 
                        countryList={countryList} 
                    />
                </Card>
            </form>
            {/* Country selection  */}
            {
                selectState === true ? <Drawer
                    isOpen={isOpenDrawer}
                    placement='bottom'
                    size="lg"
                    // finalFocusRef={btnRef}
                    onClose={isCloseDrawer}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader >
                            <Text fontWeight='bold' fontSize='3xl'>
                                Change country
                            </Text>
                        </DrawerHeader>
                        <DrawerBody>
                            <Text fontSize='xl'>By switching your shipping country important changes might affect your cart!</Text>
                            <Text mt='2' fontSize='xl'>If you do so please check the items by clicking on the ORDER SUMMARY panel at the top of the page.</Text>
                            <Flex flexDirection='column' >
                                <Button colorScheme='teal' mt='10' size='lg' onClick={() => { isCloseDrawer() }}>CONFIRM COUNTRY CHANGE</Button>
                                <Button colorScheme='teal' variant='outline' mt='5' size='lg' onClick={() => { undo() }}>UNDO</Button>
                            </Flex>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
                    : ""
            }
        </div >
    )
}

export default Checkout
