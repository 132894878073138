import React, { useState, useRef } from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    useToast,
    ChakraProvider,
    Center,
    Container,
    Image,
    Avatar,
    Text,
    Portal,
    Alert,
    AlertIcon
} from "@chakra-ui/react";
import { resetPassword } from "../../components/API/AuthAPI";
import storecover from "../../assets/img/storecover.jpg"
import profile from "../../assets/img/profile.png"
import { BsFacebook, BsInstagram, BsPinterest } from "react-icons/bs";
import { FaInstagramSquare, FaTwitterSquare } from "react-icons/fa";
import { useLocation, Link } from "react-router-dom";
import AuthNavbar1 from "components/Navbars/SearchBar/AuthNavbar1";

function ResetPassword1() {
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const navRef = React.useRef();
    const wrapper = React.createRef();
    const toast = useToast();
    let location = useLocation();

    let path = location.pathname;

    let fields = path.split('/');
    
    // console.log("new string",fields)

    let uid = fields[2];

    let token = fields[3];

    // console.log("newToken", token)



    const handleChange = () => {
        resetPassword(token, uid, password, confirmPassword)
            .then((responseJson) => {
                if (responseJson) {
                    let userEmail = {
                        token: token,
                        uid: uid,
                        new_password1: password,
                        new_password2: confirmPassword
                    }
                    if (responseJson["message"]) {

                        toast({
                            description: "Password set successfully.",
                            status: 'success',
                            duration: 9000,
                            isClosable: true,
                          })
                    }

                }
            })
    }


    return (
        <ChakraProvider>

            <Center py="30px">
                <Heading
                    color="#219691"
                >
                    MY BOUTIQ
                </Heading>
            </Center>
            <Flex w="100%
            " h="100%"
            >
                <Container 
                    maxW='lg'
                >
                    <Image
                        src={storecover}
                        h={{ sm:"280px", md:"300px", lg:"350px" }}
                        w="70vh"
                    />
                    {/* <Flex
                        pt="20px"
                        justify={"center"}
                    >
                        <Avatar
                            src={profile}
                            justifyContent="center"
                            size='2xl'
                        />
                    </Flex> */}
                    <Flex pt="20px" pl="10px">
                        <Text 
                            fontSize={"xl"}
                        >
                            Hi Michael,
                        </Text>

                    </Flex>
                    <Flex pt="15px" pl="10px">
                        <Text fontSize={"lg"}>
                            To complete the process of changing your password, you must confirm your new address below:
                        </Text>
                    </Flex>
                    <Flex
                        pt="40px"
                        justify={"center"}
                    >
                        <Link to = "https://adminmyboutiqe.progfeel.co.in/seller-register/">
                        <Button
                            bg="#219691"
                            color="#ffffff"
                            colorScheme="none"
                            w={{ md:"md",lg: "sm" }}
                            h="50px"
                            fontSize={"lg"}
                            // onClick={handleAccept}
                        >
                            CONFIRM YOUR EMAIL
                        </Button>
                        </Link>
                    </Flex>
                    <Center display='flex' mt='10'>
                        <BsFacebook size='8%' />
                        <FaInstagramSquare size='8%' style={{ marginLeft: "10px" }} />
                        <BsPinterest size='8%' style={{ marginLeft: "10px" }} />
                        <FaTwitterSquare size='8%' style={{ marginLeft: "10px" }} />
                    </Center>

                    <Center display='flex' pt='40px'>
                        <Flex>
                            <Text>
                                This email was sent by Michael Peters.
                            </Text>
                        </Flex>
                    </Center>
                    <Center display='flex'>
                        <Flex>
                            <Text>
                                Imprint | Data Privacy
                            </Text>
                        </Flex>
                    </Center>
                    <Center display='flex'>
                        <Flex>
                            <Text as='u'>
                                Unsubscribe
                            </Text>
                        </Flex>
                    </Center>
                </Container>

            </Flex>
        
        </ChakraProvider>
    );
}

export default ResetPassword1;
